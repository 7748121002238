import React from "react";
import { useQuery } from "react-query";
import Logo from "../logo/Logo";
import { useNavigate } from "react-router-dom";

const fetchLogo = async () => {
  const response = await fetch("images/masterlogo.webp");
  const data = await response.blob();

  return URL.createObjectURL(data);
};

const LogoWrapper: React.FC = () => {
  const Navigate = useNavigate();

  const { data: logoSrc } = useQuery("logo", fetchLogo, {
    staleTime: 600000,
  });

  const handleLogoClick = () => {
    Navigate("/main")
  }

  return <Logo src={logoSrc} onClick={handleLogoClick}/>;
};

export default LogoWrapper;
