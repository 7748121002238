import React from "react";
import { Field, Form, Formik, useFormikContext } from "formik";
import styles from "./WithdrawPage.module.scss";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

const validationSchema = Yup.object().shape({
  cells: Yup.array().of(
    Yup.lazy((value) => {
      if (typeof value === "number") {
        return Yup.number().typeError("Must be a number").nullable();
      } else if (/^\d{10}$/.test(value)) {
        return Yup.string()
          .required("This field is required")
          .matches(/^\d{10}$/, "Invalid phone number");
      }
      return Yup.string().required("This field is required");
    })
  ),
});

const currencies = ["USD", "IQD"];

const handleCurrencyChange = (formik: any, selectedCurrency: string) => {
  formik.setFieldValue("currency", selectedCurrency);
};

const CurrencyToggleButtons = () => {
  const formik = useFormikContext();

  return (
    <div className={`mt-2 ml-2 p-2 ${styles["currency-toggle"]}`}>
      {currencies.map((currency) => (
        <button
          key={currency}
          type="button"
          onClick={() => handleCurrencyChange(formik, currency)}
          className={`${styles["currency-button"]} ${
            (formik.values as any).currency === currency
              ? styles["currency-selected"]
              : ""
          }`}
        >
          {currency}
        </button>
      ))}
    </div>
  );
};

const WithdrawPage = () => {
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={{
        cells: Array.from({ length: 7 * 4 }, (_, index) =>
          index === 0 ? "" : null
        ),
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        // Handle form submission
        console.log(values);
      }}
    >
      <Form>
        <div className="flex justify-center">
          <div className="grid grid-cols-2 gap-6 items-center mt-16">
            <p className="text-2xl font-bold text-center text-black">
              {t("Withdraw")}
            </p>
            <div className="p-6">
              <label
                htmlFor="receivedDate"
                className="font-bold ml-2 text-black mt-5"
              >
                {t("Currency")}
              </label>
              <CurrencyToggleButtons />
            </div>
          </div>
        </div>
        <div className="flex justify-center items-center">
          <div className="grid grid-cols-3 gap-y-4 p-6">
            <div className="p-6">
              <label htmlFor="cells.0" className="font-bold">
                {t("Withdraw By")}
              </label>
              <Field
                as="input"
                id="cells.0"
                name="cells.0"
                type="text"
                placeholder="Full Name"
                className="block w-full border border-gray-400 mt-2 p-2"
              />
            </div>
            <div className="p-6">
              <label htmlFor="cells.2" className="font-bold">
                {t("Phone Number")}
              </label>
              <Field
                as="input"
                id="cells.2"
                name="cells.2"
                type="number"
                placeholder="Phone No."
                className="block w-full border border-gray-400 mt-2 p-2"
              />
            </div>
            <div className="p-6">
              <label htmlFor="amount" className="font-bold">
                {t("Amount Of")}
              </label>
              <div className="flex items-center">
                <Field
                  as="input"
                  id="amount"
                  name="amount"
                  type="number"
                  placeholder="Deposit Amount"
                  className="block w-full border border-gray-400 mt-2 p-2"
                />
                <span className="ml-3 mt-6 font-bold">USD</span>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center items-center mt-8">
          <div className="flex space-x-4">
            <button className="text-white font-bold py-2 px-4 bg-gradient-to-b bg-gray-800 hover:from-blue-900 hover:to-blue-600 h-[58px] w-[180px] mr-3">
              {t("Cancel")}
            </button>
            <button className="text-white font-bold py-2 px-4 bg-gradient-to-b bg-gray-800 hover:from-blue-900 hover:to-blue-600 h-[58px] w-[180px]">
              {t("Withdraw")}
            </button>
          </div>
        </div>
      </Form>
    </Formik>
  );
};

export default WithdrawPage;
