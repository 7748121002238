/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import QRCodeComponent from "../../qrCode/QrCode";
import { useTranslation } from "react-i18next";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { fetchEmployeesAsync } from "../../../redux/employeeSlice";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../../redux/store";

interface RentalContractPrintProps {
  isCopy?: Boolean;
}

const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

const RentalContractPrint: React.FC<RentalContractPrintProps> = ({
  isCopy,
}) => {
  const dispatch: ThunkDispatch<RootState, any, any> = useDispatch();
  const [loading, setLoading] = useState(true);
  console.log(loading);
  const [currentDate, setCurrentDate] = useState("");
  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  const data = useSelector((state: any) => state);
  const contractData = data.rentalContract.contractData;
  const propertyNumbers = data.propertyNumber?.propertyNumbers;
  const customers = data.customer?.customers;
  const projects = data.project?.projects;
  const employees = useTypedSelector((state) => state.employee.employees);
  const profile = useTypedSelector((state) => state.profile.profile);

  const property_number_id = contractData?.property_number_id;
  const customer_id = contractData?.customer_id;
  const propertyOwner_id = contractData?.propertyOwner_id;
  console.log("propertyOwner_id", propertyOwner_id);
  const project_id = contractData?.project_id;
  const propertyOwner = customers.find(
    (customer: { id: any }) => customer?.id === propertyOwner_id
  );
  console.log("propertyOwner=1", propertyOwner);
  const propertyNumber = propertyNumbers.find(
    (propertyNumber: { id: any }) => propertyNumber?.id === property_number_id
  );
  const customer = customers.find(
    (customer: { id: any }) => customer?.id === customer_id
  );
  const project = projects.find(
    (project: { id: any }) => project?.id === project_id
  );

  function calculateContractDuration(
    contractEnding: any,
    contractBeginning: any
  ) {
    if (contractEnding && contractBeginning) {
      const endingDate = new Date(contractEnding);
      const beginningDate = new Date(contractBeginning);

      // Calculate the difference in milliseconds
      const timeDifference = endingDate.getTime() - beginningDate.getTime();

      // Convert milliseconds to days (1 day = 24 * 60 * 60 * 1000 milliseconds)
      const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

      return daysDifference;
    } else {
      return null;
    }
  }

  const [employeeName, setEmployeeName] = useState<string | null>(null);

  useEffect(() => {
    dispatch(fetchEmployeesAsync());
  }, [dispatch]);

  useEffect(() => {
    if (profile) {
      const employee = employees.find((emp) => emp?.user_id === profile?.id);
      if (employee) {
        setEmployeeName(employee.first_name + " " + employee.last_name);
      }
    }
  }, [profile, employees]);

  useEffect(() => {
    const getCurrentDate = () => {
      const date = new Date();
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      return `${day} / ${month} / ${year}`;
    };

    setCurrentDate(getCurrentDate());
  }, []);

  useEffect(() => {
    // Simulate loading time with a timeout
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 1000); // Set the timeout duration as needed

    return () => clearTimeout(timeoutId); // Cleanup on component unmount
  }, []);

  const [receiptNumberLocalStorage, setReceiptNumberLocalStorage] = useState(
    localStorage.getItem("receiptNumber") || ""
  );

  useEffect(() => {
    const handleStorageChange = () => {
      setReceiptNumberLocalStorage(localStorage.getItem("receiptNumber") || "");
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setReceiptNumberLocalStorage(localStorage.getItem("receiptNumber") || "");
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div className="flex items-center justify-center text-sm h-[90rem] -mt-[22.05rem]">
      {/* Content for the second column */}
      <div className="flex flex-col w-[870px] h-[700px] mt-6 mr-6 ml-6">
        {/* First Row */}
        <div
          className="bg-cover"
          style={{
            backgroundImage: 'url("/images/purchasingContractPrint.png")',
          }}
        >
          <div className="flex">
            {/* First Column */}
            <div className="flex-1 p-4 mt-5 ml-10">
              <div className="flex flex-row">
                <p className="text-white text-base mr-1">{currentDate} </p>
                <p className="text-white text-base">:{t("Date")}</p>
              </div>
              <p className="text-white text-base -ml-[0.1rem]">
                <span>{receiptNumberLocalStorage} :</span>
                {t("Contract No")}
              </p>
            </div>

            {/* Second Column */}
            <div className="flex-1 p-4">
              <p className="text-white">
                <img
                  src="/images/shkarRealEstateWhiteLogo.webp"
                  style={{ height: "80%", width: "80%" }}
                  className="mt-[15px]"
                />
              </p>
            </div>

            {/* Third Column */}
            <div className="flex-1 pt-4 pb-4 text-right mr-8 mt-4">
              <p className="text-white text-xl">{t("Sales Base")}</p>
              <p className="text-white text-xl">{t("Shkar Company")}</p>
            </div>
          </div>
        </div>
        {/* Second Row */}
        <div
          className="flex-grow p-4 bg-cover"
          style={{
            backgroundImage: 'url("/images/purchasingAndSellingContract.png")',
          }}
        >
          <div className="grid grid-rows-2 grid-cols-2 gap-4">
            <div className="-ml-[10rem] mt-2">
              <QRCodeComponent size={100} />
            </div>
            <div className="row-start-1 col-start-2 p-4 flex justify-center items-center">
              {/* Content for the first row */}
              <div className="grid grid-rows-2 -mt-10 justify-center items-center -ml-[22.3rem]">
                {/* First Row with One Column */}
                <div className="row-start-1 col-start-1">
                  {/* Content for the first row */}
                  <p
                    className="text-gray-646566 text-center text-base mt-[1.2rem]"
                    style={{ opacity: "7" }}
                  >
                    {t("Your Way for a Brighter Future")}
                  </p>
                </div>
                {/* Second Row */}
                <div
                  className="bg-cover h-[4.5rem] w-[21.5rem] -mt-6"
                  style={{
                    backgroundImage:
                      lang === "kr"
                        ? `url("/images/rentalContractLabel_kr.png")`
                        : `url("/images/rentalContractLabel_ar.png")`,
                  }}
                ></div>
              </div>
            </div>
          </div>
          {/* Third Row */}
          <div className="flex -mt-[9.3rem] -mb-6" dir="rtl">
            {/* First Column */}
            <div className="flex-1 mb-4 mt-4 mr-4">
              {/* Content for the first column */}
              <div className="grid grid-rows-5 grid-cols-1 gap-4">
                {/* Row 1 */}
                <div className="font-bold">{t("firstSidePropertyOwner")}</div>
                {/* Row 2 */}
                <div className="">
                  <div className="relative">
                    {/* Render the propertyOwner component here */}
                    <div className="absolute text-center top-0 left-6 w-full">
                      {propertyOwner?.firstName + " " + propertyOwner?.lastName}
                    </div>
                  </div>
                  <div className="text-black">
                    {lang === "ar" ? (
                      <>
                        {t("Full Name")}
                        :.................................................................
                      </>
                    ) : (
                      <>
                        {t("Full Name")}
                        :...................................................................
                      </>
                    )}
                  </div>
                </div>
                {/* Row 3 */}
                {/* <div className="">
                  <div className="relative">
                    <div className="absolute text-center top-0 left-10 w-full">
                      65654
                    </div>
                  </div>
                  <div className="text-black">
                    {lang === "ar" ? (
                      <>
                        {t("ID number")}
                        :...................................................................
                      </>
                    ) : (
                      <>
                        {t("ID number")}
                        :........................................................................
                      </>
                    )}
                  </div>
                </div> */}
                {/* Row 4 */}
                <div className="">
                  <div className="relative">
                    <div className="absolute text-center top-0 left-10 w-full">
                      {t("Iraqi") ?? t("Iraqi")}
                    </div>
                  </div>
                  <div className="text-black">
                    {lang === "ar" ? (
                      <>
                        {t("Nationality")}
                        :.........................................................................
                      </>
                    ) : (
                      <>
                        {t("Nationality")}
                        :.......................................................................
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* Second Column */}
            <div className="flex-1 mb-4 mt-4">
              {/* Content for the first column */}
              <div className="grid grid-rows-5 grid-cols-1 gap-4">
                {/* Row 1 */}
                <div className="font-bold">{t("secondSideTenant")}</div>
                {/* Row 2 */}
                <div className="">
                  <div className="relative">
                    {/* Render the customer component here */}
                    <div className="absolute text-center top-0 left-6 w-full">
                      {customer?.firstName + " " + customer?.lastName}
                    </div>
                  </div>
                  <div className="text-black">
                    {lang === "ar" ? (
                      <>
                        {t("Full Name")}
                        :.................................................................
                      </>
                    ) : (
                      <>
                        {t("Full Name")}
                        :...................................................................
                      </>
                    )}
                  </div>
                </div>
                {/* Row 3 */}
                {/* <div className="">
                  <div className="relative">
                    <div className="absolute text-center top-0 left-10 w-full">
                      65657
                    </div>
                  </div>
                  <div className="text-black">
                    {lang === "ar" ? (
                      <>
                        {t("ID number")}
                        :...................................................................
                      </>
                    ) : (
                      <>
                        {t("ID number")}
                        :........................................................................
                      </>
                    )}
                  </div>
                </div> */}
                {/* Row 4 */}
                <div className="">
                  <div className="relative">
                    <div className="absolute text-center top-0 left-10 w-full">
                      {t("Iraqi") ?? t("Iraqi")}
                    </div>
                  </div>
                  <div className="text-black">
                    {lang === "ar" ? (
                      <>
                        {t("Nationality")}
                        :.........................................................................
                      </>
                    ) : (
                      <>
                        {t("Nationality")}
                        :........................................................................
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="ml-4 mr-4 mt-4 pb-3" dir="rtl">
            <p className="font-bold">
              {t("Both parties have agreed upon the following points")}:
            </p>
          </div>
          <div
            className="grid grid-rows-13 grid-cols-1 gap-4 ml-1 mr-4"
            dir="rtl"
          >
            <div dir="rtl">
              <div className="leading-relaxed">
                1. {t("I am the First Party, the owner of the property, the")}{" "}
                ..
                {t(contractData?.propertyType)}.., {t("the number of")} ..
                {propertyNumber?.name + " "}.. {t("at")} ..
                {t(project?.name)}..
                {t("I rent to the Second Party from")} ..
                {contractData?.contractBeginning}.. {t("to")} ..
                {contractData?.contractEnding}
                .. {t("on monthly rent, and in return, I receive")} ..
                {contractData?.monthlyRentPayment}{" "}
                {contractData?.currency_id === 2 ? "دۆلار" : "دینار"}.. ..{" "}
                {t("as rent and amount of")} ..
                {contractData?.advancePayment}{" "}
                {contractData?.currency_id === 2 ? "دۆلار" : "دینار"}..
                {t("as advance payment for")} ..{contractData?.numberOfMonths}..{" "}
                {t("Month")}.
              </div>
            </div>
            {/* <div className="-mt-2">
              {t("Property Type")} ...{t(contractData?.propertyType)}...{" "}
              {t("Location")} .....
              {t(project?.name)} .... {t("Area")}....
              {contractData?.unitArea}م٢... {t("Unit No")} ...
              {contractData?.unitArea}...
            </div> */}
            {/* Row 2 */}
            <div>
              2. {t("I, the Second Party, have rented the property")} ..
              {t(contractData?.propertyType) + " "}..م{" "}
              {t("from the First Party for the")} ..
              {calculateContractDuration(
                contractData?.contractEnding,
                contractData?.contractBeginning
              )}
              .. {t("Day")}.
            </div>
            {/* Row 3 */}
            <div>
              3. {t("I, the Second Party, am obliged to pay the rent for")} ..
              {contractData?.numberOfMonths}.. {t("Month")} {t("in advance")}.
            </div>
            {/* Row 4 */}
            <div className="leading-relaxed">
              4. {t("The First Party is obliged to hand over")} ..{" "}
              {t(contractData?.propertyType) + " "}..م{" "}
              {t("without any defects from")} {contractData?.contractEnding}{" "}
              {t(
                "otherwise, he must pay a fine to the Second Party in the amount of"
              )}{" "}
              ..{contractData?.handoverPenalty}.
              {contractData?.currency_id === 2 ? "دۆلار" : "دینار"}..{" "}
              {t("for each day of delay")}.
            </div>
            {/* <div>
              {lang == "kr" ? (
                <>
                  {t("The amount of deposit that")} ...
                  {contractData?.receivedAmount + " "}
                  {contractData?.currency_id === 1 ? "دۆلار" : "دینار"}... ،{" "}
                  {t("on the date of")} {contractData?.remainDate}{" "}
                  {t("the remainder shall be paid whose amount is")} ...
                  {contractData?.remainPayment + " "}
                  {contractData?.currency_id === 1 ? "دۆلار" : "دینار"}...
                </>
              ) : (
                <>
                  {t("After receiving the amount of")}{" "}
                  {contractData?.advancePayment + " "}
                  {contractData?.currency_id === 1 ? "دۆلار" : "دینار"}{" "}
                  {t(
                    "The remaining amount of the selling price, which amounts to"
                  )}{" "}
                  {contractData?.remainPayment + " "}
                  {contractData?.currency_id === 1 ? "دۆلار" : "دینار"}{" "}
                  {t("on the date")} {contractData?.remainDate ?? "1-1-2024"}
                </>
              )}
            </div> */}
            {/* Row 5 */}
            <div className="leading-relaxed">
              5.{" "}
              {t(
                "The Second Party is obliged to pay the rent on the specified date, if paying the rent is delayed for five days or more, it must pay a fine of"
              )}{" "}
              ..{contractData?.latePenalty}..
              {contractData?.currency_id === 2 ? "دۆلار" : "دینار"}..{" "}
              {t(
                "to the First Party for each day of delay, in addition to the monthly rent"
              )}
              .
            </div>
            {/* Row 6 */}
            <div className="mt-0">
              {lang === "kr" ? (
                <>
                  {" "}
                  6.{" "}
                  {t(
                    "After the expiration of the contract period or when vacating"
                  )}{" "}
                  ..{t(contractData?.propertyType)}..{" "}
                  {t("the Second Party must hand over")} ..
                  {t(contractData?.propertyType)}..{" "}
                  {t("to the First Party without any defects")}.
                </>
              ) : null}
            </div>
            <div className="mb-16"></div>
          </div>

          <div
            className={`flex justify-center items-center ${lang === "kr" ? "-mb-14" : ""}`}
          >
            <div className="flex" dir="rtl">
              <div className="p-4">
                <p> {t("First Party")}</p>
                <div className="text-center">
                  {propertyOwner?.firstName + " " + propertyOwner?.lastName}
                </div>
              </div>
              <div className="p-4 mr-24">
                <p> {t("Contract Organizer")}</p>
                <div className="text-center">
                  {employeeName ?? profile?.name}
                </div>
              </div>
              <div className="p-4 mr-24">
                <p> {t("Second Party")}</p>
                <div className="text-center">
                  {customer?.firstName + " " + customer?.lastName}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Fourth Row */}
        <div
          className="grid grid-cols-2 grid-rows-2 h-20 mt-14"
          style={{
            backgroundImage: 'url("/images/receiveRecieptFooter.png")',
          }}
        >
          <div className="flex items-center ml-[4.4rem]">
            <img
              src="/icons/email.png"
              alt="Logo"
              style={{ maxWidth: "35%", height: "35%" }}
              className="mr-2"
            />
            <div
              className="text-white mt-[3px]"
              style={{ letterSpacing: "1px" }}
            >
              INFO@SHKARREALESTATE.NET
            </div>
          </div>
          <div className="flex items-center ml-24">
            <img
              src="/icons/whatsUp.png"
              alt="Logo"
              style={{ maxWidth: "45%", height: "45%" }}
              className="mr-2"
            />
            <div
              className="text-white mt-[3px]"
              style={{ letterSpacing: "0.5px" }}
            >
              0750 300 33 66 - 0750 300 66 99
            </div>
          </div>
          <div className="flex items-center ml-[4.4rem]">
            <img
              src="/icons/web.png"
              alt="Logo"
              style={{ maxWidth: "45%", height: "45%" }}
              className="mr-2"
            />
            <div
              className="text-white mt-[3px]"
              style={{ letterSpacing: "1.1px" }}
            >
              WWW.SHKARREALESTATE.NET
            </div>
          </div>
          <div className="flex items-center ml-24">
            <img
              src="/icons/address.png"
              alt="Logo"
              style={{ maxWidth: "45%", height: "45%" }}
              className="mr-3"
            />
            <div
              className="text-white mt-[3px]"
              style={{ letterSpacing: "1.2px" }}
            >
              IRAQ - ERBIL - WAVEY AVENUE
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RentalContractPrint;
