import React from "react";
import { useSelector } from "react-redux";
import { selectUnitTypeById } from "../../redux/unitTypeSlice";

interface Receipt {
  id: number;
  unitType_id: number;
}

interface Props {
  receipt: Receipt;
}

const UnitTypeDetails: React.FC<Props> = ({ receipt }) => {
  const unitType = useSelector((state: { unitType: any }) =>
    selectUnitTypeById(receipt.unitType_id)(state)
  );
  return (
    <td className="px-6 py-4 whitespace-nowrap text-right" dir="ltr">
      {unitType?.name}
    </td>
  );
};

export default UnitTypeDetails;
