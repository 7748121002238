import React from "react";
import LogoWrapper from "../logoWrapper/LogoWrapper";
import LanguageButtons from "../languageButtons/LanguageButtons";
import "./Header.scss";
import UserDropdown from "../../userDropdown/UserDropdownComponent";
import DropdownNotification from "../../dropdownNotification/DropdownNotification";

interface HeaderProps {
  showLogo: boolean;
  isAuthPage: boolean;
}

const Header: React.FC<HeaderProps> = ({ showLogo, isAuthPage }) => {
  return (
    <header className="flex justify-between items-center mb-4">
      {showLogo && <LogoWrapper />}
      {isAuthPage ? (
        <>
          <div className="-mb-9 ml-2">
            <DropdownNotification />
          </div>
          <div className="-mb-7 mr-1 ml-2">
            <LanguageButtons isAuthPage={isAuthPage} />
          </div>
          <div className="-mb-5 -ml-1">
            <UserDropdown />
          </div>
        </>
      ) : (
        <>
          <LanguageButtons isAuthPage={!isAuthPage} />
          <div></div>
        </>
      )}
    </header>
  );
};

export default Header;
