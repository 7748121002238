import React from 'react'

const NextArrow: React.FC = (props) => {
  return (
    // eslint-disable-next-line jsx-a11y/alt-text
    <img
      {...props}
      className="slick-arrow"
      style={{ right: "28px", zIndex: 1, fontSize: "24px", position: "absolute", top: "44%" }}
      src="/images/nextArrow.webp"
    />
  );
};

export default NextArrow