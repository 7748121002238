import React, { useCallback, useEffect, useState } from "react";
import QRCodeComponent from "../../qrCode/QrCode";
import { FaXTwitter } from "react-icons/fa6";
import { TypedUseSelectorHook, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { RootState } from "../../../redux/store";

interface ReceivePrintProps {
  isCopy?: Boolean;
  title: string;
}

const ReceivePrint: React.FC<ReceivePrintProps> = ({ isCopy, title }) => {
  const [, setLoading] = useState(true);
  const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

  const [currentDate, setCurrentDate] = useState<{
    day: number;
    month: number;
    year: number;
  } | null>(null);
  const [currentTime, setCurrentTime] = useState<{
    hours: number;
    minutes: number;
    seconds: number;
  } | null>(null);

  const { t } = useTranslation();

  const dataReducers = [
    "receiveCommissionReceipt",
    "receiveReceipt",
    "rentReceiveReceipt",
    "insuranceReceiveReceipt",
  ];

  const data = useSelector((state: any) => state);

  let selectedData: any = null;

  if (data) {
    console.log("data", data);
    for (const reducer of dataReducers) {
      const reducerData = data[reducer];

      console.log("reducerData", reducerData);

      if (reducerData && reducerData[`${reducer}Data`]) {
        selectedData = reducerData[`${reducer}Data`];
        console.log("selectedData", selectedData);
        break;
      }
    }
  }

  const customers = useTypedSelector((state) => state.customer.customers);
  const propertyNumbers = useTypedSelector(
    (state) => state.propertyNumber.propertyNumbers
  );
  const profile = useTypedSelector((state) => state.profile.profile);
  const projects = useTypedSelector((state) => state.project.projects);

  const employees = useTypedSelector((state) => state.employee.employees);

  const findEmployeeByUserId = useCallback(
    (employees: any[], userId: any) => {
      console.log("employees", employees, "userId", userId);
      return employees.find(
        (employee: { user_id: any }) => employee.user_id === userId
      );
    },
    [] // Empty dependency array means this function is stable and won't change
  );
  const employee = findEmployeeByUserId(employees, profile?.id);

  const customer = customers.find(
    (customer) => customer.id === selectedData.customer_id
  );
  const propertyNumber = propertyNumbers.find(
    (propertyNumber) => propertyNumber.id === selectedData.property_number_id
  );
  const project = projects.find(
    (project) => project.id === selectedData.project_id
  );

  const amountOf = selectedData?.amountOf;
  const currency_id = selectedData?.currency_id;
  const amountInWords = selectedData?.amountInWords;
  const startDate = selectedData?.startDate;
  const endDate = selectedData?.endDate;

  useEffect(() => {
    const getCurrentDate = () => {
      const date = new Date();
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      return { day, month, year };
    };

    const getCurrentTime = () => {
      const date = new Date();
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const seconds = date.getSeconds();

      return { hours, minutes, seconds };
    };

    setCurrentDate(getCurrentDate());
    setCurrentTime(getCurrentTime());
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 10000);

    return () => clearTimeout(timeoutId);
  }, []);

  const [receiptNumberLocalStorage, setReceiptNumberLocalStorage] = useState(
    localStorage.getItem("receiptNumber") || ""
  );

  useEffect(() => {
    const handleStorageChange = () => {
      setReceiptNumberLocalStorage(localStorage.getItem("receiptNumber") || "");
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setReceiptNumberLocalStorage(localStorage.getItem("receiptNumber") || "");
    }, 300);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div
      className="flex items-center justify-center mb-[9.4rem]"
      style={{ direction: "ltr" }}
    >
      {/* Content for the second column */}
      <div className="flex flex-col w-[734.99999999999999999999999999999999999999999px] h-[410px] mt-6">
        {/* First Row */}
        <div
          className="bg-cover"
          style={{
            backgroundImage: 'url("/images/receiveRecieptHeader.png")',
          }}
        >
          <div className="flex -mt-6">
            {/* First Column */}
            <div className="flex-1 p-4 mt-5 ml-10">
              <p className="text-white text-base -ml-[0.5rem]">
                {/* <span>{receiptNumber?.toString().padStart(5, "0")} :</span> */}
                <span>{receiptNumberLocalStorage} :</span>
                ژمارەی پسوولە
              </p>
              <div className="flex flex-row">
                {currentDate && (
                  <>
                    <p className="text-white text-base mt-[2px] mr-1">
                      {currentDate.day}/{currentDate.month}/{currentDate.year}
                    </p>
                    <p className="text-white text-base">:بەروار</p>
                  </>
                )}
              </div>
            </div>
            {/* Second Column */}
            <div className="flex-1 p-4">
              <p className="text-white">
                <img
                  src="/images/shkarRealEstateWhiteLogo.webp"
                  alt="Shkar Real Estate White Logo"
                  style={{ height: "60%", width: "60%" }}
                  className="mt-6 ml-6"
                />
              </p>
            </div>

            {/* Third Column */}
            <div className="flex-1 pt-4 pb-4 text-right mr-8 mt-4">
              <p className="text-white text-xl">بنکەی فرۆشتنی</p>
              <p className="text-white text-xl">کۆمپانیای شکار</p>
            </div>
          </div>
        </div>

        {/* Second Row */}
        <div
          className="flex-grow p-4 bg-cover"
          style={{ backgroundImage: 'url("/images/recieveReceipt.png")' }}
        >
          <div className="grid grid-rows-2 gap-4">
            {/* First Row */}
            <div className="row-start-1 col-start-1 p-4">
              {/* Content for the first row */}
              <div className="grid grid-rows-2 -mt-10 justify-center items-center">
                {/* First Row with One Column */}
                <div className="row-start-1 col-start-1">
                  {/* Content for the first row */}
                  <p className="text-gray-646566 text-center text-base mt-[10px]">
                    دەرگای تۆ بۆ داهاتوویەکی ڕووناکتر
                  </p>
                </div>
                {/* Second Row */}
                <div
                  className="bg-cover h-[4.6rem] w-[21.3rem] -mt-7"
                  style={{
                    backgroundImage: 'url("/images/Receive Receipt 02.png")',
                  }}
                ></div>
                <div className="flex flex-row gap-2 justify-center">
                  <span className="text-gray-500 mt-1">
                    {" "}
                    {isCopy ? "کۆپی" : ""}
                  </span>
                  <p className="text-center mt-1">{t(title)} </p>
                </div>
              </div>
            </div>

            {/* Second Row */}
            <div className="row-start-2 col-start-1 p-4">
              {/* Content for the second row */}
              <div className="grid grid-cols-2 justify-center items-center -mt-[4.7rem]">
                {/* First Column */}
                <div
                  className={`col-span-1 -ml-48 ${title === "Rent" || title === "دڵنیایی (تأمينات)" ? "-translate-y-[0.80rem]" : "-translate-y-[1.95rem]"}`}
                >
                  <QRCodeComponent size={100} />
                  <div
                    className={`grid grid-rows-2 gap-2 justify-center items-center mt-4 ${title === "Rent" || title === "دڵنیایی (تأمينات)" ? "-translate-y-1" : "-translate-y-3"}`}
                  >
                    {/* First Row */}
                    <div className="row-start-1 col-start-1 p-2 text-center">
                      {/* Content for the first row */}
                      <div className="flex items-center justify-center">
                        <img
                          src="/icons/instagram.png"
                          alt="loading..."
                          className="h-5 w-[1.35rem] mr-[5px]"
                        />
                        <img
                          src="/icons/tiktok.png"
                          alt="loading..."
                          className="h-4 w-[1.1rem] mr-[5px]"
                        />
                        <img
                          src="/icons/snap.png"
                          alt="loading..."
                          className="h-4 w-[1.1rem] mr-[5px]"
                        />
                        <div className="mr-[5px]">
                          <FaXTwitter />
                        </div>
                        <img
                          src="/icons/youtube.png"
                          alt="loading..."
                          className="h-4 w-[1.1rem]"
                        />
                      </div>
                    </div>
                    <div className="row-start-2 col-start-1 p-2 text-center -mt-8">
                      <h3 className="italic">shkar.realestate</h3>
                    </div>
                  </div>
                </div>
                <span
                  className="inline-block border border-gray-300 text-gray-800 font-light py-1 text-center -mt-[23.6rem]"
                  style={{ marginLeft: "10rem" }}
                >
                  <span>{currency_id === 1 ? "IQD" : "$"}</span> {amountOf}
                </span>
                <div
                  className="col-span-2 mt-[1.5rem] -mr-8"
                  style={{ fontSize: "18px" }}
                >
                  <div className="flex space-x-4 -mt-60 ml-[10rem]">
                    <div className="mt-4">
                      <h2 style={{ letterSpacing: "0.5px" }}>Receipt from :</h2>
                    </div>
                    <div className="mt-4 w-[14.8rem]">
                      <div style={{ position: "relative" }}>
                        <div
                          className="dots"
                          style={{
                            position: "absolute",
                            top: -1,
                            left: -10,
                            zIndex: 1,
                            opacity: 0.15,
                          }}
                        >
                          ............................................................
                        </div>
                        <div style={{ position: "relative" }}>
                          <p className="text-center text-base text-black">
                            {customer?.firstName +
                              // " " +
                              // customer?.middleName +
                              " " +
                              customer?.lastName}
                          </p>
                        </div>
                      </div>
                    </div>

                    {/* Item 3 */}
                    <div>
                      <div className="text-center">
                        {/* Content for the fourth column */}
                        <p className="mt-4" style={{ letterSpacing: "0.5px" }}>
                          : وەرگیرا لە بەڕێز
                        </p>
                        <p style={{ fontSize: "9px" }}>
                          استلمت من السيد / السيدة
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flex space-x-4 ml-[10rem] mt-[9px]">
                    {/* Item 1 */}
                    <div>
                      <h2 style={{ letterSpacing: "0.5px" }}>Amount :</h2>
                    </div>

                    {/* Item 2 */}
                    <div className="w-[20.7rem]">
                      <div style={{ position: "relative" }}>
                        {/* Display dots */}
                        <div
                          className="dots"
                          style={{
                            position: "absolute",
                            top: -0.8,
                            left: -10,
                            zIndex: 1,
                            opacity: 0.15,
                          }}
                        >
                          .................................................................................
                        </div>
                        <div style={{ position: "relative" }}>
                          <p className="text-center text-base text-black">
                            {/* <span>{currency_id === 1 ? "IQD" : "$"}</span> */}
                            <span dir="rtl">
                              {currency_id === 1 ? " دینار" : " دۆلار"}
                            </span>{" "}
                            <span>{amountInWords}</span>
                            {/* <span className="mr-4">{amountOf}</span> */}
                          </p>
                        </div>
                      </div>
                    </div>

                    {/* Item 3 */}
                    <div>
                      <div className="text-center">
                        {/* Content for the fourth column */}
                        <p style={{ letterSpacing: "0.5px" }}>: بڕی پارە</p>
                        <p style={{ fontSize: "9px" }}>مبلغ قدره</p>
                      </div>
                    </div>
                  </div>
                  <div className="flex space-x-4 ml-[10rem] mt-3">
                    {/* Item 1 */}
                    <div>
                      <h2 style={{ letterSpacing: "0.5px" }}>For :</h2>
                    </div>

                    {/* Item 2 */}
                    <div className="w-[24.2rem]">
                      <div style={{ position: "relative" }}>
                        {/* Display dots */}
                        <div
                          className="dots"
                          style={{
                            position: "absolute",
                            top: -0.8,
                            left: -10,
                            zIndex: 1,
                            opacity: 0.15,
                          }}
                        >
                          ..............................................................................................
                        </div>
                        <div style={{ position: "relative" }}>
                          <p className="text-center text-base text-black">
                            {project?.name +
                              "\u00A0\u00A0" +
                              "لە" +
                              "\u00A0\u00A0\u00A0" +
                              propertyNumber?.name +
                              "\u00A0\u00A0" +
                              "ژمارەی موڵکی"}{" "}
                            {title === "Commission" ? (
                              <span dir="rtl">
                                {selectedData?.category_id === 2
                                  ? "کرێی"
                                  : selectedData?.category_id === 3
                                    ? "کڕینی"
                                    : "فرۆشتنی"}
                              </span>
                            ) : (
                              ""
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="text-center">
                        <p style={{ letterSpacing: "0.5px" }}>: لەبڕی</p>
                        <p style={{ fontSize: "9px" }}>و ذالك عن</p>
                      </div>
                    </div>
                  </div>
                  {title === "دڵنیایی (تأمينات)" || title === "Rent" ? (
                    <div className="flex space-x-4 ml-[10.7rem] mt-1">
                      {/* Item 2 */}
                      <div className="w-[24.2rem]">
                        <div style={{ position: "relative" }}>
                          {/* Display dots */}
                          <div
                            className="dots"
                            style={{
                              position: "absolute",
                              top: -0.8,
                              left: -10,
                              zIndex: 1,
                              opacity: 0.15,
                            }}
                          >
                            ......................................................................................................................
                          </div>
                          <div style={{ position: "relative" }}>
                            <p className="text-right text-base text-black -mr-12">
                              {"لە بەرواری" +
                                "\u00A0\u00A0" +
                                startDate +
                                "\u00A0\u00A0\u00A0" +
                                "بۆ بەرواری" +
                                "\u00A0\u00A0" +
                                endDate +
                                "\u00A0\u00A0\u00A0"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <div className="flex space-x-4 ml-[10rem] mt-3">
                    {/* Item 1 */}
                    <div>
                      <h2 style={{ letterSpacing: "0.5px" }}>Receptor :</h2>
                    </div>

                    {/* Item 2 */}
                    <div className="w-[21.5rem]">
                      <div style={{ position: "relative" }}>
                        {/* Display dots */}
                        <div
                          className="dots"
                          style={{
                            position: "absolute",
                            top: -0.8,
                            left: -10,
                            zIndex: 1,
                            opacity: 0.15,
                          }}
                        >
                          ....................................................................................
                        </div>
                        <div style={{ position: "relative" }}>
                          <p className="text-center text-base text-black -mr-20">
                            {profile?.name}
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* Item 3 */}
                    <div>
                      <div className="text-center">
                        {/* Content for the fourth column */}
                        <p style={{ letterSpacing: "0.5px" }}>: وەرگر</p>
                        <p style={{ fontSize: "9px" }}>المستلم</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`flex flex-row -mt-6 ml-[39px] ${title === "Rent" || title === "دڵنیایی (تأمينات)" ? "-translate-y-[2.02rem]" : "-translate-y-[3.85rem]"}`}
          >
            {currentTime && (
              <>
                <p className="text-black text-base mt-[1px] mr-1">Time : </p>
              </>
            )}
            <p className="text-black text-base mt-[2px]">
              {currentTime?.hours}:{currentTime?.minutes}:{currentTime?.seconds}
            </p>
          </div>
        </div>
        {/* Third Row */}
        <div
          className="grid grid-cols-2 grid-rows-2 h-16 -mt-4"
          style={{
            backgroundImage: 'url("/images/receiveRecieptFooter.png")',
          }}
        >
          <div className="flex items-center ml-[4.4rem]">
            <img
              src="/icons/email.png"
              alt="Logo"
              style={{ maxWidth: "35%", height: "35%" }}
              className="mr-2"
            />
            <div
              className="text-white mt-[3px]"
              style={{ letterSpacing: "1px" }}
            >
              INFO@SHKARREALESTATE.NET
            </div>
          </div>
          <div className="flex items-center ml-24">
            <img
              src="/icons/whatsUp.png"
              alt="Logo"
              style={{ maxWidth: "45%", height: "45%" }}
              className="mr-2"
            />
            <div
              className="text-white mt-[3px]"
              style={{ letterSpacing: "0.5px" }}
            >
              {employee?.branch?.phone_number_1} -{" "}
              {employee?.branch?.phone_number_2}
            </div>
          </div>
          <div className="flex items-center ml-[4.4rem]">
            <img
              src="/icons/web.png"
              alt="Logo"
              style={{ maxWidth: "45%", height: "45%" }}
              className="mr-2"
            />
            <div
              className="text-white mt-[3px]"
              style={{ letterSpacing: "1.1px" }}
            >
              WWW.SHKARREALESTATE.NET
            </div>
          </div>
          <div className="flex items-center ml-24">
            <img
              src="/icons/address.png"
              alt="Logo"
              style={{ maxWidth: "45%", height: "45%" }}
              className="mr-3"
            />
            <div
              className="text-white mt-[3px]"
              style={{ letterSpacing: "1.2px" }}
            >
              IRAQ - ERBIL - {employee?.branch?.name}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReceivePrint;
