import { useTranslation } from "react-i18next";

const useTranslatedOptions = () => {
  const { t } = useTranslation();

  const commercialOptions: string[] = [t("SHOP"), t("FLOOR"), t("BUILDING")];
  const residentialOptions = [t("APARTMENT"), t("HOUSE")];
  const rentalOptions = [t("COMMERCIAL"), t("RESIDENTIAL")];
  const advertisingPrintingHospitalityOptions: string[] = [
    t("ADVERTISING"),
    t("PUBLISHING AND PRINTING"),
    t("CELEBRATIONS"),
    t("EXHIBITION EXPENSES"),
    t("HOSPITALITY"),
  ];
  const miscellaneousOptions: string[] = [
    t("SUPPLIES AND EQUIPMENT"),
    t("STATIONERY"),
    t("WASTE AND CONSUMABLES"),
  ];
  const miscellaneousServiceExpensesOptions: string[] = [
    t("LEGAL SERVICES"),
    t("BANKING SERVICES"),
    t("TRAINING AND QUALIFICATION"),
  ];
  const fuelAndOilsOptions: string[] = [
    t("OILS AND GREASES"),
    t("PETROLEUM MATERIALS"),
    t("GAS"),
  ];
  const transportationDispatchCommunicationsOptions: string[] = [
    t("TRANSPORTING WORKERS"),
    t("TRANSPORT OF GOODS AND MERCHANDISE"),
    t("TRAVEL AND DELEGATION"),
    t("PUBLIC COMMUNICATIONS"),
  ];
  const currencies = ["USD", "IQD"];
  const categories = [
    { value: "", label: `${t("Select a Category")}` },
    { value: "commission", label: `${t("Commission")}` },
    { value: "rents", label: `${t("Rents")}` },
    { value: "trust", label: `${t("Trust")}` },
    { value: "Renting Fixed Assets", label: `${t("Renting Fixed Assets")}` },
    { value: "uses", label: `${t("Uses")}` },
    { value: "waterAndElectricity", label: `${t("Water And Electricity")}` },
    {
      value: "Advertising Printing Hospitality",
      label: `${t("Advertising, Printing And Hospitality")}`,
    },
    { value: "miscellaneous", label: `${t("Miscellaneous")}` },
    {
      value: "miscellaneousServiceExpenses",
      label: `${t("Miscellaneous Service Expenses")}`,
    },
    { value: "fuelAndOils", label: `${t("Fuel & Oils")}` },
    {
      value: "transportationDispatchCommunications",
      label: `${t("Transportation, Dispatch And Communications")}`,
    },
    { value: "loans", label: `${t("Loans")}` },
    { value: "taxesAndFees", label: `${t("Taxes And Fees")}` },
  ];

  const categoriesOfInsurance = [
    { value: "", label: `${t("Select a Category")}` },
    { id: 1, value: "insurance", label: `${t("Insurance")}` },
  ];

  const categoriesOfReceive = [
    { id: 0, value: "", label: `${t("Select a Category")}` },
    { id: 1, value: "commission", label: `${t("Commission")}` },
    { id: 2, value: "araboon", label: `${t("Araboon")}` },
    { id: 3, value: "purchase", label: `${t("Purchase")}` },
    { id: 4, value: "sales", label: `${t("Sales")}` },
    { id: 5, value: "rents", label: `${t("Rents")}` },
    { id: 6, value: "trust", label: `${t("Trust")}` },
  ];

  const categoriesOfSpend = [
    { id: 1, value: "", label: `${t("Select a Category")}` },
    { id: 2, value: "araboon", label: `${t("Araboon")}` },
    { id: 3, value: "purchase", label: `${t("Purchase")}` },
    { id: 4, value: "sales", label: `${t("Sales")}` },
    { id: 5, value: "rents", label: `${t("Rents")}` },
    { id: 6, value: "trust", label: `${t("Trust")}` },
    {
      id: 9,
      value: "waterAndElectricity",
      label: `${t("Water And Electricity")}`,
    },
    {
      id: 10,
      value: "Advertising Printing",
      label: `${t("Advertising, Printing")}`,
    },
    { id: 15, value: "loans", label: `${t("Loans")}` },
    { id: 16, value: "taxesAndFees", label: `${t("Taxes And Fees")}` },
  ];

  const categoriesOfRent = [
    { id: 1, value: "", label: `${t("Select a Category")}` },
    { id: 2, value: "rents", label: `${t("Rents")}` },
  ];

  const categoriesOfCommission = [
    { id: 1, value: "", label: `${t("Select a Category")}` },
    { id: 2, value: "rental", label: `${t("Rental")}` },
    { id: 3, value: "purchase", label: `${t("Purchase")}` },
    { id: 4, value: "sales", label: `${t("Sales")}` },
  ];

  const countryOptions = [
    { value: "iraq", label: "Iraq" },
    { value: "turkey", label: "Turkey" },
    { value: "kurdistan", label: "Kurdistan" },
  ];

  const cityOptions = [
    { value: "erbil", label: "Erbil" },
    { value: "duhok", label: "Duhok" },
  ];

  return {
    commercialOptions,
    residentialOptions,
    rentalOptions,
    advertisingPrintingHospitalityOptions,
    miscellaneousOptions,
    miscellaneousServiceExpensesOptions,
    fuelAndOilsOptions,
    transportationDispatchCommunicationsOptions,
    categoriesOfCommission,
    currencies,
    categories,
    countryOptions,
    cityOptions,
    categoriesOfRent,
    categoriesOfReceive,
    categoriesOfSpend,
    categoriesOfInsurance,
  };
};

export default useTranslatedOptions;
