import React from "react";
import { FaFacebook, FaTwitter, FaLinkedin, FaInstagram } from "react-icons/fa";

const SocialIcons: React.FC = () => {
  const iconStyle = {
    backgroundColor: "#333333",
    borderRadius: "50%",
    padding: "8px", // Adjust the padding as needed
    marginRight: "1rem", // Adjust the margin to add space between icons
  };

  return (
    <div className="flex">
      <div style={iconStyle}>
        <FaFacebook size={29} className="text-white" />
      </div>
      <div style={iconStyle}>
        <FaInstagram size={29} className="text-white" />
      </div>
      <div style={iconStyle}>
        <FaTwitter size={29} className="text-white" />
      </div>
      <div style={iconStyle}>
        <FaLinkedin size={29} className="text-white" />
      </div>
    </div>
  );
};

export default SocialIcons;
