import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
// import axios from "../services/api/api";

interface Currency {
  id: number;
  name: string;
  code: string;
  symbol: string;
}

const currencies: Currency[] = [
  {
    id: 1,
    name: "IQD",
    code: "TTD",
    symbol: "IQD",
  },
  {
    id: 2,
    name: "USD",
    code: "TTD",
    symbol: "$",
  },
];

interface CurrencyState {
  currencies: Currency[];
  loading: "idle" | "pending" | "succeeded" | "failed";
  error: string | null;
}

const initialState: CurrencyState = {
  currencies: [],
  loading: "idle",
  error: null,
};

export const fetchCurrenciesAsync = createAsyncThunk(
  "currency/fetchCurrencies",
  async () => {
    // const response = await axios.get("/currencies");
    // return response.data.data.slice(0, 2) as Currency[];
    return currencies as Currency[];
  }
);

const currencySlice = createSlice({
  name: "currency",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCurrenciesAsync.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(
        fetchCurrenciesAsync.fulfilled,
        (state, action: PayloadAction<Currency[]>) => {
          state.currencies = action.payload;
          state.loading = "succeeded";
          state.error = null;
        }
      )
      .addCase(fetchCurrenciesAsync.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.error.message ?? "An error occurred";
      });
  },
});

export default currencySlice.reducer;
