import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "../services/api/api";

interface RentalSpendReceiptState {
  rentalSpendReceiptData: RentalSpendReceiptData | null;
  allSpendRentalReceipts: RentalSpendReceiptData[] | null;
  loading: boolean;
  error: string | null;
}

interface RentalSpendReceiptData {
  customer_id: number;
  receiptNumber: number;
  amountOf: string;
  amountInWords: string;
  receiver: string;
  employee_id: number;
  category_id: number;
  unitType_id: number;
  real_estate_id: number;
  spender: string;
  currency_id: number;
  property_number_id: number;
  startDate: Date;
  endDate: Date;
  categoryUnitType: string;
  project_id: number;
  radioOption: string;
  rentingFixedAssets: string;
  note: string;
}

const initialState: RentalSpendReceiptState = {
  rentalSpendReceiptData: null,
  allSpendRentalReceipts: null,
  loading: false,
  error: null,
};

export const sendRentalSpendReceiptData = createAsyncThunk(
  "rentalSpendReceipt/sendRentalSpendReceiptData",
  async (data: RentalSpendReceiptData, thunkAPI) => {
    try {
      const response = await axios.post("/spendRentalReceipt", data);
      return response.data.data;
    } catch (error: unknown) {
      if (typeof error === "object" && error && "response" in error) {
        return thunkAPI.rejectWithValue((error as any).response.data);
      } else {
        return thunkAPI.rejectWithValue("Unknown error occurred");
      }
    }
  }
);

export const getAllSpendRentalReceipts = createAsyncThunk(
  "spendRentalReceipt/getAllSpendRentalReceipts",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get("/spendRentalReceipt");
      return response.data.data;
    } catch (error: unknown) {
      console.error("Error fetching all rental spend receipts:", error);
      return thunkAPI.rejectWithValue(
        "Error fetching all rental spend receipts"
      );
    }
  }
);

export const getAllSpendRentalReceiptsByUserId = createAsyncThunk(
  "spendRentalReceiptByUserId/getAllSpendRentalReceiptsByUserId",
  async (userId: number, thunkAPI) => {
    try {
      const response = await axios.get(`/spendRentalReceipt/user/${userId}`);
      const data = response.data.data;

      if (data.length === 0) {
        return thunkAPI.rejectWithValue({
          message: "No Rental receipts found for this user.",
          data: [],
        });
      }

      return data;
    } catch (error: unknown) {
      console.error("Error fetching Rent receipts by user ID:", error);
      return thunkAPI.rejectWithValue(
        "Error fetching Rental receipts by user ID"
      );
    }
  }
);

export const RESET_RENTAL_SPEND_RECEIPT_DATA =
  "RESET_RENTAL_SPEND_RECEIPT_DATA";

const rentalSpendReceiptSlice = createSlice({
  name: "rentalSpendReceipt",
  initialState,
  reducers: {
    rentalSpendReceipt: (
      state,
      action: PayloadAction<RentalSpendReceiptData>
    ) => {
      state.rentalSpendReceiptData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(sendRentalSpendReceiptData.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(sendRentalSpendReceiptData.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      // state.rentalSpendReceiptData = action.payload;
    });
    builder.addCase(sendRentalSpendReceiptData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(getAllSpendRentalReceipts.pending, (state) => {});
    builder.addCase(getAllSpendRentalReceipts.fulfilled, (state, action) => {
      state.allSpendRentalReceipts = action.payload;
    });
    builder.addCase(getAllSpendRentalReceipts.rejected, (state, action) => {});
    builder.addCase(RESET_RENTAL_SPEND_RECEIPT_DATA, (state) => {
      state.rentalSpendReceiptData = null;
    });
    builder.addCase(getAllSpendRentalReceiptsByUserId.pending, (state) => {});
    builder.addCase(
      getAllSpendRentalReceiptsByUserId.fulfilled,
      (state, action) => {
        console.log("Spend rental receipts by user ID:", action.payload);
        state.allSpendRentalReceipts = action.payload;
      }
    );
    builder.addCase(
      getAllSpendRentalReceiptsByUserId.rejected,
      (state, action) => {
        if (
          action.payload &&
          typeof action.payload === "object" &&
          "message" in action.payload
        ) {
          console.warn(action.payload.message);
          state.allSpendRentalReceipts = [];
        } else {
          console.error(
            "Error fetching insurance receipts by user ID:",
            action.payload
          );
          state.allSpendRentalReceipts = [];
        }
      }
    );
  },
});

export const { rentalSpendReceipt } = rentalSpendReceiptSlice.actions;
export default rentalSpendReceiptSlice.reducer;
