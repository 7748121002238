import React from 'react';
import { motion } from 'framer-motion';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Success.module.scss'; // Assuming you have a separate SCSS file for styling

const Success = () => {
  const customToastStyle = {
    background: '#4CAF50', // Green color as a background
    color: '#fff', // White text color
  };



  const handleToastClick = () => {
    // Add any additional action on toast click
    console.log('Toast clicked!');
  };

  return (
    <>
      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0 }}
        transition={{ duration: 0.6, type: 'spring', stiffness: 200 }}
        style={customToastStyle}
        onClick={handleToastClick}
        className="custom-toast"
      >
        New customer is added!
      </motion.div>
      <button onClick={() => toast.success('Another toast!')}>Trigger Another Toast</button>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.6 }}
        style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <ToastContainer
          // transition={bounceTransition}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </motion.div>
    </>
  );
};

export default Success;
