import React from "react";
import { useSelector } from "react-redux";
import { selectProjectById } from "../../redux/projectSlice";

interface Receipt {
  id: number;
  project_id: number;
}

interface Props {
  receipt: Receipt;
}

const ProjectDetails: React.FC<Props> = ({ receipt }) => {
  const project = useSelector((state: { project: any }) =>
    selectProjectById(receipt.project_id)(state)
  );
  return (
    <td className="px-6 py-4 whitespace-nowrap text-right" dir="ltr">
      {project?.name}
    </td>
  );
};

export default ProjectDetails;
