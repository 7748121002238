import React from "react";
import EmployeeOfTheMonthFrame from "../../components/frames/EmployeeOfTheMonthFrame";
import Login from "../../components/login/Login";
import "./AuthPage.scss";

const AuthPage: React.FC = () => {
  return (
    <>
      <div className="flex justify-between items-center px-4 md:px-8 lg:px-16">
        <EmployeeOfTheMonthFrame />
        <div className="hidden md:block w-4"></div>
        <Login />
      </div>
    </>
  );
};

export default AuthPage;
