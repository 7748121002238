import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

interface Country {
  id: number;
  name: string;
}

const countries: Country[] = [
  {
    id: 1,
    name: "Iraq",
  },
  {
    id: 2,
    name: "Turkey",
  },
  {
    id: 3,
    name: "Iran",
  },
];

interface CountryState {
  countries: Country[];
  loading: "idle" | "pending" | "succeeded" | "failed";
  error: string | null;
}

const initialState: CountryState = {
  countries: [],
  loading: "idle",
  error: null,
};

export const fetchCountriesAsync = createAsyncThunk(
  "info/fetchCountries",
  async () => {
    // const response = await axios.get("/countries"); // backend endpoint
    // return response.data as Country[];
    return countries as Country[];
  }
);

const countrySlice = createSlice({
  name: "country",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCountriesAsync.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(
        fetchCountriesAsync.fulfilled,
        (state, action: PayloadAction<Country[]>) => {
          state.countries = action.payload;
          state.loading = "succeeded";
          state.error = null;
        }
      )
      .addCase(fetchCountriesAsync.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.error.message ?? "An error occurred";
      });
  },
});

export default countrySlice.reducer;
