import React, { useEffect } from "react";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./InsuranceSpendReceiptsPage.module.scss";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import {
  Formik,
  Field,
  Form,
  FormikHelpers,
  ErrorMessage,
  FieldProps,
} from "formik";
import CustomSelect from "../../components/custom/CustomSelect";
import { useTranslation } from "react-i18next";
import useTranslatedOptions from "../../translatedOptions";
import CustomerModal from "../../components/common/customerModal/customerModal";
import { RootState } from "../../redux/store";
import { fetchCustomersAsync } from "../../redux/customerSlice";
import { fetchEmployeesAsync } from "../../redux/employeeSlice";
import { ThunkDispatch } from "redux-thunk";
import { insuranceSpendReceipt } from "../../redux/insuranceSpendReceiptSlice";
// @ts-ignore
import KurdishSoraniNumbersToWords from "kurdish-numbers-to-words/Sorani.js";
import { fetchCountriesAsync } from "../../redux/countrySlice";
import { fetchCitiesAsync } from "../../redux/citySlice";
import { fetchUnitTypesAsync } from "../../redux/unitTypeSlice";
import { fetchPropertyNumbersAsync } from "../../redux/propertyNumberSlice";
import { fetchProjectsAsync } from "../../redux/projectSlice";
import { generateReceiptNumber } from "../../utils/helpers";
import { fetchPropertyOwnersAsync } from "../../redux/propertyOwnerSlice";
import { fetchCurrenciesAsync } from "../../redux/currencySlice";
import { RESET_SPEND_RECEIPT_DATA } from "../../redux/spendReceiptSlice";
import { RESET_SPEND_COMMISSION_RECEIPT_DATA } from "../../redux/spendCommissionReceiptSlice";
import { RESET_RENTAL_SPEND_RECEIPT_DATA } from "../../redux/rentalSpendReceiptSlice";
import BottomNavigationButton from "../../components/common/bottomNavigationButton/BottomNavigationButton";
import { toast } from "react-toastify";
import { RESET_RECEIVE_INSURANCE_RECEIPT_DATA } from "../../redux/insuranceReceiveReceiptSlice";
import { RESET_RECEIVE_COMMISSION_RECEIPT_DATA } from "../../redux/receiveCommissionReceiptSlice";
import { RESET_RENTAL_RECEIVE_RECEIPT_DATA } from "../../redux/rentReceiveReceiptSlice";

const { useState } = React;

interface Values {
  customer_id: number;
  receiptNumber: number;
  amountOf: string;
  amountInWords: string;
  project_id: number;
  employee_id: number;
  currency_id: number;
  category_id: number;
  categoryUnitType: string;
  property_number_id: number;
  prevoiusReceiptNumber: number;
  radioOption: string;
  rentingFixedAssets: string;
  startDate: Date;
  endDate: Date;
  unitType_id: number;
  note: string;
}

const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

interface InsuranceSpendReceiptsProps {
  title: string;
}

const InsuranceSpendReceipts: React.FC<InsuranceSpendReceiptsProps> = ({
  title,
}) => {
  const dispatch: ThunkDispatch<RootState, any, any> = useDispatch();
  const customers = useTypedSelector((state) => state.customer.customers);
  const employees = useTypedSelector((state) => state.employee.employees);
  const profile = useTypedSelector((state) => state.profile.profile);
  const currencies = useTypedSelector((state) => state.currency.currencies);
  const countries = useTypedSelector((state) => state.country.countries);
  const cities = useTypedSelector((state) => state.city.cities);
  const projects = useTypedSelector((state) => state.project.projects);
  const unitTypes = useTypedSelector((state) => state.unitType.unitTypes);
  const propertyNumbers = useTypedSelector(
    (state) => state.propertyNumber.propertyNumbers
  );

  const location = useLocation();
  const availableReceiptData = location.state?.availableReceiptData;

  const [, setEmployeeId] = useState<number | null>(null);

  useEffect(() => {
    if (profile) {
      const employee = employees.find((emp) => emp?.user_id === profile?.id);
      if (employee) {
        setEmployeeId(employee.id);
      }
    }
  }, [profile, employees]);

  useEffect(() => {
    dispatch(fetchCustomersAsync());
    dispatch(fetchEmployeesAsync());
    dispatch(fetchCountriesAsync());
    dispatch(fetchCitiesAsync());
    dispatch(fetchPropertyNumbersAsync());
    dispatch(fetchProjectsAsync());
    dispatch(fetchUnitTypesAsync());
    dispatch(fetchPropertyOwnersAsync());
    dispatch(fetchCurrenciesAsync());

    dispatch({ type: RESET_RECEIVE_INSURANCE_RECEIPT_DATA });
    dispatch({ type: RESET_RECEIVE_COMMISSION_RECEIPT_DATA });
    dispatch({ type: RESET_RENTAL_RECEIVE_RECEIPT_DATA });

    dispatch({ type: RESET_SPEND_RECEIPT_DATA });
    dispatch({ type: RESET_SPEND_COMMISSION_RECEIPT_DATA });
    dispatch({ type: RESET_RENTAL_SPEND_RECEIPT_DATA });
  }, [dispatch]);

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const language = i18n.language;

  const validationSchema = Yup.object({
    // spendTo: Yup.string().required("Full Name is required"),
    // amountOf: Yup.number()
    //   .required("Amount is required")
    //   .positive(t("The amount must be a positive number")),
    // receiver: Yup.string().required("Full Name is required"),
    // receptor: Yup.string().required("Full Name is required"),
    // currency: Yup.string().required("Required"),
    // category: Yup.string().required("Required"),
  });

  const [isModalOpen, setModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState<string>("");

  const [selectedCurrency, setSelectedCurrency] = useState<number>(
    availableReceiptData?.currency_id ?? 2
  );
  const [selectedCategory, setSelectedCategory] = useState(
    availableReceiptData?.category_id
  );

  const [showCommissionOptions, setShowCommissionOptions] = useState(false);
  const [showRentsOptions, setShowRentsOptions] = useState(false);
  const [showRentingFixedAssets, setShowRentingFixedAssets] = useState(false);
  const [showUses, setShowUses] = useState(false);
  const [selectedRadio, setSelectedRadio] = useState("");
  const [
    showAdvertisingPrintingHospitality,
    setShowAdvertisingPrintingHospitality,
  ] = useState(false);
  const [showMiscellaneous, setShowMiscellaneous] = useState(false);
  const [
    showMiscellaneousServiceExpenses,
    setShowMiscellaneousServiceExpenses,
  ] = useState(false);
  const [showFuelAndOils, setShowFuelAndOils] = useState(false);
  const [
    showTransportationDispatchCommunications,
    setShowTransportationDispatchCommunications,
  ] = useState(false);

  const handleCurrencyChange = (currencyId: number) => {
    setSelectedCurrency(currencyId);
  };

  const handleCategoryChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    const selectedCategory = event.target.value;
    setSelectedCategory(selectedCategory);

    setShowCommissionOptions(selectedCategory === "commission");
    setShowRentsOptions(selectedCategory === "rents");
    setShowRentingFixedAssets(selectedCategory === "Renting Fixed Assets");
    setShowUses(selectedCategory === "uses");
    setShowAdvertisingPrintingHospitality(
      selectedCategory === "Advertising Printing Hospitality"
    );
    setShowMiscellaneous(selectedCategory === "miscellaneous");
    setShowMiscellaneousServiceExpenses(
      selectedCategory === "miscellaneousServiceExpenses"
    );
    setShowFuelAndOils(selectedCategory === "fuelAndOils");
    setShowTransportationDispatchCommunications(
      selectedCategory === "transportationDispatchCommunications"
    );
  };

  const {
    commercialOptions,
    residentialOptions,
    advertisingPrintingHospitalityOptions,
    miscellaneousOptions,
    miscellaneousServiceExpensesOptions,
    fuelAndOilsOptions,
    transportationDispatchCommunicationsOptions,
    categoriesOfInsurance,
  } = useTranslatedOptions();


  const [amountOf, setAmountOf] = useState<string>(
    availableReceiptData?.amountOf
  );
  const [amountInWords, setAmountInWords] = useState<string>(
    availableReceiptData?.amountInWords
  );

  const handleAmountOfChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setAmountOf(value);
    const parsedAmount = parseInt(value, 10);
    if (!isNaN(parsedAmount)) {
      const words = KurdishSoraniNumbersToWords.convert(parsedAmount);
      setAmountInWords(words);
    }
  };

  const initialValues = availableReceiptData
    ? {
        customer_id: availableReceiptData.customer_id,
        receiptNumber: availableReceiptData.receiptNumber,
        prevoiusReceiptNumber: availableReceiptData.prevoiusReceiptNumber,
        amountOf: availableReceiptData.amountOf,
        amountInWords: availableReceiptData.amountInWords,
        currency_id: availableReceiptData.currency_id,
        unitType_id: availableReceiptData.unitType_id,
        employee_id: availableReceiptData.employee_id,
        project_id: availableReceiptData.project_id,
        property_number_id: availableReceiptData.property_number_id,
        category_id: availableReceiptData.category_id,
        categoryUnitType: availableReceiptData.categoryUnitType,
        radioOption: availableReceiptData.radioOption,
        rentingFixedAssets: availableReceiptData.rentingFixedAssets,
        startDate: new Date(availableReceiptData.startDate),
        endDate: new Date(availableReceiptData.endDate),
        note: availableReceiptData.note,
      }
    : {
        customer_id: 0,
        receiptNumber: 1,
        prevoiusReceiptNumber: 0,
        amountOf: "",
        amountInWords: "",
        currency_id: 1,
        unitType_id: 0,
        employee_id: 1,
        project_id: 0,
        property_number_id: 0,
        category_id: 1,
        categoryUnitType: "",
        radioOption: "",
        rentingFixedAssets: "",
        startDate: new Date(),
        endDate: new Date(),
        note: "",
      };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(
          values: Values,
          { setSubmitting }: FormikHelpers<Values>
        ) => {
          if (!amountOf) {
            toast.error(t("amount of is required"));
            return;
          }
          if (!parseInt(selectedCategory)) {
            toast.error(t("category is required"));
            return;
          }
          if (selectedCurrency !== undefined) {
            values.currency_id = selectedCurrency;
          }
          values.currency_id = selectedCurrency!;
          values.employee_id = profile?.id ?? 1;
          values.amountOf = amountOf;
          values.amountInWords = amountInWords;
          values.category_id = 1;
          values.receiptNumber = generateReceiptNumber();
          dispatch(insuranceSpendReceipt(values));
          navigate("/spend-receipt-preview", { state: { title } });
          setTimeout(() => {
            // alert(JSON.stringify(values, null, 2));
            setSubmitting(false);
          }, 500);
        }}
      >
        <div className="flex flex-col">
          <Form className={styles.grid} title={t("Insurance Spend Receipt")}>
            <Field name="customer_id" key={Math.random()}>
              {({ field, form }: FieldProps<string>) => (
                <CustomSelect
                  field={field}
                  form={form}
                  options={customers.map((propertyOwner) => ({
                    label: `${propertyOwner.firstName + " "}${propertyOwner.lastName}`,
                    value: propertyOwner.id,
                    key: `${propertyOwner.firstName + " "}${propertyOwner.lastName}`,
                  }))}
                  labelName="spendTo"
                  name="customer_id"
                  creatable={false}
                />
              )}
            </Field>
            <div className={`${styles["col-span-1"]} ${styles["form-group"]}`}>
              <label htmlFor="amountOf" className={styles.label}>
                {t("Amount Of")}
              </label>
              <Field
                id="amountOf"
                name="amountOf"
                placeholder={t("Amount of Paid to Us")}
                className={styles["input-field"]}
                onChange={handleAmountOfChange}
                value={amountOf}
              />
              <ErrorMessage
                name="amountOf"
                component="div"
                className={styles.error}
              />
            </div>
            <div className={`${styles["col-span-1"]} ${styles["form-group"]}`}>
              <label htmlFor="amountInWords" className={styles.label}>
                {t("Amount In Words")}
              </label>
              <Field
                id="amountInWords"
                name="amountInWords"
                placeholder={t("Amount of Paid to Us")}
                className={styles["input-field"]}
                value={amountInWords}
                readOnly
              />
              <ErrorMessage
                name="amountInWords"
                component="div"
                className={styles.error}
              />
            </div>
            <div className={`${styles["col-span-1"]} ${styles["form-group"]}`}>
              <label htmlFor="spender" className={styles.label}>
                {t("Spender")}
              </label>
              <Field
                id="spender"
                name="spender"
                placeholder="Full Name"
                value={profile?.name}
                disabled
                className={styles["input-field"]}
              />
              <ErrorMessage
                name="spender"
                component="div"
                className={styles.error}
              />
            </div>
            <div className={`${styles["col-span-1"]} ${styles["form-group"]}`}>
              <label htmlFor="category" className={styles.label}>
                <span className={styles.for}>{t("For")} </span>
                <span className={styles.database}>
                  {t("Store In Database")}
                </span>
              </label>
              <Field
                as="select"
                id="category"
                name="category"
                value={selectedCategory}
                onChange={handleCategoryChange}
                className={`${styles["input-field"]} ${styles["black-background"]}`}
              >
                {categoriesOfInsurance.map((category) => (
                  <option
                    key={category.value}
                    value={category.id}
                    label={category.label}
                  >
                    {category.label}
                  </option>
                ))}
              </Field>
              <ErrorMessage
                name="category"
                component="div"
                className={styles.error}
              />
            </div>
            <div
              className={`${styles["col-span-1"]} ${styles["form-group"]} ml-0 mt-[1.9rem]`}
            >
              <div
                onClick={() => {
                  setModalTitle(t("Create A New Property Owner"));
                  setModalOpen(true);
                }}
                className={`${styles["black-background"]} text-white w-[auto] h-[2.67rem] border-none cursor-pointer text-16}`}
              >
                <p className="pt-3 pb-2 pl-2 pr-2 text-center">
                  {t("Create A New Property Owner")}
                </p>
              </div>
            </div>
            <Field name="project_id" key={Math.random()}>
              {({ field, form }: FieldProps<string>) => (
                <CustomSelect
                  field={field}
                  form={form}
                  options={projects.map((project, index) => ({
                    label: project.name,
                    value: project.id,
                    key: `${project.name}_${index}`,
                  }))}
                  labelName="projects"
                  name="project_id"
                  creatable={true}
                  mt={2}
                  dispatchAction={() => dispatch(fetchProjectsAsync())}
                />
              )}
            </Field>
            <div
              className={`flex space-x-6 ${language === "en" ? "flex-row" : "flex-row-reverse"}`}
            >
              <div
                className={`${styles["col-span-1"]} ${styles["form-group"]}`}
              >
                <label className={styles.label}>{t("Currency")}</label>
                <div className={styles["currency-toggle"]}>
                  {currencies?.map((currency) => (
                    <button
                      key={currency.id}
                      type="button"
                      onClick={() => handleCurrencyChange(currency.id)}
                      className={`${styles["currency-button"]} ${
                        selectedCurrency === currency.id
                          ? styles["currency-selected"]
                          : ""
                      }`}
                    >
                      {currency.name}
                    </button>
                  ))}
                </div>
              </div>
              <div
                className={`${styles["col-span-1"]} ${styles["form-group"]}`}
              >
                <label htmlFor="prevoiusReceiptNumber" className={styles.label}>
                  {t("Previous Receipt Number")}
                </label>
                <Field
                  id="prevoiusReceiptNumber"
                  name="prevoiusReceiptNumber"
                  placeholder={t("previous receipt number")}
                  className={`${styles["input-field"]} h-[2.65rem]`}
                />
                <ErrorMessage
                  name="prevoiusReceiptNumber"
                  component="div"
                  className={styles.error}
                />
              </div>
            </div>
            <div
              className={`flex space-x-6 ${language === "en" ? "flex-row" : "flex-row-reverse"}`}
            >
              <div className={`w-[12.7rem] ${styles["form-group"]}`}>
                <Field name="unitType_id" key={Math.random()}>
                  {({ field, form }: FieldProps<string>) => (
                    <CustomSelect
                      field={field}
                      form={form}
                      options={unitTypes.map((unitType, index) => ({
                        label: unitType.name,
                        value: unitType.id,
                        key: `${unitType.name}_${index}`,
                      }))}
                      labelName="unitType"
                      name="unitType_id"
                      creatable={true}
                      dispatchAction={() => dispatch(fetchUnitTypesAsync())}
                    />
                  )}
                </Field>
              </div>
              <div className={`w-[12.7rem] ${styles["form-group"]}`}>
                <Field name="property_number_id" key={Math.random()}>
                  {({ field, form }: FieldProps<string>) => (
                    <CustomSelect
                      field={field}
                      form={form}
                      options={propertyNumbers.map((propertyNumber, index) => ({
                        label: propertyNumber.name,
                        value: propertyNumber.id,
                        key: `${propertyNumber.name}_${index}`,
                      }))}
                      labelName="propertyNumber"
                      name="property_number_id"
                      creatable={true}
                      dispatchAction={() =>
                        dispatch(fetchPropertyNumbersAsync())
                      }
                    />
                  )}
                </Field>
              </div>
            </div>
            <div className={`${styles["col-span-1"]} ${styles["form-group"]}`}>
              <label htmlFor="startDate" className="font-bold">
                {t("Start Date")}
              </label>
              <div className="relative">
                <Field
                  as="input"
                  id="startDate"
                  name="startDate"
                  type="date"
                  placeholder="Start Date"
                  className="block w-full border border-gray-400 mt-1 p-2 bg-gray-800 text-white"
                />
                <style>
                  {`
                  #startDate::-webkit-calendar-picker-indicator {
                    filter: invert(1);
                  }
                `}
                </style>
              </div>
            </div>
            <div className={`${styles["col-span-1"]} ${styles["form-group"]}`}>
              <label htmlFor="endDate" className="font-bold">
                {t("End Date")}
              </label>
              <div className="relative">
                <Field
                  as="input"
                  id="endDate"
                  name="endDate"
                  type="date"
                  placeholder="End Date"
                  className="block w-full border border-gray-400 mt-1 p-2 bg-gray-800 text-white"
                />
                <style>
                  {`
                  #endDate::-webkit-calendar-picker-indicator {
                    filter: invert(1);
                  }
                `}
                </style>
              </div>
            </div>
            <div className={`${styles["col-span-1"]} ${styles["form-group"]}`}>
              <label htmlFor="note" className={styles.label}>
                {t("Note")}
              </label>
              <Field
                id="note"
                name="note"
                dir="rtl"
                placeholder={t("Write something")}
                className={styles["input-field"]}
              />
              <ErrorMessage
                name="note"
                component="div"
                className={styles.error}
              />
            </div>
            {/* Render radio buttons only when the selected category is "commission" */}
            <div
              className={`${styles["col-span-3"]} ${styles["form-group"]} ${styles["-mt-1"]} ${styles["flex-container"]}`}
            >
              <div
                className={`${styles["radio-group"]} ${styles["flex-container"]}`}
              >
                {showCommissionOptions && (
                  <div
                    className={`${styles["col-span-1"]} ${styles["form-group"]}`}
                  >
                    {/* <label className={styles.label}>Commission Options</label> */}
                    <div>
                      <label className={styles["radio-label"]}>
                        <Field
                          type="radio"
                          name="radioOption"
                          value="COMMERCIAL"
                          onClick={() => setSelectedRadio("COMMERCIAL")}
                        />
                        {t("COMMERCIAL")}
                      </label>
                      <label className={styles["radio-label"]}>
                        <Field
                          type="radio"
                          name="radioOption"
                          value="RESIDENTIAL"
                          onClick={() => setSelectedRadio("RESIDENTIAL")}
                        />
                        {t("RESIDENTIAL")}
                      </label>
                    </div>
                  </div>
                )}
                {showRentsOptions && (
                  <div>
                    <label className={styles["radio-label"]}>
                      <Field
                        type="radio"
                        name="radioOption"
                        value="RESIDENTIAL"
                        onClick={() => setSelectedRadio("RESIDENTIAL")}
                      />
                      {t("RESIDENTIAL")}
                    </label>
                    <div className="mt-[1.3rem]"></div>
                    <label className={styles["radio-label"]}>
                      <Field
                        type="radio"
                        name="radioOption"
                        value="COMMERCIAL"
                        onClick={() => setSelectedRadio("COMMERCIAL")}
                      />
                      {t("COMMERCIAL")}
                    </label>
                  </div>
                )}
                {/* Conditionally render radio buttons without dropdown based on selected category */}
                {showRentingFixedAssets && (
                  <div
                    className={`${styles["col-span-3"]} ${styles["form-group"]} ${styles["-mt-1"]} ${styles["flex-container"]}`}
                  >
                    {/* First Column */}
                    <div
                      className={`${styles["radio-group"]} ${styles["flex-container"]}`}
                    >
                      <label className={styles["radio-label"]}>
                        <Field
                          type="radio"
                          name="radioOption"
                          value="RENTING BUILDINGS AND CONSTRUCTIONS"
                          onClick={() =>
                            setSelectedRadio(
                              "RENTING BUILDINGS AND CONSTRUCTIONS"
                            )
                          }
                        />
                        {t("RENTING BUILDINGS AND CONSTRUCTIONS")}
                      </label>
                      <label className={styles["radio-label"]}>
                        <Field
                          type="radio"
                          name="radioOption"
                          value="RENTING MACHINES AND EQUIPMENT"
                          onClick={() =>
                            setSelectedRadio("RENTING MACHINES AND EQUIPMENT")
                          }
                        />
                        {t("RENTING MACHINES AND EQUIPMENT")}
                      </label>
                      <label className={styles["radio-label"]}>
                        <input
                          type="radio"
                          name="radioOption"
                          value="TRANSPORTATION_RENTAL"
                          onChange={(e) =>
                            setSelectedRadio("TRANSPORTATION_RENTAL")
                          }
                        />
                        {t("TRANSPORTATION RENTAL")}
                      </label>
                    </div>
                  </div>
                )}
                {/* Conditionally render radio buttons without dropdown based on selected category */}
                {showUses && (
                  <div
                    className={`${styles["col-span-3"]} ${styles["form-group"]} ${styles["-mt-1"]} ${styles["flex-container"]}`}
                  >
                    {/* First Column */}
                    <div
                      className={`${styles["radio-group"]} ${styles["flex-container"]}`}
                    >
                      <label className={styles["radio-label"]}>
                        <Field
                          type="radio"
                          name="radioOption"
                          value="salaries and wages"
                          onClick={() => setSelectedRadio("salaries and wages")}
                        />
                        {t("SALARIES AND WAGES")}
                      </label>
                      <label className={styles["radio-label"]}>
                        <Field
                          type="radio"
                          name="radioOption"
                          value="incentive bouns"
                          onClick={() => setSelectedRadio("incentive bouns")}
                        />
                        {t("INCENTIVE BONUS")}
                      </label>
                      <label className={styles["radio-label"]}>
                        <Field
                          type="radio"
                          name="radioOption"
                          value="other allocations"
                          onClick={() => setSelectedRadio("other allocations")}
                        />
                        {t("OTHER ALLOCATIONS")}
                      </label>
                    </div>

                    {/* Second Column (no dropdown for uses) */}
                  </div>
                )}
                {/* Conditionally render radio buttons without dropdown based on selected category */}
                {showAdvertisingPrintingHospitality && (
                  <div
                    className={`${styles["col-span-3"]} ${styles["form-group"]} ${styles["-mt-1"]} ${styles["flex-container"]}`}
                  >
                    {/* First Column */}
                    <div
                      className={`${styles["radio-group"]} ${styles["flex-container"]}`}
                    >
                      {advertisingPrintingHospitalityOptions.map((option) => (
                        <label key={option} className={styles["radio-label"]}>
                          <Field
                            type="radio"
                            name="radioOption"
                            value={option}
                            onClick={() => setSelectedRadio(option)}
                          />
                          {option}
                        </label>
                      ))}
                    </div>
                  </div>
                )}
                {/* Conditionally render radio buttons without dropdown based on selected category */}
                {showMiscellaneous && (
                  <div
                    className={`${styles["col-span-3"]} ${styles["form-group"]} ${styles["-mt-1"]} ${styles["flex-container"]}`}
                  >
                    {/* First Column */}
                    <div
                      className={`${styles["radio-group"]} ${styles["flex-container"]}`}
                    >
                      {miscellaneousOptions.map((option) => (
                        <label key={option} className={styles["radio-label"]}>
                          <Field
                            type="radio"
                            name="radioOption"
                            value={option}
                            onClick={() => setSelectedRadio(option)}
                          />
                          {option}
                        </label>
                      ))}
                    </div>
                  </div>
                )}
                {/* Conditionally render radio buttons without dropdown based on selected category */}
                {showMiscellaneousServiceExpenses && (
                  <div
                    className={`${styles["col-span-3"]} ${styles["form-group"]} ${styles["-mt-1"]} ${styles["flex-container"]}`}
                  >
                    {/* First Column */}
                    <div
                      className={`${styles["radio-group"]} ${styles["flex-container"]}`}
                    >
                      {miscellaneousServiceExpensesOptions.map((option) => (
                        <label key={option} className={styles["radio-label"]}>
                          <Field
                            type="radio"
                            name="radioOption"
                            value={option}
                            onClick={() => setSelectedRadio(option)}
                          />
                          {option}
                        </label>
                      ))}
                    </div>

                    {/* Second Column (no dropdown for miscellaneous service expenses) */}
                  </div>
                )}
                {/* Conditionally render radio buttons without dropdown based on selected category */}
                {showFuelAndOils && (
                  <div
                    className={`${styles["col-span-3"]} ${styles["form-group"]} ${styles["-mt-1"]} ${styles["flex-container"]}`}
                  >
                    <div
                      className={`${styles["radio-group"]} ${styles["flex-container"]}`}
                    >
                      {fuelAndOilsOptions.map((option) => (
                        <label key={option} className={styles["radio-label"]}>
                          <Field
                            type="radio"
                            name="radioOption"
                            value={option}
                            onClick={() => setSelectedRadio(option)}
                          />
                          {option}
                        </label>
                      ))}
                    </div>
                  </div>
                )}
                {/* Conditionally render radio buttons without dropdown based on selected category */}
                {showTransportationDispatchCommunications && (
                  <div
                    className={`${styles["col-span-3"]} ${styles["form-group"]} ${styles["-mt-1"]} ${styles["flex-container"]}`}
                  >
                    <div
                      className={`${styles["radio-group"]} ${styles["flex-container"]}`}
                    >
                      {transportationDispatchCommunicationsOptions.map(
                        (option) => (
                          <label key={option} className={styles["radio-label"]}>
                            <Field
                              type="radio"
                              name="radioOption"
                              value={option}
                              onClick={() => setSelectedRadio(option)}
                            />
                            {option}
                          </label>
                        )
                      )}
                    </div>
                  </div>
                )}
              </div>
              {/* Conditionally render the unit type select dropdown */}
              {selectedRadio === "COMMERCIAL" && (
                <div
                  className={`${styles["col-span-1"]} ${styles["form-group"]}`}
                >
                  <label htmlFor="categoryUnitType" className={styles.label}>
                    <span className={styles.for}>{t("Type")}</span>
                    <span className={styles.database}> {t("Of Unit")}</span>
                  </label>
                  <Field
                    as="select"
                    id="categoryUnitType"
                    name="categoryUnitType"
                    className={`${styles["input-field"]} ${styles["black-background"]}`}
                  >
                    <option value="" label={t("Select Unit Type")} />
                    {commercialOptions.map((option) => (
                      <option key={option} value={option} label={option} />
                    ))}
                  </Field>
                </div>
              )}
              {selectedRadio === "RESIDENTIAL" && (
                <div
                  className={`${styles["col-span-1"]} ${styles["form-group"]}`}
                >
                  <label htmlFor="categoryUnitType" className={styles.label}>
                    <span className={styles.for}>{t("Type")}</span>
                    <span className={styles.database}> {t("Of Unit")}</span>
                  </label>
                  <Field
                    as="select"
                    id="categoryUnitType"
                    name="categoryUnitType"
                    className={`${styles["input-field"]} ${styles["black-background"]}`}
                  >
                    <option value="" label={t("Select Unit Type")} />
                    {residentialOptions.map((option) => (
                      <option key={option} value={option} label={option} />
                    ))}
                  </Field>
                </div>
              )}
            </div>
            <div
              className={`${styles["col-span-3"]} ${styles["form-group"]} ${styles["centered-container"]}`}
            >
              <button
                type="submit"
                className="bg-gradient-to-b from-blue-900 to-blue-500 text-white w-[245px] h-[55px] border-none cursor-pointer text-16 -mt-4"
              >
                {t("Preview")}
              </button>
            </div>
          </Form>
        </div>
      </Formik>
      <CustomerModal
        isModalOpen={isModalOpen}
        setModalOpen={setModalOpen}
        title={modalTitle}
        countries={countries}
        cities={cities}
        dispatchAction={() => {
          setTimeout(() => {
            dispatch(fetchPropertyOwnersAsync());
          }, 2000);
        }}
      />
      <div className="flex justify-center -mt-4">
        <BottomNavigationButton content="Main Menu" to="/main" />
        <BottomNavigationButton content="Finance" to="/finance" />
        <BottomNavigationButton content="Receipts" to="/receipts" />
        <BottomNavigationButton
          content="Insurance Spend"
          to="/insurance-spend-receipt"
        />
      </div>
    </>
  );
};

export default InsuranceSpendReceipts;
