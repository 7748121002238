import React, { MouseEvent, useState } from "react";
import styles from "./MacbookButton.module.scss";
import { useTranslation } from "react-i18next";

interface MacbookButtonProps {
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  buttonText: string;
  large?: boolean;
  icon?: React.ReactNode;
  width?: string;
  height?: string;
  fontSize?: string;
}

const MacbookButton: React.FC<MacbookButtonProps> = ({
  onClick,
  buttonText,
  large,
  icon,
  width,
  height,
  fontSize,
}) => {
  const { t } = useTranslation();

  const [isHovered, setIsHovered] = useState(false);
  const [isLoading, ] = useState(false);

  return (
    <button
      className={`${styles["grid-item"]} ${styles["font-medium"]} ${styles["macbook-button"]} relative ${large ? styles["large"] : ""}`}
      style={{ width: width, height: height, fontSize: fontSize }}
      onClick={onClick}
      disabled={isLoading}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {isLoading ? (
        <div className={styles["loading-spinner"]} />
      ) : (
        <>
          {t(buttonText)}
          {icon && (
            <span
              className={`${styles["icon"]} absolute top-5 right-8 ${isHovered ? styles["icon-hovered"] : ""}`}
            >
              {icon}
            </span>
          )}
        </>
      )}
    </button>
  );
};

export default MacbookButton;
