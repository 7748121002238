import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSpring, animated } from "react-spring";

interface RadioOptionProps {
  label: string;
  checked: boolean;
  onChange: () => void;
}

const RadioOption: React.FC<RadioOptionProps> = ({
  label,
  checked,
  onChange,
}) => {
  const springProps = useSpring({
    opacity: 1,
    transform: "translateX(0)",
    from: { opacity: 0, transform: "translateX(-10px)" },
  });

  return (
    <animated.label
      style={springProps}
      className="flex items-center cursor-pointer mb-2"
    >
      <input
        type="radio"
        className="form-radio text-indigo-600 h-5 w-5 mr-2"
        checked={checked}
        onChange={onChange}
      />
      <span className="text-sm">{label}</span>
    </animated.label>
  );
};

const ClientTypeSelector: React.FC<{
  onTypeChange: (type: string) => void;
}> = ({ onTypeChange }) => {
  const [clientType, setClientType] = useState<string>("normal");
  const { t } = useTranslation();

  const handleRadioChange = (type: string) => {
    setClientType(type);
    onTypeChange(type);
  };

  return (
    <div className="text-center p-4 border-b border-gray-300 -mt-6 flex flex-row">
      <p className="font-semibold text-lg mb-2 tracking-wider">
        {t("Client Type")}:
      </p>
      <div className="flex flex-row justify-center mt-1">
        <RadioOption
          label={t("Normal")}
          checked={clientType === "normal"}
          onChange={() => handleRadioChange("normal")}
        />
        <RadioOption
          label={t("Real Estate")}
          checked={clientType === "realEstate"}
          onChange={() => handleRadioChange("realEstate")}
        />
      </div>
    </div>
  );
};

export default ClientTypeSelector;
