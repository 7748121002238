import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "../services/api/api";

interface PropertyOwner {
  id: number;
  userId: number;
  firstName: string;
  lastName: string;
  identityNo: string;
  email: string;
  phoneNumber: number;
  cityId: number;
  countryId: number;
}

interface PropertyOwnerState {
  propertyOwners: PropertyOwner[];
  loading: "idle" | "pending" | "succeeded" | "failed";
  error: string | null;
}

const initialState: PropertyOwnerState = {
  propertyOwners: [],
  loading: "idle",
  error: null,
};

export const fetchPropertyOwnersAsync = createAsyncThunk(
  "propertyOwner/fetchPropertyOwners",
  async () => {
    const response = await axios.get("/propertyOwner");
    const propertyOwners: PropertyOwner[] = response.data.data.map(
      (owner: any) => {
        return {
          id: owner.id,
          userId: owner.user_id,
          firstName: owner.first_name,
          lastName: owner.last_name,
          identityNo: owner.identityNo,
          email: owner.email,
          phoneNumber: owner.phone_number,
          cityId: owner.city_id,
          countryId: owner.country_id,
        };
      }
    );
    return propertyOwners;
  }
);

export const createPropertyOwnerAsync = createAsyncThunk(
  "propertyOwner/createPropertyOwner",
  async (propertyOwnerData: PropertyOwner) => {
    const dataToSend = {
      user_id: propertyOwnerData.userId,
      first_name: propertyOwnerData.firstName,
      last_name: propertyOwnerData.lastName,
      identityNo: propertyOwnerData.identityNo,
      email: propertyOwnerData.email,
      phone_number: propertyOwnerData.phoneNumber,
      description: "property owner",
      address: "Erbil",
      city: "Erbil",
      country: "Iraq",
    };
    const response = await axios.post("/propertyOwner", dataToSend);
    console.log("create.response", response);
    return response.data as PropertyOwner;
  }
);

const propertyOwnerSlice = createSlice({
  name: "propertyOwner",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPropertyOwnersAsync.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(
        fetchPropertyOwnersAsync.fulfilled,
        (state, action: PayloadAction<PropertyOwner[]>) => {
          state.propertyOwners = action.payload;
          state.loading = "succeeded";
          state.error = null;
        }
      )
      .addCase(fetchPropertyOwnersAsync.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.error.message ?? "An error occurred";
      });
  },
});

export default propertyOwnerSlice.reducer;
