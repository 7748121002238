import React, { useEffect, useState } from "react";
import { PropagateLoader } from "react-spinners";
import "./PrintRecieveComponent.module.scss"
import RecievePrint from "../common/recievePrint/recievePrint";


interface PrintReceiveComponentProps {
  title: string;
  content: React.ReactNode;
}

const PrintReceiveComponent = React.forwardRef<HTMLDivElement, PrintReceiveComponentProps>(
  (props, ref) => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      // Simulate loading time with a timeout
      const timeoutId = setTimeout(() => {
        setLoading(false);
      }, 1000);

      return () => clearTimeout(timeoutId);
    }, []);

    if (loading) {
      return (
        <div className="flex items-center justify-center mt-80">
          <PropagateLoader color="#4A90E2" size={15} />
        </div>
      );
    }

    return (
      <div ref={ref}>
        <RecievePrint title={props.title}/>
        <div className="-mt-[2.4rem]">
          <RecievePrint isCopy={true} title={props.title}/>
        </div>
      </div>
    );
  }
);

export default PrintReceiveComponent;
