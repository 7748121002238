import React, { useRef, useState } from "react";
import styles from "./SpendReceiptPreview.module.scss";
import ReactToPrint from "react-to-print";
import PrintSpendComponent from "../../components/printSpend/PrintSpendComponent";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
  sendSpendReceiptData,
  spendReceipt,
} from "../../redux/spendReceiptSlice";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../redux/store";
import { toast } from "react-toastify";
import {
  sendCommissionReceiptData,
  spendCommissionReceipt,
} from "../../redux/spendCommissionReceiptSlice";
import { sendInsuranceReceiptData } from "../../redux/insuranceSpendReceiptSlice";
import { sendRentalSpendReceiptData } from "../../redux/rentalSpendReceiptSlice";
import { insuranceReceiveReceipt } from "../../redux/insuranceReceiveReceiptSlice";
import rentalContractSlice from "../../redux/rentalContractSlice";

const SpendReceiptPreview: React.FC = () => {
  const dispatch: ThunkDispatch<RootState, any, any> = useDispatch();
  const componentRef = useRef<any>();
  const data = useSelector((state: any) => state);
  const { t } = useTranslation();

  const location = useLocation();
  const title = location.state?.title;

  const Navigate = useNavigate();

  const availableReceiptData =
    data.spendReceipt?.spendReceiptData ||
    data.spendCommissionReceipt?.spendCommissionReceiptData ||
    data.rentalSpendReceipt?.rentalSpendReceiptData ||
    data.insuranceSpendReceipt?.insuranceSpendReceiptData;

  const saveData = async () => {
    if (!availableReceiptData) {
      console.error("No receipt data available.");
      return;
    }

    let actionToSend: any = null;
    let actionToGet: any = null;
    if (data.spendReceipt?.spendReceiptData) {
      actionToSend = sendSpendReceiptData;
      actionToGet = spendReceipt;
    } else if (data.spendCommissionReceipt?.spendCommissionReceiptData) {
      actionToSend = sendCommissionReceiptData;
      actionToGet = spendCommissionReceipt;
    } else if (data.insuranceSpendReceipt?.insuranceSpendReceiptData) {
      actionToSend = sendInsuranceReceiptData;
      actionToGet = insuranceReceiveReceipt;
    } else if (data.rentalSpendReceipt?.rentalSpendReceiptData) {
      actionToSend = sendRentalSpendReceiptData;
      actionToGet = rentalContractSlice;
    }

    if (!actionToSend) {
      console.error("No action to send data.");
      return;
    }

    if (!actionToGet) {
      console.error("No action to get data.");
      return;
    }

    try {
      new Promise((resolve, reject) => {
        dispatch(actionToSend(availableReceiptData))
          .then((action: any) => {
            localStorage.removeItem("receiptNumber");
            console.log("action.payload", action.payload);
            const receiptNumber = action.payload.receiptNumber;

            localStorage.setItem("receiptNumber", receiptNumber);

            resolve(action);
          })
          .catch((error: any) => {
            reject(error);
          });
      })
        .then((action: any) => {
          const receiptNumber = action.payload.receiptNumber;
          console.log("receiptNumber", receiptNumber);
        })
        .catch((error) => {
          console.error("Error sending receipt data:", error);
        });
    } catch (error) {
      toast.error(t("Failed to send receipt data! Please try again."), {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        className: "black-and-white-toast",
      });
    }
  };

  const [showPrintButton, setShowPrintButton] = useState(false);

  const handleFirstButtonClick = () => {
    saveData();
    setShowPrintButton(true);
  };

  const handleBackButtonClick = () => {
    Navigate(-1);
  };

  const handleEditClick = (id: number) => {
    // If no receipt data is available, return
    if (!availableReceiptData) {
      console.error("No receipt data available.");
      return;
    }
    console.log("availableReceiptData", availableReceiptData);

    let dynamicUrl = "";

    if (data.spendReceipt?.spendReceiptData) {
      dynamicUrl = "/spend-receipt";
    } else if (data.spendCommissionReceipt?.spendCommissionReceiptData) {
      dynamicUrl = "/commission-spend-receipt";
    } else if (data.rentalSpendReceipt?.rentalSpendReceiptData) {
      dynamicUrl = "/rental-spend-receipt";
    } else if (data.insuranceSpendReceipt?.insuranceSpendReceiptData) {
      dynamicUrl = "/insurance-spend-receipt";
    } else {
      console.error("No receipt data available.");
      return;
    }
    console.log("availableReceiptDataPreview", availableReceiptData);
    Navigate(`${dynamicUrl}/${id}`, { state: { availableReceiptData } });
  };

  return (
    <>
      <div className="flex">
        {/* First Column */}
        <div
          className="w-[30.3125rem]"
          style={{
            background: `linear-gradient(to bottom, rgba(225, 227, 230, 1) 0%, rgba(225, 227, 230, 0.0) 100%)`,
            padding: "3.7rem",
          }}
        >
          {/* Content for the first column */}
          <div>
            {/* <div
              className={`w-[18.75rem] ${styles["col-span-1"]} ${styles["form-group"]}`}
            >
              <label htmlFor="category" className={styles.label}>
                <span className={styles.for}>{t("For")} </span>
                <span className={styles.database}>
                  {t("Store In Database")}
                </span>
              </label>
              <select
                id="category"
                name="category"
                className={`${styles["input-field"]} ${styles["black-background"]}`}
              > */}
            {/* <option value="" label="Purpose of Receive" /> */}
            {/* <option
                  value={category}
                  label={t(
                    category.charAt(0).toUpperCase() + category.slice(1)
                  )}
                />
              </select>
            </div> */}
            {/* First Column */}
            {/* <div
              className={`mt-8 ${styles["radio-group"]} ${styles["flex-container"]}`}
            >
              <label className={styles["radio-label"]}>
                <input
                  type="radio"
                  name="radioOption"
                  value={radioOption}
                  checked={true}
                />
                {radioOption}
              </label>
            </div>
            {categoryUnitType !== "" && (
              <div
                className={`mt-8 ${styles["form-group"]}`}
                style={{ width: "225px" }}
              >
                <label htmlFor="categoryUnitType" className={styles.label}>
                  <span className={styles.for}>Type</span>
                  <span className={styles.database}> Of Unit</span>
                </label>
                <select
                  id="categoryUnitType"
                  name="categoryUnitType"
                  className={`${styles["input-field"]} ${styles["black-background"]}`}
                >
                  <option value={categoryUnitType} label={categoryUnitType} />
                </select>
              </div>
            )} */}
            <div className="flex flex-col items-center justify-center">
              <div style={{ marginBottom: "20px", marginTop: "15rem" }}>
                <button
                  type="button"
                  className={`${styles["preview-button"]} ${styles["black-background"]}`}
                  onClick={() =>
                    handleEditClick(availableReceiptData?.receiptNumber)
                  }
                >
                  {t("Edit")}
                </button>
              </div>
              <div style={{ marginBottom: "20px", marginTop: "0rem" }}>
                <button
                  type="submit"
                  onClick={handleFirstButtonClick}
                  className={`${styles["preview-button"]} ${styles["black-background"]}`}
                >
                  {t("Save")}
                </button>
              </div>
              <div style={{ marginBottom: "20px", marginTop: "0rem" }}>
                <button
                  type="submit"
                  onClick={handleBackButtonClick}
                  className={`${styles["preview-button"]} ${styles["black-background"]}`}
                >
                  {t("Back")}
                </button>
              </div>
              {showPrintButton && (
                <ReactToPrint
                  trigger={() => (
                    <button
                      className={`${styles["preview-button"]} ${styles["blue-background"]}`}
                    >
                      {t("Print")}
                    </button>
                  )}
                  content={() => componentRef.current}
                />
              )}
            </div>
          </div>
        </div>

        <div className="w-[77.5rem] bg-gradient-to-b from-black to-gray-300 p-8">
          <PrintSpendComponent
            ref={componentRef}
            title={title}
            content={<p></p>}
          />
        </div>
      </div>
    </>
  );
};

export default SpendReceiptPreview;
