import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "../services/api/api";

interface Employee {
  id: number;
  department_id: number;
  branch_id: number;
  user_id: number;
  number: string;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  description?: string | null;
  address: string;
  city_id: number;
  country_id?: number | null;
  position: string;
  type: string;
  salary: number;
  hourly_rate?: number | null;
  level: string;
  contract_type: string;
  contract_start_date: string; // Assuming you'll handle date as string
  contract_end_date?: string | null; // Assuming you'll handle date as string
  created_at: string; // Assuming you'll handle date as string
  updated_at: string; // Assuming you'll handle date as string
}

interface EmployeeState {
  employees: Employee[];
  loading: "idle" | "pending" | "succeeded" | "failed";
  error: string | null;
}

const initialState: EmployeeState = {
  employees: [],
  loading: "idle",
  error: null,
};

export const fetchEmployeesAsync = createAsyncThunk(
  "employee/fetchEmployees",
  async () => {
    const response = await axios.get("/employees");
    console.log("response", response);
    return response.data.data as Employee[];
  }
);

const employeeSlice = createSlice({
  name: "employee",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchEmployeesAsync.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(
        fetchEmployeesAsync.fulfilled,
        (state, action: PayloadAction<Employee[]>) => {
          state.employees = action.payload;
          state.loading = "succeeded";
          state.error = null;
        }
      )
      .addCase(fetchEmployeesAsync.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.error.message ?? "An error occurred";
      });
  },
});

export default employeeSlice.reducer;
