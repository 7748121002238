import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  createSelector,
} from "@reduxjs/toolkit";
import axios from "../services/api/api";

interface Customer {
  id: number;
  userId: number;
  firstName: string;
  lastName: string;
  identityNo: string;
  email: string;
  phoneNumber: string;
  cityId: number;
  countryId: number;
}

interface CustomerState {
  customers: Customer[];
  loading: "idle" | "pending" | "succeeded" | "failed";
  error: string | null;
}

const initialState: CustomerState = {
  customers: [],
  loading: "idle",
  error: null,
};

export const fetchCustomersAsync = createAsyncThunk(
  "customer/fetchCustomers",
  async () => {
    const response = await axios.get("/customers");
    console.log("responseOfCustomers=", response.data.data);
    const customers: Customer[] = response.data.data.map((customer: any) => {
      return {
        id: customer.id,
        userId: customer.user_id,
        firstName: customer.first_name,
        lastName: customer.last_name,
        identityNo: customer.identityNo,
        email: customer.email,
        phoneNumber: customer.phone_number,
        cityId: customer.city_id,
        countryId: customer.country_id,
      };
    });
    return customers;
  }
);

export const createCustomerAsync = createAsyncThunk(
  "customer/createCustomer",
  async (customerData: Customer) => {
    const dataToSend = {
      user_id: customerData.userId,
      first_name: customerData.firstName,
      last_name: customerData.lastName,
      identityNo: customerData.identityNo,
      email: customerData.email,
      phone_number: customerData.phoneNumber,
      description: "customer",
      address: "Erbil",
      city_id: 1,
      country_id: 1,
    };
    const response = await axios.post("/customers", dataToSend);
    return response.data as Customer;
  }
);

export const findCustomerByIdAsync = createAsyncThunk(
  "customer/findCustomerById",
  async (customerId: any) => {
    const response = await axios.get(`/customers/${customerId}`);
    console.log("customer", response.data.data);
    return response.data.data as Customer;
  }
);

const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomersAsync.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(
        fetchCustomersAsync.fulfilled,
        (state, action: PayloadAction<Customer[]>) => {
          state.customers = action.payload;
          state.loading = "succeeded";
          state.error = null;
        }
      )
      .addCase(fetchCustomersAsync.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.error.message ?? "An error occurred";
      })
      .addCase(findCustomerByIdAsync.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(
        findCustomerByIdAsync.fulfilled,
        (state, action: PayloadAction<Customer>) => {
          state.customers.push(action.payload);
          state.loading = "succeeded";
          state.error = null;
        }
      )
      .addCase(findCustomerByIdAsync.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.error.message ?? "An error occurred";
      });
  },
});

export const selectCustomerById = (customerId: number) =>
  createSelector(
    (state: { customer: { customers: any[] } }) => state.customer.customers,
    (customers) => {
      if (customers && customers.length > 0) {
        const selectedCustomer = customers.find(
          (customer: { id: number }) => customer.id === customerId
        );

        console.log("selectedCustomer=", selectedCustomer);

        if (selectedCustomer) {
          return {
            id: selectedCustomer.id,
            userId: selectedCustomer.userId,
            firstName: selectedCustomer.firstName,
            lastName: selectedCustomer.lastName,
            identityNo: selectedCustomer.identityNo,
            email: selectedCustomer.email,
            phoneNumber: selectedCustomer.phoneNumber,
            cityId: selectedCustomer.cityId,
            countryId: selectedCustomer.countryId,
          };
        }
      }

      // If no customer found or customers array is empty, return undefined or null
      // Alternatively, you can return an empty object {} or handle as needed
      return null; // Return null if no customer found
    }
  );

export default customerSlice.reducer;
