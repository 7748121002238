import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Pagination, DatePicker, Tooltip, message } from "antd";
import { RootState } from "../../redux/store";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import moment from "moment";
import { IoMdCreate } from "react-icons/io";
// import { IoPrint } from "react-icons/io5";
import BottomNavigationButton from "../../components/common/bottomNavigationButton/BottomNavigationButton";
import {
  fetchAllContractData,
  fetchAllContractDataByUser,
} from "../../redux/rentalContractSlice";
import ProjectDetails from "../../components/projectDetails/ProjectDetails";
import UnitTypeDetails from "../../components/unitTypeDetails/UnitTypeDetails";
import { fetchCustomersAsync } from "../../redux/customerSlice";
import { fetchUsersAsync } from "../../redux/userSlice";
import { fetchUnitTypesAsync } from "../../redux/unitTypeSlice";
import { fetchPropertyNumbersAsync } from "../../redux/propertyNumberSlice";
import { fetchProjectsAsync } from "../../redux/projectSlice";

const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

const RentArchiveResultPage: React.FC = () => {
  const dispatch: ThunkDispatch<RootState, any, any> = useDispatch();
  const { t } = useTranslation();

  const location = useLocation();
  const profile = location.state?.profile;
  console.log("hacker_it", profile);

  const [, setLoading] = useState(true);
  const [searchCriteria, setSearchCriteria] = useState("");
  const [dateRange, setDateRange] = useState<
    [moment.Moment | null, moment.Moment | null]
  >([null, null]);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 8;

  const allContractData = useTypedSelector(
    (state) => state.rentalContract.allContractData
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (profile?.role !== "founder" && profile?.role !== "admin") {
          await dispatch(fetchAllContractDataByUser(profile?.id));
        } else {
          await dispatch(fetchAllContractData());
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching contract data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch, profile]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        await Promise.all([
          dispatch(fetchCustomersAsync()),
          dispatch(fetchUsersAsync()),
          dispatch(fetchUnitTypesAsync()),
          dispatch(fetchPropertyNumbersAsync()),
          dispatch(fetchProjectsAsync()),
        ]);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch]);

  const filterData = (data: any[] | null) => {
    return data?.filter((contract) => {
      return Object.values(contract).some((value) => {
        if (
          typeof value === "string" &&
          value.toLowerCase().includes(searchCriteria.toLowerCase())
        ) {
          return true;
        }
        if (
          value &&
          typeof value === "object" &&
          "first_name" in value &&
          "last_name" in value &&
          "phone_number" in value
        ) {
          const namedValue = value as {
            first_name: string;
            last_name: string;
            phone_number: string;
          };
          return (
            (namedValue.first_name &&
              namedValue.first_name
                .toLowerCase()
                .includes(searchCriteria.toLowerCase())) ||
            (namedValue.last_name &&
              namedValue.last_name
                .toLowerCase()
                .includes(searchCriteria.toLowerCase())) ||
            (namedValue.phone_number &&
              namedValue.phone_number
                .toLowerCase()
                .includes(searchCriteria.toLowerCase()))
          );
        }
        return false;
      });
    });
  };

  const filterByDateRange = (data: any[] | null | undefined) => {
    if (data === undefined) {
      return null; // Treat undefined as null
    }

    if (!dateRange[0] || !dateRange[1]) return data; // If no date range selected, return all data

    const startDate = dateRange[0].format("YYYY-MM-DD");
    const endDate = dateRange[1].format("YYYY-MM-DD");

    return data?.filter((contract) => {
      const contractDate = moment
        .utc(contract.created_at)
        .local()
        .format("YYYY-MM-DD");
      return moment(contractDate).isBetween(startDate, endDate, null, "[]");
    });
  };
  console.log("allContractData", allContractData);
  const indexOfLastItem = currentPage * pageSize;
  const indexOfFirstItem = indexOfLastItem - pageSize;
  const filteredData = Array.isArray(allContractData)
    ? filterByDateRange(filterData(allContractData))
    : [];

  console.log("filteredData=", filteredData);
  const currentItems = filteredData?.slice(indexOfFirstItem, indexOfLastItem);

  const paginationStyles = {
    fontFamily: "Helvetica, Arial, sans-serif",
    backgroundColor: "#f8f8f8",
    border: "none",
    borderRadius: "5px",
    padding: "8px",
  };

  const handleDateRangeChange = (dates: any, dateStrings: [string, string]) => {
    setDateRange(dates);
  };

  const handleResetFilters = () => {
    setSearchCriteria("");
    setDateRange([null, null]);
  };

  const handleEdit = (contract: any) => {
    if (true) {
      message.info(t("Sorry, you don't have permission to edit"));
      return;
    }
  };

  // const handlePrint = (contractId: number) => {
  //   dispatch({ type: RESET_RENTAL_CONTRACT_DATA });
  //   navigate(`/rental-contract-preview/${contractId}`);
  // };

  const formatDate = (dateString: string | number | Date) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
  };

  console.log("currentItems");

  return (
    <div className="flex flex-col p-6 overflow-x-auto">
      <div className="">
        <div className="flex items-center mb-4">
          <div className="relative">
            <input
              type="text"
              placeholder={t("Search")}
              value={searchCriteria}
              onChange={(e) => setSearchCriteria(e.target.value)}
              className="border border-gray-300 bg-white h-8 px-5 pr-10 rounded-md text-sm focus:outline-none"
            />
            <button
              className="absolute right-0 top-0 mt-[0.5rem] mr-4"
              onClick={() => setSearchCriteria("")}
            >
              <svg
                className="h-4 w-4 fill-current"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M17.172 5.172a1 1 0 0 1 1.414 1.414L13.414 12l4.172 4.172a1 1 0 1 1-1.414 1.414L12 13.414l-4.172 4.172a1 1 0 1 1-1.414-1.414L10.586 12 6.414 7.828a1 1 0 1 1 1.414-1.414L12 10.586l4.172-4.172z"
                />
              </svg>
            </button>
          </div>
          {/* <BsFilter size={20} className="text-gray-600 cursor-pointer" onClick={handleFilterClick} /> */}
          <div>
            <DatePicker.RangePicker
              style={{ marginRight: 16 }}
              onChange={handleDateRangeChange}
              format="YYYY-MM-DD"
              placeholder={[t("Start Date"), t("End Date")]}
            />
          </div>
          <button
            onClick={handleResetFilters}
            className="mr-3 bg-gray-200 text-gray-700 rounded-full py-1 px-3 focus:outline-none hover:bg-gray-300"
          >
            {t("Reset Filters")}
          </button>
        </div>
        <table className="min-w-full divide-y divide-gray-200 shadow-md overflow-hidden mb-1">
          <thead className="bg-gray-100">
            <tr>
              <th className="px-6 py-3 whitespace-nowrap text-right text-sm font-semibold text-gray-600 uppercase tracking-wider">
                {t("Edit")}
              </th>
              {/* <th className="px-6 py-3 whitespace-nowrap text-right text-sm font-semibold text-gray-600 uppercase tracking-wider">
                {t("Print")}
              </th> */}
              <th className="px-6 py-3 whitespace-nowrap text-sm text-center font-semibold text-gray-600 uppercase tracking-wider">
                {t("First Side Property Owner")}
              </th>
              <th className="px-6 py-3 whitespace-nowrap text-right text-sm font-semibold text-gray-600 uppercase tracking-wider">
                {t("Second Side Tenant")}
              </th>
              <th className="px-6 py-3 whitespace-nowrap text-sm text-center font-semibold text-gray-600 uppercase tracking-wider">
                {t("Projects")}
              </th>
              <th className="px-6 py-3 whitespace-nowrap text-sm text-center font-semibold text-gray-600 uppercase tracking-wider">
                {t("Property Type")}
              </th>
              <th className="px-6 py-3 whitespace-nowrap text-sm text-center font-semibold text-gray-600 uppercase tracking-wider">
                {t("Unit Type")}
              </th>
              <th className="px-6 py-3 whitespace-nowrap text-sm text-center font-semibold text-gray-600 uppercase tracking-wider">
                {t("Unit Area")}
              </th>
              {/* <th className="px-6 py-3 whitespace-nowrap text-sm text-center font-semibold text-gray-600 uppercase tracking-wider">
                {t("Property Number")}
              </th> */}
              <th className="px-6 py-3 whitespace-nowrap text-sm text-center font-semibold text-gray-600 uppercase tracking-wider">
                {t("Monthly Rent Payment")}
              </th>
              <th className="px-6 py-3 whitespace-nowrap text-sm text-center font-semibold text-gray-600 uppercase tracking-wider">
                {t("Number of Months")}
              </th>
              <th className="px-6 py-3 whitespace-nowrap text-sm text-center font-semibold text-gray-600 uppercase tracking-wider">
                {t("Insurance")}
              </th>
              <th className="px-6 py-3 whitespace-nowrap text-sm text-center font-semibold text-gray-600 uppercase tracking-wider">
                {t("Date of Contract")}
              </th>
              <th className="px-6 py-3 whitespace-nowrap text-sm text-center font-semibold text-gray-600 uppercase tracking-wider">
                {t("Contract number")}
              </th>
              <th className="px-6 py-3 whitespace-nowrap text-sm text-center font-semibold text-gray-600 uppercase tracking-wider">
                {t("Contract Beginning")}
              </th>
              <th className="px-6 py-3 whitespace-nowrap text-sm text-center font-semibold text-gray-600 uppercase tracking-wider">
                {t("Contract Ending")}
              </th>
              <th className="px-6 py-3 whitespace-nowrap text-right text-sm font-semibold text-gray-600 uppercase tracking-wider">
                {t("Note")}
              </th>
            </tr>
          </thead>
          <tbody
            className="bg-white divide-y divide-gray-200"
            style={{ backgroundColor: "#f8f8f8" }}
          >
            {currentItems?.length ? (
              currentItems?.map((contract) => (
                <tr
                  key={contract.id}
                  className="hover:bg-gray-50 transition-colors duration-200"
                >
                  <td className="px-6 py-4 whitespace-nowrap">
                    <Tooltip title={t("Edit")} placement="top">
                      <button
                        className="flex items-center bg-gray-100 text-gray-700 py-1 px-2 rounded-lg border border-gray-300 hover:bg-gray-200"
                        onClick={() => handleEdit(contract.id)}
                      >
                        <IoMdCreate size={16} className="mr-1" />
                        {t("Edit")}
                      </button>
                    </Tooltip>
                  </td>
                  {/* Print button column */}
                  {/* <td className="px-6 py-4 whitespace-nowrap">
                    <Tooltip title={t("Print")} placement="top">
                      <button
                        className="flex items-center bg-gray-100 text-gray-700 py-1 px-2 rounded-lg border border-gray-300 hover:bg-gray-200"
                        onClick={() => handlePrint(contract.id)}
                      >
                        <IoPrint size={16} className="mr-1" />
                        {t("Print")}
                      </button>
                    </Tooltip>
                  </td> */}
                  <td className="px-6 py-4 whitespace-nowrap text-sm">{`${contract?.customer?.first_name} ${contract?.customer?.last_name}`}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm">{`${contract?.customer?.first_name} ${contract?.customer?.last_name}`}</td>
                  <ProjectDetails receipt={contract} />
                  <td className="px-6 py-4 whitespace-nowrap text-sm ">
                    {contract?.propertyType}
                  </td>
                  <UnitTypeDetails receipt={contract} />
                  <td className="px-6 py-4 whitespace-nowrap text-sm ">
                    {contract?.unitArea}
                  </td>
                  {/* <PropertyNumberDetails receipt={contract} /> */}
                  <td className="px-6 py-4 whitespace-nowrap text-sm ">
                    {contract?.monthlyRentPayment ?? "1000"}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm ">
                    {contract?.numberOfMonths}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm ">
                    {contract?.insurance}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm">
                    {contract?.created_at
                      ? formatDate(contract.created_at)
                      : ""}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm ">
                    {contract?.receiptNumber}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm">
                    {contract?.contractBeginning
                      ? formatDate(contract.contractBeginning)
                      : ""}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm">
                    {contract?.contractEnding
                      ? formatDate(contract.contractEnding)
                      : ""}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm">
                    {contract?.note ? (
                      contract.note
                    ) : (
                      <span className="">{t("No additional notes")}</span>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan={7}
                  className="px-6 py-4 whitespace-nowrap text-center text-gray-500"
                >
                  {t("No data available")}
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <Pagination
          style={paginationStyles}
          current={currentPage}
          pageSize={pageSize}
          total={filteredData?.length}
          onChange={(page) => setCurrentPage(page)}
        />
        <div className="flex justify-center -mt-14">
          <BottomNavigationButton content="Main Menu" to="/main" />
          <BottomNavigationButton content="Finance" to="/finance" />
          <BottomNavigationButton content="Contracts" to="/contracts" />
          <BottomNavigationButton content="Archive" to="/rent-archive" />
          <BottomNavigationButton
            content="Office Archive"
            to="/rent-archive-result"
          />
        </div>
      </div>
    </div>
  );
};

export default RentArchiveResultPage;
