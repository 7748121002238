import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "../services/api/api";

interface Estate {
  id: number;
  name: string;
}

interface EstateState {
  estates: Estate[];
  loading: "idle" | "pending" | "succeeded" | "failed";
  error: string | null;
}

const initialState: EstateState = {
  estates: [],
  loading: "idle",
  error: null,
};

export const fetchEstatesAsync = createAsyncThunk(
  "estate/fetchEstates",
  async () => {
    const response = await axios.get("/realEstates"); // backend endpoint
    return response.data.data as Estate[];
    // return realEstates as RealEstate[];
  }
);

const estatesSlice = createSlice({
  name: "estate",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchEstatesAsync.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(
        fetchEstatesAsync.fulfilled,
        (state, action: PayloadAction<Estate[]>) => {
          state.estates = action.payload;
          state.loading = "succeeded";
          state.error = null;
        }
      )
      .addCase(fetchEstatesAsync.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.error.message ?? "An error occurred";
      });
  },
});

export default estatesSlice.reducer;
