import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "../services/api/api";

interface ReceiptState {
  receiveReceiptData: ReceiveReceiptData | null;
  allReceiveReceipts: ReceiveReceiptData[] | null;
  loading: boolean;
  error: string | null;
}

interface ReceiveReceiptData {
  id: number;
  customer_id: number;
  receiptNumber: number;
  amountOf: string;
  amountInWords: string;
  employee_id: number;
  category_id: number;
  unitType_id: number;
  property_number_id: number;
  currency_id: number;
  categoryUnitType: string;
  radioOption: string;
  rentingFixedAssets: string;
  project_id: number;
  note: string;
}

const initialState: ReceiptState = {
  receiveReceiptData: null,
  allReceiveReceipts: null,
  loading: false,
  error: null,
};

export const sendReceiptData = createAsyncThunk(
  "receiveReceipt/sendReceiptData",
  async (data: ReceiveReceiptData, thunkAPI) => {
    try {
      console.log("request payload", data);
      const response = await axios.post("/receiveReceipt", data);

      return response.data.data;
    } catch (error: unknown) {
      if (typeof error === "object" && error && "response" in error) {
        return thunkAPI.rejectWithValue((error as any).response.data);
      } else {
        return thunkAPI.rejectWithValue("Unknown error occurred");
      }
    }
  }
);

export const findReceiveReceiptDataById = createAsyncThunk(
  "receiveReceipt/findReceiveReceiptDataById",
  async (id: number, thunkAPI) => {
    try {
      const response = await axios.get(`/receiveReceipt/${id}`);
      console.log("response", response);
      return response.data.data;
    } catch (error: unknown) {
      if (typeof error === "object" && error && "response" in error) {
        return thunkAPI.rejectWithValue((error as any).response.data);
      } else {
        return thunkAPI.rejectWithValue("Unknown error occurred");
      }
    }
  }
);

export const getAllReceiveReceipts = createAsyncThunk(
  "receiveReceipt/getAllReceiveReceipts",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get("/receiveReceipt");
      return response.data.data;
    } catch (error: unknown) {
      console.error("Error fetching all receive  receipts:", error);
      return thunkAPI.rejectWithValue("Error fetching all receive  receipts");
    }
  }
);

export const getAllReceiveReceiptsByUserId = createAsyncThunk(
  "receiveReceiptByUserId/getAllReceiveReceiptsByUserId",
  async (userId: number, thunkAPI) => {
    try {
      const response = await axios.get(`/receiveReceipt/user/${userId}`);
      const data = response.data.data;

      if (data.length === 0) {
        return thunkAPI.rejectWithValue({
          message: "No receipts found for this user.",
          data: [],
        });
      }

      return data;
    } catch (error: unknown) {
      console.error("Error fetching receipts by user ID:", error);
      return thunkAPI.rejectWithValue("Error fetching  receipts by user ID");
    }
  }
);

export const RESET_RECEIVE_RECEIPT_DATA = "RESET_RECEIVE_RECEIPT_DATA";

const receiveReceiptSlice = createSlice({
  name: "receiveReceipt",
  initialState,
  reducers: {
    receiveReceipt: (state, action: PayloadAction<ReceiveReceiptData>) => {
      console.log("action.payload", action.payload);
      state.receiveReceiptData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(sendReceiptData.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(sendReceiptData.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(sendReceiptData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(getAllReceiveReceipts.fulfilled, (state, action) => {
      state.allReceiveReceipts = action.payload;
    });
    builder.addCase(RESET_RECEIVE_RECEIPT_DATA, (state) => {
      state.receiveReceiptData = null;
      state.error = null;
    });
    builder.addCase(getAllReceiveReceiptsByUserId.pending, (state) => {});
    builder.addCase(
      getAllReceiveReceiptsByUserId.fulfilled,
      (state, action) => {
        console.log("Received  receipts by user ID:", action.payload);
        state.allReceiveReceipts = action.payload;
      }
    );
    builder.addCase(getAllReceiveReceiptsByUserId.rejected, (state, action) => {
      if (
        action.payload &&
        typeof action.payload === "object" &&
        "message" in action.payload
      ) {
        console.warn(action.payload.message);
        state.allReceiveReceipts = [];
      } else {
        console.error("Error fetching  receipts by user ID:", action.payload);
        state.allReceiveReceipts = [];
      }
    });
  },
});

export const { receiveReceipt } = receiveReceiptSlice.actions;

export default receiveReceiptSlice.reducer;
