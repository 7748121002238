import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  createSelector,
} from "@reduxjs/toolkit";
import axios from "../services/api/api";

interface PropertyNumber {
  id: number;
  name: string;
}

interface PropertyNumberState {
  propertyNumbers: PropertyNumber[];
  loading: "idle" | "pending" | "succeeded" | "failed";
  error: string | null;
}

const initialState: PropertyNumberState = {
  propertyNumbers: [],
  loading: "idle",
  error: null,
};

export const fetchPropertyNumbersAsync = createAsyncThunk(
  "propertyNumber/fetchPropertyNumbers",
  async () => {
    try {
      const response = await axios.get("/propertyNumber");
      return response.data.data as PropertyNumber[];
    } catch (error) {
      throw new Error("Failed to fetch property numbers.");
    }
  }
);

export const selectPropertyNumberById = (propertyNumberId: number) =>
  createSelector(
    (state: { propertyNumber: PropertyNumberState }) =>
      state.propertyNumber.propertyNumbers,
    (propertyNumbers) =>
      propertyNumbers.find(
        (propertyNumber) => propertyNumber.id === propertyNumberId
      )
  );

const propertyNumberSlice = createSlice({
  name: "propertyNumber",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPropertyNumbersAsync.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(
        fetchPropertyNumbersAsync.fulfilled,
        (state, action: PayloadAction<PropertyNumber[]>) => {
          state.propertyNumbers = action.payload;
          state.loading = "succeeded";
          state.error = null;
        }
      )
      .addCase(fetchPropertyNumbersAsync.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.error.message ?? "An error occurred";
      });
  },
});

export default propertyNumberSlice.reducer;
