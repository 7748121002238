import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "../services/api/api";

interface ContractState {
  contractData: BuyingAndSellingContractData | null;
  allContractData: BuyingAndSellingContractData[] | null;
  loading: boolean;
  error: string | null;
}

interface BuyingAndSellingContractData {
  id?: number;
  receiptNumber: number;
  customer_id: number;
  user_id: number;
  propertyOwner_id: number;
  totalPrice: number;
  receivedDate: string;
  remainPayment: number;
  receivedAmount: number;
  remainDate: string;
  currency_id: number;
  unitType_id: number;
  unitArea: number;
  property_number_id: number;
  handoverDate: string;
  handoverPenalty: number;
  cancellationCompensation: number;
  cancellationPenalty: number;
  project_id: number;
  propertyType: string;
  unitNumber: number;
  contractLanguage: string;
  lawyerFullName: string;
  note?: string;
  customer?: Customer;
  property_owner?: PropertyOwner;
  created_at?: string;
}

interface Customer {
  id: number;
  user_id: number;
  first_name: string;
  last_name: string;
  identityNo: string;
  email: string;
  phone_number: string;
  description?: string;
  address?: string;
  city_id?: number;
  country_id?: number;
  created_at?: string;
  updated_at?: string;
}

interface PropertyOwner {
  id: number;
  first_name: string;
  last_name: string;
  identityNo: string;
  phone_number: string;
  city?: string;
  country?: string;
  email?: string;
  created_at?: string;
  updated_at?: string;
}

const initialState: ContractState = {
  contractData: null,
  allContractData: null,
  loading: false,
  error: null,
};

export const sendContractData = createAsyncThunk(
  "buyingAndSellingContract/sendContractData",
  async (data: BuyingAndSellingContractData, thunkAPI) => {
    try {
      const response = await axios.post("/buyingAndSellingContract", data);
      return response.data.data;
    } catch (error: unknown) {
      if (typeof error === "object" && error && "response" in error) {
        return thunkAPI.rejectWithValue((error as any).response.data);
      } else {
        return thunkAPI.rejectWithValue("Unknown error occurred");
      }
    }
  }
);

export const fetchAllContractData = createAsyncThunk(
  "buyingAndSellingContract/fetchAllContractData",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get("/buyingAndSellingContract");
      console.log("response.data.data", response.data.data);
      return response.data.data;
    } catch (error: unknown) {
      if (typeof error === "object" && error && "response" in error) {
        return thunkAPI.rejectWithValue((error as any).response.data);
      } else {
        return thunkAPI.rejectWithValue("Unknown error occurred");
      }
    }
  }
);

export const fetchAllContractDataByUser = createAsyncThunk(
  "buyingAndSellingContract/fetchAllContractDataByUser",
  async (userId: number, thunkAPI) => {
    try {
      const response = await axios.get("/buyingAndSellingContract");
      const allContractData: BuyingAndSellingContractData[] =
        response.data.data;

      const filteredData = allContractData.filter(
        (contract) => contract?.customer?.user_id === userId
      );
      console.log("filteredData", filteredData, allContractData);
      return filteredData;
    } catch (error: unknown) {
      if (typeof error === "object" && error && "response" in error) {
        return thunkAPI.rejectWithValue((error as any).response.data);
      } else {
        return thunkAPI.rejectWithValue("Unknown error occurred");
      }
    }
  }
);

export const fetchContractDataById = createAsyncThunk(
  "buyingAndSellingContract/fetchContractDataById",
  async (id: number, thunkAPI) => {
    try {
      const response = await axios.get(`/buyingAndSellingContract/${id}`);
      return response.data.data;
    } catch (error: unknown) {
      if (typeof error === "object" && error && "response" in error) {
        return thunkAPI.rejectWithValue((error as any).response.data);
      } else {
        return thunkAPI.rejectWithValue("Unknown error occurred");
      }
    }
  }
);

export const RESET_BUYING_AND_SELLING_CONTRACT_DATA =
  "RESET_BUYING_AND_SELLING_CONTRACT_DATA";

const buyingAndSellingContractSlice = createSlice({
  name: "buyingAndSellingContract",
  initialState,
  reducers: {
    buyingAndSellingContract: (
      state,
      action: PayloadAction<BuyingAndSellingContractData>
    ) => {
      state.contractData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(sendContractData.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(sendContractData.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(sendContractData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });

    builder.addCase(fetchAllContractData.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchAllContractData.fulfilled, (state, action) => {
      state.loading = false;
      state.allContractData = action.payload;
    });
    builder.addCase(fetchAllContractData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(fetchContractDataById.pending, (state) => {
      state.contractData = null;
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchContractDataById.fulfilled, (state, action) => {
      state.loading = false;
      state.allContractData = action.payload;
    });
    builder.addCase(fetchContractDataById.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(fetchAllContractDataByUser.pending, (state) => {
      state.loading = true;
      state.allContractData = null;
      state.error = null;
    });
    builder.addCase(fetchAllContractDataByUser.fulfilled, (state, action) => {
      state.loading = false;
      state.allContractData = action.payload;
    });
    builder.addCase(fetchAllContractDataByUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(RESET_BUYING_AND_SELLING_CONTRACT_DATA, (state) => {
      state.contractData = null;
    });
  },
});

export const { buyingAndSellingContract } =
  buyingAndSellingContractSlice.actions;

export default buyingAndSellingContractSlice.reducer;
