
const PrevArrow: React.FC = (props) => {
    return (
        // eslint-disable-next-line jsx-a11y/alt-text
        <img
        {...props}
        className="slick-arrow"
        style={{ left: "28px", zIndex: 1, fontSize: "24px", position: "absolute", top: "44%" }}
        src="/images/prevArrow.webp"
        />
    );
};

export default PrevArrow;