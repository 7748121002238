import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./MainPage.module.scss";
import {
  User,
  Users,
  Briefcase,
  Activity,
  Edit3,
  Monitor,
  BarChart2,
  Home,
  PieChart,
  Truck,
  Settings,
  AlertCircle,
} from "react-feather";
import BottomNavigationButton from "../../components/common/bottomNavigationButton/BottomNavigationButton";

const MainPage: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [newSalesCount] = useState(5);

  const [isHovered, setIsHovered] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const handleFinance = async () => {
    try {
      setIsLoading(true);
      await new Promise((resolve) => setTimeout(resolve, 250));

      navigate("/finance");
    } catch (error) {
      console.error("Error:", error);
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className={styles["grid-container"]}>
        {/* Row 1 */}
        <button
          className={`${styles["grid-item"]} ${styles["font-medium"]} ${styles["macbook-button"]}`}
        >
          <Home
            className={`${styles["icon"]} absolute top-5 right-8 thin-icon`}
            size={20}
          />
          {t("Sales")}
        </button>

        <button
          className={`${styles["grid-item"]} ${styles["font-medium"]} ${styles["macbook-button"]} relative`}
          onClick={handleFinance}
          disabled={isLoading}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          {isLoading ? (
            <div className={styles["loading-spinner"]} />
          ) : (
            <>
              {t("Finance")}
              {newSalesCount > 0 && (
                <div className={styles["notification-dot"]} />
              )}
              <BarChart2
                className={`${styles["icon"]} absolute top-5 right-8 ${isHovered ? styles["icon-hovered"] : ""}`}
                size={20}
              />
            </>
          )}
        </button>
        <button
          className={`${styles["grid-item"]} ${styles["font-medium"]} ${styles["macbook-button"]}`}
        >
          {t("Human Resource")}
          <Users
            className={`${styles["icon"]} absolute top-5 right-8`}
            size={20}
          />
        </button>
        <button
          className={`${styles["grid-item"]} ${styles["font-medium"]} ${styles["macbook-button"]}`}
        >
          {t("Project Management")}
          <Briefcase
            className={`${styles["icon"]} absolute top-5 right-8`}
            size={20}
          />
        </button>
        <button
          className={`${styles["grid-item"]} ${styles["font-medium"]} ${styles["macbook-button"]}`}
        >
          {t("Marketing")}
          <Activity
            className={`${styles["icon"]} absolute top-5 right-8`}
            size={20}
          />
        </button>
        <button
          className={`${styles["grid-item"]} ${styles["font-medium"]} ${styles["macbook-button"]}`}
        >
          {t("Creative")}
          <Edit3
            className={`${styles["icon"]} absolute top-6 right-8`}
            size={20}
          />
        </button>
        <button
          className={`${styles["grid-item"]} ${styles["font-medium"]} ${styles["macbook-button"]}`}
        >
          {t("Information Technology")}
          <Monitor
            className={`${styles["icon"]} absolute top-6 right-8`}
            size={20}
          />
        </button>
        <button
          className={`${styles["grid-item"]} ${styles["font-medium"]} ${styles["macbook-button"]} `}
        >
          {t("CRM")}
          <User
            className={`${styles["icon"]} absolute top-6 right-8`}
            size={20}
          />
        </button>
        <button
          className={`${styles["grid-item"]} ${styles["font-medium"]} ${styles["macbook-button"]}`}
        >
          {t("Business Development")}
          <PieChart
            className={`${styles["icon"]} absolute top-6 right-8`}
            size={20}
          />
        </button>
        <button
          className={`${styles["grid-item"]} ${styles["font-medium"]} ${styles["macbook-button"]}`}
        >
          {t("Overdue Tasks")}
          <AlertCircle
            className={`${styles["icon"]} absolute top-6 right-8`}
            size={20}
          />
        </button>

        {/* Row 4 */}
        <button
          className={`${styles["grid-item"]} ${styles["font-medium"]} ${styles["macbook-button"]}`}
        >
          {t("Warehouse")}
          <Settings
            className={`${styles["icon"]} absolute top-6 right-8`}
            size={20}
          />
        </button>
        <button
          className={`${styles["grid-item"]} ${styles["font-medium"]} ${styles["macbook-button"]}`}
        >
          {t("Logistic")}
          <Truck
            className={`${styles["icon"]} absolute top-6 right-8`}
            size={20}
          />
        </button>
      </div>
      <div className="text-center">
        <BottomNavigationButton content="Main Menu" to="/main" />
      </div>
    </>
  );
};

export default MainPage;
