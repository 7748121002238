import React, { ChangeEvent, useEffect, useState } from "react";
import {
  ErrorMessage,
  Field,
  FieldProps,
  Form,
  Formik,
  FormikHelpers,
} from "formik";
import styles from "./RentalContractPage.module.scss";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CustomSelect from "../../components/custom/CustomSelect";
import { fetchProjectsAsync } from "../../redux/projectSlice";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../redux/store";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import CustomerModal from "../../components/common/customerModal/customerModal";
import { fetchUnitTypesAsync } from "../../redux/unitTypeSlice";
import { fetchCitiesAsync } from "../../redux/citySlice";
import { fetchCountriesAsync } from "../../redux/countrySlice";
import { fetchCustomersAsync } from "../../redux/customerSlice";
import { fetchPropertyTypesAsync } from "../../redux/propertyTypeSlice";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchPropertyOwnersAsync } from "../../redux/propertyOwnerSlice";
import { fetchCurrenciesAsync } from "../../redux/currencySlice";
import { generateReceiptNumber } from "../../utils/helpers";
import { rentalContract } from "../../redux/rentalContractSlice";
import { fetchPropertyNumbersAsync } from "../../redux/propertyNumberSlice";
import BottomNavigationButton from "../../components/common/bottomNavigationButton/BottomNavigationButton";
import { toast } from "react-toastify";

interface Values {
  receiptNumber: number;
  customer_id: number;
  propertyOwner_id: number;
  currency_id: number;
  unitType_id: number;
  unitArea: number;
  contractBeginning: string;
  monthlyRentPayment: number;
  contractEnding: string;
  advancePayment: number;
  contractType: string;
  numberOfMonths: number;
  latePenalty: number;
  insurance: number;
  unitAddress: string;
  // handoverDate: string;
  handoverPenalty: number;
  project_id: number;
  courtCity: string;
  propertyType: string;
  property_number_id: number;
  contractLanguage: string;
  lawyerFullName: string;
  note?: string;
}

const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

const RentalContractPage = () => {
  const Navigate = useNavigate();
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    contractBeginning: Yup.date().required(t("Contract Beginning is required")),
    contractEnding: Yup.date().required(t("Contract Ending is required")),
    unitArea: Yup.number()
      .required(t("Unit Area is required"))
      .positive(t("Unit Area must be positive")),
    latePenalty: Yup.number()
      .positive(t("Late Penalty must be positive"))
      .required(t("Late Penalty is required")),
    insurance: Yup.number()
      .positive(t("Insurance must be positive"))
      .required(t("Insurance is required")),
    handoverPenalty: Yup.number()
      .positive(t("Handover Penalty must be positive"))
      .required(t("Handover Penalty is required")),
    // handoverDate: Yup.date().required(t("Handover Date is required")),
    propertyType: Yup.string().required(t("Property Type is required")),
    // channelLead: Yup.string().required(t("Channel Lead is required")),
    // lawyerFullName: Yup.string(),
  });

  const dispatch: ThunkDispatch<RootState, any, any> = useDispatch();

  const location = useLocation();
  const contractData = location.state?.contractData;

  const projects = useTypedSelector((state) => state.project.projects);
  const customers = useTypedSelector((state) => state.customer.customers);
  const currencies = useTypedSelector((state) => state.currency.currencies);
  const countries = useTypedSelector((state) => state.country.countries);
  const cities = useTypedSelector((state) => state.city.cities);
  const propertyNumbers = useTypedSelector(
    (state) => state.propertyNumber.propertyNumbers
  );
  console.log(propertyNumbers, "propertyNumbers");
  const unitTypes = useTypedSelector((state) => state.unitType.unitTypes);
  const [selectedLanguage] = useState<string>("En");

  const [monthlyRentPayment, setMonthlyRentPayment] = useState<number | "">("");
  const [numberOfMonths, setNumberOfMonths] = useState<number | "">("");
  const [advancePayment, setAdvancePayment] = useState<number>();

  useEffect(() => {
    if (monthlyRentPayment !== "" && numberOfMonths !== "") {
      const result = Number(monthlyRentPayment) * Number(numberOfMonths);
      setAdvancePayment(result);
    }
  }, [monthlyRentPayment, numberOfMonths]);

  const handleMonthlyRentPaymentChange = (e: ChangeEvent<HTMLInputElement>) => {
    setMonthlyRentPayment(
      e.target.value !== "" ? parseFloat(e.target.value) : ""
    );
  };

  const handleNumberOfMonthsChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNumberOfMonths(e.target.value !== "" ? parseInt(e.target.value) : "");
  };

  const [selectedCurrency, setSelectedCurrency] = useState<number>(
    contractData?.currency_id ?? 2
  );
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState<string>("");

  const handleCurrencyChange = (currencyId: number) => {
    setSelectedCurrency(currencyId);
  };

  useEffect(() => {
    dispatch(fetchProjectsAsync());
    dispatch(fetchUnitTypesAsync());
    dispatch(fetchCitiesAsync());
    dispatch(fetchCountriesAsync());
    dispatch(fetchCustomersAsync());
    dispatch(fetchPropertyOwnersAsync());
    dispatch(fetchPropertyTypesAsync());
    dispatch(fetchUnitTypesAsync());
    dispatch(fetchCountriesAsync());
    dispatch(fetchCitiesAsync());
    dispatch(fetchPropertyNumbersAsync());
    dispatch(fetchCurrenciesAsync());
  }, [dispatch]);

  const fetchCustomersAndPropertyOwnersAsync = createAsyncThunk(
    "customerAndPropertyOwner/fetchCustomersAndPropertyOwners",
    async () => {
      await Promise.all([
        dispatch(fetchCustomersAsync()),
        dispatch(fetchPropertyOwnersAsync()),
      ]);
    }
  );

  const propertyTypes = useTypedSelector(
    (state) => state.propertyType.propertyTypes
  );

  const initialValues: Values = contractData
    ? {
        customer_id: contractData.customer_id || 1,
        propertyOwner_id: contractData.propertyOwner_id || 1,
        receiptNumber: contractData.receiptNumber || 0,
        unitType_id: contractData.unitType_id || 0,
        unitArea: contractData.unitArea || null,
        unitAddress: contractData.unitAddress || "",
        contractBeginning: contractData.contractBeginning || "",
        contractEnding: contractData.contractEnding || "",
        advancePayment: contractData.advancePayment || 0,
        numberOfMonths: contractData.numberOfMonths || 0,
        monthlyRentPayment: contractData.monthlyRentPayment || 0,
        property_number_id: contractData.property_number_id || 0,
        insurance: contractData.insurance || 0,
        latePenalty: contractData.latePenalty || null,
        handoverPenalty: contractData.handoverPenalty || 0,
        currency_id: contractData.currency_id || 0,
        // handoverDate: contractData.handoverDate || "",
        courtCity: contractData.courtCity || "",
        project_id: contractData.project_id || 1,
        contractType: contractData.contractType || "rental",
        propertyType: contractData.propertyType || "",
        contractLanguage: contractData.contractLanguage || "",
        lawyerFullName: contractData.lawyerFullName || "",
        note: contractData.note || "",
      }
    : {
        customer_id: 0,
        propertyOwner_id: 0,
        receiptNumber: 0,
        monthlyRentPayment: null,
        unitType_id: 0,
        unitArea: null,
        unitAddress: "",
        contractBeginning: "",
        contractEnding: "",
        advancePayment: null,
        numberOfMonths: null,
        property_number_id: 0,
        insurance: null,
        latePenalty: null,
        handoverPenalty: null,
        currency_id: 2,
        // handoverDate: "",
        courtCity: "",
        project_id: 0,
        propertyType: "",
        contractType: "rental",
        contractLanguage: "",
        lawyerFullName: "",
        note: "",
      };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(
          values: Values,
          { setSubmitting }: FormikHelpers<Values>
        ) => {
          if (selectedCurrency !== undefined) {
            values.currency_id = selectedCurrency;
          }
          if (!monthlyRentPayment) {
            toast.error(t("Monthly Rent Payment is required"));
            return;
          }
          if (!numberOfMonths) {
            toast.error(t("Number Of Months is required"));
            return;
          }
          console.log("numberOfMonths", numberOfMonths);
          values.contractLanguage = selectedLanguage;
          values.numberOfMonths = numberOfMonths;
          if (advancePayment) {
            values.advancePayment = advancePayment;
          }
          if (monthlyRentPayment) {
            values.monthlyRentPayment = monthlyRentPayment;
          }
          values.receiptNumber = generateReceiptNumber();
          values.lawyerFullName = "بڵند";
          dispatch(rentalContract(values));
          Navigate("/rental-contract-preview");
          setTimeout(() => {
            // alert(JSON.stringify(values, null, 2));
            setSubmitting(false);
          }, 500);
        }}
      >
        <Form className={styles.grid} title={t("Rental Contract")}>
          <div className="overflow-y-auto h-[750px]">
            <div className="grid grid-cols-4 gap-y-4 p-6">
              <div className="p-6 bg-white">
                <Field name="propertyOwner_id" key={Math.random()}>
                  {({ field, form }: FieldProps<string>) => (
                    <CustomSelect
                      field={field}
                      form={form}
                      options={customers.map((propertyOwner) => ({
                        label: `${propertyOwner.firstName + " "}${propertyOwner.lastName}`,
                        value: propertyOwner.id,
                        key: `${propertyOwner.firstName + " "}${propertyOwner.lastName}`,
                      }))}
                      labelName="firstSidePropertyOwner"
                      name="propertyOwner_id"
                      mt={2}
                      creatable={false}
                    />
                  )}
                </Field>
              </div>
              <div className="p-6 bg-white">
                <div
                  onClick={() => {
                    setModalTitle(t("Create A New Property Owner"));
                    setModalOpen(true);
                  }}
                  className={`${styles["black-background"]} text-white w-[310px] h-[39.3px] border-none cursor-pointer text-16}`}
                >
                  <p className="pt-3 pb-2 pl-2 mt-12 text-center">
                    {t("Create A New Property Owner")}
                  </p>
                </div>
              </div>
              <div className="p-6 bg-white">
                <Field name="customer_id" key={Math.random()}>
                  {({ field, form }: FieldProps<string>) => (
                    <CustomSelect
                      field={field}
                      form={form}
                      options={customers.map((customer) => ({
                        label: `${customer.firstName + " "}${customer.lastName}`,
                        value: customer.id,
                        key: `${customer.firstName + " "}${customer.lastName}`,
                      }))}
                      labelName="secondSideTenant"
                      name="customer_id"
                      mt={2}
                      creatable={false}
                    />
                  )}
                </Field>
              </div>
              <div className="p-6 bg-white">
                <div
                  onClick={() => {
                    setModalTitle(t("Create A New Customer"));
                    setModalOpen(true);
                  }}
                  className={`${styles["black-background"]} text-white w-[310px] h-[39.3px] border-none cursor-pointer text-16}`}
                >
                  <p className="pt-3 pb-2 pl-2 mt-12 text-center">
                    {t("Create A New Tenant")}
                  </p>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-4 gap-y-4 pl-6 pr-6 -mt-1">
              <div className="p-6 bg-white mt-6 mb-6">
                <Field name="unitType_id" key={Math.random()}>
                  {({ field, form }: FieldProps<string>) => (
                    <CustomSelect
                      field={field}
                      form={form}
                      options={unitTypes.map((unitType, index) => ({
                        label: unitType.name,
                        value: unitType.id,
                        key: `${unitType.name}_${index}`,
                      }))}
                      labelName="unitType"
                      name="unitType_id"
                      creatable={true}
                      mt={2}
                      dispatchAction={() => dispatch(fetchUnitTypesAsync())}
                    />
                  )}
                </Field>
              </div>
              <div className="p-6 bg-white mb-4">
                <label htmlFor="unitArea" className="font-bold">
                  {t("Unit Area")}
                </label>
                <div className="flex items-center">
                  <Field
                    as="input"
                    id="unitArea"
                    name="unitArea"
                    type="number"
                    placeholder={t("Area m2")}
                    className="block w-full border border-gray-400 mt-2 p-2"
                  />
                </div>
                <ErrorMessage
                  name="unitArea"
                  component="div"
                  className={styles.error}
                />
              </div>
              <div className="p-6 bg-white mb-4">
                <Field name="property_number_id" key={Math.random()}>
                  {({ field, form }: FieldProps<string>) => (
                    <CustomSelect
                      field={field}
                      form={form}
                      options={propertyNumbers.map((propertyNumber, index) => ({
                        label: propertyNumber.name,
                        value: propertyNumber.id,
                        key: `${propertyNumber.name}_${index}`,
                      }))}
                      labelName="propertyNumber"
                      name="property_number_id"
                      creatable={true}
                      mt={2}
                      dispatchAction={() =>
                        dispatch(fetchPropertyNumbersAsync())
                      }
                    />
                  )}
                </Field>
              </div>
              <div className="p-6 bg-white mb-4">
                <div
                  className={`${styles["col-span-1"]} ${styles["form-group"]}`}
                >
                  <label className={`${styles["label"]} font-bold`}>
                    {t("Currency")}
                  </label>
                  <div className={`${styles["currency-toggle"]} mt-6`}>
                    {currencies?.map((currency) => (
                      <button
                        key={currency.id}
                        type="button"
                        onClick={() => handleCurrencyChange(currency.id)}
                        className={`${styles["currency-button"]} ${
                          selectedCurrency === currency.id
                            ? styles["currency-selected"]
                            : ""
                        }`}
                      >
                        {currency.name}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-4 gap-y-4 pl-6 pr-6 -mt-1">
              <div className="p-6 bg-white mb-4">
                <label htmlFor="contractBeginning" className="font-bold">
                  {t("Contract Beginning")}
                </label>
                <div className="relative">
                  <Field
                    as="input"
                    min="0"
                    id="contractBeginning"
                    name="contractBeginning"
                    type="date"
                    placeholder={t("Contract Beginning")}
                    className="block w-full border border-gray-400 mt-2 p-2 bg-gray-800 text-white"
                  />
                  <style>
                    {`
                    #contractBeginning::-webkit-calendar-picker-indicator {
                      filter: invert(1);
                    }
                  `}
                  </style>
                </div>
                <ErrorMessage
                  name="contractBeginning"
                  component="div"
                  className="text-red-500"
                />
              </div>
              <div className="p-6 bg-white mb-4">
                <label htmlFor="contractEnding" className="font-bold">
                  {t("Contract Ending")}
                </label>
                <div className="relative">
                  <Field
                    as="input"
                    min="0"
                    id="contractEnding"
                    name="contractEnding"
                    type="date"
                    placeholder={t("Contract Ending")}
                    className="block w-full border border-gray-400 mt-2 p-2 bg-gray-800 text-white"
                  />
                  <style>
                    {`
                    #contractEnding::-webkit-calendar-picker-indicator {
                      filter: invert(1);
                    }
                  `}
                  </style>
                </div>
                <ErrorMessage
                  name="contractEnding"
                  component="div"
                  className="text-red-500"
                />
              </div>
              <div className="p-6 bg-white mb-4">
                <label htmlFor="monthlyRentPayment" className="font-bold">
                  {t("Monthly Rent Payment")}
                </label>
                <div className="flex items-center">
                  <Field
                    as="input"
                    id="monthlyRentPayment"
                    name="monthlyRentPayment"
                    type="number"
                    placeholder={t("Amount of Rent Payment Monthly")}
                    className="block w-full border border-gray-400 mt-2 p-2"
                    onChange={handleMonthlyRentPaymentChange}
                    value={monthlyRentPayment}
                  />
                  {/* <span className="ml-3 mt-6 font-bold">USD</span> */}
                </div>
                <ErrorMessage
                  name="monthlyRentPayment"
                  component="div"
                  className="text-red-500"
                />
              </div>
              <div className="p-6 bg-white mb-4">
                <label htmlFor="numberOfMonths" className="font-bold">
                  {t("Number of Months")}
                </label>
                <div className="flex items-center">
                  <Field
                    as="input"
                    id="numberOfMonths"
                    name="numberOfMonths"
                    type="number"
                    placeholder={t(
                      "Number of Months to Receive Advance Payments"
                    )}
                    className="block w-full border border-gray-400 mt-2 p-2"
                    onChange={handleNumberOfMonthsChange}
                    value={numberOfMonths}
                    min="0"
                  />
                </div>
                <ErrorMessage
                  name="numberOfMonths"
                  component="div"
                  className="text-red-500"
                />
              </div>
            </div>
            <div className="grid grid-cols-4 gap-y-4 pl-6 pr-6 -mt-1">
              <div className="p-6 bg-white">
                <label htmlFor="advancePayment" className="font-bold">
                  {t("Advance Payment")}
                </label>
                <div className="flex items-center">
                  <Field
                    as="input"
                    id="advancePayment"
                    name="advancePayment"
                    type="number"
                    placeholder={t("Amount of Advance Payment")}
                    className="block w-full border border-gray-400 mt-2 p-2"
                    value={advancePayment}
                  />
                  {/* <span className="ml-3 mt-6 font-bold">USD</span> */}
                </div>
              </div>
              <div className="p-6 bg-white">
                <label htmlFor="latePenalty" className="font-bold">
                  {t("Late Penalty")}
                </label>
                <div className="flex items-center">
                  <Field
                    as="input"
                    id="latePenalty"
                    name="latePenalty"
                    type="number"
                    placeholder={t("Late Payment Penalty Per Day")}
                    className="block w-full border border-gray-400 mt-2 p-2"
                  />
                  <div className="ml-3">
                    <div className="flex flex-col">
                      {/* <span className="font-bold text-sm">USD</span>
                      <span className="text-sm font-bold text-black">
                        Per<span>-Day</span>
                      </span> */}
                    </div>
                  </div>
                </div>
                <ErrorMessage
                  name="latePenalty"
                  component="div"
                  className="text-red-500"
                />
              </div>
              <div className="p-6 bg-white">
                <label htmlFor="insurance" className="font-bold">
                  {t("Insurance")}
                </label>
                <div className="flex items-center">
                  <Field
                    as="input"
                    id="insurance"
                    name="insurance"
                    type="number"
                    placeholder={t("Insurance Payment")}
                    className="block w-full border border-gray-400 mt-2 p-2"
                  />
                </div>
                <ErrorMessage
                  name="insurance"
                  component="div"
                  className="text-red-500"
                />
              </div>
              <div className="p-6 bg-white">
                <label htmlFor="handoverPenalty" className="font-bold">
                  {t("Handover Penalty")}
                </label>
                <div className="flex items-center">
                  <Field
                    as="input"
                    id="handoverPenalty"
                    name="handoverPenalty"
                    type="number"
                    placeholder={t("Amount of Handover Penalty")}
                    className="block w-full border border-gray-400 mt-2 p-2"
                  />
                  <div className="ml-3">
                    <div className="flex flex-col">
                      {/* <span className="font-bold text-sm">USD</span>
                      <span className="text-sm font-bold text-black">
                        Per<span>-Day</span>
                      </span> */}
                    </div>
                  </div>
                </div>
                <ErrorMessage
                  name="handoverPenalty"
                  component="div"
                  className="text-red-500"
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-y-4 pl-6 pr-6 mt-5">
              <div className="p-6 bg-white">
                <Field name="project_id" key={Math.random()}>
                  {({ field, form }: FieldProps<string>) => (
                    <CustomSelect
                      field={field}
                      form={form}
                      options={projects.map((project, index) => ({
                        label: project.name,
                        value: project.id,
                        key: `${project.name}_${index}`,
                      }))}
                      labelName="projects"
                      name="project_id"
                      creatable={true}
                      mt={2}
                      dispatchAction={() => dispatch(fetchProjectsAsync())}
                    />
                  )}
                </Field>
              </div>
              <div className="p-6 bg-white">
                <Field name="propertyType" key={Math.random()}>
                  {({ field, form }: FieldProps<string>) => (
                    <CustomSelect
                      field={field}
                      form={form}
                      options={propertyTypes.map((propertyType) => ({
                        label: t(`${propertyType.name}`),
                        value: `${propertyType.name}`,
                        key: `${propertyType.name}`,
                      }))}
                      labelName="propertyType"
                      mt={2}
                      creatable={false}
                    />
                  )}
                </Field>
              </div>
            </div>
            <div className="grid grid-cols-3 gap-y-4 pl-6 pr-6 mt-5">
              <div className="p-6 bg-white">
                <label htmlFor="note" className="font-bold">
                  {t("Note")}
                </label>
                <Field
                  as="input"
                  id="note"
                  name="note"
                  type="text"
                  placeholder={t("Write Down Any Notes if Available")}
                  className="block w-full border border-gray-400 mt-2 p-2"
                />
              </div>
              <div className="p-6 bg-white mt-12 ml-5">
                <button
                  type="submit"
                  className="bg-gradient-to-b from-blue-900 to-blue-500 text-white w-[220px] h-[44px] border-none cursor-pointer text-16"
                >
                  Preview
                </button>
              </div>
            </div>
          </div>
        </Form>
      </Formik>
      <CustomerModal
        isModalOpen={isModalOpen}
        setModalOpen={setModalOpen}
        title={modalTitle}
        countries={countries}
        cities={cities}
        dispatchAction={() => {
          setTimeout(() => {
            dispatch(fetchCustomersAndPropertyOwnersAsync());
          }, 2000);
        }}
      />
      <div className="flex justify-center mt-5">
        <BottomNavigationButton content="Main Menu" to="/main" />
        <BottomNavigationButton content="Finance" to="/finance" />
        <BottomNavigationButton content="Contracts" to="/contracts" />
        <BottomNavigationButton
          content="Rental Contract"
          to="/rental-contracts"
        />
      </div>
    </>
  );
};

export default RentalContractPage;
