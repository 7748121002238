import React, { useEffect, useState } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetchEmployeesAsync } from "../../../redux/employeeSlice";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../../redux/store";
import { fetchPropertyOwnersAsync } from "../../../redux/propertyOwnerSlice";
import { fetchPropertyNumbersAsync } from "../../../redux/apartmentNumberSlice";
import { fetchProjectsAsync } from "../../../redux/projectSlice";
import { fetchCustomersAsync } from "../../../redux/customerSlice";
import axios from "../../../services/api/api";
import { useParams } from "react-router-dom";

interface PurchasingContractPrintProps {
  isCopy?: Boolean;
}

const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

const PurchasingContractPrint: React.FC<PurchasingContractPrintProps> = ({
  isCopy,
}) => {
  const dispatch: ThunkDispatch<RootState, any, any> = useDispatch();
  const [, setLoading] = useState(true);
  const { id } = useParams();
  const numericId = id ? parseInt(id) : undefined;

  const [currentDate, setCurrentDate] = useState("");

  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  const data = useSelector((state: any) => state);
  const contractData = data.buyingAndSellingContract.contractData;

  const customers = data.customer?.customers;
  const profile = useTypedSelector((state) => state.profile.profile);

  useEffect(() => {
    const fetchContractData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `/buyingAndSellingContract/${numericId}`
        );
        dispatch(fetchPropertyOwnersAsync());
        dispatch(fetchPropertyNumbersAsync());
        dispatch(fetchProjectsAsync());
        dispatch(fetchCustomersAsync());
        setReceiptNumber(response.data.data.receiptNumber);
        setProperty_number_id(response.data.data.property_number_id);
        setCustomer_id(response.data.data.customer_id);
        setPropertyOwner_id(response.data.data.propertyOwner_id);
        setProject_id(response.data.data.project_id);
        setReceivedAmount(response.data.data.receivedAmount);
        setRemainDate(response.data.data.remainDate);
        setRemainPayment(response.data.data.remainPayment);
        setHandoverDate(response.data.data.handoverDate);
        setHandoverPenalty(response.data.data.handoverPenalty);
        setCancellationCompensation(
          response.data.data.cancellationCompensation
        );
        setLawyerFullName(response.data.data.lawyerFullName);
        setAdvancePayment(response.data.data.advancePayment);
        setUnitArea(response.data.data.unitArea);
        setPropertyType(response.data.data.propertyType);
        setCurrency_id(response.data.data.currency_id);
        setReceivedDate(response.data.data.receivedDate);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching contract data:", error);
        setLoading(false);
      }
    };

    if (numericId !== undefined) {
      fetchContractData();
    }
  }, [dispatch, numericId]);

  const [, setReceiptNumber] = useState(
    contractData?.receiptNumber
  );
  const [customer_id, setCustomer_id] = useState(contractData?.customer_id);
  const [propertyOwner_id, setPropertyOwner_id] = useState(
    contractData?.propertyOwner_id
  );
  const [, setProject_id] = useState(contractData?.project_id);
  const [, setProperty_number_id] = useState(
    contractData?.property_number_id
  );
  const [, setReceivedAmount] = useState(
    contractData?.receivedAmount
  );
  const [, setRemainPayment] = useState(
    contractData?.remainPayment
  );
  const [, setAdvancePayment] = useState(
    contractData?.advancePayment
  );
  const [, setRemainDate] = useState(contractData?.remainDate);
  const [, setUnitArea] = useState(contractData?.unitArea);
  const [, setPropertyType] = useState(contractData?.propertyType);
  const [currency_id, setCurrency_id] = useState(contractData?.currency_id);
  const [, setReceivedDate] = useState(contractData?.receivedDate);
  const [, setHandoverDate] = useState(contractData?.handoverDate);
  const [, setHandoverPenalty] = useState(
    contractData?.handoverPenalty
  );
  const [cancellationCompensation, setCancellationCompensation] = useState(
    contractData?.cancellationCompensation
  );
  const [lawyerFullName, setLawyerFullName] = useState(
    contractData?.lawyerFullName
  );
  const propertyOwner = customers.find(
    (customer: { id: any }) => customer?.id === propertyOwner_id
  );
  const customer = customers.find(
    (customer: { id: any }) => customer?.id === customer_id
  );

  const employees = useTypedSelector((state) => state.employee.employees);

  useEffect(() => {
    // Function to get the current date in the desired format
    const getCurrentDate = () => {
      const date = new Date();
      const day = date.getDate();
      const month = date.getMonth() + 1; // Months are zero-based
      const year = date.getFullYear();

      return `${day} / ${month} / ${year}`;
    };

    // Set the current date when the component mounts
    setCurrentDate(getCurrentDate());
  }, []);

  useEffect(() => {
    // Simulate loading time with a timeout
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 1000); // Set the timeout duration as needed

    return () => clearTimeout(timeoutId); // Cleanup on component unmount
  }, []);

  const [employeeName, setEmployeeName] = useState<string | null>(null);

  useEffect(() => {
    dispatch(fetchEmployeesAsync());
  }, [dispatch]);

  useEffect(() => {
    if (profile) {
      const employee = employees.find((emp) => emp?.user_id === profile?.id);
      if (employee) {
        setEmployeeName(employee.first_name + " " + employee.last_name);
      }
    }
  }, [profile, employees]);

  const [
    receiptNumberContractLocalStorage,
    setReceiptNumberContractLocalStorage,
  ] = useState(localStorage.getItem("receiptNumber") || "");

  useEffect(() => {
    const handleStorageChange = () => {
      setReceiptNumberContractLocalStorage(
        localStorage.getItem("receiptNumber") || ""
      );
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setReceiptNumberContractLocalStorage(
        localStorage.getItem("receiptNumber") || ""
      );
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div className="flex items-center justify-center text-sm">
      {/* Content for the second column */}
      <div className="flex flex-col w-[870px] h-[710px] mt-6 mr-6 ml-6">
        {/* First Row */}
        <div
          className="bg-cover"
          style={{
            backgroundImage: 'url("/images/purchasingContractPrint.png")',
          }}
        >
          <div className="flex">
            {/* First Column */}
            <div className="flex-1 p-4 mt-5 ml-10">
              <p className="text-white text-base ml-[0.3rem]">
                <span>{receiptNumberContractLocalStorage ?? "00012"} :</span>
                ژ.گرێبەست
              </p>
              <div className="flex flex-row">
                <p className="text-white text-base mt-[2px] mr-1">
                  {currentDate}{" "}
                </p>
                <p className="text-white text-base">:بەروار</p>
              </div>
            </div>

            {/* Second Column */}
            <div className="flex-1 p-4">
              <p className="text-white">
              <img
  src="/images/shkarRealEstateWhiteLogo.webp"
  style={{ height: "80%", width: "80%" }}
  className="mt-[15px]"
  alt=""
/>
              </p>
            </div>

            {/* Third Column */}
            <div className="flex-1 pt-4 pb-4 text-right mr-8 mt-4">
              <p className="text-white text-xl">{t("Sales Base")}</p>
              <p className="text-white text-xl">{t("Shkar Company")}</p>
            </div>
          </div>
        </div>

        {/* Second Row */}
        <div
          className="flex-grow p-4 bg-cover"
          style={{
            backgroundImage: 'url("/images/purchasingAndSellingContract.png")',
          }}
        >
          <div className="grid grid-rows-2 grid-cols-2 gap-4">
            <div className="row-start-1 col-start-2 p-4 flex justify-center items-center">
              {/* Content for the first row */}
              <div className="grid grid-rows-2 -mt-10 justify-center items-center -ml-[22.3rem]">
                {/* First Row with One Column */}
                <div className="row-start-1 col-start-1">
                  {/* Content for the first row */}
                  <p
                    className="text-gray-646566 text-center text-base mt-[1.2rem]"
                    style={{ opacity: "7" }}
                  >
                    {t("Your Way for a Brighter Future")}
                  </p>
                </div>

                {/* Second Row */}
                <div
                  className="bg-cover h-[4rem] w-[21rem] -mt-3"
                  style={{
                    backgroundImage:
                      lang === "kr"
                        ? `url("/images/purchasingContractLabel_kr.png")`
                        : `url("/images/purchasingContractLabel_ar.png")`,
                  }}
                ></div>
              </div>
            </div>
          </div>
          <div
            className="grid grid-rows-18 grid-cols-1 gap-4 -mt-[4rem]"
            dir="rtl"
          >
            {/* Row 1 */}
            <div className="-mt-1 text-sm">
              9.{" "}
              {t(
                "Should either party to this contract experience regret, they agree to pay a compensation amount"
              )}
              ...{cancellationCompensation + " "}
              {currency_id === 2 ? "دۆلار" : "دینار"}...{" "}
              {t("to the other party")}
            </div>
            {/* Row 2 */}
            <div className="-mt-1 text-sm">
              {" "}
              {t(
                "thereby avoiding court proceedings or the need for a formal notice. This agreement shall be directly enforceable by the Implementation Directorate"
              )}
            </div>
            {/* Row 3 */}
            <div className="-mt-1 text-sm">
              {t("in the city of")} ..{contractData?.courtCity ?? t("Erbil")}..{" "}
              {t(
                "with the compensation amount determined by the Shkar Real Estate Company"
              )}
              .
            </div>
            {/* Row 4 */}
            <div>
              10.{" "}
              {t(
                "Any party responsible for clause No. (9) of this contract shall be obliged to pay commission to Shkar Real Estate Company on behalf of both parties"
              )}
              .
            </div>
            {/* <div className="-mt-1 text-sm"> */}
            {/* بۆ خانووبەرە لەبری هەردوو لایەن. */}
            {/* </div> */}
            {/* Row 5 */}
            <div className="-mt-1 text-sm ml-14 leading-relaxed">
              11.{" "}
              {t(
                "The Second Party is responsible for paying the checking and real estate registration fees if the property has cadastral registration. If the property is not under cadastral registration, the Second Party is obliged to pay the registration fee amount"
              )}
              .
            </div>
            {/* Row 6 */}
            {/* <div className="-mt-1 text-sm"> */}
            {/* تاپۆ نەبوو ئەوە لایەنی دووەم پاباندن بە پیدانی بڕی پارەی
              بەناوکردن. */}
            {/* </div> */}
            <div className="-mt-4 text-sm leading-relaxed">
              12.
              {""}
              {lang === "en" || lang === "kr" ? (
                <>
                  {t("The First Party must authorize the lawyer")} ..
                  {lawyerFullName}..{" "}
                  {t(
                    "to issue a Power of Attorney for the property at the Notary Public Office. This is required to settle the transactions and register the property in the name of the Second Party"
                  )}
                  .
                </>
              ) : (
                <>
                  {t("The First Party must authorize the lawyer ar")} ..
                  {lawyerFullName}..{" "}
                  {t(
                    "to issue a Power of Attorney for the property at the Notary Public Office. This is required to settle the transactions and register the property in the name of the Second Party"
                  )}
                  .
                </>
              )}
            </div>
            <div className="-mt-1 text-sm leading-relaxed">
              13.{" "}
              {t(
                "The First Party shall be obliged to pay a commission to Shkar Real Estate Company in exchange for the purchase of the property"
              )}
              .
            </div>
            <div className="-mt-1">
              14.{" "}
              {t(
                "The Second Party shall be obliged to pay a commission to Shkar Real Estate Company in exchange for the purchase of the property"
              )}
              .
            </div>
            <div className="-mt-1 text-sm leading-relaxed">
              15.{" "}
              {t(
                "In the event of any dispute regarding the content of this contract, both parties must first resolve the dispute through negotiations, but if it is not resolved through negotiations, it will be resolved through the court"
              )}
              .
            </div>
            <div className="-mt-1 text-sm">
              16.{" "}
              {t(
                "I, as the Second Party, have looked at this property carefully. I have bought this property from the First Party without any pressure and with my consent"
              )}
              .
            </div>
            <div className="mb-10"></div>
          </div>

          <div className="flex justify-center items-center mt-28 -mb-2">
            <div className="flex" dir="rtl">
              <div className="p-4">
                <p> {t("First Party")}:</p>
                <div className="text-center">
                  {propertyOwner?.firstName + " " + propertyOwner?.lastName}
                </div>
              </div>
              <div className="p-4 mr-24">
                <p> {t("Contract Organizer")}</p>
                <div className="text-center">
                  {employeeName ?? profile?.name}
                </div>
              </div>
              <div className="p-4 mr-24">
                <p> {t("Second Party")}</p>
                <div className="text-center">
                  {customer?.firstName + " " + customer?.lastName}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Fourth Row */}
        <div
          className="grid grid-cols-2 grid-rows-2 h-20 mt-11"
          style={{
            backgroundImage: 'url("/images/receiveRecieptFooter.png")',
          }}
        >
          <div className="flex items-center ml-[4.4rem]">
            <img
              src="/icons/email.png"
              alt="Logo"
              style={{ maxWidth: "35%", height: "35%" }}
              className="mr-2"
            />
            <div
              className="text-white mt-[3px]"
              style={{ letterSpacing: "1px" }}
            >
              INFO@SHKARREALESTATE.NET
            </div>
          </div>
          <div className="flex items-center ml-24">
            <img
              src="/icons/whatsUp.png"
              alt="Logo"
              style={{ maxWidth: "45%", height: "45%" }}
              className="mr-2"
            />
            <div
              className="text-white mt-[3px]"
              style={{ letterSpacing: "0.5px" }}
            >
              0750 300 33 66 - 0750 300 66 99
            </div>
          </div>
          <div className="flex items-center ml-[4.4rem]">
            <img
              src="/icons/web.png"
              alt="Logo"
              style={{ maxWidth: "45%", height: "45%" }}
              className="mr-2"
            />
            <div
              className="text-white mt-[3px]"
              style={{ letterSpacing: "1.1px" }}
            >
              WWW.SHKARREALESTATE.NET
            </div>
          </div>
          <div className="flex items-center ml-24">
            <img
              src="/icons/address.png"
              alt="Logo"
              style={{ maxWidth: "45%", height: "45%" }}
              className="mr-3"
            />
            <div
              className="text-white mt-[3px]"
              style={{ letterSpacing: "1.2px" }}
            >
              IRAQ - ERBIL - WAVEY AVENUE
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PurchasingContractPrint;
