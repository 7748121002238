import React from "react";
import QRCode from "qrcode.react";

interface QrCodeProps {
  size?: number;
}

const QRCodeComponent: React.FC<QrCodeProps> = ({ size }) => {
  const websiteUrl = "https://www.facebook.com/ShkarRealEstate";

  return (
    <div className="flex flex-col items-center justify-start">
      <QRCode value={websiteUrl} size={size} />
    </div>
  );
};

export default QRCodeComponent;
