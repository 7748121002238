import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./SafeBoxPage.module.scss"; // Updated import
import { useTranslation } from "react-i18next";

const SafeBoxPage: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleDeposit = () => {
    // Perform logic here if needed
    // Navigate to the "/deposit" page
    navigate("/deposit");
  };

  const handleWithdraw = () => {
    // Perform logic here if needed
    // Navigate to the "/withdraw" page
    navigate("/withdraw");
  };

  const handleExchangeRate = () => {
    // Perform logic here if needed
    // Navigate to the "/archive" page
    navigate("/exchange-rate");
  };

  const handleReview = () => {
    // Perform logic here if needed
    // Navigate to the "/review" page
    navigate("/review");
  };

  const createButton = (label: string, onClick: () => void) => (
    <button
      className={`${styles["grid-item"]} ${styles["font-medium"]}`}
      onClick={onClick}
    >
      {label}
    </button>
  );

  return (
    <div className={styles["center-container"]}>
      <div className={styles["grid-container"]}>
        {/* Row 1 */}
        {createButton(`${t("Deposit")}`, handleDeposit)}
        {createButton(`${t("Withdraw")}`, handleWithdraw)}
        {createButton(`${t("Exchange Rate")}`, handleExchangeRate)}
        {createButton(`${t("Review")}`, handleReview)}
      </div>
    </div>
  );
};

export default SafeBoxPage;
