import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./authSlice";
import spendReceiptReducer from "./spendReceiptSlice";
import receiveReceiptReducer from "./receiveReceiptSlice";
import customerReducer from "./customerSlice";
import employeeReducer from "./employeeSlice";
import receiveCommissionReceipt from "./receiveCommissionReceiptSlice";
import insuranceReceiveReceiptReducer from "./insuranceReceiveReceiptSlice";
import insuranceSpendReceipt from "./insuranceSpendReceiptSlice";
import rentReceiveReceipt from "./rentReceiveReceiptSlice";
import projectReducer from "./projectSlice";
import infoReducer from "./infoSlice";
import amountInWordReducer from "./amountInWordSlice";
import rentalSpendReceiptReducer from "./rentalSpendReceiptSlice";
import propertyNumberReducer from "./propertyNumberSlice";
import propertyTypeReducer from "./propertyTypeSlice";
// import channelLeadReducer from "./channelLeadSlice";
import propertyOwnershipReducer from "./propertyOwnershipSlice";
import spendCommissionReceiptReducer from "./spendCommissionReceiptSlice";
import cityReducer from "./citySlice";
import unitTypeReducer from "./unitTypeSlice";
import countriesReducer from "./countrySlice";
import buyingAndSellingContractReducer from "./buyingAndSellingContractSlice";
import rentalContractReducer from "./rentalContractSlice";
import profileReducer from "./profileSlice";
import currencyReducer from "./currencySlice";
import propertyOwnerReducer from "./propertyOwnerSlice";
import userReducer from "./userSlice";
import estateReducer from "./estatesSlice";

const store = configureStore({
  reducer: {
    auth: authReducer,
    spendReceipt: spendReceiptReducer,
    rentalSpendReceipt: rentalSpendReceiptReducer,
    receiveReceipt: receiveReceiptReducer,
    receiveCommissionReceipt: receiveCommissionReceipt,
    spendCommissionReceipt: spendCommissionReceiptReducer,
    insuranceReceiveReceipt: insuranceReceiveReceiptReducer,
    insuranceSpendReceipt: insuranceSpendReceipt,
    rentReceiveReceipt: rentReceiveReceipt,
    customer: customerReducer,
    employee: employeeReducer,
    project: projectReducer,
    info: infoReducer,
    amountInWord: amountInWordReducer,
    propertyNumber: propertyNumberReducer,
    propertyOwner: propertyOwnerReducer,
    propertyType: propertyTypeReducer,
    // channelLead: channelLeadReducer,
    propertyOwnership: propertyOwnershipReducer,
    city: cityReducer,
    country: countriesReducer,
    unitType: unitTypeReducer,
    buyingAndSellingContract: buyingAndSellingContractReducer,
    rentalContract: rentalContractReducer,
    profile: profileReducer,
    currency: currencyReducer,
    user: userReducer,
    estate: estateReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export default store;
