import React, { useEffect, useState } from "react";
import { PropagateLoader } from "react-spinners";
import "./PrintPurchasingContractComponent.module.scss";
import PurchasingContractPrint from "../../components/common/purchasingContractPrint/PurchasingContractPrint";

interface PrintComponentProps {
  content: React.ReactNode;
}

const PrintComponent = React.forwardRef<HTMLDivElement, PrintComponentProps>(
  ({ content }, ref) => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      const timeoutId = setTimeout(() => {
        setLoading(false);
      }, 1000);

      return () => clearTimeout(timeoutId);
    }, []);

    if (loading) {
      return (
        <div className="flex items-center justify-center mt-80">
          <PropagateLoader color="#4A90E2" size={15} />
        </div>
      );
    }

    return (
      <div ref={ref}>
        <PurchasingContractPrint />
      </div>
    );
  }
);

export default PrintComponent;
