import React, { useEffect } from "react";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./CommissionReceiveReceiptsPage.module.scss";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { receiveCommissionReceipt } from "../../redux/receiveCommissionReceiptSlice";
import {
  Formik,
  Field,
  Form,
  FormikHelpers,
  ErrorMessage,
  FieldProps,
} from "formik";
import CustomSelect from "../../components/custom/CustomSelect";
import { useTranslation } from "react-i18next";
import useTranslatedOptions from "../../translatedOptions";
import CustomerModal from "../../components/common/customerModal/customerModal";
import { RootState } from "../../redux/store";
import { fetchCustomersAsync } from "../../redux/customerSlice";
import { fetchEmployeesAsync } from "../../redux/employeeSlice";
import { ThunkDispatch } from "redux-thunk";
import { fetchPropertyNumbersAsync } from "../../redux/propertyNumberSlice";
import { fetchProjectsAsync } from "../../redux/projectSlice";
// @ts-ignore
import KurdishSoraniNumbersToWords from "kurdish-numbers-to-words/Sorani.js";
import { fetchCountriesAsync } from "../../redux/countrySlice";
import { fetchCitiesAsync } from "../../redux/citySlice";
import { fetchUnitTypesAsync } from "../../redux/unitTypeSlice";
import { fetchCurrenciesAsync } from "../../redux/currencySlice";
import { generateReceiptNumber } from "../../utils/helpers";
import { RESET_RECEIVE_RECEIPT_DATA } from "../../redux/receiveReceiptSlice";
import { RESET_RECEIVE_INSURANCE_RECEIPT_DATA } from "../../redux/insuranceReceiveReceiptSlice";
import { RESET_RENTAL_RECEIVE_RECEIPT_DATA } from "../../redux/rentReceiveReceiptSlice";
import { toast } from "react-toastify";
import BottomNavigationButton from "../../components/common/bottomNavigationButton/BottomNavigationButton";
import { fetchUsersAsync } from "../../redux/userSlice";

const { useState } = React;

interface Values {
  customer_id: number;
  receiptNumber: number;
  amountOf: string;
  amountInWords: string;
  employee_id: number;
  sales_employee_id: number;
  category_id: number;
  unitType_id: number;
  property_number_id: number;
  currency_id: number;
  saleOrRentPrice: number;
  project_id: number;
  categoryUnitType: string;
  radioOption: string;
  rentingFixedAssets: string;
  note: string;
}

const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

interface CommissionReceiveReceiptsProps {
  title: string;
}

const CommissionReceiveReceipts: React.FC<CommissionReceiveReceiptsProps> = ({
  title,
}) => {
  const dispatch: ThunkDispatch<RootState, any, any> = useDispatch();
  const customers = useTypedSelector((state) => state.customer.customers);
  const profile = useTypedSelector((state) => state.profile.profile);
  const projects = useTypedSelector((state) => state.project.projects);
  const unitTypes = useTypedSelector((state) => state.unitType.unitTypes);
  const propertyNumbers = useTypedSelector(
    (state) => state.propertyNumber.propertyNumbers
  );
  const countries = useTypedSelector((state) => state.country.countries);
  const cities = useTypedSelector((state) => state.city.cities);
  const currencies = useTypedSelector((state) => state.currency.currencies);
  const users = useTypedSelector((state) => state.user.users);
  const employees = useTypedSelector((state) => state.employee.employees);

  const location = useLocation();
  const availableReceiptData = location.state?.availableReceiptData;

  const [, setEmployeeId] = useState<number | null>(null);

  useEffect(() => {
    if (profile) {
      const employee = employees.find((emp) => emp?.user_id === profile?.id);
      if (employee) {
        setEmployeeId(employee.id);
      }
    }
  }, [profile, employees]);

  const { t, i18n } = useTranslation();

  const validationSchema = Yup.object().shape({
    customer_id: Yup.number().required(),
    receiptNumber: Yup.number().required(),
    employee_id: Yup.number().required(),
    sales_employee_id: Yup.number().required(),
    property_number_id: Yup.number().required(),
    project_id: Yup.number().required(),
    unitType_id: Yup.number().required(),
    currency_id: Yup.number().required(),
    saleOrRentPrice: Yup.number().min(99).required(),
    note: Yup.string(),
  });

  useEffect(() => {
    dispatch(fetchCustomersAsync());
    dispatch(fetchEmployeesAsync());
    dispatch(fetchPropertyNumbersAsync());
    dispatch(fetchProjectsAsync());
    dispatch(fetchCountriesAsync());
    dispatch(fetchCitiesAsync());
    dispatch(fetchUnitTypesAsync());
    dispatch(fetchCurrenciesAsync());
    dispatch(fetchUsersAsync());

    dispatch({ type: RESET_RECEIVE_RECEIPT_DATA });
    dispatch({ type: RESET_RECEIVE_INSURANCE_RECEIPT_DATA });
    dispatch({ type: RESET_RENTAL_RECEIVE_RECEIPT_DATA });
  }, [dispatch]);

  const navigate = useNavigate();

  const [isModalOpen, setModalOpen] = useState(false);

  const [selectedCurrency, setSelectedCurrency] = useState<number>(
    availableReceiptData?.currency_id ?? 2
  );
  const [selectedCategory, setSelectedCategory] = useState(
    availableReceiptData?.category_id
  );

  const [showCommissionOptions, setShowCommissionOptions] = useState(false);
  const [showRentsOptions, setShowRentsOptions] = useState(false);
  const [showRentingFixedAssets, setShowRentingFixedAssets] = useState(false);
  const [showUses, setShowUses] = useState(false);
  const [selectedRadio, setSelectedRadio] = useState("");
  const [
    showAdvertisingPrintingHospitality,
    setShowAdvertisingPrintingHospitality,
  ] = useState(false);
  const [showMiscellaneous, setShowMiscellaneous] = useState(false);
  const [
    showMiscellaneousServiceExpenses,
    setShowMiscellaneousServiceExpenses,
  ] = useState(false);
  const [showFuelAndOils, setShowFuelAndOils] = useState(false);
  const [
    showTransportationDispatchCommunications,
    setShowTransportationDispatchCommunications,
  ] = useState(false);

  const handleCurrencyChange = (currencyId: number) => {
    setSelectedCurrency(currencyId);
  };

  const handleCategoryChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    const selectedCategory = event.target.value;
    setSelectedCategory(selectedCategory);

    setShowCommissionOptions(selectedCategory === "commission");
    setShowRentsOptions(selectedCategory === "rents");
    setShowRentingFixedAssets(selectedCategory === "Renting Fixed Assets");
    setShowUses(selectedCategory === "uses");
    setShowAdvertisingPrintingHospitality(
      selectedCategory === "Advertising Printing Hospitality"
    );
    setShowMiscellaneous(selectedCategory === "miscellaneous");
    setShowMiscellaneousServiceExpenses(
      selectedCategory === "miscellaneousServiceExpenses"
    );
    setShowFuelAndOils(selectedCategory === "fuelAndOils");
    setShowTransportationDispatchCommunications(
      selectedCategory === "transportationDispatchCommunications"
    );
  };

  const {
    commercialOptions,
    residentialOptions,
    advertisingPrintingHospitalityOptions,
    miscellaneousOptions,
    miscellaneousServiceExpensesOptions,
    fuelAndOilsOptions,
    transportationDispatchCommunicationsOptions,
    categoriesOfCommission,
  } = useTranslatedOptions();

  const language = i18n.language;

  const [amountOf, setAmountOf] = useState<string>(
    availableReceiptData?.amountOf
  );
  const [amountInWords, setAmountInWords] = useState<string>(
    availableReceiptData?.amountInWords
  );

  const handleAmountOfChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setAmountOf(value);
    const parsedAmount = parseInt(value, 10);
    if (!isNaN(parsedAmount)) {
      const words = KurdishSoraniNumbersToWords.convert(parsedAmount);
      setAmountInWords(words);
    }
  };

  const initialValues = availableReceiptData
    ? {
        customer_id: availableReceiptData.customer_id || 1,
        receiptNumber: availableReceiptData.receiptNumber || 1,
        employee_id: availableReceiptData.employee_id || 1,
        sales_employee_id: availableReceiptData.sales_employee_id || 1,
        category_id: availableReceiptData.category_id || 1,
        amountOf: availableReceiptData.amountOf || "",
        categoryUnitType: availableReceiptData.categoryUnitType || "",
        amountInWords: availableReceiptData.amountInWords || "",
        property_number_id: availableReceiptData.property_number_id || 1,
        project_id: availableReceiptData.project_id || 1,
        unitType_id: availableReceiptData.unitType_id || 1,
        currency_id: availableReceiptData.currency_id || 1,
        saleOrRentPrice: availableReceiptData.saleOrRentPrice || 1,
        radioOption: availableReceiptData.radioOption || "",
        rentingFixedAssets: availableReceiptData.rentingFixedAssets || "",
        note: availableReceiptData.note || "",
      }
    : {
        customer_id: 0,
        receiptNumber: 1,
        employee_id: 1,
        sales_employee_id: 1,
        category_id: 1,
        amountOf: "",
        categoryUnitType: "",
        amountInWords: "",
        property_number_id: 0,
        project_id: 0,
        unitType_id: 0,
        currency_id: 1,
        saleOrRentPrice: null,
        radioOption: "",
        rentingFixedAssets: "",
        note: "",
      };

  console.log("users", users);

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(
          values: Values,
          { setSubmitting }: FormikHelpers<Values>
        ) => {
          if (!amountOf) {
            toast.error(t("amount of is required"));
            return;
          }
          if (!parseInt(selectedCategory)) {
            toast.error(t("category is required"));
            return;
          }
          if (selectedCurrency !== undefined) {
            values.currency_id = selectedCurrency;
          }
          values.currency_id = selectedCurrency!;
          values.employee_id = profile?.id ?? 1;
          values.amountOf = amountOf;
          values.amountInWords = amountInWords;
          values.category_id = parseInt(selectedCategory);
          values.receiptNumber = generateReceiptNumber();
          dispatch(receiveCommissionReceipt(values));
          navigate("/receive-receipt-preview", { state: { title } });
          setTimeout(() => {
            // alert(JSON.stringify(values, null, 2));
            setSubmitting(false);
          }, 500);
        }}
      >
        <div className="flex flex-col">
          <Form className={styles.grid}>
            <Field name="customer_id" key={Math.random()}>
              {({ field, form }: FieldProps<string>) => (
                <CustomSelect
                  field={field}
                  form={form}
                  options={customers.map((customer) => ({
                    label: `${customer.firstName + " "}${customer.lastName}`,
                    value: customer.id,
                    key: `${customer.firstName + " "}${customer.lastName}`,
                  }))}
                  labelName="receivedFrom"
                  name="customer_id"
                  creatable={false}
                />
              )}
            </Field>
            <ErrorMessage
              name="customer_id"
              component="div"
              className={styles.error}
            />
            <div className={`${styles["col-span-1"]} ${styles["form-group"]}`}>
              <label htmlFor="amountOf" className={styles.label}>
                {t("Amount Of")}
              </label>
              <Field
                id="amountOf"
                name="amountOf"
                placeholder={t("Amount of Paid to Us")}
                className={styles["input-field"]}
                onChange={handleAmountOfChange}
                value={amountOf}
              />
              <ErrorMessage
                name="amountOf"
                component="div"
                className={styles.error}
              />
            </div>
            <div className={`${styles["col-span-1"]} ${styles["form-group"]}`}>
              <label htmlFor="amountInWords" className={styles.label}>
                {t("Amount In Words")}
              </label>
              <Field
                id="amountInWords"
                name="amountInWords"
                placeholder={t("Amount of Paid to Us")}
                className={styles["input-field"]}
                value={amountInWords}
                readOnly
              />
              <ErrorMessage
                name="amountInWords"
                component="div"
                className={styles.error}
              />
            </div>
            <div className={`${styles["col-span-1"]} ${styles["form-group"]}`}>
              <label htmlFor="saleOrRentPrice" className={styles.label}>
                {t("Sale or Rent Price")}
              </label>
              <Field
                id="saleOrRentPrice"
                name="saleOrRentPrice"
                placeholder={t("Enter sale price...")}
                className={styles["input-field"]}
              />
              <ErrorMessage
                name="saleOrRentPrice"
                component="div"
                className={styles.error}
              />
            </div>
            <Field name="sales_employee_id" key={Math.random()}>
              {({ field, form }: FieldProps<string>) => (
                <CustomSelect
                  field={field}
                  form={form}
                  options={users.map((user: { id: number; name: string }) => ({
                    label: `${user.name}`,
                    value: user.id,
                    key: `${user.name}`,
                  }))}
                  labelName="employeeName"
                  name="sales_employee_id"
                />
              )}
            </Field>
            <div className={`${styles["col-span-1"]} ${styles["form-group"]}`}>
              <label htmlFor="receptor" className={styles.label}>
                {t("Receptor")}
              </label>
              <Field
                id="receptor"
                name="receptor"
                placeholder="Full Name"
                value={profile?.name}
                disabled
                className={styles["input-field"]}
              />
              <ErrorMessage
                name="receptor"
                component="div"
                className={styles.error}
              />
            </div>
            <div className={`${styles["col-span-1"]} ${styles["form-group"]}`}>
              <label htmlFor="category" className={styles.label}>
                <span className={styles.for}>{t("For")} </span>
                <span className={styles.database}>
                  {t("Store In Database")}
                </span>
              </label>
              <Field
                as="select"
                id="category"
                name="category"
                value={selectedCategory}
                onChange={handleCategoryChange}
                className={`${styles["input-field"]} ${styles["black-background"]}`}
              >
                {categoriesOfCommission.map((category) => (
                  <option
                    key={category.value}
                    value={category.id}
                    label={category.label}
                  >
                    {category.label}
                  </option>
                ))}
              </Field>
              <ErrorMessage
                name="category"
                component="div"
                className={styles.error}
              />
            </div>
            <div
              className={`${styles["col-span-1"]} ${styles["form-group"]} ml-0 mt-[1.9rem]`}
            >
              <div
                onClick={() => setModalOpen(true)}
                className={`${styles["black-background"]} text-white w-[auto] h-[2.67rem] border-none cursor-pointer text-16}`}
              >
                <p className="pt-3 pb-2 pl-2 text-center">
                  {t("Create A New Customer")}
                </p>
              </div>
            </div>
            <Field name="project_id" key={Math.random()}>
              {({ field, form }: FieldProps<string>) => (
                <CustomSelect
                  field={field}
                  form={form}
                  options={projects.map((project, index) => ({
                    label: project.name,
                    value: project.id,
                    key: `${project.name}_${index}`,
                  }))}
                  labelName="projects"
                  name="project_id"
                  creatable={true}
                  dispatchAction={() => dispatch(fetchProjectsAsync())}
                />
              )}
            </Field>
            <div
              className={`flex space-x-6 ${language === "en" ? "flex-row" : "flex-row-reverse"}`}
            >
              <div className={`w-[12.7rem] ${styles["form-group"]}`}>
                <Field name="unitType_id" key={Math.random()}>
                  {({ field, form }: FieldProps<string>) => (
                    <CustomSelect
                      field={field}
                      form={form}
                      options={unitTypes.map((unitType, index) => ({
                        label: unitType.name,
                        value: unitType.id,
                        key: `${unitType.name}_${index}`,
                      }))}
                      labelName="unitType"
                      name="unitType_id"
                      creatable={true}
                      dispatchAction={() => dispatch(fetchUnitTypesAsync())}
                    />
                  )}
                </Field>
              </div>
              <div className={`w-[12.7rem] ${styles["form-group"]}`}>
                <Field name="property_number_id" key={Math.random()}>
                  {({ field, form }: FieldProps<string>) => (
                    <CustomSelect
                      field={field}
                      form={form}
                      options={propertyNumbers.map((propertyNumber, index) => ({
                        label: propertyNumber.name,
                        value: propertyNumber.id,
                        key: `${propertyNumber.name}_${index}`,
                      }))}
                      labelName="propertyNumber"
                      name="property_number_id"
                      creatable={true}
                      dispatchAction={() =>
                        dispatch(fetchPropertyNumbersAsync())
                      }
                    />
                  )}
                </Field>
              </div>
            </div>
            <div className={`${styles["col-span-1"]} ${styles["form-group"]}`}>
              <label className={styles.label}>{t("Currency")}</label>
              <div className={styles["currency-toggle"]}>
                {currencies?.map((currency) => (
                  <button
                    key={currency.id}
                    type="button"
                    onClick={() => handleCurrencyChange(currency.id)}
                    className={`${styles["currency-button"]} ${
                      selectedCurrency === currency.id
                        ? styles["currency-selected"]
                        : ""
                    }`}
                  >
                    {currency.name}
                  </button>
                ))}
              </div>
            </div>
            <div className={`${styles["col-span-1"]} ${styles["form-group"]}`}>
              <label htmlFor="note" className={styles.label}>
                {t("Note")}
              </label>
              <Field
                id="note"
                name="note"
                placeholder={t("Write something")}
                className={styles["input-field"]}
              />
              <ErrorMessage
                name="note"
                component="div"
                className={styles.error}
              />
            </div>
            {/* Render radio buttons only when the selected category is "commission" */}
            <div
              className={`${styles["col-span-3"]} ${styles["form-group"]} ${styles["-mt-1"]} ${styles["flex-container"]}`}
            >
              <div
                className={`${styles["radio-group"]} ${styles["flex-container"]}`}
              >
                {showCommissionOptions && (
                  <div
                    className={`${styles["col-span-1"]} ${styles["form-group"]}`}
                  >
                    {/* <label className={styles.label}>Commission Options</label> */}
                    <div>
                      <label className={styles["radio-label"]}>
                        <Field
                          type="radio"
                          name="radioOption"
                          value="COMMERCIAL"
                          onClick={() => setSelectedRadio("COMMERCIAL")}
                        />
                        {t("COMMERCIAL")}
                      </label>
                      <label className={styles["radio-label"]}>
                        <Field
                          type="radio"
                          name="radioOption"
                          value="RESIDENTIAL"
                          onClick={() => setSelectedRadio("RESIDENTIAL")}
                        />
                        {t("RESIDENTIAL")}
                      </label>
                    </div>
                  </div>
                )}
                {showRentsOptions && (
                  <div
                    className={`${styles["col-span-1"]} ${styles["form-group"]}`}
                  >
                    {/* <label className={styles.label}>Rents Options</label> */}
                    <div>
                      <label className={styles["radio-label"]}>
                        <Field
                          type="radio"
                          name="radioOption"
                          value="COMMERCIAL"
                          onClick={() => setSelectedRadio("COMMERCIAL")}
                        />
                        {t("COMMERCIAL")}
                      </label>
                      <label className={styles["radio-label"]}>
                        <Field
                          type="radio"
                          name="radioOption"
                          value="RESIDENTIAL"
                          onClick={() => setSelectedRadio("RESIDENTIAL")}
                        />
                        {t("RESIDENTIAL")}
                      </label>
                    </div>
                  </div>
                )}
                {/* Conditionally render radio buttons without dropdown based on selected category */}
                {showRentingFixedAssets && (
                  <div
                    className={`${styles["col-span-3"]} ${styles["form-group"]} ${styles["-mt-1"]} ${styles["flex-container"]}`}
                  >
                    {/* First Column */}
                    <div
                      className={`${styles["radio-group"]} ${styles["flex-container"]}`}
                    >
                      <label className={styles["radio-label"]}>
                        <Field
                          type="radio"
                          name="radioOption"
                          value="RENTING BUILDINGS AND CONSTRUCTIONS"
                          onClick={() =>
                            setSelectedRadio(
                              "RENTING BUILDINGS AND CONSTRUCTIONS"
                            )
                          }
                        />
                        {t("RENTING BUILDINGS AND CONSTRUCTIONS")}
                      </label>
                      <label className={styles["radio-label"]}>
                        <Field
                          type="radio"
                          name="radioOption"
                          value="RENTING MACHINES AND EQUIPMENT"
                          onClick={() =>
                            setSelectedRadio("RENTING MACHINES AND EQUIPMENT")
                          }
                        />
                        {t("RENTING MACHINES AND EQUIPMENT")}
                      </label>
                      <label className={styles["radio-label"]}>
                        <input
                          type="radio"
                          name="radioOption"
                          value="TRANSPORTATION_RENTAL"
                          onChange={() =>
                            setSelectedRadio("TRANSPORTATION_RENTAL")
                          }
                        />
                        {t("TRANSPORTATION RENTAL")}
                      </label>
                    </div>
                  </div>
                )}
                {/* Conditionally render radio buttons without dropdown based on selected category */}
                {showUses && (
                  <div
                    className={`${styles["col-span-3"]} ${styles["form-group"]} ${styles["-mt-1"]} ${styles["flex-container"]}`}
                  >
                    {/* First Column */}
                    <div
                      className={`${styles["radio-group"]} ${styles["flex-container"]}`}
                    >
                      <label className={styles["radio-label"]}>
                        <Field
                          type="radio"
                          name="radioOption"
                          value="salaries and wages"
                          onClick={() => setSelectedRadio("salaries and wages")}
                        />
                        {t("SALARIES AND WAGES")}
                      </label>
                      <label className={styles["radio-label"]}>
                        <Field
                          type="radio"
                          name="radioOption"
                          value="incentive bouns"
                          onClick={() => setSelectedRadio("incentive bouns")}
                        />
                        {t("INCENTIVE BONUS")}
                      </label>
                      <label className={styles["radio-label"]}>
                        <Field
                          type="radio"
                          name="radioOption"
                          value="other allocations"
                          onClick={() => setSelectedRadio("other allocations")}
                        />
                        {t("OTHER ALLOCATIONS")}
                      </label>
                    </div>

                    {/* Second Column (no dropdown for uses) */}
                  </div>
                )}
                {/* Conditionally render radio buttons without dropdown based on selected category */}
                {showAdvertisingPrintingHospitality && (
                  <div
                    className={`${styles["col-span-3"]} ${styles["form-group"]} ${styles["-mt-1"]} ${styles["flex-container"]}`}
                  >
                    {/* First Column */}
                    <div
                      className={`${styles["radio-group"]} ${styles["flex-container"]}`}
                    >
                      {advertisingPrintingHospitalityOptions.map((option) => (
                        <label key={option} className={styles["radio-label"]}>
                          <Field
                            type="radio"
                            name="radioOption"
                            value={option}
                            onClick={() => setSelectedRadio(option)}
                          />
                          {option}
                        </label>
                      ))}
                    </div>
                  </div>
                )}
                {/* Conditionally render radio buttons without dropdown based on selected category */}
                {showMiscellaneous && (
                  <div
                    className={`${styles["col-span-3"]} ${styles["form-group"]} ${styles["-mt-1"]} ${styles["flex-container"]}`}
                  >
                    {/* First Column */}
                    <div
                      className={`${styles["radio-group"]} ${styles["flex-container"]}`}
                    >
                      {miscellaneousOptions.map((option) => (
                        <label key={option} className={styles["radio-label"]}>
                          <Field
                            type="radio"
                            name="radioOption"
                            value={option}
                            onClick={() => setSelectedRadio(option)}
                          />
                          {option}
                        </label>
                      ))}
                    </div>
                  </div>
                )}
                {/* Conditionally render radio buttons without dropdown based on selected category */}
                {showMiscellaneousServiceExpenses && (
                  <div
                    className={`${styles["col-span-3"]} ${styles["form-group"]} ${styles["-mt-1"]} ${styles["flex-container"]}`}
                  >
                    {/* First Column */}
                    <div
                      className={`${styles["radio-group"]} ${styles["flex-container"]}`}
                    >
                      {miscellaneousServiceExpensesOptions.map((option) => (
                        <label key={option} className={styles["radio-label"]}>
                          <Field
                            type="radio"
                            name="radioOption"
                            value={option}
                            onClick={() => setSelectedRadio(option)}
                          />
                          {option}
                        </label>
                      ))}
                    </div>

                    {/* Second Column (no dropdown for miscellaneous service expenses) */}
                  </div>
                )}
                {/* Conditionally render radio buttons without dropdown based on selected category */}
                {showFuelAndOils && (
                  <div
                    className={`${styles["col-span-3"]} ${styles["form-group"]} ${styles["-mt-1"]} ${styles["flex-container"]}`}
                  >
                    <div
                      className={`${styles["radio-group"]} ${styles["flex-container"]}`}
                    >
                      {fuelAndOilsOptions.map((option) => (
                        <label key={option} className={styles["radio-label"]}>
                          <Field
                            type="radio"
                            name="radioOption"
                            value={option}
                            onClick={() => setSelectedRadio(option)}
                          />
                          {option}
                        </label>
                      ))}
                    </div>
                  </div>
                )}
                {/* Conditionally render radio buttons without dropdown based on selected category */}
                {showTransportationDispatchCommunications && (
                  <div
                    className={`${styles["col-span-3"]} ${styles["form-group"]} ${styles["-mt-1"]} ${styles["flex-container"]}`}
                  >
                    <div
                      className={`${styles["radio-group"]} ${styles["flex-container"]}`}
                    >
                      {transportationDispatchCommunicationsOptions.map(
                        (option) => (
                          <label key={option} className={styles["radio-label"]}>
                            <Field
                              type="radio"
                              name="radioOption"
                              value={option}
                              onClick={() => setSelectedRadio(option)}
                            />
                            {option}
                          </label>
                        )
                      )}
                    </div>
                  </div>
                )}
              </div>
              {/* Conditionally render the unit type select dropdown */}
              {selectedRadio === "COMMERCIAL" && (
                <div
                  className={`${styles["col-span-1"]} ${styles["form-group"]}`}
                >
                  <label htmlFor="categoryUnitType" className={styles.label}>
                    <span className={styles.for}>{t("Type")}</span>
                    <span className={styles.database}> {t("Of Unit")}</span>
                  </label>
                  <Field
                    as="select"
                    id="categoryUnitType"
                    name="categoryUnitType"
                    className={`${styles["input-field"]} ${styles["black-background"]}`}
                  >
                    <option value="" label={t("Select Unit Type")} />
                    {commercialOptions.map((option) => (
                      <option key={option} value={option} label={option} />
                    ))}
                  </Field>
                </div>
              )}
              {selectedRadio === "RESIDENTIAL" && (
                <div
                  className={`${styles["col-span-1"]} ${styles["form-group"]}`}
                >
                  <label htmlFor="categoryUnitType" className={styles.label}>
                    <span className={styles.for}>{t("Type")}</span>
                    <span className={styles.database}> {t("Of Unit")}</span>
                  </label>
                  <Field
                    as="select"
                    id="categoryUnitType"
                    name="categoryUnitType"
                    className={`${styles["input-field"]} ${styles["black-background"]}`}
                  >
                    <option value="" label={t("Select Unit Type")} />
                    {residentialOptions.map((option) => (
                      <option key={option} value={option} label={option} />
                    ))}
                  </Field>
                </div>
              )}
            </div>
            <div
              className={`${styles["col-span-3"]} ${styles["form-group"]} ${styles["centered-container"]}`}
            >
              <button
                type="submit"
                className="bg-gradient-to-b from-blue-900 to-blue-500 text-white w-[245px] h-[55px] border-none cursor-pointer text-16 -mt-8"
              >
                {t("Preview")}
              </button>
            </div>
          </Form>
        </div>
      </Formik>
      <CustomerModal
        isModalOpen={isModalOpen}
        setModalOpen={setModalOpen}
        countries={countries}
        cities={cities}
        dispatchAction={() => {
          setTimeout(() => {
            dispatch(fetchCustomersAsync());
          }, 2000);
        }}
      />
      <div className="flex justify-center">
        <BottomNavigationButton content="Main Menu" to="/main" />
        <BottomNavigationButton content="Finance" to="/finance" />
        <BottomNavigationButton content="Receipts" to="/receipts" />
        <BottomNavigationButton
          content="Commission Receive"
          to="/commission-receive-receipt"
        />
      </div>
    </>
  );
};

export default CommissionReceiveReceipts;
