import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  createSelector,
} from "@reduxjs/toolkit";
import axios from "../services/api/api";

interface UnitType {
  id: number;
  name: string;
}

interface UnitTypeState {
  unitTypes: UnitType[];
  loading: "idle" | "pending" | "succeeded" | "failed";
  error: string | null;
}

const initialState: UnitTypeState = {
  unitTypes: [],
  loading: "idle",
  error: null,
};

export const fetchUnitTypesAsync = createAsyncThunk(
  "unitType/fetchUnitTypes",
  async () => {
    const response = await axios.get("/unitType");
    return response.data.data as UnitType[];
  }
);

export const selectUnitTypeById = (unitTypeId: number) =>
  createSelector(
    (state: { unitType: UnitTypeState }) => state.unitType.unitTypes,
    (unitTypes) => unitTypes.find((unitType) => unitType.id === unitTypeId)
  );

const unitTypeSlice = createSlice({
  name: "unitType",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUnitTypesAsync.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(
        fetchUnitTypesAsync.fulfilled,
        (state, action: PayloadAction<UnitType[]>) => {
          state.unitTypes = action.payload;
          state.loading = "succeeded";
          state.error = null;
        }
      )
      .addCase(fetchUnitTypesAsync.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.error.message ?? "An error occurred";
      });
  },
});

export default unitTypeSlice.reducer;
