import { useEffect, useState } from "react";
import {
  ErrorMessage,
  Field,
  FieldProps,
  Form,
  Formik,
  FormikHelpers,
} from "formik";
import styles from "./BuyingAndSellingContractPage.module.scss";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CustomSelect from "../../components/custom/CustomSelect";
import { fetchCustomersAsync } from "../../redux/customerSlice";
import { fetchEmployeesAsync } from "../../redux/employeeSlice";
import { ThunkDispatch } from "redux-thunk";
import { fetchPropertyNumbersAsync } from "../../redux/propertyNumberSlice";
import { fetchInfosAsync } from "../../redux/infoSlice";
import { fetchAmountInWordsAsync } from "../../redux/amountInWordSlice";
import { fetchProjectsAsync } from "../../redux/projectSlice";
import { RootState } from "../../redux/store";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { fetchPropertyTypesAsync } from "../../redux/propertyTypeSlice";
import { fetchUnitTypesAsync } from "../../redux/unitTypeSlice";
import { fetchCountriesAsync } from "../../redux/countrySlice";
import { buyingAndSellingContract } from "../../redux/buyingAndSellingContractSlice";
import CustomerModal from "../../components/common/customerModal/customerModal";
import { fetchCitiesAsync } from "../../redux/citySlice";
import { fetchPropertyOwnersAsync } from "../../redux/propertyOwnerSlice";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { generateReceiptNumber } from "../../utils/helpers";
import { fetchCurrenciesAsync } from "../../redux/currencySlice";
import BottomNavigationButton from "../../components/common/bottomNavigationButton/BottomNavigationButton";

interface Values {
  customer_id: number;
  propertyOwner_id: number;
  user_id: number;
  receiptNumber: number;
  totalPrice: number;
  receivedDate: string;
  remainPayment: number;
  remainDate: string;
  currency_id: number;
  receivedAmount: number;
  unitType_id: number;
  unitArea: number;
  property_number_id: number;
  handoverDate: string;
  handoverPenalty: number;
  cancellationCompensation: number;
  cancellationPenalty: number;
  project_id: number;
  propertyType: string;
  contractLanguage: string;
  lawyerFullName: string;
  unitNumber: number;
  note?: string;
}

const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

const BuyingAndSellingContractPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    propertyType: Yup.string().required(t("Property Type is required")),
    receivedDate: Yup.date().required(t("Date is required")),
    remainDate: Yup.date().required(t("Date is required")),
    handoverDate: Yup.date().required(t("Date is required")),
    handoverPenalty: Yup.number()
      .required(t("Handover Penalty is required"))
      .min(0, t("Handover Penalty must be a non-negative number")),
    cancellationCompensation: Yup.number()
      .required(t("Cancellation Compensation is required"))
      .min(0, t("Cancellation Compensation must be a non-negative number")),
    cancellationPenalty: Yup.number()
      .required(t("Cancellation Penalty is required"))
      .min(0, t("Cancellation Penalty must be a non-negative number")),
    totalPrice: Yup.number()
      .required(t("Total Price is required"))
      .min(0, "Total Price must be at least 0")
      .typeError("Total Price must be a number"),
  });

  const location = useLocation();
  const contractData = location.state?.contractData;

  const dispatch: ThunkDispatch<RootState, any, any> = useDispatch();
  const currencies = useTypedSelector((state) => state.currency.currencies);
  const customers = useTypedSelector((state) => state.customer.customers);
  const projects = useTypedSelector((state) => state.project.projects);
  const propertyTypes = useTypedSelector(
    (state) => state.propertyType.propertyTypes
  );
  const propertyNumbers = useTypedSelector(
    (state) => state.propertyNumber.propertyNumbers
  );
  const unitTypes = useTypedSelector((state) => state.unitType.unitTypes);
  const countries = useTypedSelector((state) => state.country.countries);
  const cities = useTypedSelector((state) => state.city.cities);
  const profile = useTypedSelector((state) => state.profile.profile);

  useEffect(() => {
    dispatch(fetchCustomersAsync());
    dispatch(fetchEmployeesAsync());
    dispatch(fetchPropertyNumbersAsync());
    dispatch(fetchInfosAsync());
    dispatch(fetchAmountInWordsAsync());
    dispatch(fetchProjectsAsync());
    dispatch(fetchPropertyTypesAsync());
    dispatch(fetchUnitTypesAsync());
    dispatch(fetchCountriesAsync());
    dispatch(fetchCitiesAsync());
    dispatch(fetchCurrenciesAsync());
    dispatch(fetchPropertyOwnersAsync());
  }, [dispatch]);

  const [selectedLanguage] = useState<string>("En");

  const [selectedCurrency, setSelectedCurrency] = useState<number>(
    contractData?.currency_id ?? 2
  );
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState<string>("");

  const handleCurrencyChange = (currencyId: number) => {
    setSelectedCurrency(currencyId);
  };

  const fetchCustomersAndPropertyOwnersAsync = createAsyncThunk(
    "customerAndPropertyOwner/fetchCustomersAndPropertyOwners",
    async () => {
      await Promise.all([
        dispatch(fetchCustomersAsync()),
        dispatch(fetchPropertyOwnersAsync()),
      ]);
    }
  );

  const initialValues: Values = contractData
    ? {
        customer_id: contractData.customer_id || 1,
        propertyOwner_id: contractData.propertyOwner_id || 1,
        user_id: contractData.customer_id || 1,
        receiptNumber: contractData.receiptNumber || 0,
        totalPrice: contractData.totalPrice || 50000,
        receivedDate: contractData.receivedDate || "",
        remainPayment: contractData.remainPayment || 10000,
        remainDate: contractData.remainDate || "",
        currency_id: contractData.currency_id || 0,
        receivedAmount: contractData.receivedAmount || 40000,
        unitType_id: contractData.unitType_id || 1,
        unitArea: contractData.unitArea || null,
        property_number_id: contractData.property_number_id || 1,
        handoverDate: contractData.handoverDate || "",
        handoverPenalty: contractData.handoverPenalty || null,
        cancellationCompensation: contractData.cancellationCompensation || null,
        cancellationPenalty: contractData.cancellationPenalty || null,
        project_id: contractData.project_id || 1,
        propertyType: contractData.propertyType || 1,
        contractLanguage: contractData.contractLanguage || "",
        lawyerFullName: contractData.lawyerFullName || "",
        unitNumber: contractData.unitNumber || 1,
        note: contractData.note || "",
      }
    : {
        customer_id: 0,
        user_id: 0,
        propertyOwner_id: 0,
        receiptNumber: 0,
        totalPrice: null,
        receivedDate: "",
        remainPayment: null,
        remainDate: "",
        currency_id: 0,
        receivedAmount: null,
        unitType_id: 0,
        unitArea: null,
        property_number_id: 0,
        handoverDate: "",
        handoverPenalty: null,
        cancellationCompensation: null,
        cancellationPenalty: null,
        project_id: 0,
        propertyType: 1,
        contractLanguage: "",
        lawyerFullName: "",
        unitNumber: 1,
        note: "",
      };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(
          values: Values,
          { setSubmitting }: FormikHelpers<Values>
        ) => {
          if (selectedCurrency !== undefined) {
            values.currency_id = selectedCurrency;
          }
          values.contractLanguage = selectedLanguage;
          values.receiptNumber = generateReceiptNumber();
          const handoverDateStr = (
            values.handoverDate as unknown as string
          ).split("T")[0];
          values.handoverDate = handoverDateStr;

          const receivedDateStr = (
            values.receivedDate as unknown as string
          ).split("T")[0];
          values.receivedDate = receivedDateStr;
          const remainDateStr = (values.remainDate as unknown as string).split(
            "T"
          )[0];
          values.remainDate = remainDateStr;
          values.lawyerFullName = "بڵند";
          values.user_id = profile?.id ?? 0;
          dispatch(buyingAndSellingContract(values));
          navigate("/purchasing-contract-preview");
          setTimeout(() => {
            // alert(JSON.stringify(values, null, 2));
            setSubmitting(false);
          }, 500);
        }}
      >
        <Form>
          <div className="overflow-y-auto h-[750px]">
            <div className="grid grid-cols-4 gap-y-4 p-6">
              <div className="p-6 bg-white">
                <Field name="propertyOwner_id" key={Math.random()}>
                  {({ field, form }: FieldProps<string>) => (
                    <CustomSelect
                      field={field}
                      form={form}
                      options={customers.map((propertyOwner) => ({
                        label: `${propertyOwner.firstName + " "}${propertyOwner.lastName}`,
                        value: propertyOwner.id,
                        key: `${propertyOwner.firstName + " "}${propertyOwner.lastName}`,
                      }))}
                      labelName="firstSideSeller"
                      name="propertyOwner_id"
                      mt={2}
                      creatable={false}
                    />
                  )}
                </Field>
              </div>
              <div className="p-6 bg-white">
                <div
                  onClick={() => {
                    setModalTitle(t("Create A New Property Owner"));
                    setModalOpen(true);
                  }}
                  className={`${styles["black-background"]} mt-12 text-white w-[310px] h-[39.5px] border-none cursor-pointer text-16 flex justify-center items-center`}
                >
                  <p className="text-center">
                    {t("Create A New Property Owner")}
                  </p>
                </div>
              </div>
              <div className="p-6 bg-white">
                <Field name="customer_id" key={Math.random()}>
                  {({ field, form }: FieldProps<string>) => (
                    <CustomSelect
                      field={field}
                      form={form}
                      options={customers.map((customer) => ({
                        label: `${customer.firstName + " "}${customer.lastName}`,
                        value: customer.id,
                        key: `${customer.firstName + " "}${customer.lastName}`,
                      }))}
                      labelName="secondSideBuyer"
                      name="customer_id"
                      mt={2}
                      creatable={false}
                    />
                  )}
                </Field>
              </div>
              <div className="p-6 bg-white">
                <div
                  onClick={() => {
                    setModalTitle(t("Create A New Customer"));
                    setModalOpen(true);
                  }}
                  className={`${styles["black-background"]} mt-12 text-white w-[310px] h-[39.5px] border-none cursor-pointer text-16 flex justify-center items-center`}
                >
                  <p className="text-center">{t("Create A New Customer")}</p>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-5 gap-y-4 pl-6 pr-6">
              <div className="p-6 bg-white">
                <label htmlFor="totalPrice" className="font-bold">
                  {t("Total Price")}
                </label>
                <div className="flex items-center">
                  <Field
                    as="input"
                    min="0"
                    id="totalPrice"
                    name="totalPrice"
                    type="number"
                    placeholder={t("Amount Received")}
                    className="block w-full border border-gray-400 mt-2 p-2"
                  />
                  <style>
                    {`
                    #totalPrice::-webkit-calendar-picker-indicator {
                      filter: invert(1);
                    }
                  `}
                  </style>
                </div>
                <ErrorMessage
                  name="totalPrice"
                  component="div"
                  className={styles.error}
                />
              </div>
              <div className="p-6 bg-white">
                <label htmlFor="receivedAmount" className="font-bold">
                  {t("Received Payment")}
                </label>
                <div className="flex items-center">
                  <Field
                    as="input"
                    min="0"
                    id="receivedAmount"
                    name="receivedAmount"
                    type="number"
                    placeholder={t("Received Amount")}
                    className="block w-full border border-gray-400 mt-2 p-2"
                  />
                </div>
              </div>
              <div className="p-6 bg-white">
                <label htmlFor="receivedDate" className="font-bold">
                  {t("Received Date")}
                </label>
                <div className="relative">
                  <Field
                    as="input"
                    min="0"
                    id="receivedDate"
                    name="receivedDate"
                    type="date"
                    placeholder={t("Received Date")}
                    className="block w-full border border-gray-400 mt-2 p-2 bg-gray-800 text-white"
                  />
                  <style>
                    {`
                    #receivedDate::-webkit-calendar-picker-indicator {
                      filter: invert(1);
                    }
                  `}
                  </style>
                </div>
                <ErrorMessage
                  name="receivedDate"
                  component="div"
                  className={styles.error}
                />
              </div>
              <div className="p-6 bg-white">
                <label htmlFor="remainPayment" className="font-bold ml-5">
                  {t("Remain Payment")}
                </label>
                <div className="flex items-center">
                  <Field
                    as="input"
                    min="0"
                    id="remainPayment"
                    name="remainPayment"
                    type="number"
                    placeholder={t("Remain Payment")}
                    className="block w-full border border-gray-400 mt-2 p-2 ml-5"
                  />
                </div>
              </div>
              <div className="p-6 bg-white">
                <label htmlFor="remainDate" className="font-bold">
                  {t("Receiving Remain Date")}
                </label>
                <div className="relative">
                  <Field
                    as="input"
                    min="0"
                    id="remainDate"
                    name="remainDate"
                    type="date"
                    placeholder={t("Receiving Remain Date")}
                    className="block w-full border border-gray-400 mt-2 p-2 bg-gray-800 text-white"
                  />
                  <style>
                    {`
                    #remainDate::-webkit-calendar-picker-indicator {
                      filter: invert(1);
                    }
                  `}
                  </style>
                </div>
                <ErrorMessage
                  name="remainDate"
                  component="div"
                  className={styles.error}
                />
              </div>
            </div>
            <div className="grid grid-cols-4 gap-y-4 pl-6 pr-6 -mt-1">
              <div className="p-6 bg-white mt-6 mb-6">
                <div
                  className={`${styles["col-span-1"]} ${styles["form-group"]}`}
                >
                  <label className={`${styles["label"]} font-bold`}>
                    {t("Currency")}
                  </label>
                  <div className={`${styles["currency-toggle"]} mt-6`}>
                    {currencies?.map((currency) => (
                      <button
                        key={currency.id}
                        type="button"
                        onClick={() => handleCurrencyChange(currency.id)}
                        className={`${styles["currency-button"]} ${
                          selectedCurrency === currency.id
                            ? styles["currency-selected"]
                            : ""
                        }`}
                      >
                        {currency.name}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
              <div className="p-6 bg-white mt-6 mb-6">
                <Field name="unitType_id" key={Math.random()}>
                  {({ field, form }: FieldProps<string>) => (
                    <CustomSelect
                      field={field}
                      form={form}
                      options={unitTypes.map((unitType, index) => ({
                        label: unitType.name,
                        value: unitType.id,
                        key: `${unitType.name}_${index}`,
                      }))}
                      labelName="unitType"
                      name="unitType_id"
                      creatable={true}
                      mt={2}
                      dispatchAction={() => dispatch(fetchUnitTypesAsync())}
                    />
                  )}
                </Field>
              </div>
              <div className="p-6 bg-white mb-4">
                <label htmlFor="unitArea" className="font-bold">
                  {t("Unit Area")}
                </label>
                <div className="flex items-center">
                  <Field
                    as="input"
                    min="0"
                    id="unitArea"
                    name="unitArea"
                    type="number"
                    placeholder={t("Area (m2)")}
                    className="block w-full border border-gray-400 mt-2 p-2"
                  />
                </div>
              </div>
              <div className="p-6 bg-white mb-4">
                <Field name="property_number_id" key={Math.random()}>
                  {({ field, form }: FieldProps<string>) => (
                    <CustomSelect
                      field={field}
                      form={form}
                      options={propertyNumbers.map((propertyNumber, index) => ({
                        label: propertyNumber.name,
                        value: propertyNumber.id,
                        key: `${propertyNumber.name}_${index}`,
                      }))}
                      labelName="propertyNumber"
                      name="property_number_id"
                      creatable={true}
                      mt={2}
                      dispatchAction={() =>
                        dispatch(fetchPropertyNumbersAsync())
                      }
                    />
                  )}
                </Field>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-y-4 pl-6 pr-6">
              <div className="p-6 bg-white">
                <Field name="project_id" key={Math.random()}>
                  {({ field, form }: FieldProps<string>) => (
                    <CustomSelect
                      field={field}
                      form={form}
                      options={projects.map((project, index) => ({
                        label: project.name,
                        value: project.id,
                        key: `${project.name}_${index}`,
                      }))}
                      labelName="projects"
                      name="project_id"
                      creatable={true}
                      mt={2}
                      dispatchAction={() => dispatch(fetchProjectsAsync())}
                    />
                  )}
                </Field>
              </div>
              <div className="p-6 bg-white">
                <Field name="propertyType" key={Math.random()}>
                  {({ field, form }: FieldProps<string>) => (
                    <CustomSelect
                      field={field}
                      form={form}
                      options={propertyTypes.map((propertyType) => ({
                        label: t(`${propertyType.name}`),
                        value: `${propertyType.name}`,
                        key: `${propertyType.name}`,
                      }))}
                      labelName="propertyType"
                      mt={2}
                      creatable={false}
                    />
                  )}
                </Field>
              </div>
            </div>
            <div className="grid grid-cols-4 gap-y-4 pl-6 pr-6 mt-5">
              <div className="p-6 bg-white">
                <label htmlFor="handoverDate" className="font-bold">
                  {t("Handover Date")}
                </label>
                <div className="relative">
                  <Field
                    as="input"
                    min="0"
                    id="handoverDate"
                    name="handoverDate"
                    type="date"
                    placeholder="Handover Date"
                    className="block w-full border border-gray-400 mt-2 p-2 bg-gray-800 text-white"
                  />
                  <style>
                    {`
                    #handoverDate::-webkit-calendar-picker-indicator {
                      filter: invert(1);
                    }
                  `}
                  </style>
                </div>
                <ErrorMessage
                  name="handoverDate"
                  component="div"
                  className={styles.error}
                />
              </div>
              <div className="p-6 bg-white">
                <label htmlFor="handoverPenalty" className="font-bold">
                  {t("Handover Penalty")}
                </label>
                <div className="flex items-center">
                  <Field
                    as="input"
                    min="0"
                    id="handoverPenalty"
                    name="handoverPenalty"
                    type="number"
                    placeholder={t("Amount of Handover Penalty")}
                    className="block w-full border border-gray-400 mt-2 p-2"
                  />
                </div>
                <ErrorMessage
                  name="handoverPenalty"
                  component="div"
                  className="text-red-500"
                />
              </div>
              <div className="p-6 bg-white">
                <label htmlFor="cancellationCompensation" className="font-bold">
                  {t("Cancellation Compensation")}
                </label>
                <div className="flex items-center">
                  <Field
                    as="input"
                    min="0"
                    id="cancellationCompensation"
                    name="cancellationCompensation"
                    type="number"
                    placeholder={t("Amount of Cancellation Compensation")}
                    className="block w-full border border-gray-400 mt-2 p-2"
                  />
                </div>
                <ErrorMessage
                  name="cancellationCompensation"
                  component="div"
                  className="text-red-500"
                />
              </div>
              <div className="p-6 bg-white">
                <label htmlFor="cancellationPenalty" className="font-bold">
                  {t("Cancellation Penalty")}
                </label>
                <div className="flex items-center">
                  <Field
                    as="input"
                    min="0"
                    id="cancellationPenalty"
                    name="cancellationPenalty"
                    type="number"
                    placeholder={t("Amount of Cancellation Penalty")}
                    className="block w-full border border-gray-400 mt-2 p-2"
                  />
                </div>
                <ErrorMessage
                  name="cancellationPenalty"
                  component="div"
                  className="text-red-500"
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-y-4 pl-6 pr-6 mt-5">
              <div className="p-6 bg-white">
                <label htmlFor="lawyerFullName" className="font-bold">
                  {t("Lawyer")}
                </label>
                <Field
                  as="input"
                  min="0"
                  id="lawyerFullName"
                  name="lawyerFullName"
                  type="text"
                  value="بڵند"
                  disabled
                  placeholder={t("Lawyer Full Name Placeholder")}
                  className="block w-full border border-gray-400 mt-2 p-2"
                />
              </div>
              <div className="p-6 bg-white">
                <label htmlFor="note" className="font-bold">
                  {t("Note")}
                </label>
                <Field
                  as="input"
                  min="0"
                  id="note"
                  name="note"
                  type="text"
                  placeholder={t("Write Down Any Notes if Available")}
                  className="block w-full border border-gray-400 mt-2 p-2"
                />
              </div>
            </div>
            <div className="p-6 bg-white mt-8 ml-5 text-center">
              <button
                type="submit"
                className="bg-gradient-to-b from-blue-900 to-blue-500 text-white w-[220px] h-[44px] border-none cursor-pointer text-16"
              >
                Preview
              </button>
            </div>
          </div>
        </Form>
      </Formik>
      <CustomerModal
        isModalOpen={isModalOpen}
        setModalOpen={setModalOpen}
        title={modalTitle}
        countries={countries}
        cities={cities}
        dispatchAction={() => {
          setTimeout(() => {
            dispatch(fetchCustomersAndPropertyOwnersAsync());
          }, 2000);
        }}
      />
      <div className="flex justify-center mt-5">
        <BottomNavigationButton content="Main Menu" to="/main" />
        <BottomNavigationButton content="Finance" to="/finance" />
        <BottomNavigationButton content="Contracts" to="/contracts" />
        <BottomNavigationButton
          content="Buying And Selling"
          to="/buying-and-selling-contract"
        />
      </div>
    </>
  );
};

export default BuyingAndSellingContractPage;
