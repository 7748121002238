import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./FinancePage.module.scss";
import MacbookButton from "../../components/common/macbookButton/MacbookButton";
import {
  FileText,
  Clipboard,
  XCircle,
  BarChart2,
  File,
  Lock,
} from "react-feather";
import BottomNavigationButton from "../../components/common/bottomNavigationButton/BottomNavigationButton";

const FinancePage: React.FC = () => {
  const navigate = useNavigate();

  const handleContracts = () => {
    navigate("/contracts");
  };

  const handleReceipts = () => {
    navigate("/receipts");
  };

  const handleCancellation = () => {
    return;
    // navigate("/cancellation");
  };

  const handleOverview = () => {
    return;
    // navigate("/overview");
  };

  const handleAccountStatement = () => {
    navigate("/account-statement");
  };

  const handleSafeBox = () => {
    return;
    // navigate("/safe-box");
  };

  return (
    <>
      <div className="flex justify-center">
        <div className={styles["grid-container"]}>
          {/* Row 1 */}
          <MacbookButton
            onClick={handleContracts}
            buttonText="Contracts"
            icon={<FileText size={20} />}
          />
          <MacbookButton
            onClick={handleReceipts}
            buttonText="Receipts"
            icon={<Clipboard size={20} />}
          />
          <MacbookButton
            onClick={handleCancellation}
            buttonText="Cancellation"
            icon={<XCircle size={20} />}
          />

          {/* Row 2 */}
          <MacbookButton
            onClick={handleOverview}
            buttonText="Overview"
            icon={<BarChart2 size={20} />}
          />
          <MacbookButton
            onClick={handleAccountStatement}
            buttonText="Account Statement"
            icon={<File size={20} />}
          />
          <MacbookButton
            onClick={handleSafeBox}
            buttonText="Safe Box"
            icon={<Lock size={20} />}
          />
        </div>
      </div>
      <div className="flex justify-center -mt-44">
        <BottomNavigationButton content="Main Menu" to="/main" />
        <BottomNavigationButton content="Finance" to="/finance" />
      </div>
    </>
  );
};

export default FinancePage;
