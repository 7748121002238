import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

interface PropertyType {
  id: number;
  name: string;
}

const propertyTypes: PropertyType[] = [
  {
    id: 1,
    name: "Residential",
  },
  {
    id: 2,
    name: "Commercial",
  },
  {
    id: 3,
    name: "Other",
  },
];

interface PropertyTypeState {
  propertyTypes: PropertyType[];
  loading: "idle" | "pending" | "succeeded" | "failed";
  error: string | null;
}

const initialState: PropertyTypeState = {
  propertyTypes: [],
  loading: "idle",
  error: null,
};

export const fetchPropertyTypesAsync = createAsyncThunk(
  "propertyType/fetchPropertyTypes",
  async () => {
    // const response = await axios.get("/propertyTypes"); // backend endpoint
    // return response.data as PropertyType[];
    return propertyTypes as PropertyType[];
  }
);

const propertyTypeSlice = createSlice({
  name: "propertyType",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPropertyTypesAsync.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(
        fetchPropertyTypesAsync.fulfilled,
        (state, action: PayloadAction<PropertyType[]>) => {
          state.propertyTypes = action.payload;
          state.loading = "succeeded";
          state.error = null;
        }
      )
      .addCase(fetchPropertyTypesAsync.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.error.message ?? "An error occurred";
      });
  },
});

export default propertyTypeSlice.reducer;
