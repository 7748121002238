import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../services/api/api";

interface ReceiveCommissionReceiptState {
  receiveCommissionReceiptData: ReceiveCommissionReceiptData | null;
  allReceiveCommissionReceipts: ReceiveCommissionReceiptData[] | null;
}

interface ReceiveCommissionReceiptData {
  customer_id: number;
  receiptNumber: number;
  amountOf: string;
  amountInWords: string;
  employee_id: number;
  sales_employee_id: number;
  category_id: number;
  unitType_id: number;
  property_number_id: number;
  currency_id: number;
  saleOrRentPrice: number;
  project_id: number;
  categoryUnitType: string;
  radioOption: string;
  rentingFixedAssets: string;
  note: string;
}

const initialState: ReceiveCommissionReceiptState = {
  receiveCommissionReceiptData: null,
  allReceiveCommissionReceipts: null,
};

export const sendCommissionReceiptData = createAsyncThunk(
  "receiveCommissionReceipt/sendCommissionReceiptData",
  async (data: ReceiveCommissionReceiptData, thunkAPI) => {
    try {
      const response = await axios.post("/receiveCommissionReceipt", data);
      console.log("response.data", response.data);
      return response.data.data;
    } catch (error: unknown) {
      if (typeof error === "object" && error && "response" in error) {
        return thunkAPI.rejectWithValue(
          (error as any).response?.data ?? "Unknown error occurred"
        );
      } else {
        return thunkAPI.rejectWithValue("Unknown error occurred");
      }
    }
  }
);

export const getAllReceiveCommissionReceipts = createAsyncThunk(
  "receiveCommissionReceipt/getAllReceiveCommissionReceipts",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get("/receiveCommissionReceipt");
      return response.data.data;
    } catch (error: unknown) {
      console.error("Error fetching all receive commission receipts:", error);
      return thunkAPI.rejectWithValue(
        "Error fetching all receive commission receipts"
      );
    }
  }
);

export const getAllReceiveCommissionReceiptsByUserId = createAsyncThunk(
  "receiveCommissionReceiptByUserId/getAllReceiveCommissionReceiptsByUserId",
  async (userId: number, thunkAPI) => {
    try {
      const response = await axios.get(
        `/receiveCommissionReceipt/user/${userId}`
      );
      const data = response.data.data;

      if (data.length === 0) {
        // Handle the case where no data is found
        return thunkAPI.rejectWithValue({
          message: "No commission receipts found for this user.",
          data: [],
        });
      }

      return data;
    } catch (error: unknown) {
      console.error("Error fetching commission receipts by user ID:", error);
      return thunkAPI.rejectWithValue(
        "Error fetching commission receipts by user ID"
      );
    }
  }
);

export const RESET_RECEIVE_COMMISSION_RECEIPT_DATA =
  "RESET_RECEIVE_COMMISSION_RECEIPT_DATA";

const receiveCommissionReceiptSlice = createSlice({
  name: "receiveCommissionReceipt",
  initialState,
  reducers: {
    receiveCommissionReceipt: (
      state,
      action: PayloadAction<ReceiveCommissionReceiptData>
    ) => {
      state.receiveCommissionReceiptData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendCommissionReceiptData.pending, (state) => {
        // Optionally, reset data and set loading state
      })
      .addCase(sendCommissionReceiptData.fulfilled, (state) => {
        // Optionally, handle successful response
      })
      .addCase(sendCommissionReceiptData.rejected, (state, action) => {
        // Handle error and set error message in state
      })
      .addCase(getAllReceiveCommissionReceipts.pending, (state) => {
        // Optionally reset data and set loading state
      })
      .addCase(getAllReceiveCommissionReceipts.fulfilled, (state, action) => {
        // Update state with the fetched data
        state.allReceiveCommissionReceipts = action.payload;
      })
      .addCase(getAllReceiveCommissionReceipts.rejected, (state, action) => {
        // Handle error
      })
      .addCase(getAllReceiveCommissionReceiptsByUserId.pending, (state) => {
        // Optionally reset data and set loading state
      })
      .addCase(
        getAllReceiveCommissionReceiptsByUserId.fulfilled,
        (state, action) => {
          console.log("allReceiveCommissionReceiptsByUserId", action.payload);
          state.allReceiveCommissionReceipts = action.payload;
        }
      )
      .addCase(
        getAllReceiveCommissionReceiptsByUserId.rejected,
        (state, action) => {
          if (
            action.payload &&
            typeof action.payload === "object" &&
            "message" in action.payload
          ) {
            console.warn(action.payload.message);
            state.allReceiveCommissionReceipts = [];
          } else {
            // Handle other errors
            console.error(
              "Error fetching commission receipts by user ID:",
              action.payload
            );
            state.allReceiveCommissionReceipts = [];
          }
        }
      );
  },
});

export const { receiveCommissionReceipt } =
  receiveCommissionReceiptSlice.actions;

export default receiveCommissionReceiptSlice.reducer;
