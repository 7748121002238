import React from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const validationSchema = Yup.object().shape({
  cells: Yup.array().of(
    Yup.lazy((value) => {
      if (typeof value === "number") {
        return Yup.number().typeError("Must be a number").nullable();
      } else if (/^\d{10}$/.test(value)) {
        return Yup.string()
          .required("This field is required")
          .matches(/^\d{10}$/, "Invalid phone number");
      }
      return Yup.string().required("This field is required");
    })
  ),
});

const ExchangeRatePage = () => {
  const { t } = useTranslation();

  const saveData = () => {
    toast.success(t("Exchange rate data has been sent successfully!"), {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      className: "black-and-white-toast",
    });

  }

  return (
    <Formik
      initialValues={{
        cells: Array.from({ length: 7 * 4 }, (_, index) =>
          index === 0 ? "" : null
        ),
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        // Handle form submission
        console.log(values);
      }}
    >
      <Form>
        <div className="flex justify-center">
          <p className="text-2xl font-bold text-center text-black mt-16">
            {t("Exchange Rate")}
          </p>
        </div>
        <div className="flex justify-center items-center mt-16">
          <div className="p-6 mx-auto" style={{ width: "500px" }} dir="rtl">
            <label htmlFor="amount" className="font-bold">
              {t("Enter the dollar price")}
            </label>
            <div className="flex items-center">
              <Field
                as="input"
                id="amount"
                name="amount"
                type="number"
                placeholder="Input IQD Amounts = 1 USD"
                className="block w-full border border-gray-400 mt-2 p-2"
              />
            </div>
          </div>
        </div>
        <div className="flex justify-center items-center mt-16">
          <div style={{ width: "500px" }}>
            <div className="flex justify-center space-x-8 gap-4">
              <button className="text-white font-bold py-2 px-4 bg-gradient-to-b bg-gray-800 hover:from-blue-900 hover:to-blue-600 h-[58px] w-[210px]">
                {t("Cancel")}
              </button>
              <button onClick={() => saveData()} className="text-white font-bold py-2 px-4 bg-gradient-to-b bg-gray-800 hover:from-blue-900 hover:to-blue-600 h-[58px] w-[210px]">
                {t("Save")}
              </button>
            </div>
          </div>
        </div>
      </Form>
    </Formik>
  );
};

export default ExchangeRatePage;
