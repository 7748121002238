import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { fetchProfileAsync } from "../../redux/profileSlice";
import { ThunkDispatch } from "redux-thunk";

const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

const UserDropdown: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const dropdownRef = useRef<HTMLDivElement>(null);

  const dispatch: ThunkDispatch<RootState, any, any> = useDispatch();

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  const toggleDropdown = () => {
    setIsOpen((prevState) => !prevState);
  };

  const handleSignOut = () => {
    localStorage.removeItem("token");
    setIsOpen(false);
  };

  const profile = useTypedSelector((state) => state.profile.profile);
  console.log("profile", profile);

  useEffect(() => {
    dispatch(fetchProfileAsync());
  }, [dispatch]);

  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Define different styles based on screen width condition
  let imgStyle;
  if (windowWidth <= 1366) {
    imgStyle = { width: "9rem", height: "2.2rem", marginTop: "0.5rem" };
  } else {
    imgStyle = { width: "9rem", height: "3.2rem", marginTop: "0.5rem" };
  }

  return (
    <div className="relative mr-1">
      <img
        id="avatarButton"
        className="rounded-full cursor-pointer"
        src="/images/profile/user.png"
        // style={{ width: "7rem", height: "2.5rem", marginTop: "0.5rem" }}
        style={imgStyle}
        alt="User dropdown"
        onClick={toggleDropdown}
      />
      <AnimatePresence>
        {isOpen && (
          <motion.div
            ref={dropdownRef}
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.2 }}
            className="absolute z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600 left-0"
          >
            <div className="px-4 py-3 text-sm text-gray-900 dark:text-white">
              <div>{profile?.name}</div>
              <div className="font-medium truncate">{profile?.email}</div>
            </div>
            <ul className="py-2 text-sm text-gray-700 dark:text-gray-200">
              <li>
                <Link
                  to="/main"
                  className="block px-4 py-2 dark:hover:text-white"
                  onClick={() => setIsOpen(false)}
                >
                  {t("Dashboard")}
                </Link>
              </li>
              <li>
                <button
                  className="block px-4 py-2 dark:hover:text-white"
                  onClick={() => setIsOpen(false)}
                >
                  {t("Settings")}
                </button>
              </li>
              <li>
                <button
                  className="block px-4 py-2 dark:hover:text-white"
                  onClick={() => setIsOpen(false)}
                >
                  {t("Earnings")}
                </button>
              </li>
            </ul>
            <div className="py-1">
              <Link
                to="/"
                className="block px-4 py-2 text-sm text-gray-700 dark:text-gray-200 dark:hover:text-white"
                onClick={handleSignOut}
              >
                {t("Sign out")}
              </Link>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default UserDropdown;
