import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import api from "../services/api/api";

interface Profile {
  id: number;
  name: string;
  username: string;
  email: string;
  phone_no: string;
  address: string | null;
  role: string;
  image: string;
  gender: number;
  position: string;
  join_date: string | null;
  is_active: number;
  ip_address: string;
}

export const fetchProfileAsync = createAsyncThunk(
  "profile/fetchProfile",
  async () => {
    try {
      const response = await api.get("/me");
      return response.data as Profile;
    } catch (error) {
      throw new Error("Failed to fetch profile data");
    }
  }
);

interface ProfileState {
  profile: Profile | null;
  loading: "idle" | "pending" | "succeeded" | "failed";
  error: string | null;
}

const initialState: ProfileState = {
  profile: null,
  loading: "idle",
  error: null,
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProfileAsync.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(
        fetchProfileAsync.fulfilled,
        (state, action: PayloadAction<Profile>) => {
          state.profile = action.payload;
          state.loading = "succeeded";
          state.error = null;
        }
      )
      .addCase(fetchProfileAsync.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.error.message ?? "An error occurred";
      });
  },
});

export default profileSlice.reducer;
