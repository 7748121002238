import React from "react";
import { useSelector } from "react-redux";
import { selectCustomerById } from "../../redux/customerSlice";

interface Receipt {
  id: number;
  customer_id: number;
}

interface Props {
  receipt: Receipt;
}

const CustomerDetails: React.FC<Props> = ({ receipt }) => {
  const customer = useSelector((state: { customer: any }) =>
    selectCustomerById(receipt.customer_id)(state)
  );
  console.log("customer=", customer);
  return (
    <>
      <td className="px-6 py-4 whitespace-nowrap text-right" dir="ltr">
        {customer?.firstName + " " + customer?.lastName}
      </td>
    </>
  );
};

export default CustomerDetails;
