import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "../services/api/api";

interface RentState {
  rentReceiveReceiptData: RentReceiveReceiptData | null;
  allReceiveRentalReceipts: RentReceiveReceiptData[] | null;
  loading: boolean;
  error: string | null;
}

interface RentReceiveReceiptData {
  customer_id: number;
  receiptNumber: number;
  amountOf: string;
  amountInWords: string;
  employee_id: number;
  category_id: number;
  unitType_id: number;
  property_number_id: number;
  currency_id: number;
  startDate: Date;
  endDate: Date;
  project_id: number;
  categoryUnitType: string;
  radioOption: string;
  rentingFixedAssets: string;
  note: string;
}

const initialState: RentState = {
  rentReceiveReceiptData: null,
  allReceiveRentalReceipts: null,
  loading: false,
  error: null,
};

export const sendRentReceiveReceiptData = createAsyncThunk(
  "rentReceiveReceipt/sendRentReceiveReceiptData",
  async (data: RentReceiveReceiptData, thunkAPI) => {
    try {
      const response = await axios.post("/receiveRentalReceipt", data);
      return response.data.data;
    } catch (error: unknown) {
      if (typeof error === "object" && error && "response" in error) {
        return thunkAPI.rejectWithValue((error as any).response.data);
      } else {
        return thunkAPI.rejectWithValue("Unknown error occurred");
      }
    }
  }
);

export const fetchRentReceiveReceiptData = createAsyncThunk(
  "rentReceiveReceipt/fetchRentReceiveReceiptData",
  async (id: number, thunkAPI) => {
    try {
      const response = await axios.get(`/rentReceiveReceipt/${id}`);
      return response.data.data;
    } catch (error: unknown) {
      if (typeof error === "object" && error && "response" in error) {
        return thunkAPI.rejectWithValue((error as any).response.data);
      } else {
        return thunkAPI.rejectWithValue("Unknown error occurred");
      }
    }
  }
);

export const getAllReceiveRentalReceipts = createAsyncThunk(
  "receiveRentalReceipts/getAllReceiveRentalReceipts",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get("/receiveRentalReceipt");
      return response.data.data;
    } catch (error: unknown) {
      console.error("Error fetching all receive rental receipts:", error);
      return thunkAPI.rejectWithValue(
        "Error fetching all receive rental receipts"
      );
    }
  }
);

export const getAllReceiveRentalReceiptsByUserId = createAsyncThunk(
  "receiveRentalReceiptByUserId/getAllReceiveRentalReceiptsByUserId",
  async (userId: number, thunkAPI) => {
    try {
      const response = await axios.get(`/receiveRentalReceipt/user/${userId}`);
      const data = response.data.data;

      if (data.length === 0) {
        return thunkAPI.rejectWithValue({
          message: "No Rental receipts found for this user.",
          data: [],
        });
      }

      return data;
    } catch (error: unknown) {
      console.error("Error fetching Rent receipts by user ID:", error);
      return thunkAPI.rejectWithValue(
        "Error fetching Rental receipts by user ID"
      );
    }
  }
);

export const RESET_RENTAL_RECEIVE_RECEIPT_DATA =
  "RESET_RENTAL_RECEIVE_RECEIPT_DATA";

const rentReceiveReceiptSlice = createSlice({
  name: "rentReceiveReceipt",
  initialState,
  reducers: {
    rentReceiveReceipt: (
      state,
      action: PayloadAction<RentReceiveReceiptData>
    ) => {
      state.rentReceiveReceiptData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendRentReceiveReceiptData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(sendRentReceiveReceiptData.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
        // Optionally handle successful response if needed
      })
      .addCase(sendRentReceiveReceiptData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
        console.error(
          "Error sending rent receive receipt data:",
          action.payload
        );
      })
      .addCase(fetchRentReceiveReceiptData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchRentReceiveReceiptData.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.rentReceiveReceiptData = action.payload;
      })
      .addCase(fetchRentReceiveReceiptData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
        console.error(
          "Error fetching rent receive receipt data:",
          action.payload
        );
      })
      .addCase(getAllReceiveRentalReceipts.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllReceiveRentalReceipts.fulfilled, (state, action) => {
        state.loading = false;
        state.allReceiveRentalReceipts = action.payload;
        state.error = null;
      })
      .addCase(getAllReceiveRentalReceipts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
        state.allReceiveRentalReceipts = [];
        console.error(
          "Error fetching all receive rental receipts:",
          action.payload
        );
      })
      .addCase(RESET_RENTAL_RECEIVE_RECEIPT_DATA, (state) => {
        state.rentReceiveReceiptData = null;
      })
      .addCase(getAllReceiveRentalReceiptsByUserId.pending, (state) => {
        // Optionally set loading state
      })
      .addCase(
        getAllReceiveRentalReceiptsByUserId.fulfilled,
        (state, action) => {
          console.log(
            "Received insurance receipts by user ID:",
            action.payload
          );
          state.allReceiveRentalReceipts = action.payload;
        }
      )
      .addCase(
        getAllReceiveRentalReceiptsByUserId.rejected,
        (state, action) => {
          if (
            action.payload &&
            typeof action.payload === "object" &&
            "message" in action.payload
          ) {
            console.warn(action.payload.message);
            state.allReceiveRentalReceipts = [];
          } else {
            // Handle other errors
            console.error(
              "Error fetching insurance receipts by user ID:",
              action.payload
            );
            state.allReceiveRentalReceipts = [];
          }
        }
      );
  },
});

export const { rentReceiveReceipt } = rentReceiveReceiptSlice.actions;
export default rentReceiveReceiptSlice.reducer;
