/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetchEmployeesAsync } from "../../../redux/employeeSlice";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../../redux/store";

interface RentalContractPrintProps {
  isCopy?: Boolean;
}

const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

const RentalContractPrint: React.FC<RentalContractPrintProps> = ({
  isCopy,
}) => {
  const dispatch: ThunkDispatch<RootState, any, any> = useDispatch();
  const [loading, setLoading] = useState(true);
  console.log(loading);
  const [currentDate, setCurrentDate] = useState("");

  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  const data = useSelector((state: any) => state);
  const contractData = data.rentalContract.contractData;

  const customers = data.customer?.customers;
  const employees = useTypedSelector((state) => state.employee.employees);
  const profile = useTypedSelector((state) => state.profile.profile);

  const customer_id = contractData?.customer_id;
  const propertyOwner_id = contractData?.propertyOwner_id;
  const propertyOwner = customers.find(
    (customer: { id: any }) => customer?.id === propertyOwner_id
  );
  const customer = customers.find(
    (customer: { id: any }) => customer?.id === customer_id
  );

  useEffect(() => {
    // Function to get the current date in the desired format
    const getCurrentDate = () => {
      const date = new Date();
      const day = date.getDate();
      const month = date.getMonth() + 1; // Months are zero-based
      const year = date.getFullYear();

      return `${day} / ${month} / ${year}`;
    };

    // Set the current date when the component mounts
    setCurrentDate(getCurrentDate());
  }, []);

  useEffect(() => {
    // Simulate loading time with a timeout
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 1000); // Set the timeout duration as needed

    return () => clearTimeout(timeoutId); // Cleanup on component unmount
  }, []);

  const [employeeName, setEmployeeName] = useState<string | null>(null);

  useEffect(() => {
    dispatch(fetchEmployeesAsync());
  }, [dispatch]);

  useEffect(() => {
    if (profile) {
      const employee = employees.find((emp) => emp?.user_id === profile?.id);
      if (employee) {
        setEmployeeName(employee.first_name + " " + employee.last_name);
      }
    }
  }, [profile, employees]);

  const [receiptNumberLocalStorage, setReceiptNumberLocalStorage] = useState(
    localStorage.getItem("receiptNumber") || ""
  );

  useEffect(() => {
    const handleStorageChange = () => {
      setReceiptNumberLocalStorage(localStorage.getItem("receiptNumber") || "");
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setReceiptNumberLocalStorage(localStorage.getItem("receiptNumber") || "");
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div className="flex items-center justify-center text-sm">
      {/* Content for the second column */}
      <div className="flex flex-col w-[870px] h-[710px] mt-6 mr-6 ml-6">
        {/* First Row */}
        <div
          className="bg-cover"
          style={{
            backgroundImage: 'url("/images/purchasingContractPrint.png")',
          }}
        >
          <div className="flex">
            {/* First Column */}
            <div className="flex-1 p-4 mt-5 ml-10">
              <div className="flex flex-row">
                <p className="text-white text-base mt-[2px] mr-1">
                  {currentDate}{" "}
                </p>
                <p className="text-white text-base">:{t("Date")}</p>
              </div>
              <p className="text-white text-base ml-[0.65rem]">
                <span>{receiptNumberLocalStorage} :</span>
                {t("Contract No")}
              </p>
            </div>

            {/* Second Column */}
            <div className="flex-1 p-4">
              <p className="text-white">
                <img
                  src="/images/shkarRealEstateWhiteLogo.webp"
                  style={{ height: "80%", width: "80%" }}
                  className="mt-[15px]"
                />
              </p>
            </div>

            {/* Third Column */}
            <div className="flex-1 pt-4 pb-4 text-right mr-8 mt-4">
              <p className="text-white text-xl">{t("Sales Base")}</p>
              <p className="text-white text-xl">{t("Shkar Company")}</p>
            </div>
          </div>
        </div>

        {/* Second Row */}
        <div
          className="flex-grow p-4 bg-cover"
          style={{
            backgroundImage: 'url("/images/purchasingAndSellingContract.png")',
          }}
        >
          <div className="grid grid-rows-2 grid-cols-2 gap-4">
            <div className="row-start-1 col-start-2 p-4 flex justify-center items-center">
              {/* Content for the first row */}
              <div className="grid grid-rows-2 -mt-10 justify-center items-center -ml-[22.3rem]">
                {/* First Row with One Column */}
                <div className="row-start-1 col-start-1">
                  {/* Content for the first row */}
                  <p
                    className="text-gray-646566 text-center text-base mt-[1.2rem]"
                    style={{ opacity: "7" }}
                  >
                    {t("Your Way for a Brighter Future")}
                  </p>
                </div>

                {/* Second Row */}
                <div
                  className="bg-cover h-[4.5rem] w-[21.5rem] -mt-6"
                  style={{
                    backgroundImage:
                      lang === "kr"
                        ? `url("/images/rentalContractLabel_kr.png")`
                        : `url("/images/rentalContractLabel_ar.png")`,
                  }}
                ></div>
              </div>
            </div>
          </div>
          <div
            className="grid grid-rows-18 grid-cols-1 gap-4 -mt-[5rem]"
            dir="rtl"
          >
            {/* Row 1 */}
            <div className="ml-14 leading-relaxed">
              19.{" "}
              {t(
                "The First Party shall pay the public, and private electricity and utilities. They shall clear it before renting the property"
              )}
              .
            </div>
            <div className="-mt-1 text-sm">
              20.{" "}
              {t(
                "The Second Party is obliged to pay the national electricity bill (public) when the electricity bill is issued or visit the national electricity office"
              )}
              .
            </div>
            {/* Row 4 */}
            <div>
              21.{" "}
              {t(
                "After the expiration of the contract period, the contract shall be renewed at the current price and with the consent of both parties and arrangement by Shkar Real Estate Company for pricing, the way of paying the rent; otherwise, the property shall be vacated and shall be handed over to the owner"
              )}
              .
            </div>
            {/* <div className="-mt-1 text-sm"> */}
            {/* بۆ خانووبەرە لەبری هەردوو لایەن. */}
            {/* </div> */}
            {/* Row 5 */}
            <div className="-mt-1 text-sm ml-14 leading-relaxed mb-3">
              22.{" "}
              {t(
                "The Second Party must use the property for the agreed-upon purpose and refrain from engaging in any improper or illegal acts; otherwise, the Second Party shall be held responsible under the law, and the contract shall be considered void"
              )}
              .
            </div>
            {/* Row 6 */}
            {/* <div className="-mt-1 text-sm"> */}
            {/* تاپۆ نەبوو ئەوە لایەنی دووەم پاباندن بە پیدانی بڕی پارەی
              بەناوکردن. */}
            {/* </div> */}
            <div className="-mt-4 text-sm leading-relaxed">
              23.
              {t(
                "The Second Party must not cause any inconvenience to its neighbors; otherwise, it will be held responsible, and the contract shall be considered void"
              )}
              .
            </div>
            <div className="-mt-1 text-sm leading-relaxed">
              24.{" "}
              {t(
                "In the event of any dispute regarding the content of this contract, both parties must first resolve the dispute through negotiations, but if it is not resolved through negotiations. It will be resolved through the"
              )}
              .
            </div>
            <div className="-mt-1">
              25.{" "}
              {t(
                "The provisions of this contract are drawn up in the light of the Iraqi Lease Law No. (87) of 1979"
              )}
              .
            </div>
            <div className="-mt-1 text-sm">
              26.{" "}
              {t(
                "I, as the First Party, after reading all the clauses of the contract, agree to all the above clauses without any pressure"
              )}
              .
            </div>
            <div className="-mt-1 text-sm">
              27.{" "}
              {t(
                "I, as the Second Party, after reading all the clauses of the contract, agree to all the above clauses without any pressure"
              )}
              .
            </div>
            <div className="mb-10"></div>
          </div>

          <div className="flex justify-center items-center mt-16 -mb-2">
            <div className="flex" dir="rtl">
              <div className="p-4">
                <p> {t("First Party")}:</p>
                <div className="text-center">
                  {propertyOwner?.firstName + " " + propertyOwner?.lastName}
                </div>
              </div>
              <div className="p-4 mr-24">
                <p> {t("Contract Organizer")}</p>
                <div className="text-center">
                  {employeeName ?? profile?.name}
                </div>
              </div>
              <div className="p-4 mr-24">
                <p> {t("Second Party")}</p>
                <div className="text-center">
                  {customer?.firstName + " " + customer?.lastName}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Fourth Row */}
        <div
          className="grid grid-cols-2 grid-rows-2 h-20 mt-4"
          style={{
            backgroundImage: 'url("/images/receiveRecieptFooter.png")',
          }}
        >
          <div className="flex items-center ml-[4.4rem]">
            <img
              src="/icons/email.png"
              alt="Logo"
              style={{ maxWidth: "35%", height: "35%" }}
              className="mr-2"
            />
            <div
              className="text-white mt-[3px]"
              style={{ letterSpacing: "1px" }}
            >
              INFO@SHKARREALESTATE.NET
            </div>
          </div>
          <div className="flex items-center ml-24">
            <img
              src="/icons/whatsUp.png"
              alt="Logo"
              style={{ maxWidth: "45%", height: "45%" }}
              className="mr-2"
            />
            <div
              className="text-white mt-[3px]"
              style={{ letterSpacing: "0.5px" }}
            >
              0750 300 33 66 - 0750 300 66 99
            </div>
          </div>
          <div className="flex items-center ml-[4.4rem]">
            <img
              src="/icons/web.png"
              alt="Logo"
              style={{ maxWidth: "45%", height: "45%" }}
              className="mr-2"
            />
            <div
              className="text-white mt-[3px]"
              style={{ letterSpacing: "1.1px" }}
            >
              WWW.SHKARREALESTATE.NET
            </div>
          </div>
          <div className="flex items-center ml-24">
            <img
              src="/icons/address.png"
              alt="Logo"
              style={{ maxWidth: "45%", height: "45%" }}
              className="mr-3"
            />
            <div
              className="text-white mt-[3px]"
              style={{ letterSpacing: "1.2px" }}
            >
              IRAQ - ERBIL - WAVEY AVENUE
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RentalContractPrint;
