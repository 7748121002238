import React, { ReactNode, useEffect, useState } from "react";
import Header from "../header/Header";
import CookieConsentNotification from "../../cookieConsentNotification/CookieConsentNotification";

interface LayoutProps {
  children: ReactNode;
  isAuthPage?: boolean;
}

const Layout: React.FC<LayoutProps> = ({ children, isAuthPage }) => {
  const [mainStyle, setMainStyle] = useState({});
  const [acceptedCookies, setAcceptedCookies] = useState(false);

  const acceptAllCookies = () => {
    setAcceptedCookies(true);
  };

  useEffect(() => {
    setMainStyle((prevStyle) =>
      isAuthPage
        ? { ...prevStyle, background: "none" }
        : {
            ...prevStyle,
            // background: `linear-gradient(to bottom, #E8ECF1, #FFFFFF)`,
            background: `#E8ECF1`,
            boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.1)",
          }
    );
  }, [isAuthPage]);

  return (
    <div style={{ marginRight: "6.3%", marginLeft: "6.3%" }}>
      <Header showLogo={!isAuthPage} isAuthPage={!isAuthPage} />
      <div className="contentContainer">
        <main className="main" style={mainStyle}>
          {children}
        </main>
        {!acceptedCookies && (
          <CookieConsentNotification onAccept={acceptAllCookies} />
        )}
      </div>
    </div>
  );
};

export default Layout;
