import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../services/api/api";

interface SpendCommissionReceiptState {
  spendCommissionReceiptData: SpendCommissionReceiptData | null;
  allSpendCommissionReceipts: SpendCommissionReceiptData[] | null;
  loading: boolean;
  error: string | null;
}

interface SpendCommissionReceiptData {
  customer_id: number;
  receiptNumber: number;
  salePrice: number;
  amountOf: string;
  amountInWords: string;
  employee_id: number;
  category_id: number;
  unitType_id: number;
  real_estate_id: number;
  receiver: string;
  property_number_id: number;
  currency_id: number;
  spender: string;
  startDate: Date;
  endDate: Date;
  project_id: number;
  categoryUnitType: string;
  radioOption: string;
  rentingFixedAssets: string;
  note: string;
}

const initialState: SpendCommissionReceiptState = {
  spendCommissionReceiptData: null,
  allSpendCommissionReceipts: null,
  loading: false,
  error: null,
};

export const sendCommissionReceiptData = createAsyncThunk(
  "spendCommissionReceipt/sendCommissionReceiptData",
  async (data: SpendCommissionReceiptData, thunkAPI) => {
    try {
      const response = await axios.post("/spendCommissionReceipt", data);
      console.log("response", response);
      return response.data.data;
    } catch (error: unknown) {
      if (typeof error === "object" && error && "response" in error) {
        return thunkAPI.rejectWithValue(
          (error as any).response?.data ?? "Unknown error occurred"
        );
      } else {
        return thunkAPI.rejectWithValue("Unknown error occurred");
      }
    }
  }
);

export const getAllSpendCommissionReceipts = createAsyncThunk(
  "spendCommissionReceipts/getAllSpendCommissionReceipts",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get("/spendCommissionReceipt");
      return response.data.data;
    } catch (error: unknown) {
      console.error("Error fetching all spend rental receipts:", error);
      return thunkAPI.rejectWithValue(
        "Error fetching all spend rental receipts"
      );
    }
  }
);

export const getAllSpendCommissionReceiptsByUserId = createAsyncThunk(
  "spendCommissionReceiptByUserId/getAllSpendCommissionReceiptsByUserId",
  async (userId: number, thunkAPI) => {
    try {
      const response = await axios.get(
        `/spendCommissionReceipt/user/${userId}`
      );
      const data = response.data.data;

      if (data.length === 0) {
        return thunkAPI.rejectWithValue({
          message: "No commission receipts found for this user.",
          data: [],
        });
      }

      return data;
    } catch (error: unknown) {
      console.error("Error fetching commission receipts by user ID:", error);
      return thunkAPI.rejectWithValue(
        "Error fetching commission receipts by user ID"
      );
    }
  }
);

export const RESET_SPEND_COMMISSION_RECEIPT_DATA =
  "RESET_SPEND_COMMISSION_RECEIPT_DATA";

const spendCommissionReceiptSlice = createSlice({
  name: "spendCommissionReceipt",
  initialState,
  reducers: {
    spendCommissionReceipt: (
      state,
      action: PayloadAction<SpendCommissionReceiptData>
    ) => {
      state.spendCommissionReceiptData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(sendCommissionReceiptData.pending, (state) => {});
    builder.addCase(sendCommissionReceiptData.fulfilled, (state) => {});
    builder.addCase(sendCommissionReceiptData.rejected, (state, action) => {});
    builder.addCase(getAllSpendCommissionReceipts.pending, (state) => {});
    builder.addCase(
      getAllSpendCommissionReceipts.fulfilled,
      (state, action) => {
        state.allSpendCommissionReceipts = action.payload;
      }
    );
    builder.addCase(
      getAllSpendCommissionReceipts.rejected,
      (state, action) => {}
    );
    builder.addCase(RESET_SPEND_COMMISSION_RECEIPT_DATA, (state) => {
      state.spendCommissionReceiptData = null;
    });
    builder.addCase(
      getAllSpendCommissionReceiptsByUserId.pending,
      (state) => {}
    );
    builder.addCase(
      getAllSpendCommissionReceiptsByUserId.fulfilled,
      (state, action) => {
        state.allSpendCommissionReceipts = action.payload;
      }
    );
    builder.addCase(
      getAllSpendCommissionReceiptsByUserId.rejected,
      (state, action) => {
        if (
          action.payload &&
          typeof action.payload === "object" &&
          "message" in action.payload
        ) {
          console.warn(action.payload.message);
          state.allSpendCommissionReceipts = [];
        } else {
          console.error("Error fetching  receipts by user ID:", action.payload);
          state.allSpendCommissionReceipts = [];
        }
      }
    );
  },
});

export const { spendCommissionReceipt } = spendCommissionReceiptSlice.actions;

export default spendCommissionReceiptSlice.reducer;
