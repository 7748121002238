// Star.tsx

import React, { FC, HTMLAttributes } from "react";

interface StarProps extends HTMLAttributes<SVGElement> {
  // Other optional props if needed
}

const Star: FC<StarProps> = ({ className, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="#f4e93d" // Set the fill color to #f4e93d
      stroke="#f4e93d" // Set the stroke color to #f4e93d (optional, adjust as needed)
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={`w-6 h-6 ${className}`} // Include className in the SVG element
      {...rest}
    >
      <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
    </svg>
  );
};

export default Star;
