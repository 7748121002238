import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./CancellationPage.module.scss";
import { useTranslation } from "react-i18next";

const CancellationPage: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleContractCancellation = () => {
    // Navigate to the "/contract-cancellation" page
    navigate("/contract-cancellation-search");
  };

  const handleReceiptCancellation = () => {
    // Navigate to the "/receipt-cancellation" page
    navigate("/receipt-cancellation");
  };

  const createButton = (label: string, onClick: () => void) => (
    <button
      className={`${styles["grid-item"]} ${styles["font-medium"]}`}
      onClick={onClick}
    >
      {label}
    </button>
  );

  return (
    <div className={styles["center-container"]}>
      <div className={styles["grid-container"]}>
        {/* Row 1 */}
        {createButton(
          `${t("Contract Cancellation")}`,
          handleContractCancellation
        )}
        {createButton(
          `${t("Receipt Cancellation")}`,
          handleReceiptCancellation
        )}
      </div>
    </div>
  );
};

export default CancellationPage;
