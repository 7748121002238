import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../services/api/api";

interface LoginData {
  email: string;
  password: string;
}

interface AuthState {
  isAuthenticated: boolean;
  user: any;
  token: string | null;
  error: string | null;
}

const initialState: AuthState = {
  isAuthenticated: false,
  user: null,
  token: null,
  error: null,
};

export const loginUser = createAsyncThunk(
  "auth/loginUser",
  async (loginData: LoginData, thunkAPI) => {
    try {
      console.log("/login", loginData);
      const response = await api.post("/login", loginData);
      localStorage.setItem("token", response.data.token);
      return response.data;
    } catch (error: any) {
      console.log("error", error.message);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout(state) {
      state.isAuthenticated = false;
      state.user = null;
      state.token = null; // Clearing token on logout
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.isAuthenticated = true;
      state.user = action.payload.user;
      state.token = action.payload.token; // Storing token in state upon successful login
      state.error = null;
    });
    builder.addCase(loginUser.rejected, (state, action) => {
      state.isAuthenticated = false;
      state.user = null;
      state.token = null; // Clearing token on rejection
      state.error = action.payload as string;
    });
  },
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;
