import React, { useCallback, useEffect, useState } from "react";
import QRCodeComponent from "../../qrCode/QrCode";
import { FaXTwitter } from "react-icons/fa6";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { RootState } from "../../../redux/store";
import { ThunkDispatch } from "redux-thunk";
import { fetchEmployeesAsync } from "../../../redux/employeeSlice";

interface SpendPrintProps {
  isCopy?: Boolean;
  title: string;
}

const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

const SpendPrint: React.FC<SpendPrintProps> = ({ isCopy, title }) => {
  const dispatch: ThunkDispatch<RootState, any, any> = useDispatch();
  const [, setLoading] = useState(true);

  const [currentDate, setCurrentDate] = useState<{
    day: number;
    month: number;
    year: number;
  } | null>(null);
  const [currentTime, setCurrentTime] = useState<{
    hours: number;
    minutes: number;
    seconds: number;
  } | null>(null);

  const { t } = useTranslation();

  const dataReducers = [
    "spendCommissionReceipt",
    "spendReceipt",
    "rentalSpendReceipt",
    "insuranceSpendReceipt",
  ];

  const data = useSelector((state: any) => state);

  let selectedData: any = null;

  for (const reducer of dataReducers) {
    const reducerData = data[reducer];

    if (reducerData && reducerData[`${reducer}Data`]) {
      selectedData = reducerData[`${reducer}Data`];
      break;
    }
  }

  const customers = useTypedSelector((state) => state.customer.customers);
  const propertyNumbers = useTypedSelector(
    (state) => state.propertyNumber.propertyNumbers
  );
  const profile = useTypedSelector((state) => state.profile.profile);
  const employees = useTypedSelector((state) => state.employee.employees);
  const projects = useTypedSelector((state) => state.project.projects);

  const findEmployeeByUserId = useCallback(
    (employees: any[], userId: any) => {
      return employees.find(
        (employee: { user_id: any }) => employee.user_id === userId
      );
    },
    [] // Empty dependency array means this function is stable and won't change
  );
  const employee = findEmployeeByUserId(employees, profile?.id);

  const customer = customers.find(
    (customer) => customer.id === selectedData.customer_id
  );
  const propertyNumber = propertyNumbers.find(
    (propertyNumber) => propertyNumber.id === selectedData.property_number_id
  );
  const project = projects.find(
    (project) => project.id === selectedData.project_id
  );

  const [employeeName, setEmployeeName] = useState<string | null>(null);

  useEffect(() => {
    dispatch(fetchEmployeesAsync());
  }, [dispatch]);

  useEffect(() => {
    if (profile) {
      const employee = employees.find((emp) => emp?.user_id === profile?.id);
      if (employee) {
        setEmployeeName(employee.first_name + " " + employee.last_name);
      }
    }
  }, [profile, employees]);

  const amountOf = selectedData?.amountOf;
  const currency_id = selectedData?.currency_id;
  const amountInWords = selectedData?.amountInWords;
  const startDate = selectedData?.startDate;
  const endDate = selectedData?.endDate;
  const prevoiusReceiptNumber = selectedData?.prevoiusReceiptNumber;

  useEffect(() => {
    const getCurrentDate = () => {
      const date = new Date();
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      return { day, month, year };
    };

    const getCurrentTime = () => {
      const date = new Date();
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const seconds = date.getSeconds();

      return { hours, minutes, seconds };
    };

    setCurrentDate(getCurrentDate());
    setCurrentTime(getCurrentTime());
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, []);

  const [receiptNumberLocalStorage, setReceiptNumberLocalStorage] = useState(
    localStorage.getItem("receiptNumber") || ""
  );

  useEffect(() => {
    const handleStorageChange = () => {
      setReceiptNumberLocalStorage(localStorage.getItem("receiptNumber") || "");
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setReceiptNumberLocalStorage(localStorage.getItem("receiptNumber") || "");
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div className="flex items-center justify-center mb-[9.5rem]" dir="ltr">
      {/* Content for the second column */}
      <div className="flex flex-col w-[734.99999999999999999999999999999999999999999px] h-[410px] mt-6">
        {/* First Row */}
        <div
          className="bg-cover"
          style={{
            backgroundImage: 'url("/images/receiveRecieptHeader.png")',
          }}
        >
          <div className="flex -mt-5">
            {/* First Column */}
            <div className="flex-1 p-4 mt-5 ml-10">
              <p className="text-white text-base -ml-[1.4rem]">
                {/* <span>{receiptNumber?.toString().padStart(5, "0")} :</span> */}
                <span>{receiptNumberLocalStorage} :</span>
                ژمارەی پسوولە
              </p>
              <div className="flex flex-row">
                {currentDate && (
                  <>
                    <p className="text-white text-base mt-[2px] mr-1">
                      {currentDate.day}/{currentDate.month}/{currentDate.year}
                    </p>
                    <p className="text-white text-base">:بەروار</p>
                  </>
                )}
              </div>
            </div>

            {/* Second Column */}
            <div className="flex-1 p-4">
              <p className="text-white">
                <img
                  src="/images/shkarRealEstateWhiteLogo.webp"
                  alt="Shkar Real Estate logo"
                  style={{ height: "60%", width: "60%" }}
                  className="mt-6 ml-6"
                />
              </p>
            </div>

            {/* Third Column */}
            <div className="flex-1 pt-4 pb-4 text-right mr-8 mt-4">
              <p className="text-white text-xl">بنکەی فرۆشتنی</p>
              <p className="text-white text-xl">کۆمپانیای شکار</p>
            </div>
          </div>
        </div>

        {/* Second Row */}
        <div
          className="flex-grow p-4 bg-cover"
          style={{ backgroundImage: 'url("/images/recieveReceipt.png")' }}
        >
          <div className="grid grid-rows-2 gap-4">
            {/* First Row */}
            <div className="row-start-1 col-start-1 p-4">
              {/* Content for the first row */}
              <div className="grid grid-rows-2 -mt-10 justify-center items-center">
                {/* First Row with One Column */}
                <div className="row-start-1 col-start-1">
                  {/* Content for the first row */}
                  <p
                    className="text-gray-646566 text-center mt-[6px]"
                    style={{ fontSize: "12px" }}
                  >
                    دەرگای تۆ بۆ داهاتوویەکی ڕووناکتر
                  </p>
                </div>
                {/* Second Row */}
                <div
                  className="bg-cover h-[3.48rem] w-[17rem] -mt-[1.45rem]"
                  style={{
                    backgroundImage: 'url("/images/spendReceiptLabel.png")',
                  }}
                ></div>
                <p className="text-center text-sm mt-[8px]">
                  {t(title)}{" "}
                  <span className="text-gray-500 mr-1">
                    {isCopy ? "کۆپی" : ""}
                  </span>
                </p>
              </div>
            </div>

            {/* Second Row */}
            <div className="row-start-2 col-start-1 p-4">
              {/* Content for the second row */}
              <div className="grid grid-cols-2 justify-center items-center -mt-16">
                {/* First Column */}
                <div
                  className={`col-span-1 -ml-48 ${title === "Rent" || title === "دڵنیایی (تأمينات)" ? "-translate-y-[3.5rem]" : "-translate-y-[2.7rem]"}`}
                >
                  <QRCodeComponent size={95} />
                  <div className="grid grid-rows-2 gap-2 justify-center items-center mt-4 -translate-y-1">
                    {/* First Row */}
                    <div className="row-start-1 col-start-1 p-2 text-center">
                      {/* Content for the first row */}
                      <div className="flex items-center justify-center">
                        <img
                          src="/icons/instagram.png"
                          alt="loading..."
                          className="h-5 w-[1.35rem] mr-[5px]"
                        />
                        <img
                          src="/icons/tiktok.png"
                          alt="loading..."
                          className="h-4 w-[1.1rem] mr-[5px]"
                        />
                        <img
                          src="/icons/snap.png"
                          alt="loading..."
                          className="h-4 w-[1.1rem] mr-[5px]"
                        />
                        <div className="mr-[5px]">
                          <FaXTwitter />
                        </div>
                        <img
                          src="/icons/youtube.png"
                          alt="loading..."
                          className="h-4 w-[1.1rem]"
                        />
                      </div>
                    </div>
                    <div className="row-start-2 col-start-1 p-2 text-center -mt-8">
                      <h3 className="italic">shkar.realestate</h3>
                    </div>
                  </div>
                </div>
                <span
                  className="inline-block border border-gray-300 text-gray-800 font-light py-1 text-center -mt-[27.5rem]"
                  style={{ marginLeft: "10rem" }}
                >
                  <span>{currency_id === 1 ? "IQD" : "$"}</span> {amountOf}
                </span>
                {title === "دڵنیایی (تأمينات)" ? (
                  <span
                    className="inline-block border border-gray-300 text-gray-800 font-light py-1 text-center -mt-[40.3rem]"
                    style={{ marginRight: "10rem" }}
                  >
                    <span className="text-sm tracking-tighter">
                      ژ . پسوڵەی پێشوو :{" "}
                      <span className="tracking-normal">
                        {prevoiusReceiptNumber}
                      </span>
                    </span>
                  </span>
                ) : null}
                <div
                  className="col-span-2 mt-[1rem] -mr-8"
                  style={{ fontSize: "18px" }}
                >
                  <div
                    className={`flex space-x-4 ${title === "Rent" || title === "دڵنیایی (تأمينات)" ? "-mt-[17.89rem]" : "-mt-[17rem]"} ml-[10rem]`}
                  >
                    <div className="mt-4">
                      <h2 style={{ letterSpacing: "0.5px" }}>Spend to :</h2>
                    </div>

                    <div className="mt-4 w-[14.8rem]">
                      <div style={{ position: "relative" }}>
                        <div
                          className="dots"
                          style={{
                            position: "absolute",
                            top: -1,
                            left: -10,
                            zIndex: 1,
                            opacity: 0.15,
                          }}
                        >
                          ..................................................................
                        </div>
                        <div style={{ position: "relative" }}>
                          <p className="text-right text-black text-base">
                            {customer?.firstName + " " + customer?.lastName}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="text-center ml-7">
                        <p className="mt-4" style={{ letterSpacing: "0.5px" }}>
                          : خەرجکرا بۆ بەڕێز
                        </p>
                        <p style={{ fontSize: "9px" }} className="ml-8">
                          دفعت إلى السيد / السيدة
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flex space-x-4 ml-[10rem] mt-[9px]">
                    <div>
                      <h2 style={{ letterSpacing: "0.5px" }}>Amount :</h2>
                    </div>
                    <div className="w-[20.7rem]">
                      <div style={{ position: "relative" }}>
                        <div
                          className="dots"
                          style={{
                            position: "absolute",
                            top: -0.8,
                            left: -10,
                            zIndex: 1,
                            opacity: 0.15,
                          }}
                        >
                          .................................................................................
                        </div>
                        <div style={{ position: "relative" }}>
                          <p className="text-center text-black text-base">
                            <span dir="rtl">
                              {currency_id === 1 ? " دینار" : " دۆلار ئەمریکی"}
                            </span>{" "}
                            <span>{amountInWords}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="text-center">
                        <p style={{ letterSpacing: "0.5px" }}>: بڕی پارە</p>
                        <p style={{ fontSize: "9px" }}>مبلغ قدره</p>
                      </div>
                    </div>
                  </div>
                  <div className="flex space-x-4 ml-[10rem] mt-3">
                    <div>
                      <h2 style={{ letterSpacing: "0.5px" }}>For :</h2>
                    </div>
                    <div className="w-[24.2rem]">
                      <div style={{ position: "relative" }}>
                        <div
                          className="dots"
                          style={{
                            position: "absolute",
                            top: -0.8,
                            left: -10,
                            zIndex: 1,
                            opacity: 0.15,
                          }}
                        >
                          ..............................................................................................
                        </div>
                        <div style={{ position: "relative" }}>
                          <p className="text-center text-black text-base">
                            {project?.name +
                              "\u00A0\u00A0" +
                              "لە" +
                              "\u00A0\u00A0\u00A0" +
                              propertyNumber?.name +
                              "\u00A0\u00A0" +
                              "ژمارەی موڵکی"}{" "}
                            {title === "Commission" ? (
                              <span dir="rtl">
                                {selectedData?.category_id === 2
                                  ? "کرێی"
                                  : selectedData?.category_id === 3
                                    ? "کڕینی"
                                    : "فرۆشتنی"}
                              </span>
                            ) : (
                              ""
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="text-center">
                        <p style={{ letterSpacing: "0.5px" }}>: لەبڕی</p>
                        <p style={{ fontSize: "9px" }}>و ذالك عن</p>
                      </div>
                    </div>
                  </div>
                  {title === "دڵنیایی (تأمينات)" || title === "Rent" ? (
                    <div className="flex space-x-4 ml-[10.7rem] mt-1">
                      <div className="w-[24.2rem]">
                        <div style={{ position: "relative" }}>
                          <div
                            className="dots"
                            style={{
                              position: "absolute",
                              top: -0.8,
                              left: -10,
                              zIndex: 1,
                              opacity: 0.15,
                            }}
                          >
                            ......................................................................................................................
                          </div>
                          <div style={{ position: "relative" }}>
                            <p className="text-right text-base text-black -mr-12">
                              {"لە بەرواری" +
                                "\u00A0\u00A0" +
                                startDate +
                                "\u00A0\u00A0\u00A0" +
                                "بۆ بەرواری" +
                                "\u00A0\u00A0" +
                                endDate +
                                "\u00A0\u00A0\u00A0"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <div
                    className={`flex justify-between space-x-4 ${title === "Rent" || title === "دڵنیایی (تأمينات)" ? "mt-14" : "mt-16"} ml-72 mr-24`}
                  >
                    <div>
                      <div className="text-center">
                        <p style={{ letterSpacing: "0.6px", fontSize: "14px" }}>
                          وەرگر
                        </p>
                        <p style={{ fontSize: "9px", marginTop: "1.5px" }}>
                          المستلم
                        </p>
                        <p style={{ fontSize: "12px", marginTop: "1.5px" }}>
                          {customer?.firstName + " " + customer?.lastName}
                        </p>
                      </div>
                    </div>
                    <div>
                      <div className="text-center">
                        <p style={{ letterSpacing: "0.6px", fontSize: "14px" }}>
                          خەرجکەر
                        </p>
                        <p style={{ fontSize: "9px", marginTop: "1.5px" }}>
                          المسلم
                        </p>
                        <p style={{ fontSize: "12px", marginTop: "1.5px" }}>
                          {employeeName ?? profile?.name}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`flex flex-row ${title === "Rent" || title === "دڵنیایی (تأمينات)" ? "-mt-6 ml-[39px] -translate-y-[5.9rem]" : "-mt-6 ml-[39px] -translate-y-[4.5rem]"}`}
          >
            {currentTime && (
              <>
                <p className="text-black text-base mt-[1px] mr-1">Time : </p>
              </>
            )}
            <p className="text-black text-base mt-[2px]">
              {currentTime?.hours}:{currentTime?.minutes}:{currentTime?.seconds}
            </p>
          </div>
        </div>
        <div
          className={`grid grid-cols-2 grid-rows-2 h-14 ${title === "Rent" || title === "دڵنیایی (تأمينات)" ? "-mt-8" : "-mt-6"}`}
          style={{
            backgroundImage: 'url("/images/receiveRecieptFooter.png")',
          }}
        >
          <div className="flex items-center ml-[4.4rem]">
            <img
              src="/icons/email.png"
              alt="Logo"
              style={{ maxWidth: "35%", height: "35%" }}
              className="mr-2"
            />
            <div
              className="text-white mt-[3px]"
              style={{ letterSpacing: "1px" }}
            >
              INFO@SHKARREALESTATE.NET
            </div>
          </div>
          <div className="flex items-center ml-24">
            <img
              src="/icons/whatsUp.png"
              alt="Logo"
              style={{ maxWidth: "40%", height: "40%" }}
              className="mr-2"
            />
            <div
              className="text-white mt-[3px]"
              style={{ letterSpacing: "0.5px" }}
            >
              {employee?.branch?.phone_number_1} -{" "}
              {employee?.branch?.phone_number_2}
            </div>
          </div>
          <div className="flex items-center ml-[4.4rem]">
            <img
              src="/icons/web.png"
              alt="Logo"
              style={{ maxWidth: "40%", height: "40%" }}
              className="mr-2"
            />
            <div
              className="text-white mt-[3px]"
              style={{ letterSpacing: "1.1px" }}
            >
              WWW.SHKARREALESTATE.NET
            </div>
          </div>
          <div className="flex items-center ml-24">
            <img
              src="/icons/address.png"
              alt="Logo"
              style={{ maxWidth: "40%", height: "40%" }}
              className="mr-3"
            />
            <div
              className="text-white mt-[3px]"
              style={{ letterSpacing: "1.2px" }}
            >
              IRAQ - ERBIL - {employee?.branch?.name}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpendPrint;
