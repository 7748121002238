import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

interface PropertyOwnership {
  id: number;
  name: string;
}

const propertyOwnerships: PropertyOwnership[] = [
  {
    id: 1,
    name: "Owned",
  },
  {
    id: 2,
    name: "Rented",
  },
  {
    id: 3,
    name: "Under Development",
  },
  {
    id: 4,
    name: "Vacant",
  },
];

interface PropertyOwnershipState {
  propertyOwnerships: PropertyOwnership[];
  loading: "idle" | "pending" | "succeeded" | "failed";
  error: string | null;
}

const initialState: PropertyOwnershipState = {
  propertyOwnerships: [],
  loading: "idle",
  error: null,
};

export const fetchPropertyOwnershipAsync = createAsyncThunk(
  "propertyOwnership/fetchPropertyOwnership",
  async () => {
    // const response = await axios.get("/propertyOwnership"); // backend endpoint
    // return response.data as PropertyOwnership[];
    return propertyOwnerships as PropertyOwnership[];
  }
);

const PropertyOwnershipSlice = createSlice({
  name: "propertyOwnership",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPropertyOwnershipAsync.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(
        fetchPropertyOwnershipAsync.fulfilled,
        (state, action: PayloadAction<PropertyOwnership[]>) => {
          state.propertyOwnerships = action.payload;
          state.loading = "succeeded";
          state.error = null;
        }
      )
      .addCase(fetchPropertyOwnershipAsync.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.error.message ?? "An error occurred";
      });
  },
});

export default PropertyOwnershipSlice.reducer;
