import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

interface propertyNumber {
  apartmentNumberID: number;
  name: string;
}

const propertyNumbers: propertyNumber[] = [
  {
    apartmentNumberID: 1,
    name: "A-20-4",
  },
  {
    apartmentNumberID: 2,
    name: "B-20-5",
  },
];

interface ApartmentNumberState {
  propertyNumbers: propertyNumber[];
  loading: "idle" | "pending" | "succeeded" | "failed";
  error: string | null;
}

const initialState: ApartmentNumberState = {
  propertyNumbers: [],
  loading: "idle",
  error: null,
};

export const fetchPropertyNumbersAsync = createAsyncThunk(
  "propertyNumber/fetchApartmentNumbers",
  async () => {
    // const response = await axios.get("/propertyNumbers"); // backend endpoint
    // return response.data as propertyNumber[];
    return propertyNumbers as propertyNumber[];
  }
);

const propertyNumberSlice = createSlice({
  name: "propertyNumber",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPropertyNumbersAsync.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(
        fetchPropertyNumbersAsync.fulfilled,
        (state, action: PayloadAction<propertyNumber[]>) => {
          state.propertyNumbers = action.payload;
          state.loading = "succeeded";
          state.error = null;
        }
      )
      .addCase(fetchPropertyNumbersAsync.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.error.message ?? "An error occurred";
      });
  },
});

export default propertyNumberSlice.reducer;
