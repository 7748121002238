import React, { useRef, useState } from "react";
import * as Yup from "yup";
import styles from "../../../pages/receiveReceipts/ReceieveReceipts.module.scss";
import { Formik, Field, Form, FormikHelpers, ErrorMessage } from "formik";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { FaTimes } from "react-icons/fa";
import Success from "../../toasts/Success";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { RootState } from "../../../redux/store";
import { ThunkDispatch } from "redux-thunk";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { createCustomerAsync } from "../../../redux/customerSlice";
import { toast } from "react-toastify";
import ClientTypeSelector from "../../clientTypeSelector/ClientTypeSelector";

interface Customer {
  id: number;
  firstName: string;
  lastName: string;
  identityNo: string;
  userId: number;
  email: string;
  phoneNumber: string;
  cityId: number;
  countryId: number;
}

interface CustomerModalProps {
  isModalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title?: string;
  countries: any[];
  cities: any[];
  dispatchAction?: () => void;
}

const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

const CustomerModal: React.FC<CustomerModalProps> = ({
  isModalOpen,
  setModalOpen,
  title,
  countries,
  cities,
  dispatchAction,
}) => {
  const dispatch: ThunkDispatch<RootState, any, any> = useDispatch();
  const { t } = useTranslation();

  const [selectedClientType, setSelectedClientType] =
    useState<string>("normal");
  const handleTypeChange = (type: string) => {
    setSelectedClientType(type);
  };

  const customerValidationSchema = Yup.object().shape({
    firstName: Yup.string().required(t("First Name is required")),
    lastName: Yup.string().required(t("Middle Name is required")),
  });

  const modalRef = useRef<HTMLDivElement>(null);

  const handleCloseButtonClick = (): void => {
    setModalOpen(false);
  };

  const handleOverlayClick = (
    event: React.MouseEvent<HTMLDivElement>
  ): void => {
    if (event.target === modalRef.current) {
      setModalOpen(false);
    }
  };

  const profile = useTypedSelector((state) => state.profile.profile);

  const transformedCountries = countries.map((country) => ({
    value: country.id,
    label: country.name,
  }));

  const transformedCities = cities.map((city) => ({
    value: city.id,
    label: city.name,
  }));

  const [selectedCountry, setSelectedCountry] = useState<{
    value: number;
    label: string;
  } | null>(null);

  const [selectedCity, setSelectedCity] = useState<{
    value: number;
    label: string;
  } | null>(null);

  const [selectedNationality, setSelectedNationality] = useState<{
    value: string;
    label: string;
  } | null>(null);

  return (
    <div>
      <div
        className={`${
          isModalOpen
            ? "fixed inset-0 flex items-center justify-center backdrop-filter backdrop-blur-md"
            : "hidden"
        } transition-opacity duration-300`}
        style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
      ></div>
      <div
        ref={modalRef}
        onClick={handleOverlayClick}
        className={`${
          isModalOpen
            ? "opacity-100 scale-100"
            : "opacity-0 scale-90 pointer-events-none"
        } transition-all transform duration-300 fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex items-center justify-center`}
      >
        <div
          className="max-w-4xl mx-auto mt-8 p-8 rounded-md shadow-md bg-white"
          dir="rtl"
        >
          <div className="flex justify-end">
            <button onClick={handleCloseButtonClick} className="cursor-pointer">
              <FaTimes size={20} />
            </button>
          </div>
          <h2 className="text-2xl font-semibold mb-10 text-center">
            {title ? title : t("Customer Information")}
          </h2>
          <Formik
            initialValues={{
              id: 0,
              userId: 0,
              firstName: "",
              lastName: "",
              identityNo: "",
              email: "",
              phoneNumber: "",
              cityId: 1,
              countryId:
                transformedCountries.length > 0
                  ? transformedCountries[0].value
                  : 0,
            }}
            validationSchema={customerValidationSchema}
            onSubmit={(
              values: Customer,
              { setSubmitting, resetForm }: FormikHelpers<Customer>
            ) => {
              if (selectedCity !== null) {
                values.cityId = selectedCity.value;
              }
              if (selectedCountry !== null) {
                values.countryId = selectedCountry.value;
              }
              if (profile?.id !== undefined) {
                values.userId = profile?.id;
              }
              console.log("selectedClientType", selectedClientType);
              if (
                values.phoneNumber === "" &&
                selectedClientType !== "realEstate"
              ) {
                toast.error(t("Phone Number is required"), {
                  position: "bottom-right",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                });
                return;
              } else {
                values.phoneNumber = "";
              }
              console.log("values=", values);
              dispatch(createCustomerAsync(values));
              try {
                toast.success(t("Successfully created!"), {
                  position: "bottom-right",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                });
              } catch (error) {
                toast.error(t("Error occurred! Please try again"), {
                  position: "bottom-right",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                });
              }
              if (dispatchAction) {
                dispatchAction();
              }
              resetForm();
              setModalOpen(false);
              Success();
            }}
          >
            {({ isValid, isSubmitting, setFieldValue, values }) => (
              <>
                <ClientTypeSelector onTypeChange={handleTypeChange} />
                <Form className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="p-2">
                    <div className="mr-2">
                      <label htmlFor="firstName" className="font-bold">
                        {t("First Name")}
                      </label>
                      <Field
                        type="text"
                        id="firstName"
                        name="firstName"
                        placeholder={t("First Name")}
                        className="block w-full lg:w-96 border border-gray-400 p-2"
                      />
                      <ErrorMessage
                        name="firstName"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div className="p-2">
                      <label htmlFor="phoneNumber" className="font-bold">
                        {t("Phone Number")}
                      </label>
                      <PhoneInput
                        id="phoneNumber"
                        name="phoneNumber"
                        placeholder={t("Enter phone number")}
                        defaultCountry="IQ"
                        value={
                          values.phoneNumber
                            ? values.phoneNumber.toString()
                            : ""
                        }
                        onChange={(value: string | undefined) =>
                          setFieldValue("phoneNumber", value)
                        }
                        className="custom-phone-input"
                        style={{
                          width: "100%",
                          padding: "8px",
                          border: "1px solid #ccc",
                        }} // Add inline styles
                      />
                      <ErrorMessage
                        name="phoneNumber"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div className="p-2">
                      <label htmlFor="identityNo" className="font-bold">
                        {t("Identity Number")}
                      </label>
                      <Field
                        type="text"
                        id="identityNo"
                        name="identityNo"
                        placeholder={t("Identity Number")}
                        className="block w-full lg:w-96 border border-gray-400 p-2"
                        min="0"
                      />
                      <ErrorMessage
                        name="identityNo"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div className="p-2">
                      <label htmlFor="email" className="font-bold">
                        {t("Email")}
                      </label>
                      <Field
                        type="email"
                        id="email"
                        name="email"
                        placeholder={t("Email")}
                        className="block w-full lg:w-96 border border-gray-400 p-2"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                  </div>
                  <div>
                    <div className="p-2">
                      <label htmlFor="lastName" className="font-bold">
                        {t("Middle Name")}
                      </label>
                      <Field
                        type="text"
                        id="lastName"
                        name="lastName"
                        placeholder={t("Middle Name")}
                        className="block w-full lg:w-96 border border-gray-400 p-2"
                      />
                      <ErrorMessage
                        name="lastName"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div className="p-2">
                      <label htmlFor="nationality" className="font-bold">
                        {t("Nationality")}
                      </label>
                      <Select
                        id="nationality"
                        name="nationality"
                        options={transformedCountries}
                        placeholder={t("Select The")}
                        value={selectedNationality}
                        onChange={(selectedOption) =>
                          setSelectedNationality(selectedOption)
                        }
                        className="select-container"
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            border: "1px solid #ccc",
                            borderRadius: "none",
                            height: "41.8px",
                          }),
                        }}
                      />
                      <ErrorMessage
                        name="nationality"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div className="p-2">
                      <label htmlFor="city" className="font-bold">
                        {t("City")}
                      </label>
                      <Select
                        id="city"
                        name="cityId"
                        options={transformedCities}
                        value={selectedCity}
                        placeholder={t("Select The")}
                        onChange={(selectedOption) =>
                          setSelectedCity(selectedOption)
                        }
                        className="select-container"
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            border: "1px solid #ccc",
                            borderRadius: "none",
                            height: "41.8px",
                          }),
                        }}
                      />
                      <ErrorMessage
                        name="city"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div className="p-2">
                      <label htmlFor="country" className="font-bold">
                        {t("Country")}
                      </label>
                      <Select
                        key={Math.random()}
                        id="country"
                        name="countryId"
                        options={transformedCountries}
                        value={selectedCountry}
                        placeholder={t("Select The")}
                        onChange={(selectedOption) =>
                          setSelectedCountry(selectedOption)
                        }
                        className="select-container"
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            border: "1px solid #ccc",
                            borderRadius: "none",
                            height: "41.8px",
                          }),
                        }}
                      />
                      <ErrorMessage
                        name="country"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                  </div>
                  <div className="col-span-2 flex justify-center mt-8">
                    <button
                      type="submit"
                      // disabled={!isValid || isSubmitting}
                      // ${(!isValid || isSubmitting) && "opacity-50 cursor-not-allowed"}
                      className={`
                      ${styles["preview-button"]}
                      ${styles["black-background"]}
                      w-full md:w-52 h-10
                    `}
                    >
                      {t("Save")}
                    </button>
                  </div>
                </Form>
              </>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default CustomerModal;
