import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { motion, AnimatePresence } from "framer-motion"; // Import motion and AnimatePresence for animations
interface LanguageButtonsProps {
  isAuthPage: boolean;
}

const LanguageButtons: React.FC<LanguageButtonsProps> = ({ isAuthPage }) => {
  const { i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState<string>(
    i18n.language || "kr"
  );
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (i18n.language === "kr") {
      document.documentElement.setAttribute("dir", "rtl");
    } else {
      document.documentElement.setAttribute("dir", "ltr");
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [i18n.language]);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setShowDropdown(false);
    }
  };

  const handleLanguageChange = (language: string) => {
    setSelectedLanguage(language);
    i18n.changeLanguage(language);
    document.documentElement.setAttribute(
      "dir",
      language === "ar" || language === "kr" ? "rtl" : "ltr"
    );
    setShowDropdown(false);
  };

  const toggleDropdown = () => {
    setShowDropdown((prevState) => !prevState);
  };

  const getLanguageName = (languageCode: string): string => {
    switch (languageCode) {
      case "en":
        return "English";
      case "ar":
        return "Arabic";
      case "kr":
        return "کوردی";
      default:
        return "";
    }
  };

  return (
    <div className="relative">
      <button
        className="pl-4 pr-4 bg-gradient-to-t from-gray-100 to-transparent rounded-md focus:outline-none"
        onClick={toggleDropdown}
      >
        {getLanguageName(selectedLanguage)}
      </button>
      <AnimatePresence>
        {" "}
        {/* AnimatePresence for animations */}
        {showDropdown && (
          <motion.div
            ref={dropdownRef}
            initial={{ opacity: 0, y: -10 }} // Initial animation values
            animate={{ opacity: 1, y: 0 }} // Animation values on appearing
            exit={{ opacity: 0, y: -10 }} // Animation values on exiting
            transition={{ duration: 0.2 }} // Animation duration
            className="absolute top-10 right-0 bg-white shadow-lg rounded-md overflow-hidden"
          >
            <button
              className="block px-4 py-2 w-full text-left hover:bg-gray-100 focus:outline-none"
              onClick={() => handleLanguageChange("en")}
            >
              English
            </button>
            <button
              className="block px-4 py-2 w-full text-left hover:bg-gray-100 focus:outline-none"
              onClick={() => handleLanguageChange("kr")}
            >
              کوردی
            </button>
            <button
              className="block px-4 py-2 w-full text-left hover:bg-gray-100 focus:outline-none"
              onClick={() => handleLanguageChange("ar")}
            >
              العربية
            </button>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default LanguageButtons;
