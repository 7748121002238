import { useEffect } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import BottomNavigationButton from "../../components/common/bottomNavigationButton/BottomNavigationButton";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { ThunkDispatch } from "redux-thunk";
import { fetchProfileAsync } from "../../redux/profileSlice";

const validationSchema = Yup.object().shape({
  cells: Yup.array().of(
    Yup.lazy((value) => {
      if (typeof value === "number") {
        return Yup.number().typeError("Must be a number").nullable();
      } else if (/^\d{10}$/.test(value)) {
        return Yup.string()
          .required("This field is required")
          .matches(/^\d{10}$/, "Invalid phone number");
      }
      return Yup.string().required("This field is required");
    })
  ),
  receivedPayment: Yup.number().typeError("Must be a number").nullable(),
  receivedDate: Yup.date().nullable().required("This field is required"),

  unitType: Yup.string().required("Unit Type is required"),
  unitArea: Yup.number()
    .required("Unit Area is required")
    .positive("Area must be positive"),
  unitAddress: Yup.string().required("Unit Address is required"),
});

const initialValues = {
  cells: Array.from({ length: 7 * 4 }, (_, index) => (index === 0 ? "" : null)),
  receivedPayment: null,
  receivedDate: null,
  currency: "USD",
  unitType: "",
  unitArea: null,
  unitAddress: "",
};

const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

const RentArchivePage = () => {
  const Navigate = useNavigate();
  const { t } = useTranslation();

  const dispatch: ThunkDispatch<RootState, any, any> = useDispatch();
  const profile = useTypedSelector((state) => state.profile.profile);

  useEffect(() => {
    dispatch(fetchProfileAsync());
  }, [dispatch]);

  const handleSearchWithUserId = () => {
    console.log("handleSearchWithUserId", profile);
    Navigate("/rent-archive-result", { state: { profile: profile } });
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          console.log(values);
        }}
      >
        <Form>
          <div className="overflow-y-auto h-[750px]">
            <div className="flex items-center justify-center mt-16">
              <div className="p-4">
                <button
                  type="submit"
                  className="font-bold text-lg bg-gray-800 text-white w-[200px] h-[75px] border-none cursor-pointer text-16 transition duration-300 ease-in-out hover:from-blue-900 hover:to-blue-500"
                  onClick={handleSearchWithUserId}
                >
                  {t("All Archive")}
                </button>
              </div>
              <div className="p-4">
                <button
                  type="submit"
                  className="font-bold text-lg bg-gray-800 text-white w-[200px] h-[75px] border-none cursor-pointer text-16 transition duration-300 ease-in-out hover:from-blue-900 hover:to-blue-500"
                  onClick={handleSearchWithUserId}
                >
                  {t("Office Archive")}
                </button>
              </div>
            </div>
          </div>
        </Form>
      </Formik>
      <div className="flex justify-center -mt-20">
        <BottomNavigationButton content="Main Menu" to="/main" />
        <BottomNavigationButton content="Finance" to="/finance" />
        <BottomNavigationButton content="Contracts" to="/contracts" />
        <BottomNavigationButton content="Archive" to="/rent-archive" />
      </div>
    </>
  );
};

export default RentArchivePage;
