import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  createSelector,
} from "@reduxjs/toolkit";
import axios from "../services/api/api";

interface Project {
  id: number;
  name: string;
}

interface ProjectState {
  projects: Project[];
  loading: "idle" | "pending" | "succeeded" | "failed";
  error: string | null;
}

const initialState: ProjectState = {
  projects: [],
  loading: "idle",
  error: null,
};

export const fetchProjectsAsync = createAsyncThunk(
  "project/fetchProjects",
  async () => {
    const response = await axios.get("/projects"); // backend endpoint
    console.log("project", response.data.data);
    return response.data.data as Project[];
    // return projects as Project[];
  }
);

export const selectProjectById = (projectId: number) =>
  createSelector(
    (state: { project: ProjectState }) => state.project.projects,
    (projects) => projects.find((project) => project.id === projectId)
  );

const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjectsAsync.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(
        fetchProjectsAsync.fulfilled,
        (state, action: PayloadAction<Project[]>) => {
          state.projects = action.payload;
          state.loading = "succeeded";
          state.error = null;
        }
      )
      .addCase(fetchProjectsAsync.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.error.message ?? "An error occurred";
      });
  },
});

export default projectSlice.reducer;
