import React from "react";
import { useQuery } from "react-query";
import { Formik, Field, Form, ErrorMessage, FormikHelpers } from "formik";
import SocialIcons from "../common/socialIcons/SocialIcons";
import "./Login.scss";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { loginUser } from "../../redux/authSlice";
import { AppDispatch } from "../../redux/store";
import * as Yup from "yup";

const fetchImage = async () => {
  const response = await fetch("images/masterlogo.webp");
  const blob = await response.blob();
  const imageUrl = URL.createObjectURL(blob);
  return imageUrl;
};

interface LoginData {
  email: string;
  password: string;
}

const Login: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { data: imageUrl } = useQuery("image", fetchImage);

  const validationSchema = Yup.object({
    email: Yup.string().required(t("Please enter your email")),
    password: Yup.string().required(t("Please enter your password")),
  });

  const initialValues: LoginData = {
    email: "",
    password: "",
  };

  const handleSubmit = async (
    values: LoginData,
    formikHelpers: FormikHelpers<LoginData>,
    dispatch: AppDispatch,
    navigate: any
  ) => {
    try {
      console.log("Values", values);
      await dispatch(loginUser(values));
      navigate('/main'); 
    } catch (error) {
      console.error('Login failed:', error);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center bg-gray w-34 mt-2">
      <div className="mb-1 p-2 text-white flex items-center justify-center">
        <img
          src={imageUrl}
          alt="Logo"
          className="mr-2 mt-2"
          style={{ height: "7.8rem", width: "25.5rem" }}
        />
      </div>
      <div className="w-full p-3 bg-blue text-white font-bold text-center text-2xl">
        ERP Dashboard Interface
      </div>
      <div className="mb-4 p-2 text-white">
        <div className="p-2">
          <Formik
            initialValues={initialValues}
            onSubmit={(values, formikHelpers) => handleSubmit(values, formikHelpers, dispatch, navigate)}
            validationSchema={validationSchema}
          >
            <Form className="flex flex-col items-center">
              <div dir="rtl">
                <div>
                  <label
                    htmlFor="email"
                    className="block text-black text-xl mb-2"
                  >
                    {t("Email")}
                  </label>
                  <Field
                    type="text"
                    id="email"
                    name="email"
                    className="border border-gray-400 text-black py-3 w-24 px-3"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-red-500"
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="password"
                    className="block text-black text-xl mb-2"
                  >
                    {t("Password")}
                  </label>
                  <Field
                    type="password"
                    id="password"
                    name="password"
                    className="border border-gray-400 text-black py-3 px-3 w-24"
                  />
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="text-red-500"
                  />
                </div>
              </div>
              <div
                className="mb-2 flex items-center gap-4"
                style={{ marginTop: "1rem" }}
              >
                <SocialIcons />
                <button
                  type="submit"
                  className="bg-blue text-white py-2 px-8 font-bold ml-5"
                >
                  {t("LOG IN")}
                </button>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default Login;
