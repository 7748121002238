import React from "react";
import { useSelector } from "react-redux";
import { selectUserById } from "../../redux/userSlice";

interface Receipt {
  id: number;
  sales_employee_id: number;
}

interface Props {
  receipt: Receipt;
}

const SalesEmployeeDetails: React.FC<Props> = ({ receipt }) => {
  const salesEmployee = useSelector((state: { user: any }) =>
    selectUserById(receipt.sales_employee_id)(state)
  );
  return (
    <tr>
      <td className="px-6 py-4 whitespace-nowrap text-right" dir="ltr">
        {salesEmployee?.name}
      </td>
    </tr>
  );
};

export default SalesEmployeeDetails;
