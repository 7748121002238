import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./ContractPage.module.scss";
import MacbookButton from "../../components/common/macbookButton/MacbookButton";
import { FileText, Home, Archive } from "react-feather";
import BottomNavigationButton from "../../components/common/bottomNavigationButton/BottomNavigationButton";

const ContractPage: React.FC = () => {
  const navigate = useNavigate();

  const handleBuyingAndSellingContracts = () => {
    navigate("/buying-and-selling-contract");
  };

  const handleRentalContracts = () => {
    navigate("/rental-contracts");
  };

  const handleArchive = () => {
    navigate("/archive-buy-and-sell");
  };

  const handleRentArchive = () => {
    navigate("/rent-archive");
  };

  return (
    <>
      <div className={styles["center-container"]}>
        <div className={`${styles["grid-container"]} mt-28`}>
          <MacbookButton
            onClick={handleBuyingAndSellingContracts}
            buttonText="Buying and selling Contract"
            icon={<FileText size={20} />}
          />
          <MacbookButton
            onClick={handleRentalContracts}
            buttonText="Rental Contract"
            icon={<Home size={20} />}
          />
          <MacbookButton
            onClick={handleArchive}
            buttonText="Archive Buy And Sell"
            icon={<Archive size={20} />}
          />
          <MacbookButton
            onClick={handleRentArchive}
            buttonText="Rent Archive"
            icon={<Archive size={20} />}
          />
        </div>
      </div>
      <div className="flex justify-center mt-24">
        <BottomNavigationButton content="Main Menu" to="/main" />
        <BottomNavigationButton content="Finance" to="/finance" />
        <BottomNavigationButton content="Contracts" to="/contracts" />
      </div>
    </>
  );
};

export default ContractPage;
