import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

interface Info {
  infoID: number;
  name: string;
}

const infos: Info[] = [
  {
    infoID: 1,
    name: "1+2",
  },
  {
    infoID: 2,
    name: "0+1",
  },
];

interface InfoState {
  infos: Info[];
  loading: "idle" | "pending" | "succeeded" | "failed";
  error: string | null;
}

const initialState: InfoState = {
  infos: [],
  loading: "idle",
  error: null,
};

export const fetchInfosAsync = createAsyncThunk("info/fetchInfos", async () => {
  // const response = await axios.get("/infos"); // backend endpoint
  // return response.data as Info[];
  return infos as Info[];
});

const infoSlice = createSlice({
  name: "info",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchInfosAsync.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(
        fetchInfosAsync.fulfilled,
        (state, action: PayloadAction<Info[]>) => {
          state.infos = action.payload;
          state.loading = "succeeded";
          state.error = null;
        }
      )
      .addCase(fetchInfosAsync.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.error.message ?? "An error occurred";
      });
  },
});

export default infoSlice.reducer;
