import React, { useEffect, useState } from "react";
import Star from "../common/star/Star";
import Confetti from "react-confetti";
import "./EmployeeOfTheMonthFrame.scss";
import { useTranslation } from "react-i18next";

const EmployeeOfTheMonthFrame: React.FC = () => {
  const { t } = useTranslation();
  const [celebrate] = useState(false);
  const [imageLoaded, ] = useState(false);

  useEffect(() => {
    // Start celebration after 1 second and end after 5 seconds (adjust as needed)
    // const celebrateTimer = setTimeout(() => {
    //   setCelebrate(true);
    //   const endCelebrateTimer = setTimeout(() => {
    //     setCelebrate(false);
    //   }, 5000); // Adjust duration of celebration (in milliseconds)
    //   return () => clearTimeout(endCelebrateTimer);
    // }, 1000); // Delay before starting celebration (in milliseconds)
    // return () => clearTimeout(celebrateTimer);
  }, []);



  return (
    <div
      className="relative employee-of-the-month-frame"
      style={{ width: "24.5rem", marginTop: "3.5rem" }}
    >
      {/* Place the Star component above the frame */}
      <Star
        style={{
          position: "absolute",
          zIndex: 10,
          bottom: "260px",
          left: "90%",
          transform: "translateX(-50%)",
        }}
      />

      {/* Confetti Animation */}
      {celebrate && (
        <div className="confetti-container">
          <Confetti
            width={390}
            height={770}
            recycle={false} // Disable confetti recycle to prevent repeated patterns
            numberOfPieces={200} // Increase the number of confetti pieces
            gravity={0.25} // Adjust the gravity to control the falling speed
            wind={0.1} // Adjust the wind to control the horizontal movement
            opacity={1} // Set confetti opacity to 1 for a brighter effect
            colors={["#FFD700", "#FF6347", "#00FF00", "#4682B4", "#FF00FF"]} // Custom confetti colors
          />
        </div>
      )}

      <div className="grid grid-rows-3">
        {/* First Row */}
        <div
          className="row-span-2 p-4 relative"
          style={{ backgroundColor: "#646566", height: "28rem" }}
        >
          {/* Loading Animation or Spinner */}
          {!imageLoaded && <div className="loading-animation"></div>}

          {/* Professional Profile Picture */}
          <img
            src="/images/shkarRealEstateWhiteLogo.webp"
            alt="Shkar Real Estate White Logo"
            style={{ height: "60%", width: "60%" }}
            className="mt-6 ml-6"
          />
        </div>

        {/* Second Row */}
        <div className="flex items-center justify-center p-3 bg-yellow h-12">
          {/* Content for the second row */}
          <h2 className="text-xl text-gray-800 font-bold">
            {t("Employee Name")}
          </h2>
        </div>

        {/* Third Row */}
        <div className="flex items-center justify-center p-3 bg-blue h-12 -mt-44">
          {/* Content for the third row */}
          <h2 className="text-xl text-white font-bold">
            {t("Employee Of The Month")}
          </h2>
        </div>
      </div>
    </div>
  );
};

export default EmployeeOfTheMonthFrame;
