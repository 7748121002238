import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

interface AmountInWords {
  id: number;
  amount: number;
  amountInWords: string;
}

const amountInWords: AmountInWords[] = [
  {
    id: 1,
    amount: 500000,
    amountInWords: "پێنج سەد هەزار",
  },
  {
    id: 2,
    amount: 600000,
    amountInWords: "شەسەد هەزار",
  },
];

interface AmountInWordsState {
  amountInWords: AmountInWords[];
  loading: "idle" | "pending" | "succeeded" | "failed";
  error: string | null;
}

const initialState: AmountInWordsState = {
  amountInWords: [],
  loading: "idle",
  error: null,
};

export const fetchAmountInWordsAsync = createAsyncThunk(
  "amountInWords/fetchAmountInWords",
  async () => {
    // You can fetch data from the backend endpoint using axios
    // const response = await axios.get("/amountInWords");
    // return response.data as AmountInWords[];
    return amountInWords as AmountInWords[];
  }
);

const amountInWordsSlice = createSlice({
  name: "amountInWords",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAmountInWordsAsync.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(
        fetchAmountInWordsAsync.fulfilled,
        (state, action: PayloadAction<AmountInWords[]>) => {
          state.amountInWords = action.payload;
          state.loading = "succeeded";
          state.error = null;
        }
      )
      .addCase(fetchAmountInWordsAsync.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.error.message ?? "An error occurred";
      });
  },
});

export default amountInWordsSlice.reducer;
