import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Pagination, DatePicker, Tooltip, message } from "antd";
import { RootState } from "../../redux/store";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import moment from "moment";
import { IoMdCreate } from "react-icons/io";
import {
  getAllReceiveReceipts,
  getAllReceiveReceiptsByUserId,
} from "../../redux/receiveReceiptSlice";
import {
  getAllSpendReceipts,
  getAllSpendReceiptsByUserId,
} from "../../redux/spendReceiptSlice";
import {
  getAllReceiveCommissionReceipts,
  getAllReceiveCommissionReceiptsByUserId,
} from "../../redux/receiveCommissionReceiptSlice";
import { fetchCustomersAsync } from "../../redux/customerSlice";
import { fetchUsersAsync } from "../../redux/userSlice";
import { fetchUnitTypesAsync } from "../../redux/unitTypeSlice";
import { fetchPropertyNumbersAsync } from "../../redux/propertyNumberSlice";
import { fetchProjectsAsync } from "../../redux/projectSlice";
import { Skeleton } from "antd";
import {
  getAllReceiveInsuranceReceipts,
  getAllReceiveInsuranceReceiptsByUserId,
} from "../../redux/insuranceReceiveReceiptSlice";
import {
  getAllReceiveRentalReceipts,
  getAllReceiveRentalReceiptsByUserId,
} from "../../redux/rentReceiveReceiptSlice";
import {
  getAllSpendCommissionReceipts,
  getAllSpendCommissionReceiptsByUserId,
} from "../../redux/spendCommissionReceiptSlice";
import {
  getAllSpendInsuranceReceipts,
  getAllSpendInsuranceReceiptsByUserId,
} from "../../redux/insuranceSpendReceiptSlice";
import {
  getAllSpendRentalReceipts,
  getAllSpendRentalReceiptsByUserId,
} from "../../redux/rentalSpendReceiptSlice";
import BottomNavigationButton from "../../components/common/bottomNavigationButton/BottomNavigationButton";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import ProjectDetails from "../../components/projectDetails/ProjectDetails";
import SalesEmployeeDetails from "../../components/salesEmployeeDetails/SalesEmployeeDetails";
import UnitTypeDetails from "../../components/unitTypeDetails/UnitTypeDetails";
import PropertyNumberDetails from "../../components/propertyNumberDetails/PropertyNumberDetails";
import CustomerDetails from "../../components/customerDetails/CustomerDetails";
import EmployeeDetails from "../../components/employeeDetails/EmployeeDetails";
import { fetchProfileAsync } from "../../redux/profileSlice";

const AccountStatementResultPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch: ThunkDispatch<RootState, any, any> = useDispatch();
  const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
  const profile = useTypedSelector((state) => state.profile.profile);

  useEffect(() => {
    console.log("profile=", profile);
    dispatch(fetchProfileAsync());
  }, [dispatch, profile]);

  const formatDate = (dateString: string | number | Date) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
  };

  const location = useLocation();
  const accountStatementStatus = location.state?.accountStatementStatus;

  const selectReceiptsByStatus = (state: RootState) => {
    if (accountStatementStatus === "receiveCommissionReceipt") {
      return state.receiveCommissionReceipt.allReceiveCommissionReceipts;
    } else if (accountStatementStatus === "receiveInsuranceReceipt") {
      return state.insuranceReceiveReceipt.allReceiveInsuranceReceipts;
    } else if (accountStatementStatus === "receiveRentalReceipt") {
      return state.rentReceiveReceipt.allReceiveRentalReceipts;
    } else if (accountStatementStatus === "spendCommissionReceipt") {
      return state.spendCommissionReceipt.allSpendCommissionReceipts;
    } else if (accountStatementStatus === "spendInsuranceReceipt") {
      return state.insuranceSpendReceipt.allSpendInsuranceReceipts;
    } else if (accountStatementStatus === "receiveReceipt") {
      return state.receiveReceipt.allReceiveReceipts;
    } else if (accountStatementStatus === "spendReceipt") {
      return state.spendReceipt.allSpendReceipts;
    } else {
      return state.rentalSpendReceipt.allSpendRentalReceipts;
    }
  };

  const data = useSelector(selectReceiptsByStatus);

  const fetchData = useCallback(async () => {
  // ...
    try {
      setLoading(true);

      if (profile?.id) {
        console.log("role", profile?.role);

        const commonActions = [
          dispatch(getAllReceiveCommissionReceipts()),
          dispatch(getAllReceiveInsuranceReceipts()),
          dispatch(getAllReceiveRentalReceipts()),
          dispatch(getAllSpendCommissionReceipts()),
          dispatch(getAllSpendInsuranceReceipts()),
          dispatch(getAllSpendRentalReceipts()),
          dispatch(getAllReceiveReceipts()),
          dispatch(getAllSpendReceipts()),
        ];

        if (profile.role === "founder") {
          await Promise.all(commonActions);
        } else {
          const promises = [];

          if (accountStatementStatus === "receiveRentalReceipt") {
            promises.push(
              dispatch(getAllReceiveRentalReceiptsByUserId(profile.id))
            );
          } else if (accountStatementStatus === "receiveCommissionReceipt") {
            promises.push(
              dispatch(getAllReceiveCommissionReceiptsByUserId(profile.id))
            );
          } else if (accountStatementStatus === "receiveInsuranceReceipt") {
            promises.push(
              dispatch(getAllReceiveInsuranceReceiptsByUserId(profile.id))
            );
          } else if (accountStatementStatus === "receiveReceipt") {
            promises.push(dispatch(getAllReceiveReceiptsByUserId(profile.id)));
          } else if (accountStatementStatus === "spendReceipt") {
            promises.push(dispatch(getAllSpendReceiptsByUserId(profile.id)));
          } else if (accountStatementStatus === "spendCommissionReceipt") {
            promises.push(
              dispatch(getAllSpendCommissionReceiptsByUserId(profile.id))
            );
          } else if (accountStatementStatus === "spendInsuranceReceipt") {
            promises.push(
              dispatch(getAllSpendInsuranceReceiptsByUserId(profile.id))
            );
          } else if (accountStatementStatus === "spendRentalReceipt") {
            promises.push(
              dispatch(getAllSpendRentalReceiptsByUserId(profile.id))
            );
          }
          await Promise.all(promises);
        }
      } else {
        console.log("error");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        await Promise.all([
          dispatch(fetchCustomersAsync()),
          dispatch(fetchUsersAsync()),
          dispatch(fetchUnitTypesAsync()),
          dispatch(fetchPropertyNumbersAsync()),
          dispatch(fetchProjectsAsync()),
        ]);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch]);

  const [loading, setLoading] = useState(true);
  const [searchCriteria, setSearchCriteria] = useState("");
  const [dateRange, setDateRange] = useState<
    [moment.Moment | null, moment.Moment | null]
  >([null, null]);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 9;

  const filterData = (data: any[] | null) => {
    return data?.filter((contract) => {
      return Object.values(contract).some((value) => {
        if (
          typeof value === "string" &&
          value.toLowerCase().includes(searchCriteria.toLowerCase())
        ) {
          return true;
        }
        if (
          value &&
          typeof value === "object" &&
          "first_name" in value &&
          "last_name" in value &&
          "phone_number" in value
        ) {
          const namedValue = value as {
            first_name: string;
            last_name: string;
            phone_number: string;
          };
          return (
            (namedValue.first_name &&
              namedValue.first_name
                .toLowerCase()
                .includes(searchCriteria.toLowerCase())) ||
            (namedValue.last_name &&
              namedValue.last_name
                .toLowerCase()
                .includes(searchCriteria.toLowerCase())) ||
            (namedValue.phone_number &&
              namedValue.phone_number
                .toLowerCase()
                .includes(searchCriteria.toLowerCase()))
          );
        }
        return false;
      });
    });
  };

  const filterByDateRange = (data: any[] | null | undefined) => {
    if (data === undefined) {
      return null;
    }

    if (!dateRange[0] || !dateRange[1]) return data;

    const startDate = dateRange[0].format("YYYY-MM-DD");
    const endDate = dateRange[1].format("YYYY-MM-DD");

    return data?.filter((contract) => {
      const contractDate = moment
        .utc(contract.created_at)
        .local()
        .format("YYYY-MM-DD");
      return moment(contractDate).isBetween(startDate, endDate, null, "[]");
    });
  };

  const indexOfLastItem = currentPage * pageSize;
  const indexOfFirstItem = indexOfLastItem - pageSize;
  const filteredData = filterByDateRange(filterData(data));
  const currentItems = filteredData?.slice(indexOfFirstItem, indexOfLastItem);

  const paginationStyles = {
    fontFamily: "Helvetica, Arial, sans-serif",
    backgroundColor: "#f8f8f8",
    border: "none",
    borderRadius: "5px",
    padding: "8px",
  };

  const handleDateRangeChange = (dates: any, dateStrings: [string, string]) => {
    setDateRange(dates);
  };

  const handleResetFilters = () => {
    setSearchCriteria("");
    setDateRange([null, null]);
  };

  const handleEdit = (contract: any) => {
    if (true) {
      message.info(t("Sorry, you don't have permission to edit"));
      return;
    }
  };



  const shimmerContainerRef = useRef<HTMLDivElement>(null);
  const [, setShimmerContainerWidth] = useState(0);
  const [, setShimmerContainerHeight] = useState(0);

  useEffect(() => {
    const { current: shimmerContainer } = shimmerContainerRef;
    if (shimmerContainer) {
      setShimmerContainerWidth(shimmerContainer.offsetWidth);
      setShimmerContainerHeight(shimmerContainer.offsetHeight);
    }
  }, [loading]);

  const onClick = () => {
    window.location.reload();
  };

  const handleExportExcel = () => {
    if (!currentItems) {
      return;
    }

    const worksheet = XLSX.utils.json_to_sheet(currentItems);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Receipts");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    saveAs(
      new Blob([excelBuffer], { type: "application/octet-stream" }),
      "receipts.xlsx"
    );
  };

  return (
    <div className="flex flex-col p-6">
      <div className="overflow-x-auto" id="table-container">
        <div className="flex items-center mb-4">
          <div className="relative">
            <input
              type="text"
              placeholder={t("Search")}
              value={searchCriteria}
              onChange={(e) => setSearchCriteria(e.target.value)}
              className="border border-gray-300 bg-white h-8 px-5 pr-10 rounded-md text-sm focus:outline-none"
            />
            <button
              className="absolute right-0 top-0 mt-[0.5rem] mr-4"
              onClick={() => setSearchCriteria("")}
            >
              <svg
                className="h-4 w-4 fill-current"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M17.172 5.172a1 1 0 0 1 1.414 1.414L13.414 12l4.172 4.172a1 1 0 1 1-1.414 1.414L12 13.414l-4.172 4.172a1 1 0 1 1-1.414-1.414L10.586 12 6.414 7.828a1 1 0 1 1 1.414-1.414L12 10.586l4.172-4.172z"
                />
              </svg>
            </button>
          </div>
          {/* <BsFilter size={20} className="text-gray-600 cursor-pointer" onClick={handleFilterClick} /> */}
          <div>
            <DatePicker.RangePicker
              style={{ marginRight: 16 }}
              onChange={handleDateRangeChange}
              format="YYYY-MM-DD"
              placeholder={[t("Start Date"), t("End Date")]}
            />
          </div>
          <button
            onClick={handleResetFilters}
            className="mr-3 bg-gray-200 text-gray-700 rounded-full py-1 px-3 focus:outline-none hover:bg-gray-300"
          >
            {t("Reset Filters")}
          </button>
          <button
            onClick={onClick}
            className="flex items-center justify-center rounded-full mr-3 w-8 h-8 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 transition-all duration-300"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 text-gray-600"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M15 14a7 7 0 00-7-7m7 7a7 7 0 000-14v6m0 0l3-3m-3 3l-3-3"
              />
            </svg>
          </button>
          {/* <button
            className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded-md mr-2"
            onClick={handleExportPDF}
          >
            Export to PDF
          </button> */}
          <button
            className="bg-gray-200 hover:bg-gray-300 text-gray-700 py-1 px-3 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-gray-100 focus:ring-opacity-50 transition-colors duration-300 mr-4"
            onClick={handleExportExcel}
          >
            Export to Excel
          </button>
        </div>
        <div className="relative">
          <table className="min-w-full divide-y divide-gray-200 shadow-md overflow-hidden mb-1">
            {!loading ? (
              <>
                <thead className="bg-gray-100">
                  <tr>
                    <th className="px-6 py-3 text-right text-sm font-semibold text-gray-600 uppercase tracking-wider">
                      {t("Edit")}
                    </th>
                    {/* <th className="px-6 py-3 text-right text-sm font-semibold text-gray-600 uppercase tracking-wider">
                          {t("Print")}
                        </th> */}
                    <th className="px-6 py-3 whitespace-nowrap text-right text-sm font-semibold text-gray-600 uppercase tracking-wider">
                      {t("Date | Time")}
                    </th>
                    <th className="px-6 py-3 whitespace-nowrap text-right text-sm font-semibold text-gray-600 uppercase tracking-wider">
                      {t("Office")}
                    </th>
                    <th className="px-6 py-3 whitespace-nowrap text-right text-sm font-semibold text-gray-600 uppercase tracking-wider">
                      {t("Received From")}
                    </th>
                    {accountStatementStatus === "receiveCommissionReceipt" ? (
                      <>
                        <th className="px-6 py-3 whitespace-nowrap text-right text-sm font-semibold text-gray-600 uppercase tracking-wider">
                          {t("The Amount Of Commission Money")}
                        </th>
                      </>
                    ) : (
                      <>
                        <th className="px-6 py-3 whitespace-nowrap text-right text-sm font-semibold text-gray-600 uppercase tracking-wider">
                          {t("Amount Of")}
                        </th>
                      </>
                    )}
                    {accountStatementStatus === "receiveCommissionReceipt" ? (
                      <>
                        <th className="px-6 py-3 whitespace-nowrap text-right text-sm font-semibold text-gray-600 uppercase tracking-wider">
                          {t("Name Of Sales Employee")}
                        </th>
                      </>
                    ) : null}
                    <th className="px-6 py-3 whitespace-nowrap text-right text-sm font-semibold text-gray-600 uppercase tracking-wider">
                      {t("Sale or Rent Price")}
                    </th>
                    <th className="px-6 py-3 whitespace-nowrap text-right text-sm font-semibold text-gray-600 uppercase tracking-wider">
                      {t("Currency")}
                    </th>
                    <th className="px-6 py-3 whitespace-nowrap text-right text-sm font-semibold text-gray-600 uppercase tracking-wider">
                      {t("Receipt Number")}
                    </th>
                    <th className="px-6 py-3 whitespace-nowrap text-right text-sm font-semibold text-gray-600 uppercase tracking-wider">
                      {t("Rental Start Date")}
                    </th>
                    <th className="px-6 py-3 whitespace-nowrap text-right text-sm font-semibold text-gray-600 uppercase tracking-wider">
                      {t("Rental End Date")}
                    </th>
                    <th className="px-6 py-3 whitespace-nowrap text-right text-sm font-semibold text-gray-600 uppercase tracking-wider">
                      {t("Project")}
                    </th>
                    {/* <th className="px-6 py-3 whitespace-nowrap text-right text-sm font-semibold text-gray-600 uppercase tracking-wider">
                     {t("Category")}
                    </th> */}
                    <th className="px-6 py-3 whitespace-nowrap text-right text-sm font-semibold text-gray-600 uppercase tracking-wider">
                      {t("Unit Type")}
                    </th>
                    <th className="px-6 py-3 whitespace-nowrap text-right text-sm font-semibold text-gray-600 uppercase tracking-wider">
                      {t("Property Number")}
                    </th>
                    <th className="px-6 py-3 whitespace-nowrap text-right text-sm font-semibold text-gray-600 uppercase tracking-wider">
                      {t("Receptor")}
                    </th>
                    <th className="px-6 py-3 whitespace-nowrap text-right text-sm font-semibold text-gray-600 uppercase tracking-wider">
                      {t("Note")}
                    </th>
                  </tr>
                </thead>
              </>
            ) : null}
            <tbody>
              {loading ? (
                <td>
                  <Skeleton active style={{ width: "100rem" }} />
                  <br />
                  <Skeleton active style={{ width: "100rem" }} />
                  <br />
                  <Skeleton active style={{ width: "100rem" }} />
                  <br />
                  <Skeleton active style={{ width: "100rem" }} />
                </td>
              ) : (
                currentItems?.map((receipt) => (
                  <tr
                    key={receipt.id}
                    className="hover:bg-gray-50 transition-colors duration-200"
                  >
                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                      {loading ? (
                        <div></div>
                      ) : (
                        <Tooltip title={t("Edit")} placement="top">
                          <button
                            className="flex items-center bg-gray-100 text-gray-700 py-1 px-2 rounded-lg border border-gray-300 hover:bg-gray-200"
                            onClick={() => handleEdit(receipt.id)}
                          >
                            <IoMdCreate size={16} className="mr-1" />
                            {t("Edit")}
                          </button>
                        </Tooltip>
                      )}
                    </td>
                    {/* <td className="px-6 py-4 whitespace-nowrap text-sm">
                      <Tooltip title={t("Print")} placement="top">
                        <button
                          className="flex items-center bg-gray-100 text-gray-700 py-1 px-2 rounded-lg border border-gray-300 hover:bg-gray-200"
                          onClick={() =>
                            handlePrint(receipt.id)
                          }
                        >
                          <IoPrint size={16} className="mr-1" />
                          {t("Print")}
                        </button>
                      </Tooltip>
                    </td> */}
                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                      {receipt?.updated_at
                        ? formatDate(receipt.created_at)
                        : ""}
                    </td>
                    <ProjectDetails receipt={receipt} />
                    <CustomerDetails receipt={receipt} />
                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                      {receipt?.amountOf ?? "..."}
                    </td>
                    {accountStatementStatus === "receiveCommissionReceipt" ? (
                      <>
                        <SalesEmployeeDetails receipt={receipt} />
                      </>
                    ) : null}
                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                      {receipt?.saleOrRentPrice ?? "نرخی فرۆشتن یان کرێ نییە"}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                      {receipt?.currency_id === 1 ? "IQD" : "USD"}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                      {receipt?.receiptNumber}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                      {receipt?.startDate ?? "بەرواری نییە"}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                      {receipt?.endDate ?? "بەرواری نییە"}
                    </td>
                    <ProjectDetails receipt={receipt} />
                    <UnitTypeDetails receipt={receipt} />
                    <PropertyNumberDetails receipt={receipt} />
                    <EmployeeDetails receipt={receipt} />
                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                      {receipt?.note}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <Pagination
          style={paginationStyles}
          current={currentPage}
          pageSize={pageSize}
          total={filteredData?.length}
          onChange={(page) => setCurrentPage(page)}
        />
        <div className="flex justify-center -mt-14">
          <BottomNavigationButton content="Main Menu" to="/main" />
          <BottomNavigationButton content="Finance" to="/finance" />
          <BottomNavigationButton
            content="Account Statement"
            to="/account-statement"
          />
          <BottomNavigationButton
            content="Office Archive"
            to="/account-statement-result"
          />
        </div>
      </div>
    </div>
  );
};

export default AccountStatementResultPage;
