import React from "react";
import { useSelector } from "react-redux";
import { selectUserById } from "../../redux/userSlice";

interface Receipt {
  id: number;
  employee_id: number;
}

interface Props {
  receipt: Receipt;
}

const EmployeeDetails: React.FC<Props> = ({ receipt }) => {
  const user = useSelector((state: { user: any }) =>
    selectUserById(receipt.employee_id)(state)
  );
  return (
    <>
      <td className="px-6 py-4 whitespace-nowrap text-right" dir="ltr">
        {user?.name}
      </td>
    </>
  );
};

export default EmployeeDetails;
