import React, { useRef, useState } from "react";
import styles from "./PurchasingContractPreviewPage.module.scss";
import ReactToPrint from "react-to-print";
import Slider from "react-slick";
import PrintPurchasingContractComponent from "../../components/printPurchasingContract/PrintPurchasingContractComponent";
import PrintPurchasingContractSliderTwoComponent from "../../components/printPurchasingContractSliderTwo/PrintPurchasingContractSliderTwoComponent";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import NextArrow from "../../components/common/nextArrow/NextArrow";
import PrevArrow from "../../components/common/prevArrow/PrevArrow";
import { useTranslation } from "react-i18next";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { toast, ToastOptions } from "react-toastify";
import { sendContractData } from "../../redux/buyingAndSellingContractSlice";
import { ThunkDispatch } from "redux-thunk";
import { useNavigate } from "react-router-dom";

const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

const PurchasingContractPreview: React.FC = () => {
  const dispatch: ThunkDispatch<RootState, any, any> = useDispatch();
  const sliderOneRef = useRef<any>();
  const sliderTwoRef = useRef<any>();
  const { t } = useTranslation();

  const [showPrintButton, setShowPrintButton] = useState(false);

  const Navigate = useNavigate();

  const contractData = useTypedSelector(
    (state) => state.buyingAndSellingContract.contractData
  );

  const toastOptions: ToastOptions = {
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    className: "black-and-white-toast",
  };

  const saveData = async () => {
    if (contractData === null || contractData === undefined) {
      toast.error(t("Contract data is missing!"), toastOptions);
      return;
    }
    try {
      const response = await dispatch(sendContractData(contractData));
      const createdContractData = response.payload;
      if (response.payload.error === "Duplicate entry.") {
        setShowPrintButton(false);
        toast.error(t("Duplicate entry."), toastOptions);
        return;
      }
      localStorage.removeItem("receiptNumber");
      localStorage.setItem("receiptNumber", createdContractData?.receiptNumber);
      toast.success(
        t("Contract data has been sent successfully!"),
        toastOptions
      );
      setShowPrintButton(true);
    } catch (error) {
      toast.error(
        t("Failed to send contract data! Please try again."),
        toastOptions
      );
    }
  };

  const handlePrint = () => {
    saveData();
  };

  const handleEditClick = (id: number) => {
    if (!contractData) {
      console.error("No receipt data available.");
      return;
    }

    Navigate(`/buying-and-selling-contract/${id}`, { state: { contractData } });
  };

  const handleBackButtonClick = () => {
    Navigate(`/buying-and-selling-contract`);
  };

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <>
      <div className="flex" dir="rtl">
        <div
          className="w-[30.3125rem]"
          style={{
            background: `linear-gradient(to bottom, rgba(225, 227, 230, 1) 0%, rgba(225, 227, 230, 0.0) 100%)`,
            padding: "3.7rem",
          }}
        >
          <div className="grid grid-rows-7 grid-cols-1">
            <div className="flex flex-col items-center justify-center gap-5">
              {/* <div className="w-60">
                <label htmlFor="category" className={`${styles.label}`}>
                  {t("Property Project")}
                </label>
                <select
                  id="category"
                  name="category"
                  className={`${styles["input-field"]} ${styles["black-background"]}`}
                >
                  <option
                    value=""
                    label={contractData?.project_id.toString()}
                    className="text-center"
                  />
                </select>
              </div> */}
              {/* <div className="w-60">
                <label htmlFor="category" className={`${styles.label}`}>
                  {t("Property Type")}
                </label>
                <select
                  id="category"
                  name="category"
                  className={`${styles["input-field"]} ${styles["black-background"]}`}
                >
                  <option
                    value=""
                    label={contractData?.propertyType}
                    className="text-center"
                  />
                </select>
              </div> */}
              {/* <div className="w-60">
                <label htmlFor="category" className={`${styles.label}`}>
                  {t("Channel Lead")}
                </label>
                <select
                  id="category"
                  name="category"
                  className={`${styles["input-field"]} ${styles["black-background"]}`}
                >
                  <option
                    value=""
                    label={contractData?.channelLead}
                    className="text-center"
                  />
                </select>
              </div> */}
              {/* <div className="mb-[8px] mt-[2rem]">
                <button
                  type="submit"
                  className={`${styles["preview-button"]} ${styles["black-background"]}`}
                >
                  {t("Edit")}
                </button>
              </div> */}
              <div className="mb-[8px] mt-56">
                <div style={{ marginBottom: "20px", marginTop: "6rem" }}>
                  <button
                    type="button"
                    className={`${styles["preview-button"]} ${styles["black-background"]}`}
                    onClick={() =>
                      handleEditClick(contractData?.receiptNumber ?? 1)
                    }
                  >
                    {t("Edit")}
                  </button>
                </div>
                <button
                  type="submit"
                  onClick={handlePrint}
                  className={`${styles["preview-button"]} ${styles["black-background"]}`}
                >
                  {t("Save")}
                </button>
                <button
                  type="submit"
                  onClick={handleBackButtonClick}
                  className={`${styles["preview-button"]} ${styles["black-background"]} mt-[1.17rem]`}
                >
                  {t("Back")}
                </button>
                <div className="mb-3"></div>
                {showPrintButton && (
                  <>
                    <ReactToPrint
                      trigger={() => (
                        <button
                          className={`${styles["preview-button"]} ${styles["blue-background"]}`}
                        >
                          {t("Print Page 1")}
                        </button>
                      )}
                      content={() => sliderOneRef.current}
                    />
                    <div className="mb-3"></div>
                    <ReactToPrint
                      trigger={() => (
                        <button
                          className={`${styles["preview-button"]} ${styles["blue-background"]}`}
                        >
                          {t("Print Page 2")}
                        </button>
                      )}
                      content={() => sliderTwoRef.current}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="w-[4.6875rem]"></div>
        <div className="w-[77.5rem] bg-gradient-to-b from-black to-gray-300 p-8">
          <Slider {...sliderSettings}>
            <div>
              <PrintPurchasingContractComponent
                ref={sliderOneRef}
                content={<p>Slide 1 Content</p>}
              />
            </div>
            <div>
              <PrintPurchasingContractSliderTwoComponent
                ref={sliderTwoRef}
                content={<p>Slide 2 Content</p>}
              />
            </div>
          </Slider>
        </div>
      </div>
    </>
  );
};

export default PurchasingContractPreview;
