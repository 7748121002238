import React from "react";
import { useSelector } from "react-redux";
import { selectPropertyNumberById } from "../../redux/propertyNumberSlice";

interface Receipt {
  id: number;
  property_number_id: number;
}

interface Props {
  receipt: Receipt;
}

const PropertyNumberDetails: React.FC<Props> = ({ receipt }) => {
  const propertyNumber = useSelector((state: { propertyNumber: any }) =>
    selectPropertyNumberById(receipt.property_number_id)(state)
  );
  return (
    <td className="px-6 py-4 whitespace-nowrap text-right" dir="ltr">
      {propertyNumber?.name}
    </td>
  );
};

export default PropertyNumberDetails;
