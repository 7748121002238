/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import QRCodeComponent from "../../qrCode/QrCode";
import { useTranslation } from "react-i18next";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { ThunkDispatch } from "redux-thunk";
import { fetchEmployeesAsync } from "../../../redux/employeeSlice";
import { useParams } from "react-router-dom";
import { createSelector } from "reselect";
import axios from "../../../services/api/api";
import { fetchPropertyOwnersAsync } from "../../../redux/propertyOwnerSlice";
import { fetchPropertyNumbersAsync } from "../../../redux/propertyNumberSlice";
import { fetchProjectsAsync } from "../../../redux/projectSlice";
import { fetchCustomersAsync } from "../../../redux/customerSlice";

interface PurchasingContractPrintProps {
  isCopy?: Boolean;
}

const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

const PurchasingContractPrint: React.FC<PurchasingContractPrintProps> = ({
  isCopy,
}) => {
  const dispatch: ThunkDispatch<RootState, any, any> = useDispatch();
  const [loading, setLoading] = useState(true);
  console.log(loading);
  const { id } = useParams();
  const numericId = id ? parseInt(id) : undefined;

  const [currentDate, setCurrentDate] = useState("");
  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  const data = useSelector((state: any) => state);

  const customers = data.customer?.customers;
  const projects = data.project?.projects;
  const profile = useTypedSelector((state) => state.profile.profile);
  const employees = useTypedSelector((state) => state.employee.employees);
  const propertyNumbers = useTypedSelector(
    (state) => state.propertyNumber.propertyNumbers
  );

  const selectContractData = (state: RootState) =>
    state.buyingAndSellingContract.contractData;

  const contractData = useTypedSelector(
    createSelector(selectContractData, (contractData) => contractData)
  );

  useEffect(() => {
    const fetchContractData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `/buyingAndSellingContract/${numericId}`
        );
        dispatch(fetchPropertyOwnersAsync());
        dispatch(fetchPropertyNumbersAsync());
        dispatch(fetchProjectsAsync());
        dispatch(fetchCustomersAsync());
        setReceiptNumber(response.data.data.receiptNumber);
        setProperty_number_id(response.data.data.property_number_id);
        setCustomer_id(response.data.data.customer_id);
        setPropertyOwner_id(response.data.data.propertyOwner_id);
        setProject_id(response.data.data.project_id);
        setTotalPrice(response.data.data.totalPrice);
        setRemainDate(response.data.data.remainDate);
        setRemainPayment(response.data.data.remainPayment);
        setHandoverDate(response.data.data.handoverDate);
        setHandoverPenalty(response.data.data.handoverPenalty);
        setCancellationPenalty(response.data.data.cancellationPenalty);
        setReceivedAmount(response.data.data.receivedAmount);
        setUnitArea(response.data.data.unitArea);
        setPropertyType(response.data.data.propertyType);
        setCurrency_id(response.data.data.currency_id);
        setReceivedDate(response.data.data.receivedDate);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching contract data:", error);
        setLoading(false);
      }
    };

    if (numericId !== undefined) {
      fetchContractData();
    }
  }, [dispatch, numericId]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [receiptNumber, setReceiptNumber] = useState(
    contractData?.receiptNumber
  );
  const [customer_id, setCustomer_id] = useState(contractData?.customer_id);
  const [propertyOwner_id, setPropertyOwner_id] = useState(
    contractData?.propertyOwner_id
  );
  const [project_id, setProject_id] = useState(contractData?.project_id);
  const [property_number_id, setProperty_number_id] = useState(
    contractData?.property_number_id
  );
  const [totalPrice, setTotalPrice] = useState(contractData?.totalPrice);
  const [remainPayment, setRemainPayment] = useState(
    contractData?.remainPayment
  );
  const [receivedAmount, setReceivedAmount] = useState(
    contractData?.receivedAmount
  );
  const [remainDate, setRemainDate] = useState(contractData?.remainDate);
  const [unitArea, setUnitArea] = useState(contractData?.unitArea);
  const [propertyType, setPropertyType] = useState(contractData?.propertyType);
  const [currency_id, setCurrency_id] = useState(contractData?.currency_id);
  const [receivedDate, setReceivedDate] = useState(contractData?.receivedDate);
  const [handoverDate, setHandoverDate] = useState(contractData?.handoverDate);
  const [handoverPenalty, setHandoverPenalty] = useState(
    contractData?.handoverPenalty
  );
  const [cancellationPenalty, setCancellationPenalty] = useState(
    contractData?.cancellationPenalty
  );
  const propertyOwner = customers.find(
    (customer: { id: any }) => customer?.id === propertyOwner_id
  );
  const customer = customers.find(
    (customer: { id: any }) => customer?.id === customer_id
  );

  const project = projects.find(
    (project: { id: any }) => project?.id === project_id
  );
  const propertyNumber = propertyNumbers.find(
    (propertyNumber: { id: any }) => propertyNumber?.id === property_number_id
  );

  const [employeeName, setEmployeeName] = useState<string | null>(null);

  useEffect(() => {
    dispatch(fetchEmployeesAsync());
  }, [dispatch]);

  useEffect(() => {
    if (profile) {
      const employee = employees.find((emp) => emp?.user_id === profile?.id);
      if (employee) {
        setEmployeeName(employee.first_name + " " + employee.last_name);
      }
    }
  }, [profile, employees]);

  useEffect(() => {
    const getCurrentDate = () => {
      const date = new Date();
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      return `${day} / ${month} / ${year}`;
    };

    setCurrentDate(getCurrentDate());
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, []);

  const [
    receiptNumberContractLocalStorage,
    setReceiptNumberContractLocalStorage,
  ] = useState(localStorage.getItem("receiptNumber") || "");

  useEffect(() => {
    const handleStorageChange = () => {
      setReceiptNumberContractLocalStorage(
        localStorage.getItem("receiptNumber") || ""
      );
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setReceiptNumberContractLocalStorage(
        localStorage.getItem("receiptNumber") || ""
      );
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div className="flex items-center justify-center text-sm h-[90rem] -mt-[22.02rem]">
      {/* Content for the second column */}
      <div className="flex flex-col w-[870px] h-[700px] mt-6 mr-6 ml-6">
        {/* First Row */}
        <div
          className="bg-cover"
          style={{
            backgroundImage: 'url("/images/purchasingContractPrint.png")',
          }}
        >
          <div className="flex">
            {/* First Column */}
            <div className="flex-1 p-4 mt-5 ml-10">
              <p className="text-white text-base ml-[0.3rem]">
                <span>{receiptNumberContractLocalStorage ?? "00012"} :</span>
                {t("Contract Number")}
              </p>
              <div className="flex flex-row">
                <p className="text-white text-base mt-[2px] mr-1">
                  {currentDate}{" "}
                </p>
                <p className="text-white text-base">:بەروار</p>
              </div>
            </div>
            {/* Second Column */}
            <div className="flex-1 p-4">
              <p className="text-white">
                <img
                  src="/images/shkarRealEstateWhiteLogo.webp"
                  style={{ height: "80%", width: "80%" }}
                  className="mt-[15px]"
                />
              </p>
            </div>

            {/* Third Column */}
            <div className="flex-1 pt-4 pb-4 text-right mr-8 mt-4">
              <p className="text-white text-xl">{t("")}</p>
              <p className="text-white text-xl">کۆمپانیای شکار</p>
            </div>
          </div>
        </div>

        {/* Second Row */}
        <div
          className="flex-grow p-4 bg-cover"
          style={{
            backgroundImage: 'url("/images/purchasingAndSellingContract.png")',
          }}
        >
          <div className="grid grid-rows-2 grid-cols-2 gap-4">
            <div className="-ml-[10rem] mt-2">
              <QRCodeComponent size={100} />
            </div>
            <div className="row-start-1 col-start-2 p-4 flex justify-center items-center">
              {/* Content for the first row */}
              <div className="grid grid-rows-2 -mt-10 justify-center items-center -ml-[22.3rem]">
                {/* First Row with One Column */}
                <div className="row-start-1 col-start-1">
                  {/* Content for the first row */}
                  <p
                    className="text-gray-646566 text-center text-base mt-[1.2rem]"
                    style={{ opacity: "7" }}
                  >
                    {t("Your Way for a Brighter Future")}
                  </p>
                </div>

                <div
                  className="bg-cover h-[4rem] w-[21rem] -mt-3"
                  style={{
                    backgroundImage:
                      lang === "kr"
                        ? `url("/images/purchasingContractLabel_kr.png")`
                        : `url("/images/purchasingContractLabel_ar.png")`,
                  }}
                ></div>
              </div>
            </div>
          </div>

          {/* Third Row */}
          <div className="flex -mt-[9.3rem] -mb-6" dir="rtl">
            {/* First Column */}
            <div className="flex-1 mb-4 mt-4 mr-4">
              {/* Content for the first column */}
              <div className="grid grid-rows-5 grid-cols-1 gap-4">
                {/* Row 1 */}
                <div className="font-bold">{t("First Party (landlord)")}</div>
                {/* Row 2 */}
                <div className="">
                  <div className="relative">
                    {/* Render the propertyOwner component here */}
                    <div className="absolute text-center top-0 left-6 w-full">
                      {propertyOwner?.firstName + " " + propertyOwner?.lastName}
                    </div>
                  </div>
                  <div className="text-black">
                    {lang === "ar" ? (
                      <>
                        {t("Full Name")}
                        :.................................................................
                      </>
                    ) : (
                      <>
                        {t("Full Name")}
                        :...................................................................
                      </>
                    )}
                  </div>
                </div>
                {/* Row 3 */}
                {/* <div className="">
                  <div className="relative">
                    <div className="absolute text-center top-0 left-16 w-full">
                      565654
                    </div>
                  </div>
                  <div className="text-black">
                    {lang === "ar" ? (
                      <>
                        {t("ID number")}
                        :...................................................................
                      </>
                    ) : (
                      <>
                        {t("ID number")}
                        :........................................................................
                      </>
                    )}
                  </div>
                </div> */}
                {/* Row 4 */}
                <div className="">
                  <div className="relative">
                    <div className="absolute text-center top-0 left-16 w-full">
                      {t("Iraqi") ?? t("Iraqi")}
                    </div>
                  </div>
                  <div className="text-black">
                    {lang === "ar" ? (
                      <>
                        {t("Nationality")}
                        :.........................................................................
                      </>
                    ) : (
                      <>
                        {t("Nationality")}
                        :.......................................................................
                      </>
                    )}
                  </div>
                </div>
                {/* Row 5 */}
                {/* <div className="">
                  <div className="relative">
                    <div
                      className="absolute text-center top-0 left-12 w-full"
                      dir="ltr"
                    >
                      {propertyOwner?.phoneNumber}
                    </div>
                  </div>
                  <div className="text-black">
                    {lang === "ar" ? (
                      <>
                        {t("Phone Number")}
                        :...................................................................
                      </>
                    ) : (
                      <>
                        {t("Phone Number")}
                        :...........................................................
                      </>
                    )}
                  </div>
                </div> */}
              </div>
            </div>

            {/* Second Column */}
            <div className="flex-1 mb-4 mt-4">
              {/* Content for the first column */}
              <div className="grid grid-rows-5 grid-cols-1 gap-4">
                {/* Row 1 */}
                <div className="font-bold">{t("Second Party (tenant)")}</div>
                {/* Row 2 */}
                <div className="">
                  <div className="relative">
                    {/* Render the customer component here */}
                    <div className="absolute text-center top-0 left-6 w-full">
                      {customer?.firstName + " " + customer?.lastName}
                    </div>
                  </div>
                  <div className="text-black">
                    {lang === "ar" ? (
                      <>
                        {t("Full Name")}
                        :.................................................................
                      </>
                    ) : (
                      <>
                        {t("Full Name")}
                        :...................................................................
                      </>
                    )}
                  </div>
                </div>
                {/* Row 3 */}
                {/* <div className="">
                  <div className="relative">
                    <div className="absolute text-center top-0 left-16 w-full">
                      657087
                    </div>
                  </div>
                  <div className="text-black">
                    {lang === "ar" ? (
                      <>
                        {t("ID number")}
                        :...................................................................
                      </>
                    ) : (
                      <>
                        {t("ID number")}
                        :........................................................................
                      </>
                    )}
                  </div>
                </div> */}
                {/* Row 4 */}
                <div className="">
                  <div className="relative">
                    <div className="absolute text-center top-0 left-16 w-full">
                      {t("Iraqi") ?? t("Iraqi")}
                    </div>
                  </div>
                  <div className="text-black">
                    {lang === "ar" ? (
                      <>
                        {t("Nationality")}
                        :.........................................................................
                      </>
                    ) : (
                      <>
                        {t("Nationality")}
                        :........................................................................
                      </>
                    )}
                  </div>
                </div>
                {/* Row 5 */}
                {/* <div className="">
                  <div className="relative">
                    <div
                      className="absolute text-center top-0 left-12 w-full"
                      dir="ltr"
                    >
                      {customer?.phoneNumber}
                    </div>
                  </div>
                  <div className="text-black">
                    {lang === "ar" ? (
                      <>
                        {t("Phone Number")}
                        :...................................................................
                      </>
                    ) : (
                      <>
                        {t("Phone Number")}
                        :............................................................
                      </>
                    )}
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <div
            className={`ml-4 mr-4 ${lang === "ar" ? "-mt-16" : "-mt-11"} pb-3`}
            dir="rtl"
          >
            <p className="font-bold">
              {t("Both parties have agreed upon the following points")}:
            </p>
          </div>
          <div
            className="grid grid-rows-13 grid-cols-1 gap-4 ml-1 mr-4"
            dir="rtl"
          >
            {/* Row 1 */}
            <div dir="rtl">
              <div className="text-black">
                {t(
                  "I, the First Party, agree to sell the following property, Property Type"
                )}
                : ...{totalPrice + " "}
                {currency_id === 1 ? "دینار" : "دۆلار"}
                ...
              </div>
            </div>
            <div className="-mt-2">
              {t("Property Type")} ...{t(propertyType ?? " ")}...{" "}
              {t("Location")} ....
              {t(project?.name)} ... {t("Area")}....
              {unitArea}م٢... {t("Unit No")} ...
              {propertyNumber?.name}...
            </div>
            {/* Row 2 */}
            <div>
              2.{" "}
              {t(
                "I, the Second Party, agree to buy the property mentioned above for"
              )}
              :...{totalPrice + " "}
              {currency_id === 1 ? "دینار" : "دۆلار"}
              ...
            </div>
            {/* Row 3 */}
            <div>
              3. {t("The First Party received")} ....
              {receivedAmount + " "}
              {currency_id === 1 ? "دینار" : "دۆلار"}
              .... {t("Received as advance on date")} {receivedDate}{" "}
              {lang === "kr" || lang === "en" ? (
                <>{t("from the Second Party")}</>
              ) : (
                ""
              )}
              .
            </div>
            {/* Row 4 */}
            <div>
              4.{" "}
              {t(
                "The amount remaining to be paid by the Second Party shall be paid to the First Party as follows"
              )}
              :
            </div>
            <div>
              {lang === "kr" ? (
                <>
                  {t("The amount of deposit that")} ...
                  {receivedAmount + " "}
                  {currency_id === 1 ? "دینار" : "دۆلار"}... ،{" "}
                  {t("on the date of")} {remainDate}{" "}
                  {t("the remainder shall be paid whose amount is")} ...
                  {remainPayment + " "}
                  {currency_id === 1 ? "دینار" : "دۆلار"}...
                </>
              ) : (
                <>
                  {t("After receiving the amount of")} {receivedAmount + " "}
                  {currency_id === 1 ? "دینار" : "دۆلار"}{" "}
                  {t(
                    "The remaining amount of the selling price, which amounts to"
                  )}{" "}
                  {remainPayment + " "}
                  {currency_id === 1 ? "دینار" : "دۆلار"} {t("on the date")}{" "}
                  {remainDate ?? "1-1-2024"}
                </>
              )}
              .
            </div>
            {/* Row 5 */}
            <div>
              5.{" "}
              {t(
                "The First Party should hand over the property to the Second Party until the date"
              )}{" "}
              ...{handoverDate}...
            </div>
            {/* Row 6 */}
            <div>
              6.{" "}
              {t(
                "If the First Party does not hand over the property to the Second Party on the specified date,"
              )}
            </div>
            <div className="">
              {t("the First Party must be obliged to pay the amount of")} ...
              {handoverPenalty + " "}
              {currency_id === 1 ? "دینار" : "دۆلار"}... {t("as a fine")}.
            </div>
            {/* Row 7 */}
            <div className="leading-relaxed">
              7.{" "}
              {t(
                "If either party withdraws from this contract for any reason, they shall be obliged to pay the amount of"
              )}
              ..{cancellationPenalty + " "}
              {currency_id === 1 ? "دینار" : "دۆلار"}..
              <br />
              {t(
                "as a fine to the other party without notifying the official authority"
              )}
              .
            </div>
            <div className="ml-14 leading-relaxed">
              8.{" "}
              {t(
                "The sale fee, transfer, separation, consolidation, correction fees and property tax must be paid by the First Party in accordance with the law. If there is no cadastral registration, the Second Party is obliged to pay the registration fee"
              )}
              .
            </div>
            <div className="mb-10"></div>
          </div>

          <div className="flex justify-center items-center -mt-0 -mb-3">
            <div className="flex" dir="rtl">
              <div className="p-4">
                <p> {t("First Party")}:</p>
                <div className="text-center">
                  {propertyOwner?.firstName + " " + propertyOwner?.lastName}
                </div>
              </div>
              <div className="p-4 mr-24">
                <p> {t("Contract Organizer")}</p>
                <div className="text-center">
                  {employeeName ?? profile?.name}
                </div>
              </div>
              <div className="p-4 mr-24">
                <p> {t("Second Party")}</p>
                <div className="text-center">
                  {customer?.firstName + " " + customer?.lastName}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Fourth Row */}
        <div
          className="grid grid-cols-2 grid-rows-2 h-20 mt-0"
          style={{
            backgroundImage: 'url("/images/receiveRecieptFooter.png")',
          }}
        >
          <div className="flex items-center ml-[4.4rem]">
            <img
              src="/icons/email.png"
              alt="Logo"
              style={{ maxWidth: "35%", height: "35%" }}
              className="mr-2"
            />
            <div
              className="text-white mt-[3px]"
              style={{ letterSpacing: "1px" }}
            >
              INFO@SHKARREALESTATE.NET
            </div>
          </div>
          <div className="flex items-center ml-24">
            <img
              src="/icons/whatsUp.png"
              alt="Logo"
              style={{ maxWidth: "45%", height: "45%" }}
              className="mr-2"
            />
            <div
              className="text-white mt-[3px]"
              style={{ letterSpacing: "0.5px" }}
            >
              0750 300 33 66 - 0750 300 66 99
            </div>
          </div>
          <div className="flex items-center ml-[4.4rem]">
            <img
              src="/icons/web.png"
              alt="Logo"
              style={{ maxWidth: "45%", height: "45%" }}
              className="mr-2"
            />
            <div
              className="text-white mt-[3px]"
              style={{ letterSpacing: "1.1px" }}
            >
              WWW.SHKARREALESTATE.NET
            </div>
          </div>
          <div className="flex items-center ml-24">
            <img
              src="/icons/address.png"
              alt="Logo"
              style={{ maxWidth: "45%", height: "45%" }}
              className="mr-3"
            />
            <div
              className="text-white mt-[3px]"
              style={{ letterSpacing: "1.2px" }}
            >
              IRAQ - ERBIL - WAVEY AVENUE
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PurchasingContractPrint;
