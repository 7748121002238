import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "../services/api/api";

interface InsuranceSpendReceiptState {
  insuranceSpendReceiptData: InsuranceSpendReceiptData | null;
  allSpendInsuranceReceipts: InsuranceSpendReceiptData[] | null;
}

interface InsuranceSpendReceiptData {
  customer_id: number;
  receiptNumber: number;
  amountOf: string;
  amountInWords: string;
  project_id: number;
  prevoiusReceiptNumber: number;
  employee_id: number;
  currency_id: number;
  category_id: number;
  categoryUnitType: string;
  property_number_id: number;
  radioOption: string;
  rentingFixedAssets: string;
  startDate: Date;
  endDate: Date;
  unitType_id: number;
  note: string;
}

const initialState: InsuranceSpendReceiptState = {
  insuranceSpendReceiptData: null,
  allSpendInsuranceReceipts: null,
};

export const sendInsuranceReceiptData = createAsyncThunk(
  "insuranceSpendReceipt/sendInsuranceReceiptData",
  async (data: InsuranceSpendReceiptData, thunkAPI) => {
    try {
      const response = await axios.post("/spendInsuranceReceipt", data);
      return response.data.data;
    } catch (error: unknown) {
      if (typeof error === "object" && error && "response" in error) {
        return thunkAPI.rejectWithValue(
          (error as any).response?.data ?? "Unknown error occurred"
        );
      } else {
        return thunkAPI.rejectWithValue("Unknown error occurred");
      }
    }
  }
);

export const getAllSpendInsuranceReceipts = createAsyncThunk(
  "spendInsuranceReceipt/getAllSpendInsuranceReceipts",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get("/spendInsuranceReceipt");
      return response.data.data;
    } catch (error: unknown) {
      console.error("Error fetching all insurance spend receipts:", error);
      return thunkAPI.rejectWithValue(
        "Error fetching all receive spend receipts"
      );
    }
  }
);

export const getAllSpendInsuranceReceiptsByUserId = createAsyncThunk(
  "spendInsuranceReceiptByUserId/getAllSpendInsuranceReceiptsByUserId",
  async (userId: number, thunkAPI) => {
    try {
      const response = await axios.get(`/spendInsuranceReceipt/user/${userId}`);
      const data = response.data.data;

      if (data.length === 0) {
        return thunkAPI.rejectWithValue({
          message: "No Insurance receipts found for this user.",
          data: [],
        });
      }

      return data;
    } catch (error: unknown) {
      console.error("Error fetching Insurance receipts by user ID:", error);
      return thunkAPI.rejectWithValue(
        "Error fetching Insurance receipts by user ID"
      );
    }
  }
);

export const RESET_INSURANCE_SPEND_RECEIPT_DATA =
  "RESET_INSURANCE_SPEND_RECEIPT_DATA";

const insuranceSpendReceiptSlice = createSlice({
  name: "insuranceSpendReceipt",
  initialState,
  reducers: {
    insuranceSpendReceipt: (
      state,
      action: PayloadAction<InsuranceSpendReceiptData>
    ) => {
      state.insuranceSpendReceiptData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(sendInsuranceReceiptData.pending, (state) => {});
    builder.addCase(sendInsuranceReceiptData.fulfilled, (state) => {});
    builder.addCase(sendInsuranceReceiptData.rejected, (state, action) => {});
    builder.addCase(getAllSpendInsuranceReceipts.pending, (state) => {});
    builder.addCase(getAllSpendInsuranceReceipts.fulfilled, (state, action) => {
      state.allSpendInsuranceReceipts = action.payload;
    });
    builder.addCase(
      getAllSpendInsuranceReceipts.rejected,
      (state, action) => {}
    );
    builder.addCase(RESET_INSURANCE_SPEND_RECEIPT_DATA, (state) => {
      state.insuranceSpendReceiptData = null;
    });
    builder.addCase(getAllSpendInsuranceReceiptsByUserId.pending, (state) => {
      // Optionally set loading state
    });
    builder.addCase(
      getAllSpendInsuranceReceiptsByUserId.fulfilled,
      (state, action) => {
        console.log("Spend insurance receipts by user ID:", action.payload);
        state.allSpendInsuranceReceipts = action.payload;
      }
    );
    builder.addCase(
      getAllSpendInsuranceReceiptsByUserId.rejected,
      (state, action) => {
        if (
          action.payload &&
          typeof action.payload === "object" &&
          "message" in action.payload
        ) {
          console.warn(action.payload.message);
          state.allSpendInsuranceReceipts = [];
        } else {
          console.error(
            "Error fetching insurance receipts by user ID:",
            action.payload
          );
          state.allSpendInsuranceReceipts = [];
        }
      }
    );
  },
});

export const { insuranceSpendReceipt } = insuranceSpendReceiptSlice.actions;
export default insuranceSpendReceiptSlice.reducer;
