import React, { useState } from "react";
import { Formik, Field } from 'formik';

const ContractCancellationResultPage: React.FC = () => {
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState(false);

  const [showFromDatePicker, setShowFromDatePicker] = useState<boolean>(false);
  const [showToDatePicker, setShowToDatePicker] = useState<boolean>(false);
  const [fromDate, setFromDate] = useState<string>("");
  const [toDate, setToDate] = useState<string>("");

  const handleCheckboxChange = (itemId: number) => {
    if (selectedItems.includes(itemId)) {
      // Item is already selected, remove it
      setSelectedItems((prevSelectedItems) =>
        prevSelectedItems.filter((id) => id !== itemId)
      );
    } else {
      // Item is not selected, add it
      setSelectedItems((prevSelectedItems) => [...prevSelectedItems, itemId]);
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      // Deselect all items
      setSelectedItems([]);
    } else {
      // Select all items
      setSelectedItems([1, 2, 3, 4]); // Replace with your actual item IDs
    }
    setSelectAll(!selectAll);
  };

  const handleFromButtonClick = () => {
    setShowFromDatePicker(!showFromDatePicker);
  };

  const handleToButtonClick = () => {
    setShowToDatePicker(!showToDatePicker);
  };

  const handleDateChange = (date: string) => {
    setFromDate(date);
    setShowFromDatePicker(false);

    // Add your logic here if needed
  };

  const handleToDateChange = (date: string) => {
    setToDate(date);
    setShowToDatePicker(false);

    // Add your logic here if needed
  };

  const handleCancel = () => {
    // Handle cancel logic
    return;
  };

  return (
    <div className="flex flex-col p-6">
      <div className="flex bg-F5F7FA">
        {/* From Date Picker */}
        <div className="relative w-1/5 p-4">
          <button
            type="button"
            className="font-bold text-white bg-gray-800 text-lg w-[165px] h-[50px] border-none cursor-pointer text-16 transition duration-300 ease-in-out hover:from-blue-900 hover:to-blue-500"
            onClick={handleFromButtonClick}
          >
            {fromDate || "From"}
          </button>

          {showFromDatePicker && (
            <input
              type="date"
              value={fromDate}
              onChange={(e) => handleDateChange(e.target.value)}
              onBlur={() => setShowFromDatePicker(false)}
              className="absolute top-full left-0 mt-2 p-2 w-[165px] border border-gray-400 rounded bg-white"
            />
          )}
        </div>

        {/* To Date Picker */}
        <div className="relative w-1/5 p-4 -ml-36">
          <button
            type="button"
            className="font-bold text-white bg-gray-800 text-lg w-[165px] h-[50px] border-none cursor-pointer text-16 transition duration-300 ease-in-out hover:from-blue-900 hover:to-blue-500"
            onClick={handleToButtonClick}
          >
            {toDate || "To"}
          </button>

          {showToDatePicker && (
            <input
              type="date"
              value={toDate}
              onChange={(e) => handleToDateChange(e.target.value)}
              onBlur={() => setShowToDatePicker(false)}
              className="absolute top-full left-0 mt-2 p-2 w-[165px] border border-gray-400 rounded bg-white"
            />
          )}
        </div>

        {/* Cancel Button */}
        <div className="w-1/5 p-4 ml-auto -mr-32">
          <button
            type="button"
            className="bg-gradient-to-b from-blue-900 to-blue-500 font-bold text-lg bg-gray-800 text-white w-[165px] h-[50px] border-none cursor-pointer text-16 transition duration-300 ease-in-out hover:from-blue-900 hover:to-blue-500"
            onClick={handleCancel}
          >
            Cancel
          </button>
        </div>
      </div>

      <div>
        {/* Table with checkboxes */}
        <table className="w-full mt-4">
          <thead>
            <tr className="bg-gray-800 text-white">
              <th className="w-[40px] p-2">
                {/* Select All checkbox */}
                <input
                  type="checkbox"
                  checked={selectAll}
                  onChange={handleSelectAll}
                  className="mr-8"
                />
              </th>
              <th>First Side (Seller)</th>
              <th>Phone No.</th>
              <th>Identity No.</th>
              <th>Contract No.</th>
              <th>Second Side</th>
              <th>Phone No.</th>
              <th>Identity No.</th>
              <th>Contract Type</th>
              {/* Add more columns as needed */}
            </tr>
          </thead>
          <tbody>
            {/* Sample table rows with checkboxes */}
            {[1, 2, 3, 4].map((item) => (
              <tr key={item} className="align-middle">
                <td>
                  <input
                    type="checkbox"
                    checked={selectedItems.includes(item)}
                    onChange={() => handleCheckboxChange(item)}
                  />
                </td>
                <td className="text-center">Data 1</td>
                <td className="text-center">Data 2</td>
                <td className="text-center">Data 1</td>
                <td className="text-center">Data 2</td>
                <td className="text-center">Data 1</td>
                <td className="text-center">Data 2</td>
                <td className="text-center">Data 1</td>
                <td className="text-center">Data 2</td>
                {/* Add more cells with data as needed */}
              </tr>
            ))}
          </tbody>

        </table>
      </div>
      <div className="mt-6">
          <Formik
            initialValues={{ cells: { '3': '', '4': '' } }}
            onSubmit={(values) => {
              // Handle form submission
              console.log(values);
            }}
          >
          {({ handleSubmit, getFieldProps }) => (
          <form onSubmit={handleSubmit}>
            <div className="flex">
              <div className="w-1/2 mt-5">
                {/* Content for the first column */}
                  <div className="flex-1">
                    {/* Content for the second row */}
                    <label htmlFor="cells.3" className="font-bold text-gray-800">
                      Cancellation Reason
                    </label>
                    <Field
                      as="select"
                      id="cells.3"
                      name="cells.3"
                      className="block w-1/2 border border-gray-400 mt-[15px] p-2 text-white bg-gray-800"
                    >
                      <option value="" label="Select Reason" />
                      <option value="option1" label="Option 1" />
                      <option value="option2" label="Option 2" />
                      {/* Add more options as needed */}
                    </Field>
                  </div>
              </div>
              <div className="w-full p-4 mt-7 -ml-72">
                {/* Content for the second column */}
                <Field
                  type="text"
                  id="cells.4"
                  name="cells.4"
                  className="block w-full border border-gray-400 mt-[15px] p-2 text-gray-800"
                  placeholder="Write Down Cancellation Reason In Detail"
                />
              </div>
            </div>
          </form>
          )}
        </Formik>
    </div>
  </div>
  );
};

export default ContractCancellationResultPage;
