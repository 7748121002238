import React from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const validationSchema = Yup.object().shape({
  cells: Yup.array().of(
    Yup.lazy((value) => {
      if (typeof value === "number") {
        return Yup.number().typeError("Must be a number").nullable();
      } else if (/^\d{10}$/.test(value)) {
        return Yup.string()
          .required("This field is required")
          .matches(/^\d{10}$/, "Invalid phone number");
      }
      return Yup.string().required("This field is required");
    })
  ),
});

const ContractCancellationSearchPage = () => {
  const Navigate = useNavigate();
  const { t } = useTranslation();

  const handleSearch = () => {
    Navigate("/contract-cancellation-result");
  };

  return (
    <Formik
      initialValues={{
        cells: Array.from({ length: 7 * 4 }, (_, index) =>
          index === 0 ? "" : null
        ),
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        // Handle form submission
        console.log(values);
      }}
    >
      <Form>
        <div className="flex justify-center">
          <div className="mt-16">
            <p className="text-2xl font-bold text-black">{t("Cancellation")}</p>
          </div>
        </div>
        <div className="flex justify-center items-center">
          <div className="grid grid-cols-4 gap-y-4 p-6">
            <div className="p-6">
              <label htmlFor="cells.0" className="font-bold">
                {t("First Side (Seller)")}
              </label>
              <Field
                as="input"
                id="cells.0"
                name="cells.0"
                type="text"
                placeholder="Full Name"
                className="block w-full md:w-72 border border-gray-400 mt-2 p-2"
              />
            </div>
            <div className="p-6">
              <label htmlFor="cells.2" className="font-bold">
                {t("Phone Number")}
              </label>
              <Field
                as="input"
                id="cells.2"
                name="cells.2"
                type="number"
                placeholder="Phone No."
                className="block w-full md:w-72 border border-gray-400 mt-2 p-2"
              />
            </div>
            <div className="p-6">
              <label htmlFor="identityNo" className="font-bold">
                {t("Identity Number")}
              </label>
              <div className="flex items-center">
                <Field
                  as="input"
                  id="identityNo"
                  name="identityNo"
                  type="number"
                  placeholder="Identity No."
                  className="block w-full md:w-72 border border-gray-400 mt-2 p-2"
                />
              </div>
            </div>
            <div className="p-6">
              <label htmlFor="contractNo" className="font-bold">
                {t("Contract No")}
              </label>
              <div className="flex items-center">
                <Field
                  as="input"
                  id="contractNo"
                  name="contractNo"
                  type="number"
                  placeholder="Contract No."
                  className="block w-full md:w-72 border border-gray-400 mt-2 p-2"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center items-center">
          <div className="grid grid-cols-4 gap-y-4 p-6">
            <div className="p-6">
              <label htmlFor="cells.0" className="font-bold">
                {t("First Side (Seller)")}
              </label>
              <Field
                as="input"
                id="cells.0"
                name="cells.0"
                type="text"
                placeholder="Full Name"
                className="block w-full md:w-72 border border-gray-400 mt-2 p-2"
              />
            </div>
            <div className="p-6">
              <label htmlFor="cells.2" className="font-bold">
                {t("Phone Number")}
              </label>
              <Field
                as="input"
                id="cells.2"
                name="cells.2"
                type="number"
                placeholder="Phone No."
                className="block w-full md:w-72 border border-gray-400 mt-2 p-2"
              />
            </div>
            <div className="p-6">
              <label htmlFor="identityNo" className="font-bold">
                {t("Identity Number")}
              </label>
              <div className="flex items-center">
                <Field
                  as="input"
                  id="identityNo"
                  name="identityNo"
                  type="number"
                  placeholder="Identity No."
                  className="block w-full md:w-72 border border-gray-400 mt-2 p-2"
                />
              </div>
            </div>
            <div className="p-6">
              <label className="font-bold">{t("Contract Type")}</label>
              <div className="flex items-center mt-8">
                <div className="flex items-center mr-4">
                  <Field
                    type="radio"
                    id="purchasing"
                    name="contractType"
                    value="Purchasing"
                    className="mr-2"
                  />
                  <label htmlFor="purchasing">{t("Purchasing")}</label>
                </div>
                <div className="flex items-center">
                  <Field
                    type="radio"
                    id="rental"
                    name="contractType"
                    value="Rental"
                    className="mr-2"
                  />
                  <label htmlFor="rental">{t("Rental")}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center items-center mt-8">
          <div className="flex space-x-4">
            <button
              className="text-white font-bold py-2 px-4 bg-gradient-to-b bg-gray-800 hover:from-blue-900 hover:to-blue-600 h-[70px] w-[205px]"
              onClick={handleSearch}
            >
              Search
            </button>
          </div>
        </div>
      </Form>
    </Formik>
  );
};

export default ContractCancellationSearchPage;
