import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "../services/api/api";

interface ContractState {
  contractData: RentalContractData | null;
  allContractData: RentalContractData[] | null;
  loading: boolean;
  error: string | null;
}

interface RentalContractData {
  receiptNumber: number;
  customer_id: number;
  propertyOwner_id: number;
  currency_id: number;
  unitType_id: number;
  property_number_id: number;
  unitArea: number;
  contractBeginning: string;
  contractEnding: string;
  advancePayment: number;
  numberOfMonths: number;
  latePenalty: number;
  insurance: number;
  contractType: string;
  monthlyRentPayment: number;
  unitAddress: string;
  // handoverDate: string;
  handoverPenalty: number;
  project_id: number;
  courtCity: string;
  propertyType: string;
  contractLanguage: string;
  lawyerFullName: string;
  customer?: Customer;
  property_owner?: PropertyOwner;
  created_at?: string;
  note?: string;
}

interface Customer {
  id: number;
  user_id: number;
  first_name: string;
  last_name: string;
  identityNo: string;
  email: string;
  phone_number: string;
  description?: string;
  address?: string;
  city_id?: number;
  country_id?: number;
  created_at?: string;
  updated_at?: string;
}

interface PropertyOwner {
  id: number;
  first_name: string;
  last_name: string;
  identityNo: string;
  phone_number: string;
  city?: string;
  country?: string;
  email?: string;
  created_at?: string;
  updated_at?: string;
}

const initialState: ContractState = {
  contractData: null,
  allContractData: null,
  loading: false,
  error: null,
};

export const sendContractData = createAsyncThunk(
  "rentalContract/sendContractData",
  async (data: RentalContractData, thunkAPI) => {
    try {
      const response = await axios.post("/rentalContract", data);
      return response.data.data;
    } catch (error: unknown) {
      if (typeof error === "object" && error && "response" in error) {
        return thunkAPI.rejectWithValue((error as any).response.data);
      } else {
        return thunkAPI.rejectWithValue("Unknown error occurred");
      }
    }
  }
);

export const fetchAllContractData = createAsyncThunk(
  "buyingAndSellingContract/fetchAllContractData",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get("/rentalContract");
      return response.data.data;
    } catch (error: unknown) {
      if (typeof error === "object" && error && "response" in error) {
        return thunkAPI.rejectWithValue((error as any).response.data);
      } else {
        return thunkAPI.rejectWithValue("Unknown error occurred");
      }
    }
  }
);

export const fetchAllContractDataByUser = createAsyncThunk(
  "rentalContract/fetchAllContractDataByUser",
  async (userId: number, thunkAPI) => {
    try {
      const response = await axios.get("/rentalContract");
      const allContractData: RentalContractData[] = response.data.data;

      const filteredData = allContractData.filter(
        (contract) => contract?.customer?.user_id === userId
      );
      return filteredData;
    } catch (error: unknown) {
      if (typeof error === "object" && error && "response" in error) {
        return thunkAPI.rejectWithValue((error as any).response.data);
      } else {
        return thunkAPI.rejectWithValue("Unknown error occurred");
      }
    }
  }
);

export const fetchContractDataById = createAsyncThunk(
  "rentalContract/fetchContractDataById",
  async (id: number, thunkAPI) => {
    try {
      const response = await axios.get(`/rentalContract/${id}`);
      return response.data.data;
    } catch (error: unknown) {
      if (typeof error === "object" && error && "response" in error) {
        return thunkAPI.rejectWithValue((error as any).response.data);
      } else {
        return thunkAPI.rejectWithValue("Unknown error occurred");
      }
    }
  }
);

export const RESET_RENTAL_CONTRACT_DATA = "RESET_RENTAL_CONTRACT_DATA";

const rentalContractSlice = createSlice({
  name: "rentalContract",
  initialState,
  reducers: {
    rentalContract: (state, action: PayloadAction<RentalContractData>) => {
      state.contractData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(sendContractData.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(sendContractData.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(sendContractData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(fetchAllContractData.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchAllContractData.fulfilled, (state, action) => {
      state.loading = false;
      state.allContractData = action.payload;
    });
    builder.addCase(fetchAllContractData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(fetchAllContractDataByUser.pending, (state) => {
      state.allContractData = null;
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchAllContractDataByUser.fulfilled, (state, action) => {
      state.loading = false;
      state.allContractData = action.payload;
    });
    builder.addCase(fetchAllContractDataByUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(fetchContractDataById.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchContractDataById.fulfilled, (state, action) => {
      state.loading = false;
      state.contractData = action.payload;
    });
    builder.addCase(fetchContractDataById.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(RESET_RENTAL_CONTRACT_DATA, (state) => {
      state.contractData = null;
    });
  },
});

export const { rentalContract } = rentalContractSlice.actions;

export default rentalContractSlice.reducer;
