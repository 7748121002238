import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "../services/api/api";

interface SpendReceiptState {
  spendReceiptData: SpendReceiptData | null;
  allSpendReceipts: SpendReceiptData[] | null;
}

interface SpendReceiptData {
  customer_id: number;
  receiptNumber: number;
  employee_id: number;
  currency_id: number;
  project_id: number;
  unitType_id: number;
  category_id: number;
  amountOf: string;
  amountInWords: string;
  categoryUnitType: string;
  radioOption: string;
  rentingFixedAssets: string;
  note: string;
}

const initialState: SpendReceiptState = {
  spendReceiptData: null,
  allSpendReceipts: null,
};

export const sendSpendReceiptData = createAsyncThunk(
  "spendReceipt/sendSpendReceiptData",
  async (receiptData: SpendReceiptData, thunkAPI) => {
    try {
      const response = await axios.post("/spendReceipt", receiptData);
      return response.data.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getAllSpendReceipts = createAsyncThunk(
  "receiveReceipt/getAllSpendReceipts",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get("/spendReceipt");
      return response.data.data;
    } catch (error: unknown) {
      console.error("Error fetching all spend  receipts:", error);
      return thunkAPI.rejectWithValue("Error fetching all spend  receipts");
    }
  }
);

export const getAllSpendReceiptsByUserId = createAsyncThunk(
  "spendReceiptByUserId/getAllSpendReceiptsByUserId",
  async (userId: number, thunkAPI) => {
    try {
      const response = await axios.get(`/spendReceipt/user/${userId}`);
      const data = response.data.data;

      if (data.length === 0) {
        return thunkAPI.rejectWithValue({
          message: "No receipts found for this user.",
          data: [],
        });
      }

      return data;
    } catch (error: unknown) {
      console.error("Error fetching receipts by user ID:", error);
      return thunkAPI.rejectWithValue("Error fetching  receipts by user ID");
    }
  }
);

export const RESET_SPEND_RECEIPT_DATA = "RESET_SPEND_RECEIPT_DATA";

const spendReceiptSlice = createSlice({
  name: "spendReceipt",
  initialState,
  reducers: {
    spendReceipt: (state, action: PayloadAction<SpendReceiptData>) => {
      state.spendReceiptData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendSpendReceiptData.fulfilled, (state, action) => {
        state.spendReceiptData = action.payload;
      })
      .addCase(sendSpendReceiptData.rejected, (state, action) => {});
    builder.addCase(RESET_SPEND_RECEIPT_DATA, (state) => {
      state.spendReceiptData = null;
    });
    builder.addCase(getAllSpendReceipts.fulfilled, (state, action) => {
      state.allSpendReceipts = action.payload;
    });
    builder.addCase(getAllSpendReceiptsByUserId.pending, (state) => {});
    builder.addCase(getAllSpendReceiptsByUserId.fulfilled, (state, action) => {
      state.allSpendReceipts = action.payload;
    });
    builder.addCase(getAllSpendReceiptsByUserId.rejected, (state, action) => {
      if (
        action.payload &&
        typeof action.payload === "object" &&
        "message" in action.payload
      ) {
        console.warn(action.payload.message);
        state.allSpendReceipts = [];
      } else {
        console.error("Error fetching  receipts by user ID:", action.payload);
        state.allSpendReceipts = [];
      }
    });
  },
});

export const { spendReceipt } = spendReceiptSlice.actions;

export default spendReceiptSlice.reducer;
