import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface BottomNavigationButtonProps {
  content: string;
  to: string;
}

const BottomNavigationButton: React.FC<BottomNavigationButtonProps> = ({
  content,
  to,
}) => {
  const { t } = useTranslation();
  const location = useLocation();

  const isActive = location.pathname === to;

  return (
    <Link to={to} className="flex justify-center">
      <button
        className="text-center w-[11rem] h-[3.4rem] p-4"
        style={
          {
            // background: `linear-gradient(to top, rgba(225, 227, 230, 1) 0%, rgba(225, 227, 230, 0.0) 100%)`,
          }
        }
      >
        <p
          style={{ backgroundColor: isActive ? "#00439C" : "#ffffffff" }}
          className={`w-[9rem] h-8 flex items-center justify-center ${isActive ? "text-white" : "text-black"}`}
        >
          {t(content)}
        </p>
      </button>
    </Link>
  );
};

export default BottomNavigationButton;
