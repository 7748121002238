import React from "react";

interface LogoProps {
  src: string | undefined;
  onClick?: () => void;
}

const Logo: React.FC<LogoProps> = ({ src, onClick }) => {
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a href="#" onClick={onClick}>
      <img
        src={src}
        alt="Logo"
        style={{ maxWidth: "11%", marginBottom: "-0.5rem", height: "auto" }}
      />
    </a>
  );
};

export default Logo;
