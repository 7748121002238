import React, { useState, useMemo, useCallback, lazy, Suspense } from "react";
import { ErrorMessage, FieldProps, FormikProps } from "formik";
import styles from "../../pages/receiveReceipts/ReceieveReceipts.module.scss";
import { useTranslation } from "react-i18next";
import axios from "../../services/api/api";

// Lazy loading for react-select components
const CreatableSelect = lazy(() => import("react-select/creatable"));
const Select = lazy(() => import("react-select"));

interface Option {
  label: string;
  value: string | number;
  key: string;
}

interface CustomSelectProps {
  field: FieldProps["field"];
  form: FormikProps<any>;
  options: Option[];
  labelName: string;
  name?: string;
  onChange?: (selectedValue: string | number | null) => void;
  creatable?: boolean;
  mt?: number;
  dispatchAction?: () => void;
}

const CustomSelect: React.FC<CustomSelectProps> = ({
  field,
  form,
  options,
  labelName,
  name,
  onChange,
  creatable = false,
  mt,
  dispatchAction,
  ...props
}) => {
  const { t } = useTranslation();

  const capitalizeFirstLetter = (word: string) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  };

  const formattedLabel = useMemo(
    () =>
      labelName
        .split(/(?=[A-Z])/)
        .map((word: string) => capitalizeFirstLetter(word))
        .join(" "),
    [labelName]
  );

  const [selectOptions, setSelectOptions] = useState<Option[]>(options);

  useMemo(() => {
    setSelectOptions(options);
  }, [options]);

  const handleChange = useCallback(
    async (selectedOption: Option | any) => {
      if (selectedOption.__isNew__) {
        if (creatable) {
          const newOption = {
            label: selectedOption.label,
            value: selectedOption.value,
            key: `${selectedOption.value}_${selectOptions.length}`,
          };

          setSelectOptions((prevOptions) => [...prevOptions, newOption]);

          form.setFieldValue(field.name, selectedOption.value);

          if (onChange) {
            onChange(selectedOption.value);
          }

          try {
            const response = await axios.post(`/${labelName}`, {
              name: selectedOption.value,
            });
            if (dispatchAction) {
              dispatchAction();
            }
            console.log("New data saved successfully:", response.data);
          } catch (error) {
            console.error("Error saving new data:", error);
          }
        }
      } else {
        form.setFieldValue(field.name, selectedOption.value);

        if (onChange) {
          onChange(selectedOption.value);
        }
      }
    },
    [
      creatable,
      dispatchAction,
      field.name,
      form,
      labelName,
      onChange,
      selectOptions.length,
    ]
  );

  return (
    <div className={`${styles["col-span-1"]} ${styles["form-group"]}`}>
      <label htmlFor={name} className={styles.label}>
        {t(formattedLabel)}
      </label>
      <Suspense fallback={<div>Loading...</div>}>
        {creatable ? (
          <CreatableSelect
            {...props}
            className={`block w-full mt-${mt} border border-gray-400 custom-select-${selectOptions.length}`}
            id={name}
            name={name}
            placeholder={t("Select The")}
            options={selectOptions}
            value={
              selectOptions.find((opt) => opt.value === field.value) || null
            }
            onChange={handleChange}
            isSearchable
            components={{ DropdownIndicator: null }}
          />
        ) : (
          <Select
            {...props}
            className={`block w-full mt-${mt} border border-gray-400 custom-select-${selectOptions.length}`}
            id={name}
            name={name}
            placeholder={t("Select The")}
            options={selectOptions}
            value={
              selectOptions.find((opt) => opt.value === field.value) || null
            }
            onChange={handleChange}
            isSearchable
            components={{ DropdownIndicator: null }}
          />
        )}
      </Suspense>
      <ErrorMessage name={labelName} component="div" className={styles.error} />
    </div>
  );
};

export default CustomSelect;
