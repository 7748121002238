import React, { useEffect } from "react";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./SpendReceiptsPage.module.scss";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import {
  Formik,
  Field,
  Form,
  FormikHelpers,
  ErrorMessage,
  FieldProps,
} from "formik";
import CustomSelect from "../../components/custom/CustomSelect";
import { useTranslation } from "react-i18next";
import useTranslatedOptions from "../../translatedOptions";
import CustomerModal from "../../components/common/customerModal/customerModal";
import { RootState } from "../../redux/store";
import { fetchCustomersAsync } from "../../redux/customerSlice";
import { fetchEmployeesAsync } from "../../redux/employeeSlice";
import { ThunkDispatch } from "redux-thunk";
import { spendReceipt } from "../../redux/spendReceiptSlice";
// @ts-ignore
import KurdishSoraniNumbersToWords from "kurdish-numbers-to-words/Sorani.js";
import { fetchCountriesAsync } from "../../redux/countrySlice";
import { fetchCitiesAsync } from "../../redux/citySlice";
import { fetchCurrenciesAsync } from "../../redux/currencySlice";
import { generateReceiptNumber } from "../../utils/helpers";
import { fetchPropertyNumbersAsync } from "../../redux/propertyNumberSlice";
import { fetchUnitTypesAsync } from "../../redux/unitTypeSlice";
import { fetchProjectsAsync } from "../../redux/projectSlice";
import BottomNavigationButton from "../../components/common/bottomNavigationButton/BottomNavigationButton";
import { toast } from "react-toastify";

const { useState } = React;

interface Values {
  customer_id: number;
  receiptNumber: number;
  project_id: number;
  unitType_id: number;
  employee_id: number;
  amountOf: string;
  amountInWords: string;
  property_number_id: string;
  currency_id: number;
  category_id: number;
  categoryUnitType: string;
  radioOption: string;
  rentingFixedAssets: string;
  note: string;
}

const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

const SpendReceipts: React.FC = () => {
  const dispatch: ThunkDispatch<RootState, any, any> = useDispatch();
  const countries = useTypedSelector((state) => state.country.countries);
  const currencies = useTypedSelector((state) => state.currency.currencies);
  const cities = useTypedSelector((state) => state.city.cities);
  const projects = useTypedSelector((state) => state.project.projects);
  const unitTypes = useTypedSelector((state) => state.unitType.unitTypes);
  const propertyNumbers = useTypedSelector(
    (state) => state.propertyNumber.propertyNumbers
  );
  const profile = useTypedSelector((state) => state.profile.profile);
  const employees = useTypedSelector((state) => state.employee.employees);
  const customers = useTypedSelector((state) => state.customer.customers);

  const { t, i18n } = useTranslation();

  const language = i18n.language;

  const validationSchema = Yup.object({
    // spendTo: Yup.string().required(t("Full Name is required")),
    // receiver: Yup.string().required("Full Name is required"),
    // receptor: Yup.string().required("Full Name is required"),
    // currency: Yup.string().required("Required"),
    // category: Yup.string().required("Required"),
  });

  useEffect(() => {
    dispatch(fetchCustomersAsync());
    dispatch(fetchEmployeesAsync());
    dispatch(fetchCountriesAsync());
    dispatch(fetchCurrenciesAsync());
    dispatch(fetchCitiesAsync());
    dispatch(fetchUnitTypesAsync());
    dispatch(fetchPropertyNumbersAsync());
    dispatch(fetchProjectsAsync());
  }, [dispatch]);

  const [employeeId, setEmployeeId] = useState<number | null>(null);

  useEffect(() => {
    if (profile) {
      const employee = employees.find((emp) => emp?.user_id === profile?.id);
      if (employee) {
        setEmployeeId(employee.id);
      }
    }
  }, [profile, employees]);

  console.log("employeeId", employeeId, "employees", employees);

  const navigate = useNavigate();

  const location = useLocation();
  const availableReceiptData = location.state?.availableReceiptData;

  const [isModalOpen, setModalOpen] = useState(false);

  // const [receiver, setReceiver] = useState("");
  const [modalTitle, setModalTitle] = useState<string>("");

  const [selectedCurrency, setSelectedCurrency] = useState<number>(
    availableReceiptData?.currency_id ?? 2
  );
  const [selectedCategory, setSelectedCategory] = useState(
    availableReceiptData?.category_id
  );

  // const [showRentsOptions, setShowRentsOptions] = useState(false);
  // const [showRentingFixedAssets, setShowRentingFixedAssets] = useState(false);
  // const [showUses, setShowUses] = useState(false);
  // const [selectedRadio, setSelectedRadio] = useState("");
  // const [
  //   showAdvertisingPrintingHospitality,
  //   setShowAdvertisingPrintingHospitality,
  // ] = useState(false);
  // const [showMiscellaneous, setShowMiscellaneous] = useState(false);
  // const [
  //   showMiscellaneousServiceExpenses,
  //   setShowMiscellaneousServiceExpenses,
  // ] = useState(false);
  // const [showFuelAndOils, setShowFuelAndOils] = useState(false);
  // const [
  //   showTransportationDispatchCommunications,
  //   setShowTransportationDispatchCommunications,
  // ] = useState(false);

  const handleCurrencyChange = (currencyId: number) => {
    setSelectedCurrency(currencyId);
  };

  const handleCategoryChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    const selectedCategory = event.target.value;
    setSelectedCategory(selectedCategory);

    // setShowRentsOptions(selectedCategory === "5");
    // setShowRentingFixedAssets(selectedCategory === "7");
    // setShowUses(selectedCategory === "8");
    // setShowAdvertisingPrintingHospitality(selectedCategory === "10");
    // setShowMiscellaneous(selectedCategory === "11");
    // setShowMiscellaneousServiceExpenses(selectedCategory === "12");
    // setShowFuelAndOils(selectedCategory === "13");
    // setShowTransportationDispatchCommunications(selectedCategory === "14");
  };

  const { categoriesOfSpend } = useTranslatedOptions();

  const [amountOf, setAmountOf] = useState<string>(
    availableReceiptData?.amountOf
  );
  const [amountInWords, setAmountInWords] = useState<string>(
    availableReceiptData?.amountInWords
  );

  const handleAmountOfChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setAmountOf(value);
    const parsedAmount = parseInt(value, 10);
    if (!isNaN(parsedAmount)) {
      const words = KurdishSoraniNumbersToWords.convert(parsedAmount);
      setAmountInWords(words);
    }
  };

  const initialValues = availableReceiptData
    ? {
        customer_id: availableReceiptData.customer_id || 2,
        employee_id: availableReceiptData.employee_id || 1,
        project_id: availableReceiptData.project_id || 1,
        unitType_id: availableReceiptData.unitType_id || 1,
        property_number_id: availableReceiptData.property_number_id || 1,
        receiptNumber: availableReceiptData.receiptNumber || 1,
        amountOf: availableReceiptData.amountOf || "",
        amountInWords: availableReceiptData.amountInWords || "",
        category_id: availableReceiptData.category_id || 1,
        currency_id: availableReceiptData.currency_id || 2,
        categoryUnitType: availableReceiptData.categoryUnitType || "",
        radioOption: availableReceiptData.radioOption || "",
        rentingFixedAssets: availableReceiptData.rentingFixedAssets || "",
        note: availableReceiptData.note || "",
      }
    : {
        customer_id: 0,
        employee_id: 1,
        project_id: 0,
        unitType_id: 0,
        property_number_id: 0,
        receiptNumber: 1,
        amountOf: "",
        amountInWords: "",
        category_id: 1,
        currency_id: 2,
        categoryUnitType: "",
        radioOption: "",
        rentingFixedAssets: "",
        note: "",
      };

  // function setSubmitting(arg0: boolean) {
  //   throw new Error("Function not implemented.");
  // }

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values: Values, formikHelpers: FormikHelpers<Values>) => {
          if (!amountOf) {
            toast.error(t("amount of is required"));
            return;
          }
          if (!parseInt(selectedCategory)) {
            toast.error(t("category is required"));
            return;
          }
          if (selectedCurrency !== undefined) {
            values.currency_id = selectedCurrency;
          }
          values.employee_id = profile?.id ?? 1;
          values.amountOf = amountOf;
          values.amountInWords = amountInWords;
          values.category_id = parseInt(selectedCategory);
          values.receiptNumber = generateReceiptNumber();
          dispatch(spendReceipt(values));
          navigate("/spend-receipt-preview");
          setTimeout(() => {
            //alert(JSON.stringify(values, null, 2));
            // setSubmitting(false);
          }, 500);
        }}
      >
        <div className="flex flex-col">
          <Form className={styles.grid} title={t("Spend Receipt")}>
            <Field name="customer_id" key={Math.random()}>
              {({ field, form }: FieldProps<string>) => (
                <CustomSelect
                  field={field}
                  form={form}
                  options={customers.map((propertyOwner) => ({
                    label: `${propertyOwner.firstName + " "}${propertyOwner.lastName}`,
                    value: propertyOwner.id,
                    key: `${propertyOwner.firstName + " "}${propertyOwner.lastName}`,
                  }))}
                  labelName="spendTo"
                  name="customer_id"
                  creatable={false}
                />
              )}
            </Field>
            <div className={`${styles["col-span-1"]} ${styles["form-group"]}`}>
              <label htmlFor="amountOf" className={styles.label}>
                {t("Amount Of")}
              </label>
              <Field
                id="amountOf"
                name="amountOf"
                placeholder={t("")}
                className={styles["input-field"]}
                onChange={handleAmountOfChange}
                value={amountOf}
              />
              <ErrorMessage
                name="amountOf"
                component="div"
                className={styles.error}
              />
            </div>
            <div className={`${styles["col-span-1"]} ${styles["form-group"]}`}>
              <label htmlFor="amountInWords" className={styles.label}>
                {t("Amount In Words")}
              </label>
              <Field
                id="amountInWords"
                name="amountInWords"
                placeholder={t("Amount of Paid to Us")}
                className={styles["input-field"]}
                value={amountInWords}
                readOnly
              />
              <ErrorMessage
                name="amountInWords"
                component="div"
                className={styles.error}
              />
            </div>
            {/* <div className={`${styles["col-span-1"]} ${styles["form-group"]}`}>
            <label htmlFor="receiver" className={styles.label}>
              {t("Receiver")}
            </label>
            <Field
              id="receiver"
              name="receiver"
              placeholder={t("Full Name")}
              value={receiver}
              className={styles["input-field"]}
            />
            <ErrorMessage
              name="receiver"
              component="div"
              className={styles.error}
            />
          </div> */}
            <div className={`${styles["col-span-1"]} ${styles["form-group"]}`}>
              <label htmlFor="spender" className={styles.label}>
                {t("Spender")}
              </label>
              <Field
                id="spender"
                name="spender"
                placeholder="Full Name"
                value={profile?.name}
                disabled
                className={styles["input-field"]}
              />
              <ErrorMessage
                name="spender"
                component="div"
                className={styles.error}
              />
            </div>
            <div className={`${styles["col-span-1"]} ${styles["form-group"]}`}>
              <label htmlFor="category" className={styles.label}>
                <span className={styles.for}>{t("For")} </span>
                <span className={styles.database}>
                  {t("Store In Database")}
                </span>
              </label>
              <Field
                as="select"
                id="category"
                name="category"
                value={selectedCategory}
                onChange={handleCategoryChange}
                className={`${styles["input-field"]} ${styles["black-background"]}`}
              >
                {categoriesOfSpend.map((category) => (
                  <option
                    key={category.value}
                    value={category.id}
                    label={category.label}
                  >
                    {category.label}
                  </option>
                ))}
              </Field>
              <ErrorMessage
                name="category"
                component="div"
                className={styles.error}
              />
            </div>
            <div
              className={`${styles["col-span-1"]} ${styles["form-group"]} ml-0 mt-[1.9rem]`}
            >
              <div
                onClick={() => {
                  setModalTitle(t("Create A New Property Owner"));
                  setModalOpen(true);
                }}
                className={`${styles["black-background"]} text-white w-[auto] h-[2.67rem] border-none cursor-pointer text-16}`}
              >
                <p className="pt-3 pb-2 pl-2 pr-2 text-center">
                  {t("Create A New Property Owner")}
                </p>
              </div>
            </div>
            <Field name="project_id" key={Math.random()}>
              {({ field, form }: FieldProps<string>) => (
                <CustomSelect
                  field={field}
                  form={form}
                  options={projects.map((project, index) => ({
                    label: project.name,
                    value: project.id,
                    key: `${project.name}_${index}`,
                  }))}
                  labelName="projects"
                  name="project_id"
                  creatable={true}
                  mt={2}
                  dispatchAction={() => dispatch(fetchProjectsAsync())}
                />
              )}
            </Field>
            <div className={`${styles["col-span-1"]} ${styles["form-group"]}`}>
              <label className={styles.label}>{t("Currency")}</label>
              <div className={styles["currency-toggle"]}>
                {currencies?.map((currency) => (
                  <button
                    key={currency.id}
                    type="button"
                    onClick={() => handleCurrencyChange(currency.id)}
                    className={`${styles["currency-button"]} ${
                      selectedCurrency === currency.id
                        ? styles["currency-selected"]
                        : ""
                    }`}
                  >
                    {currency.name}
                  </button>
                ))}
              </div>
            </div>
            <div
              className={`flex space-x-6 ${language === "en" ? "flex-row" : "flex-row-reverse"}`}
            >
              <div className={`w-[12.7rem] ${styles["form-group"]}`}>
                <Field name="unitType_id" key={Math.random()}>
                  {({ field, form }: FieldProps<string>) => (
                    <CustomSelect
                      field={field}
                      form={form}
                      options={unitTypes.map((unitType, index) => ({
                        label: unitType.name,
                        value: unitType.id,
                        key: `${unitType.name}_${index}`,
                      }))}
                      labelName="unitType"
                      name="unitType_id"
                      creatable={true}
                      dispatchAction={() => dispatch(fetchUnitTypesAsync())}
                    />
                  )}
                </Field>
              </div>
              <div className={`w-[12.7rem] ${styles["form-group"]}`}>
                <Field name="property_number_id" key={Math.random()}>
                  {({ field, form }: FieldProps<string>) => (
                    <CustomSelect
                      field={field}
                      form={form}
                      options={propertyNumbers.map((propertyNumber, index) => ({
                        label: propertyNumber.name,
                        value: propertyNumber.id,
                        key: `${propertyNumber.name}_${index}`,
                      }))}
                      labelName="propertyNumber"
                      name="property_number_id"
                      creatable={true}
                      dispatchAction={() =>
                        dispatch(fetchPropertyNumbersAsync())
                      }
                    />
                  )}
                </Field>
              </div>
            </div>
            <div className={`${styles["col-span-1"]} ${styles["form-group"]}`}>
              <label htmlFor="note" className={styles.label}>
                {t("Note")}
              </label>
              <Field
                id="note"
                name="note"
                placeholder={t("Write something")}
                className={styles["input-field"]}
              />
              <ErrorMessage
                name="note"
                component="div"
                className={styles.error}
              />
            </div>
            <div
              className={`${styles["col-span-3"]} ${styles["form-group"]} ${styles["centered-container"]}`}
            >
              <button
                type="submit"
                className="bg-gradient-to-b from-blue-900 to-blue-500 text-white w-[245px] h-[55px] border-none cursor-pointer text-16 -mt-8"
              >
                {t("Preview")}
              </button>
            </div>
          </Form>
        </div>
      </Formik>
      <CustomerModal
        isModalOpen={isModalOpen}
        setModalOpen={setModalOpen}
        title={modalTitle}
        countries={countries}
        cities={cities}
        dispatchAction={() => {
          setTimeout(() => {
            dispatch(fetchCustomersAsync());
          }, 2000);
        }}
      />
      <div className="flex justify-center">
        <BottomNavigationButton content="Main Menu" to="/main" />
        <BottomNavigationButton content="Finance" to="/finance" />
        <BottomNavigationButton content="Receipts" to="/receipts" />
        <BottomNavigationButton content="Spend Receipt" to="/spend-receipt" />
      </div>
    </>
  );
};

export default SpendReceipts;
