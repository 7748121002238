import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./ReceiptsPage.module.scss";
import MacbookButton from "../../components/common/macbookButton/MacbookButton";
import {
  FileText,
  DollarSign,
  Shield,
  Clipboard,
  Briefcase,
} from "react-feather";
import BottomNavigationButton from "../../components/common/bottomNavigationButton/BottomNavigationButton";

const ReceiptsPage: React.FC = () => {
  const navigate = useNavigate();

  const handleReceiveReceipt = () => {
    navigate("/receive-receipt");
  };

  const handleCommissionReceiveReceipt = () => {
    navigate("/commission-receive-receipt");
  };

  const handleInsuranceReceiveReceipt = () => {
    navigate("/insurance-receive-receipt");
  };

  const handleRentalReceiveReceipt = () => {
    navigate("/rental-receive-receipt");
  };

  const handleSpendReceipt = () => {
    navigate("/spend-receipt");
  };

  const handleCommissionSpendReceipt = () => {
    navigate("/commission-spend-receipt");
  };

  const handleInsuranceSpendReceipt = () => {
    navigate("/insurance-spend-receipt");
  };

  const handleRentalSpendReceipt = () => {
    navigate("/rental-spend-receipt");
  };

  const isSmallScreen = window.innerWidth <= 1366 && window.innerHeight <= 1024;

  return (
    <>
      <div className={styles["center-container"]}>
        <div className={styles["grid-container"]}>
          <MacbookButton
            width={isSmallScreen ? "13rem" : undefined}
            fontSize={isSmallScreen ? "12.9px" : undefined}
            onClick={handleReceiveReceipt}
            buttonText="Receive Receipt"
            icon={<FileText size={20} />}
          />
          <MacbookButton
            width={isSmallScreen ? "13rem" : undefined}
            fontSize={isSmallScreen ? "12.9px" : undefined}
            onClick={handleCommissionReceiveReceipt}
            buttonText="Commission Receive Receipt"
            icon={<Clipboard size={20} />}
          />
          <MacbookButton
            width={isSmallScreen ? "13rem" : undefined}
            fontSize={isSmallScreen ? "12.9px" : undefined}
            onClick={handleInsuranceReceiveReceipt}
            buttonText="Insurance Receive Receipt"
            icon={<FileText size={20} />}
          />
          <MacbookButton
            width={isSmallScreen ? "13rem" : undefined}
            fontSize={isSmallScreen ? "12.9px" : undefined}
            onClick={handleRentalReceiveReceipt}
            buttonText="Rental Receive Receipt"
            icon={<Briefcase size={20} />}
          />
          {/* Row 2 */}
          <MacbookButton
            width={isSmallScreen ? "13rem" : undefined}
            fontSize={isSmallScreen ? "12.9px" : undefined}
            onClick={handleSpendReceipt}
            buttonText="Spend Receipt"
            icon={<DollarSign size={20} />}
          />
          <MacbookButton
            width={isSmallScreen ? "13rem" : undefined}
            fontSize={isSmallScreen ? "12.9px" : undefined}
            onClick={handleCommissionSpendReceipt}
            buttonText="Commission Spend Receipt"
            icon={<Shield size={20} />}
          />
          <MacbookButton
            width={isSmallScreen ? "13rem" : undefined}
            fontSize={isSmallScreen ? "12.9px" : undefined}
            onClick={handleInsuranceSpendReceipt}
            buttonText="Insurance Spend Receipt"
            icon={<Shield size={20} />}
          />
          <MacbookButton
            onClick={handleRentalSpendReceipt}
            buttonText="Rental Spend Receipt"
            icon={<Briefcase size={20} />}
            width={isSmallScreen ? "13rem" : undefined}
            fontSize={isSmallScreen ? "12.9px" : undefined}
          />
        </div>
      </div>
      <div className="flex justify-center">
        <BottomNavigationButton content="Main Menu" to="/main" />
        <BottomNavigationButton content="Finance" to="/finance" />
        <BottomNavigationButton content="Receipts" to="/receipts" />
      </div>
    </>
  );
};

export default ReceiptsPage;
