import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useTranslation } from "react-i18next";

interface CookieConsentNotificationProps {
  onAccept: () => void;
}

const CookieConsentNotification: React.FC<CookieConsentNotificationProps> = ({
  onAccept,
}) => {
  const [isVisible, setIsVisible] = useState(true);

  const { t } = useTranslation();

  const cookieStyle = {
    backgroundColor: "#00439C",
    cursor: "pointer",
  };

  const handleHover = () => {
    cookieStyle.backgroundColor = "#FFD700";
  };

  const handleHoverLeave = () => {
    cookieStyle.backgroundColor = "#FFFFFF";
  };

  const acceptCookies = () => {
    setIsVisible(false);
    setTimeout(() => {
      onAccept();
    }, 400);
  };

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 20 }}
          transition={{ duration: 0.4 }}
          className="fixed bottom-4 left-0 right-0 w-full max-w-screen-lg mx-auto bg-white text-black shadow-md p-3 md:p-4"
        >
          <div className="flex items-center justify-between">
            <p className="text-sm md:text-base">
              {t(
                "This system uses cookies to provide you with a personalized browsing experience. Adjust your settings by selecting 'Accept'"
              )}
              .
            </p>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              style={cookieStyle}
              onMouseEnter={handleHover}
              onMouseLeave={handleHoverLeave}
              className="text-white py-2 px-4 md:px-6 shadow-sm focus:outline-none"
              onClick={acceptCookies}
            >
              {t("Accept")}
            </motion.button>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default CookieConsentNotification;
