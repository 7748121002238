import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../services/api/api";

interface InsuranceState {
  insuranceReceiveReceiptData: InsuranceReceiveReceiptData | null;
  allReceiveInsuranceReceipts: InsuranceReceiveReceiptData[] | null;
}

interface InsuranceReceiveReceiptData {
  customer_id: number;
  receiptNumber: number;
  amountOf: string;
  amountInWords: string;
  employee_id: number;
  currency_id: number;
  property_number_id: number;
  category_id: number;
  project_id: number;
  categoryUnitType: string;
  radioOption: string;
  rentingFixedAssets: string;
  startDate: Date;
  endDate: Date;
  unitType_id: number;
  note: string;
}

const initialState: InsuranceState = {
  insuranceReceiveReceiptData: null,
  allReceiveInsuranceReceipts: null,
};

export const sendInsuranceReceiptData = createAsyncThunk(
  "insuranceReceiveReceipt/sendInsuranceReceiptData",
  async (data: InsuranceReceiveReceiptData, thunkAPI) => {
    try {
      const response = await axios.post("/receiveInsuranceReceipt", data);
      return response.data.data;
    } catch (error: unknown) {
      if (typeof error === "object" && error && "response" in error) {
        return thunkAPI.rejectWithValue(
          (error as any).response?.data ?? "Unknown error occurred"
        );
      } else {
        return thunkAPI.rejectWithValue("Unknown error occurred");
      }
    }
  }
);

export const getAllReceiveInsuranceReceipts = createAsyncThunk(
  "receiveInsuranceReceipt/getAllReceiveInsuranceReceipts",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get("/receiveInsuranceReceipt");
      return response.data.data;
    } catch (error: unknown) {
      console.error("Error fetching all receive insurance receipts:", error);
      return thunkAPI.rejectWithValue(
        "Error fetching all receive insurance receipts"
      );
    }
  }
);

export const getAllReceiveInsuranceReceiptsByUserId = createAsyncThunk(
  "receiveInsuranceReceiptByUserId/getAllReceiveInsuranceReceiptsByUserId",
  async (userId: number, thunkAPI) => {
    try {
      const response = await axios.get(
        `/receiveInsuranceReceipt/user/${userId}`
      );
      const data = response.data.data;

      if (data.length === 0) {
        return thunkAPI.rejectWithValue({
          message: "No Insurance receipts found for this user.",
          data: [],
        });
      }

      return data;
    } catch (error: unknown) {
      console.error("Error fetching Insurance receipts by user ID:", error);
      return thunkAPI.rejectWithValue(
        "Error fetching Insurance receipts by user ID"
      );
    }
  }
);

export const RESET_RECEIVE_INSURANCE_RECEIPT_DATA =
  "RESET_RECEIVE_INSURANCE_RECEIPT_DATA";

const insuranceReceiveReceiptSlice = createSlice({
  name: "insuranceReceiveReceipt",
  initialState,
  reducers: {
    insuranceReceiveReceipt: (
      state,
      action: PayloadAction<InsuranceReceiveReceiptData>
    ) => {
      state.insuranceReceiveReceiptData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendInsuranceReceiptData.pending, (state) => {
        // Optionally set loading state or reset data
      })
      .addCase(sendInsuranceReceiptData.fulfilled, (state) => {
        // Optionally handle successful response or update state
      })
      .addCase(sendInsuranceReceiptData.rejected, (state, action) => {
        // Handle error and set error message in state
        console.error("Error sending insurance receipt data:", action.payload);
      })
      .addCase(getAllReceiveInsuranceReceipts.pending, (state) => {
        // Optionally set loading state
      })
      .addCase(getAllReceiveInsuranceReceipts.fulfilled, (state, action) => {
        state.allReceiveInsuranceReceipts = [];
        state.allReceiveInsuranceReceipts = action.payload;
      })
      .addCase(getAllReceiveInsuranceReceipts.rejected, (state, action) => {
        // Handle error
        console.error(
          "Error fetching all receive insurance receipts:",
          action.payload
        );
      })
      .addCase(RESET_RECEIVE_INSURANCE_RECEIPT_DATA, (state) => {
        // Reset the insurance receive receipt data
        state.insuranceReceiveReceiptData = null;
      })
      .addCase(getAllReceiveInsuranceReceiptsByUserId.pending, (state) => {
        // Optionally set loading state
      })
      .addCase(
        getAllReceiveInsuranceReceiptsByUserId.fulfilled,
        (state, action) => {
          console.log(
            "Received insurance receipts by user ID:",
            action.payload
          );
          state.allReceiveInsuranceReceipts = action.payload;
        }
      )
      .addCase(
        getAllReceiveInsuranceReceiptsByUserId.rejected,
        (state, action) => {
          if (
            action.payload &&
            typeof action.payload === "object" &&
            "message" in action.payload
          ) {
            console.warn(action.payload.message);
            state.allReceiveInsuranceReceipts = [];
          } else {
            // Handle other errors
            console.error(
              "Error fetching insurance receipts by user ID:",
              action.payload
            );
            state.allReceiveInsuranceReceipts = [];
          }
        }
      );
  },
});

export const { insuranceReceiveReceipt } = insuranceReceiveReceiptSlice.actions;

export default insuranceReceiveReceiptSlice.reducer;
