import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const UnauthorizedPage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="flex items-center justify-center h-screen">
      <div className="text-center">
        <h1 className="text-9xl font-black text-gray-600">401</h1>

        <p className="text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Uh-oh!
        </p>

        <p className="mt-4 text-gray-500">
          {t("You are not authorized to access this page")}.
        </p>

        <Link
          to="/main"
          className="mt-6 inline-block rounded bg-indigo-600 px-5 py-3 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring"
        >
          {t("Go Back Home")}
        </Link>
      </div>
    </div>
  );
};

export default UnauthorizedPage;
