import React, { ReactNode, useEffect, useState } from "react";

interface ReceiptPreviewLayoutProps {
  children: ReactNode;
  isAuthPage?: boolean; // Add a prop to indicate if it's the authPage
}

const ReceiptPreviewLayout: React.FC<
  ReceiptPreviewLayoutProps
> = ({ children, isAuthPage }) => {
  const [mainStyle, setMainStyle] = useState({});

  useEffect(() => {
    setMainStyle((prevStyle) =>
      isAuthPage
        ? { ...prevStyle, background: "none" }
        : {
            ...prevStyle,
            background: "none",
          }
    );
  }, [isAuthPage]);

  return (
    <div style={{ marginLeft: "6.3%" }}>
      {/* <Header showLogo={!isAuthPage} isAuthPage={!isAuthPage} /> */}
      <div className="contentContainer">
        <main className="main" style={mainStyle}>
          {children}
        </main>
      </div>
    </div>
  );
};

export default ReceiptPreviewLayout;
