import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  createSelector,
} from "@reduxjs/toolkit";
import axios from "../services/api/api";

interface User {
  id: number;
  name: string;
  username: string;
  email: string;
  phone_no: string;
  address: string | null;
  role: string;
  image: string;
  gender: boolean;
  position: string;
  join_date: string | null;
  is_active: boolean;
  ip_address: string;
}

interface UserState {
  users: User[];
  loading: "idle" | "pending" | "succeeded" | "failed";
  error: string | null;
}

const initialState: UserState = {
  users: [],
  loading: "idle",
  error: null,
};

export const fetchUsersAsync = createAsyncThunk("user/fetchUsers", async () => {
  const response = await axios.get("/users");
  return response.data as User[];
});

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsersAsync.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(
        fetchUsersAsync.fulfilled,
        (state, action: PayloadAction<User[]>) => {
          state.users = action.payload;
          state.loading = "succeeded";
          state.error = null;
        }
      )
      .addCase(fetchUsersAsync.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.error.message ?? "An error occurred";
      });
  },
});

export const selectUserById = (userId: number) =>
  createSelector(
    (state: { user: UserState }) => state.user.users,
    (users) => {
      const selectedUser = users.find((user) => user.id === userId);
      return selectedUser ? { ...selectedUser } : null;
    }
  );

export default userSlice.reducer;
