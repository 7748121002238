import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

interface City {
  id: number;
  name: string;
}

const cities: City[] = [
  {
    id: 1,
    name: "Erbil",
  },
  {
    id: 2,
    name: "Duhok",
  },
];

interface CityState {
  cities: City[];
  loading: "idle" | "pending" | "succeeded" | "failed";
  error: string | null;
}

const initialState: CityState = {
  cities: [],
  loading: "idle",
  error: null,
};

export const fetchCitiesAsync = createAsyncThunk(
  "city/fetchCities",
  async () => {
    // const response = await axios.get("/cities"); // backend endpoint
    // return response.data as Info[];
    return cities as City[];
  }
);

const citySlice = createSlice({
  name: "city",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCitiesAsync.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(
        fetchCitiesAsync.fulfilled,
        (state, action: PayloadAction<City[]>) => {
          state.cities = action.payload;
          state.loading = "succeeded";
          state.error = null;
        }
      )
      .addCase(fetchCitiesAsync.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.error.message ?? "An error occurred";
      });
  },
});

export default citySlice.reducer;
